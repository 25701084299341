import * as React from 'react';
import { useState, useEffect, useRef, Fragment } from 'react';
import { Paper, Button, FormControl, TextField, Box, Select, Typography, FormControlLabel, Checkbox, Dialog, DialogTitle, IconButton, Divider, DialogContent } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import { Clear, Close } from '@mui/icons-material';
import { RicercaAPI } from '../../../api/ricercaApi';
import { AccertamentoPrerequisitiAPI } from '../../../api/accertamentoPrerequisiti';
import { RegistroAPI } from '../../../api/registroApi';
import { getAnnoFormativo } from '../../shared/utilityMethods';

export default function FiltriElencoAccertamentoPrerequisiti(props) {

    const inputNomeAlunnoRef = useRef(null);
    const inputCfRef = useRef(null);

    const [classiList, setClassiList] = useState([]);
    const [corsoSelected, setCorsoSelected] = useState(-1);
    const [sediList, setSediList] = useState([]);
    const [sedeSelected, setSedeSelected] = useState(-1);
    const [searchStarted, setSearchStarted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [nomeAlunno, setNomeAlunno] = useState('');
    const [nomeValido, setNomeValido] = useState(true);
    const [nomeMessage, setNomeMessage] = useState('')
    const [cfAlunno, setCfAlunno] = useState('');
    const [cfValido, setCfValido] = useState(true);
    const [cfMessage, setCfMessage] = useState('')

    const [materieList, setMaterieList] = useState([]);
    const [materiaSelected, setMateriaSelected] = useState(-1);

    const [showOnlyNonValutati, setShowOnlyNonValutati] = useState(false);
    const [showBtnAccertamento, setShowBtnAccertamento] = useState(false);
    const [isAnnoFormativoCorrente, setIsAnnoFormativoCorrente] = useState(false);

    useEffect(() => {
        const annoFormativoTmp = getAnnoFormativo(true);
        const annoFormativoObj = JSON.parse(annoFormativoTmp);
        setIsAnnoFormativoCorrente(annoFormativoObj?.isAnnoFormativoCorrente ?? true)
    }, [])

    useEffect(() => {
        if (props.permessiUtente) {
            if (props.permessiUtente.some(permesso => permesso.nome === "showGestioneAccertamentoPrerequisiti" && permesso.value === true))
                setShowBtnAccertamento(true)
        };
    }, [props.permessiUtente, props.annoFormativo])

    useEffect(() => {
        if (props.annoFormativo)
            getSedi(props.annoFormativo);
    }, [props.annoFormativo])

    useEffect(() => {
        if (props.indexDialogDettaglioChiusa !== undefined && props.indexDialogDettaglioChiusa > 0)
            getElencoAccertamentoPrerequisiti();
    }, [props.indexDialogDettaglioChiusa])

    useEffect(() => {
        if (props.corsoSedeFiltro !== undefined && props.corsoSedeFiltro !== null) {
            setCorsoSelected(props.corsoSedeFiltro.corso);
            setSedeSelected(props.corsoSedeFiltro.sede);

            getElencoAccertamentoPrerequisiti(props.corsoSedeFiltro.corso, props.corsoSedeFiltro.sede);
        }
    }, [props.corsoSedeFiltro])

    function nomeSet(e) {
        if (e.target.value.length === 0) {
            setNomeAlunno('');
            setNomeValido(true);
            setNomeMessage('');
        }
        else if (e.target.value.length > 2) {
            setSearchStarted(true);
            setNomeAlunno(e.target.value);
            setNomeMessage('');
            setNomeValido(true);
        }
        else {
            setNomeValido(false);
            setNomeMessage('Min. 3 caratteri.');
        }
    };
    function cfSet(e) {
        let newCf = e.target.value;
        newCf = newCf.toUpperCase();
        inputCfRef.current.value = newCf;
        if (e.target.value.length === 0) {
            setCfAlunno('');
            setCfValido(true);
            setCfMessage('');
        }
        else if (e.target.value.length === 16) {
            setSearchStarted(true);
            setCfAlunno(e.target.value);
            setCfMessage('');
            setCfValido(true);
        }
        else {
            setCfValido(false);
            setCfMessage('Codice fiscale incorretto.');
        }
    };
    function changeSede(e) {
        if (e.target.value !== '') {
            setSearchStarted(true);
            setSedeSelected(e.target.value);
            getClassiBySede(e.target.value);
        }
        else {
            setSedeSelected(-1);
            setCorsoSelected(-1);
        }
    };
    function changeClasse(e) {
        if (e.target.value !== '') {
            setSearchStarted(true);
            setCorsoSelected(e.target.value);
            getListMaterie(e.target.value);
        }
        else {
            setCorsoSelected(-1);
        }
    };
    function changeMateria(e) {
        if (e.target.value !== '') {
            setSearchStarted(true);
            setMateriaSelected(e.target.value);
        }
        else setMateriaSelected(-1);

    };
    function changeNonValutatiToggle() {
        setSearchStarted(true);
        setShowOnlyNonValutati(prev => !prev);
    }
    function checkValidateInput() {
        if (sedeSelected !== -1 && ((nomeValido) && (cfValido))) return false
        else return true
    }
    function avviaRicerca() {
        setIsLoading(true);
        getElencoAccertamentoPrerequisiti()
    }
    function removeFilters() {
        setNomeAlunno("");
        setCfAlunno("");

        if (sediList.length === 1)
            setSedeSelected(sediList[0].id);
        else {
            setSedeSelected(-1);
            setClassiList([])
        }
        setCorsoSelected(-1);
        setMateriaSelected(-1);
        setMaterieList([]);
        setShowOnlyNonValutati(false);

        setNomeMessage("");
        setNomeValido(true);
        setCfValido(true);
        setCfMessage("");
        inputNomeAlunnoRef.current.value = "";
        inputCfRef.current.value = "";

        setSearchStarted(false);
        props.removeFilters();
    }
    function condizioneSede() {
        if (sedeSelected === -1 && nomeAlunno !== '' && nomeValido) return true
        else if (sedeSelected === -1 && cfAlunno !== '' && cfValido) return true
        else return false
    }
    function openGestioneAccertamentoDialog() {
        props.openGestioneAccertamentoDialog();
    }
    async function getSedi(annoFormativo) {

        RicercaAPI.getSedi({ annoFormativo: annoFormativo })
            .then((result) => {
                setSediList(result.sedi)
                if (result.sedi.length === 1) {
                    setSedeSelected(result.sedi[0].id);
                    getClassiBySede(result.sedi[0].id, annoFormativo);
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403)
                        if (props.logout())
                            props.logout();
                }


            })

    }
    async function getClassiBySede(idSede) {
        let dataObj = {
            idSede: idSede,
            annoFormativo: props.annoFormativo
        }

        if (props.permessiUtente.some(permesso => permesso.nome === "showGestioneAccertamentoPrerequisiti" && permesso.value === true)) {
            RicercaAPI.getClassiBySede(dataObj)
                .then((result) => {
                    setClassiList([...result.sedi])
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status === 403)
                            if (props.logout())
                                props.logout();
                    }
                })
        } else {
            RegistroAPI.getElencoClassiDocente(dataObj)
                .then((result) => {
                    const classiList = result
                        .map((corso) => ({
                            id: corso.corso.idCorso,
                            nomeCorso: corso.corso.nomeCorso,
                            codiceCorso: corso.corso.codiceCorso,
                            codiceSimon: corso.corso.codiceSimon,
                        }))
                        .filter((corso, index, self) =>
                            index === self.findIndex((c) => c.id === corso.id)
                        );
                    setClassiList([...classiList])
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status === 403)
                            if (props.logout())
                                props.logout();
                    }
                })
        }
    }
    async function getListMaterie(idCorso) {
        if (props.permessiUtente.some(permesso => permesso.nome === "showGestioneAccertamentoPrerequisiti" && permesso.value === true)) {
            RicercaAPI.getMaterieByClasse({ idCorso: idCorso })
                .then((result) => {
                    setMaterieList([...result?.materie])
                })
                .catch(function (error) {
                    if (error.response) {
                    }
                })
        } else {
            RegistroAPI.getElencoMateriePerClasseDocente({ idCorso: idCorso, showMateriaValutazione: false })
                .then((result) => {
                    setMaterieList([...result?.materie])
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status === 403)
                            if (props.logout())
                                props.logout();
                    }
                })
        }
    }
    async function getElencoAccertamentoPrerequisiti(corsoTmp, sedeTmp) {
        const dataObj = {
            "annoFormativo": props.annoFormativo,
            "idSede": sedeTmp !== undefined ? sedeTmp : sedeSelected,
            "idMateria": materiaSelected !== -1 ? materiaSelected : null,
            "idCorso": corsoTmp === undefined ? (corsoSelected !== -1 ? corsoSelected : null) : (corsoTmp !== -1 ? corsoTmp : null),
            "cognome": nomeAlunno !== "" ? nomeAlunno : null,
            "codiceFiscale": cfAlunno !== "" ? cfAlunno : null,
            "votiMancanti": showOnlyNonValutati ?? false
        }
        AccertamentoPrerequisitiAPI.elencoAccertamentPrerequisiti(dataObj)
            .then((result) => {
                if (props.getElencoAccertamentoPrerequisiti) {
                    setSearchStarted(true);
                    props.getElencoAccertamentoPrerequisiti(result?.necessita ?? [], sedeSelected)
                }
                setIsLoading(false);
            })
            .catch(function (error) {
                setIsLoading(false);
                if (error.response) {
                }
            })
    }

    return (
        <Fragment>
            <Box sx={{ width: '100%' }}>
                {showBtnAccertamento && (
                    <Button sx={{ mb: 3 }} variant="contained" color="secondary" onClick={openGestioneAccertamentoDialog}>
                        Inserisci richiesta accertamento
                    </Button>
                )}
                <Typography variant='h4'>Accertamenti richiesti e assegnazione voti</Typography>
            </Box>
            <Paper sx={{ width: '100%', mt: 2 }}>
                <form onSubmit={props.startSearch} style={{ width: '100%' }}>
                    <Grid container spacing={2} sx={{ width: '100%', pt: 2, px: 3 }}>
                        <Grid xs={12} md={3}>
                            <TextField sx={{ width: '100%' }} data-testid='nomeRicercaInput' error={(nomeValido) ? false : true}
                                label="Cognome" onChange={nomeSet} helperText={nomeMessage} inputRef={inputNomeAlunnoRef} />
                        </Grid>
                        <Grid xs={12} md={3}>
                            <TextField sx={{ width: '100%' }} data-testid='cfRicercaInput' inputRef={inputCfRef}
                                error={(cfValido) ? false : true} label="C.F." length={16} inputProps={{ maxLength: 16 }} onChange={cfSet} helperText={cfMessage} />
                        </Grid>
                        <Grid xs={12} md={3}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="sede-label">
                                    Sede
                                </InputLabel>
                                <Select disabled={sediList.length === 1 && sedeSelected !== -1} label="Sede" labelId="sede-label" value={sedeSelected} onChange={changeSede}  >
                                    <MenuItem key={-1} value={-1} >
                                    </MenuItem>
                                    {sediList.map((sede) => (
                                        <MenuItem key={sede.id} value={sede.id}  >
                                            {sede.nomeSede}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={3}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="classe-label">
                                    Corso
                                </InputLabel>
                                <Select disabled={sedeSelected !== -1 || classiList.length !== 0 ? false : true} label="Corso"
                                    labelId="classe-label" value={corsoSelected} onChange={changeClasse}  >
                                    <MenuItem key={-1} value={-1}  >
                                    </MenuItem>
                                    {classiList.map((classe) => (
                                        <MenuItem style={{ whiteSpace: 'normal' }} value={classe.id} sx={{ wordBreak: 'break-all' }}>
                                            {classe?.codiceCorso ?? ""} - {classe?.nomeCorso ?? ""}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid xs={12} md={3}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="materia-label">
                                    Materia
                                </InputLabel>
                                <Select disabled={corsoSelected !== -1 || materieList.length !== 0 ? false : true} label="Materia"
                                    labelId="materia-label" value={materiaSelected} onChange={changeMateria}  >
                                    <MenuItem key={-1} value={-1}  >
                                    </MenuItem>
                                    {materieList.map((materia) => (
                                        <MenuItem style={{ whiteSpace: 'normal' }} value={materia.idMateria} sx={{ wordBreak: 'break-all' }}>
                                            {materia?.nomeMateria ?? ""}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={3}>
                            <FormControlLabel control={<Checkbox onChange={changeNonValutatiToggle} checked={showOnlyNonValutati} />} label="Mostra solo accertamenti prerequisiti non valutati" />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ width: '100%', py: 2, px: 3 }} justifyContent={"end"}>
                        {condizioneSede() && (<Grid xs={12} md={3}>
                            <Box textAlign={'center'} color={'red'}>
                                <Typography variant='caption' sx={{ textAlign: 'center' }}>Per avviare la ricerca è necessario selezionare la sede.</Typography>
                            </Box>
                        </Grid>)}
                        <Grid xs={12} sx={{ display: "flex", justifyContent: 'end', alignItems: 'center' }}>
                            <Button onClick={avviaRicerca} disabled={checkValidateInput() || isLoading} data-testid='ricercaBtn' variant="contained" color="success">
                                Visualizza
                            </Button>
                            {searchStarted && (
                                <Button sx={{ ml: 2 }} disabled={isLoading} onClick={removeFilters} variant="outlined" endIcon={<Clear />} data-testid='rimuoviFiltriBtn' color="error" >
                                    Rimuovi filtri
                                </Button>
                            )}
                        </Grid>

                    </Grid>
                </form>
            </Paper>
        </Fragment>
    )
}

