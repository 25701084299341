import { DateTime } from "luxon";

export function convertDateObjectToString(date) {
    //Prende in entrata un oggetto Data
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function getDayOfTheWeek(data) {
    if (data != undefined && data != null) {
        //Prende in entrata una data in formato YYYY/MM/DD
        let [year, month, day] = data.split('-');
        let dateConverted = new Date(year, month - 1, day);
        const giorniDellaSettimana = ['Domenica', 'Lunedì', 'Martedi', 'Mercoledi', 'Giovedi', 'Venerdi', 'Sabato']
        let giorno = giorniDellaSettimana[dateConverted.getDay()];
        return giorno;
    } else return "";
}

export function getDayAndMonth(data) {
    if (data != undefined && data != null) {

        //Prende in entrata una data in formato YYYY/MM/DD
        const date = new Date(data);
        const day = date.getDate();
        let month = date.toLocaleString('it-IT', { month: 'long' });
        month = month.charAt(0).toUpperCase() + month.slice(1);
        return `${day} ${month}`;
    } else return "";
}
export function getDayMonthAndYearErrorDialog(data) {
    if (data != undefined && data != null) {
        //Prende in entrata una data in formato YYYY/MM/DD
        const date = new Date(data);
        const day = date.getDate();
        let month = date.toLocaleString('it-IT', { month: 'long' });
        month = month.charAt(0).toUpperCase() + month.slice(1);
        const year = date.getFullYear();
        //10 Agosto - 2023
        return `${day} ${month} - ${year}`;
    } else return "";

}

export function convertDataDiNascita(data) {
    //Prende in entrata una data in formato YYYY/MM/DD
    const date = DateTime.fromISO(data);
    const formattedDate = date.toFormat('dd/MM/yyyy')
    //10/08/2023
    return formattedDate;
}

export function convertToSqlFormat(data) {
    const date = DateTime.fromISO(data);
    const formattedDate = date.toFormat('yyyy-MM-dd')
    return formattedDate;
}

export function convertISODate(isoDate) {
    //Prende in entrata una data in formato ISO
    const date = new Date(isoDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
}


export function convertDataGetProssimeLezione(data, needToConvertToSql) {
    if (data != null) {
        let newData = data;
        if (needToConvertToSql === true) {
            let date = new Date(data);
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            let sqlDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
            newData = DateTime.fromSQL(sqlDate)
        }
        let dataTmp = newData.setZone('America/Los_Angeles');
        let formattedData = dataTmp.toUTC().toISO();
        return formattedData;
    } else return null
}

export function convertDataScrutinio(data, needToConvertToSql) {
    if (data != null) {
        let newData = data;
        if (needToConvertToSql === true) {
            let date = new Date(data);
            let day = date.getDate();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            let sqlDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
            newData = DateTime.fromSQL(sqlDate)
        }
        let dataTmp = newData.setZone('Europe/Rome', { keepCalendarTime: true });
        let formattedData = dataTmp.toUTC().toISO();
        return formattedData;
    } else return null
}

export function convertToItalianTimeAndShowWeekDay(utcDateString) {
    const utcDate = DateTime.fromISO(utcDateString, { zone: 'utc' });
    const italianDate = utcDate.setZone('Europe/Rome');

    const weekdayInItalian = italianDate.setLocale('it').toFormat('cccc');
    const capitalizedWeekday = weekdayInItalian.charAt(0).toUpperCase() + weekdayInItalian.slice(1);
    const formattedDate = italianDate.toFormat('dd/LL/yyyy');
    const result = `${capitalizedWeekday}, ${formattedDate}`;

    return result;
}

export function convertToItalianTime(utcDateString) {
    // Crea un oggetto DateTime dal tuo timestamp UTC
    const utcDate = DateTime.fromISO(utcDateString, { zone: 'utc' });

    // Converte l'oggetto DateTime nel fuso orario italiano
    const italianDate = utcDate.setZone('Europe/Rome');

    // Formatta la data e l'ora nel formato desiderato
    const formattedDate = italianDate.toFormat('dd/LL/yyyy'); //'dd/LL/yyyy - HH:mm'

    return formattedDate;
}

export function convertToItalianTimeFormatted(utcDateString) {
    // Crea un oggetto DateTime dal tuo timestamp UTC
    const utcDate = DateTime.fromISO(utcDateString, { zone: 'utc' });

    // Converte l'oggetto DateTime nel fuso orario italiano
    const italianDate = utcDate.setZone('Europe/Rome');

    // Formatta la data e l'ora nel formato desiderato
    const formattedDate = italianDate.toFormat('HH:mm:ss'); //'dd/LL/yyyy - HH:mm'

    return formattedDate;
}

export function convertToItalianDateTime(utcDateString) {
    // Crea un oggetto DateTime dal tuo timestamp UTC
    const utcDate = DateTime.fromISO(utcDateString, { zone: 'utc' });

    // Converte l'oggetto DateTime nel fuso orario italiano
    const italianDate = utcDate.setZone('Europe/Rome');

    // Formatta la data e l'ora nel formato desiderato
    const formattedDate = italianDate.toFormat('dd/LL/yyyy - HH:mm');

    return formattedDate;
}

export function convertToItalianDateTimeStringaParlante(utcDateString) {
    // Crea un oggetto DateTime dal tuo timestamp UTC
    const utcDate = DateTime.fromISO(utcDateString, { zone: 'utc' });

    // Converte l'oggetto DateTime nel fuso orario italiano
    const italianDate = utcDate.setZone('Europe/Rome');

    // Formatta la data e l'ora nel formato desiderato
    const formattedDate = italianDate.toFormat('il dd/LL/yyyy');
    const formattedHour = italianDate.toFormat('HH:mm');

    return formattedDate + " alle " + formattedHour;
}


export function convertOra(utcDateString) {
    if (utcDateString !== undefined && utcDateString !== null) {
        const utcDate = DateTime.fromISO(utcDateString, { zone: 'utc' });

        // Converte l'oggetto DateTime nel fuso orario italiano
        const italianDate = utcDate.setZone('Europe/Rome');

        // Formatta la data e l'ora nel formato desiderato
        const formattedDate = italianDate.toFormat('HH:mm');
        return formattedDate;
    } else return "-"
}

export function convertUtcInDatetime(utcDateString){
    if(utcDateString){
    const date = DateTime.fromISO(utcDateString);
    return date;
    }
}
export function convertUtcInLuxon(utcDateString) {
    if(utcDateString){
    const dateString = utcDateString;
    const dateTime = DateTime.fromISO(dateString);
    return dateTime
    } else return null;
}