import { Fragment, useEffect, useState } from "react";
import { Alert, Typography, Button, Divider} from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function AlertComunicazioneUrgente(props) {

    const navigate = useNavigate();
    const [numeroComunicazioniDaLeggere, setNumeroComunicazioniDaLeggere] = useState(0);

    useEffect(() => {
        if (props.numeroComunicazioniDaLeggere !== undefined) {
            setNumeroComunicazioniDaLeggere(props.numeroComunicazioniDaLeggere);
        }
    }, [props.numeroComunicazioniDaLeggere])

    function goToElencoComunicazioni() {
        navigate('/comunicazioni/elenco')
    }

    return (
        <Fragment>
            <Alert
                sx={{ mx: 5, mb:1 }}
                severity="error"
                action={
                    <Button color="inherit" size="medium" onClick={goToElencoComunicazioni}>
                        Apri elenco comunicazioni
                    </Button>
                }
            >
                {numeroComunicazioniDaLeggere === 1 ? (<Typography>
                    C'è <b>1 comunicazione</b> non letta
                </Typography>) : (<Typography>
                    Ci sono <b>{numeroComunicazioniDaLeggere} comunicazioni</b> non lette
                </Typography>)}
            </Alert>
            <Divider sx={{mb:1}}/>
        </Fragment>
    );

}