import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { Alert, Typography, Dialog, DialogContent, CircularProgress, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate, useLocation } from "react-router-dom";
import { EditNote } from '@mui/icons-material';

import { Helmet } from "react-helmet";
import CryptoJS from 'crypto-js';
import { useTranslation } from 'react-i18next';
import { EditCalendar } from '@mui/icons-material';
import { UtilityAPI } from '../../api/utilityApi';

import DataBuildVersion from '../../dataBuild.txt';
import CardHome from './cardHome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faBriefcase, faCalendar, faCalendarDays, faCheckDouble, faCoins, faComments, faDownload, faGraduationCap, faList, faListCheck, faListUl, faMagnifyingGlass, faPaperclip, faPenRuler, faPencil, faPerson, faUsers, faEdit, faIndustry, faHandshake, faHomeUser, faFile, faEnvelope, faListOl, faFolderOpen, faSchool, faHome, faHouse, faBell, faClose } from '@fortawesome/free-solid-svg-icons';
import LoadingComponent from '../shared/loadingComponent';

export default function Home(props) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const [elencoPermessiUtente, setElencoPermessiUtente] = useState([]);
    const [dialogLoginGtmOpen, setDialogLoginGtmOpen] = useState(false);
    const [dialogSubModulesOpen, setDialogSubModulesOpen] = useState(false);
    const [dialogSubModulesTirocinioOpen, setDialogSubModulesTirocinioOpen] = useState(false);
    const [dataBuildVersion, setDataBuildVersion] = useState('');
    useEffect(() => {
        // if (props.isLoading) setIsLoading(props.isLoading);
        // else setTimeout(setIsLoading(false), 200);

        fetch(DataBuildVersion)
            .then(r => r.text())
            .then(text => {
                let newVersion = text;
                setDataBuildVersion(newVersion)
            });
    }, [])

    useEffect(() => {
        if (location?.state?.isModuloTirocinio !== undefined) {
            if (location?.state?.isModuloTirocinio === true) setDialogSubModulesTirocinioOpen(true);
        }
    }, [location])

    useEffect(() => {
        if (props.permessiUtente)
            setElencoPermessiUtente(props.permessiUtente);
    }, [props.permessiUtente])


    function navigateToRicerca() {
        navigate("/ricerca-alunni")
    }
    function navigateToBes() {
        navigate("/tracciati-bes")
    }
    function navigateToValidazionePresenze() {
        navigate("/validazione-presenze")
    }
    function navigateToRegClasse() {
        navigate("/registro/registro-di-classe", { state: { isDocente: true } })
    }
    function navigateToRegDocente() {
        navigate("/registro/registro-del-docente")
    }
    function navigateToConsultaRegClasse() {
        navigate("/registro/consulta-registro-di-classe", { state: { isDocente: false } })
    }
    function navigateToRiepilogoAttivita() {
        navigate("/riepilogo-attivita-formativa", { state: { isDocente: false } })
    }
    function navigateToRiepilogoAttivitaAlunno() {
        navigate("/riepilogo-attivita-formativa-alunno", { state: { isDocente: false } })
    }
    function navigateToRegDidatticaSintesiMateria() {
        navigate("/registro-didattico-sintesi-materia", { state: { isDocente: false } })
    }
    function navigateToRiepilogoDidattico() {
        navigate("/elenco-alunni-riepilogo-didattico", { state: { isDocente: false } })
    }
    function navigateToGestioneTrimestri() {
        navigate("/gestione-periodi", { state: { isDocente: false } })
    }
    function navigateToConsultaRegDocente() {
        navigate("/registro/consulta-registro-docente", { state: { isDocente: false } })
    }
    function navigateToComunicazioni() {
        navigate("/comunicazioni/elenco")
    }
    function goToExportExante() {
        navigate("/export-ex-ante")
    }
    function goToElencoColloqui() {
        navigate("/colloqui/elenco")
    }

    function goToGestioneCalendario() {
        navigate("/colloqui/gestione-calendario");
    }

    function goToRiepilogoArgomentiSvolti() {
        navigate("/riepilogo-argomenti-svolti");
    }

    function goToMonitoraggioAppello() {
        navigate("/monitoraggio/monitoraggio-appello");
    }

    function goToMonitoraggioFirme() {
        navigate("/monitoraggio/monitoraggio-firme");
    }

    function goToGestioneCreditiFormativi() {
        navigate("/gestione-crediti-formativi");
    }

    function goToAccertamentoPrerequisiti() {
        navigate("/accertamento-prerequisiti");

    }

    function closeSubModules() {
        setDialogSubModulesOpen(false);
    }

    function closeSubModulesTirocino() {
        setDialogSubModulesTirocinioOpen(false);
    }

    function goToLoginGtm() {
        let idTmp = localStorage.getItem("id");
        let decryptedId = CryptoJS.AES.decrypt(idTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
        setDialogLoginGtmOpen(true);
        let currentUrl = window.location.href;
        let dataObj = {
            idUtente: decryptedId
        }

        UtilityAPI.loginGtm(dataObj)
            .then((result) => {
                let jwt = result;
                setDialogLoginGtmOpen(false);

                const params = new URLSearchParams();
                // params.set('url', currentUrl);
                params.set('registro', jwt);

                let gtmUrl = process.env.REACT_APP_GTM_URL + "?" + params.toString()
                window.location.replace(gtmUrl);
            })
            .catch(function (error) {
                setDialogLoginGtmOpen(false)
            }
            )
    }

    function goToTirocini() {
        navigate("/tirocini/gestione-tirocini");
    }

    function goToRegistroSintesiTirocini() {
        navigate("/tirocini/registro-sintesi-tirocini");
    }
    function goToPresenzeTutorTirocinio() {
        navigate("/tirocini/presenze-tutor-tirocinio");
    }
    function goToValidazionePresenze() {
        navigate("/tirocini/validazione-presenze-tirocini");
    }
    function goToValidazioneresenzeTutorTirocinio() {
        navigate("/tirocini/validazione-presenze-tutor-tirocinio");
    }

    function goToGestioneAziende() {
        navigate("/tirocini/gestione-aziende");
    }
    function goToGestioneConvenzione() {
        navigate("/tirocini/gestione-convenzioni");
    }
    function goToGestioneProgettiFormativi() {
        navigate("/tirocini/gestione-progetti-formativi");
    }
    function goToGestioneAziendaSimulata() {
        navigate("/tirocini/gestione-azienda-simulata");
    }
    function goToGestioneDatiTirocini() {
        navigate("/tirocini/gestione-dati-ricorrenti-sedi");
    }
    function goToGestioneDatiTirociniCorsi() {
        navigate("/tirocini/gestione-dati-ricorrenti-corsi");
    }
    function goToGiorniSospensione(){
        navigate("/tirocini/giorni-sospensione");

    }
    function navigateToSingleModule(funzioneNavigate) {
        funzioneNavigate();
    }

    return (
        <Fragment>
            <Box fluid="true" sx={{ px: 5, display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: "start", minHeight: '60vh' }}>

                {process.env.REACT_APP_IS_DEBUG == "true" ? (
                    <Alert severity={'warning'} sx={{ mt: 1 }}>
                        <Box sx={{ width: '90vw', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography> Modalità debug (test) </Typography>
                        </Box>
                    </Alert>) : null}

                <Grid spacing={4} width={"100%"} container mt={3} mb={3}>
                    {elencoPermessiUtente.some(permesso => permesso.nome === "showRegistroClasse" && permesso.value === true) && (
                        <Grid xs={12} sm={6} md={4} lg={3} >
                            <CardHome text={'Registro di classe'} isModulo={true} backgroundColorCard={'#2e7d32'} colorTextCard={'white'} navigateTo={navigateToRegClasse}
                                icon={<FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: '2.5rem' }} />}
                            />
                        </Grid>
                    )}

                    {elencoPermessiUtente.some(permesso => permesso.nome === "showRegistroDocente" && permesso.value === true) && (
                        <Grid xs={12} sm={6} md={4} lg={3} >
                            <CardHome text={'Registro docente'} isModulo={true} backgroundColorCard={'#ed6c02'} colorTextCard={'white'} navigateTo={navigateToRegDocente}
                                icon={<FontAwesomeIcon icon={faGraduationCap} style={{ fontSize: '2.5rem' }} />}
                            />
                        </Grid>
                    )}

                    {elencoPermessiUtente.some(permesso => permesso.nome === "showConsultaRegistroClasse" && permesso.value === true) && (
                        <Grid xs={12} sm={6} md={4} lg={3} >
                            <CardHome text={'Consulta registro di classe'} isModulo={true} navigateTo={navigateToConsultaRegClasse}
                                icon={<FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: '2.5rem', color: '#1976d2' }} />}

                            />
                        </Grid>
                    )}

                    {elencoPermessiUtente.some(permesso => permesso.nome === "showConsultaRegistroDocente" && permesso.value === true) && (
                        <Grid xs={12} sm={6} md={4} lg={3} >
                            <CardHome text={'Consulta registro docente'} isModulo={true} navigateTo={navigateToConsultaRegDocente}
                                icon={<FontAwesomeIcon icon={faGraduationCap} style={{ fontSize: '2.5rem', color: '#1976d2' }} />}
                            />
                        </Grid>
                    )}


                    {elencoPermessiUtente.some(permesso => (permesso.nome === "showGestioneTirocinio" && permesso.value === true) ||
                        (permesso.nome === "showRegistroTirocinio" && permesso.value === true) ||
                        (permesso.nome === "showValidazionePresenze" && permesso.value === true) ||
                        (permesso.nome === "isTutorTirocinioFormativo" && permesso.value === true) ||
                        (permesso.nome === "showPresenzeTutor" && permesso.value === true) ||
                        (permesso.nome === "showGestioneAziende" && permesso.value === true) ||
                        (permesso.nome === "showGestioneConvenzioni" && permesso.value === true) ||
                        (permesso.nome === "showGestioneProgettiFormativi" && permesso.value === true) ||
                        (permesso.nome === "precompilaConvenzioniTirocini " && permesso.value === true)
                    ) && (
                            <Grid xs={12} sm={6} md={4} lg={3} >
                                <CardHome text={'Tirocini formativi'} isModulo={true}
                                    icon={<FontAwesomeIcon icon={faBriefcase} style={{ fontSize: '2.5rem', color: '#1976d2' }} />}
                                    elencoPermessiUtente={elencoPermessiUtente} permessiModulo={['showGestioneTirocinio', 'showRegistroTirocinio', 'showPresenzeTutor', 'showValidazionePresenze', 'showGestioneConvenzioni', 'showGestioneAziende', 'isTutorTirocinioFormativo', 'showGestioneProgettiFormativi']}
                                    navigateTo={navigateToSingleModule}
                                    subModuli={[
                                        {
                                            text: 'Valutazione tirocinio formativo',
                                            isModulo: false,
                                            navigateTo: goToTirocini,
                                            icon: <FontAwesomeIcon icon={faBriefcase} style={{ fontSize: '3.5rem', color: '#1976d2' }} />,
                                            permessoNome: 'showGestioneTirocinio'
                                        },
                                        {
                                            text: 'Registro sintesi del tirocinio',
                                            isModulo: false,
                                            navigateTo: goToRegistroSintesiTirocini,
                                            icon: <FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: '3.5rem', color: '#1976d2' }} />,
                                            permessoNome: 'showRegistroTirocinio'
                                        },
                                        {
                                            text: 'Presenze tutor di tirocinio formativo',
                                            isModulo: false,
                                            navigateTo: goToPresenzeTutorTirocinio,
                                            icon: <FontAwesomeIcon icon={faEdit} style={{ fontSize: '3.5rem', color: '#1976d2' }} />,
                                            permessoNome: 'showPresenzeTutor'
                                        },
                                        {
                                            text: 'Validazione presenze tirocinio',
                                            isModulo: false,
                                            navigateTo: goToValidazionePresenze,
                                            icon: <FontAwesomeIcon icon={faCheckDouble} style={{ fontSize: '3.5rem', color: '#1976d2' }} />,
                                            permessoNome: 'showValidazionePresenze'
                                        },
                                        {
                                            text: 'Validazione presenze tutor di tirocinio formativo',
                                            isModulo: false,
                                            navigateTo: goToValidazioneresenzeTutorTirocinio,
                                            icon: <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <FontAwesomeIcon icon={faCheckDouble} style={{ fontSize: '2.5rem', color: '#1976d2', marginRight: '5px' }} />
                                                <FontAwesomeIcon icon={faEdit} style={{ fontSize: '2.5rem', color: '#1976d2' }} />
                                            </Box>,
                                            permessoNome: 'showValidazionePresenze'
                                        },
                                        {
                                            text: 'Gestione aziende',
                                            isModulo: false,
                                            navigateTo: goToGestioneAziende,
                                            icon: <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <FontAwesomeIcon icon={faBriefcase} style={{ fontSize: '2.5rem', color: '#1976d2', marginRight: '5px' }} />
                                                <FontAwesomeIcon icon={faHomeUser} style={{ fontSize: '2.3rem', color: '#1976d2' }} />
                                            </Box>,
                                            permessoNome: 'showGestioneAziende'
                                        },
                                        {
                                            text: 'Gestione convenzioni',
                                            isModulo: false,
                                            navigateTo: goToGestioneConvenzione,
                                            icon: <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <FontAwesomeIcon icon={faBriefcase} style={{ fontSize: '2.5rem', color: '#1976d2', marginRight: '5px' }} />
                                                <FontAwesomeIcon icon={faHandshake} style={{ fontSize: '2.6rem', color: '#1976d2' }} />
                                            </Box>,
                                            permessoNome: 'showGestioneConvenzioni'
                                        },
                                        {
                                            text: 'Gestione progetti formativi',
                                            isModulo: false,
                                            navigateTo: goToGestioneProgettiFormativi,
                                            icon: <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <FontAwesomeIcon icon={faBriefcase} style={{ fontSize: '2.5rem', color: '#1976d2', marginRight: '5px' }} />
                                                <FontAwesomeIcon icon={faFile} style={{ fontSize: '2.3rem', color: '#1976d2' }} />
                                            </Box>,
                                            permessoNome: 'showGestioneProgettiFormativi'
                                        },
                                        {
                                            text: 'Gestione azienda simulata',
                                            isModulo: false,
                                            navigateTo: goToGestioneAziendaSimulata,
                                            icon: <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <FontAwesomeIcon icon={faBriefcase} style={{ fontSize: '2.5rem', color: '#1976d2', marginRight: '5px' }} />
                                                <FontAwesomeIcon icon={faHome} style={{ fontSize: '2.3rem', color: '#1976d2' }} />
                                            </Box>,
                                            permessoNome: 'showGestioneProgettiFormativi'
                                        },
                                        {
                                            text: 'Gestione dati ricorrenti per sedi',
                                            isModulo: false,
                                            navigateTo: goToGestioneDatiTirocini,
                                            icon: <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <FontAwesomeIcon icon={faFolderOpen} style={{ fontSize: '2.5rem', color: '#1976d2', marginRight: '5px' }} />
                                                <FontAwesomeIcon icon={faSchool} style={{ fontSize: '2.3rem', color: '#1976d2' }} />
                                            </Box>,
                                            permessoNome: 'precompilaConvenzioniTirocini'
                                        },
                                        {
                                            text: 'Gestione dati ricorrenti per corsi',
                                            isModulo: false,
                                            navigateTo: goToGestioneDatiTirociniCorsi,
                                            icon: <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <FontAwesomeIcon icon={faFolderOpen} style={{ fontSize: '2.5rem', color: '#1976d2', marginRight: '5px' }} />
                                                <FontAwesomeIcon icon={faGraduationCap} style={{ fontSize: '2.3rem', color: '#1976d2' }} />
                                            </Box>,
                                            permessoNome: 'precompilaConvenzioniTirocini'
                                        },
                                        {
                                            text: 'Giorni di sospensione',
                                            isModulo: false,
                                            navigateTo: goToGiorniSospensione,
                                            icon: <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: '2.5rem', color: '#1976d2', marginRight: '5px' }} />
                                                <FontAwesomeIcon icon={faClose} style={{ fontSize: '2.3rem', color: '#1976d2' }} />
                                            </Box>,
                                            permessoNome: 'precompilaConvenzioniTirocini'
                                        },
                                    ]}
                                    dialogSubModulesOpen={dialogSubModulesTirocinioOpen}
                                    closeSubModules={closeSubModulesTirocino}
                                />
                            </Grid>
                        )}

                    <Grid xs={12} sm={6} md={4} lg={3} >
                        <CardHome text={'Ricerca alunni'} isModulo={true} navigateTo={navigateToRicerca}
                            icon={<FontAwesomeIcon icon={faMagnifyingGlass} style={{ fontSize: '2.5rem', color: '#1976d2' }} />}
                        />
                    </Grid>

                    <Grid xs={12} sm={6} md={4} lg={3} >
                        <CardHome text={'Comunicazioni'} isModulo={false} navigateTo={navigateToComunicazioni}
                            icon={<FontAwesomeIcon icon={faEnvelope} style={{ fontSize: '2.5rem', color: '#1976d2' }} />}
                        />
                    </Grid>

                    {elencoPermessiUtente.some(permesso => permesso.nome === "showValidazionePresenze" && permesso.value === true) && (
                        <Grid xs={12} sm={6} md={4} lg={3} >
                            <CardHome text={'Validazione presenze'} isModulo={true} navigateTo={navigateToValidazionePresenze}
                                icon={<FontAwesomeIcon icon={faCheckDouble} style={{ fontSize: '2.5rem', color: '#1976d2' }} />}
                            />
                        </Grid>
                    )}
                    {elencoPermessiUtente.some(permesso => permesso.nome === "showTracciatiBes" && permesso.value === true) && (
                        <Grid xs={12} sm={6} md={4} lg={3} >
                            <CardHome text={'Tracciati BES'} isModulo={true} navigateTo={navigateToBes}
                                icon={<FontAwesomeIcon icon={faPerson} style={{ fontSize: '2.5rem', color: '#1976d2' }} />}
                            />
                        </Grid>
                    )}


                    {elencoPermessiUtente.some(permesso => permesso.nome === "showRegistroSintesiMateria" && permesso.value === true) && (
                        <Grid xs={12} sm={6} md={4} lg={3} >
                            <CardHome text={'Registro didattico di sintesi della materia'} isModulo={false} navigateTo={navigateToRegDidatticaSintesiMateria}
                                icon={<FontAwesomeIcon icon={faPenRuler} style={{ fontSize: '2.5rem', color: '#1976d2' }} />}
                            />
                        </Grid>
                    )}
                    {elencoPermessiUtente.some(permesso => permesso.nome === "showRiepilogoDidattico" && permesso.value === true) && (
                        <Grid xs={12} sm={6} md={4} lg={3} >
                            <CardHome text={'Elenco alunni con riepilogo didattico'} isModulo={false} navigateTo={navigateToRiepilogoDidattico} icon={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <FontAwesomeIcon icon={faListUl} style={{ fontSize: '1.5rem', color: '#1976d2', marginRight: '5px' }} />
                                    <FontAwesomeIcon icon={faUsers} style={{ fontSize: '2rem', color: '#1976d2' }} />
                                </Box>} />
                        </Grid>
                    )}

                    {elencoPermessiUtente.some(permesso => permesso.nome === "showGestionePeriodi" && permesso.value === true) && (
                        <Grid xs={12} sm={6} md={4} lg={3} >
                            <CardHome text={'Gestione periodi'} isModulo={false} navigateTo={navigateToGestioneTrimestri}
                                icon={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <FontAwesomeIcon icon={faPencil} style={{ fontSize: '1.5rem', color: '#1976d2', marginRight: '5px' }} />
                                        <FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: '2rem', color: '#1976d2' }} />
                                    </Box>}
                            />
                        </Grid>
                    )}
                    {elencoPermessiUtente.some(permesso => permesso.nome === "showExportExAnte" && permesso.value === true) && (
                        <Grid xs={12} sm={6} md={4} lg={3} >
                            <CardHome text={'Export Ex ante'} isModulo={false} navigateTo={goToExportExante}
                                icon={<FontAwesomeIcon icon={faDownload} style={{ fontSize: '2.5rem', color: '#1976d2' }} />}
                            />
                        </Grid>
                    )}

                    {elencoPermessiUtente.some(permesso => (permesso.nome === "showGestioneCalendario" && permesso.value === true) || (permesso.nome === "showAnnotazioniColloquio" && permesso.value === true)) && (
                        <Grid xs={12} sm={6} md={4} lg={3} >
                            <CardHome text={'Colloqui'} isModulo={true}
                                permessiModulo={['showGestioneCalendario', 'showAnnotazioniColloquio']}
                                icon={<FontAwesomeIcon icon={faComments} style={{ fontSize: '2.5rem', color: '#1976d2' }} />}
                                elencoPermessiUtente={elencoPermessiUtente}
                                navigateTo={navigateToSingleModule}
                                subModuli={[
                                    {
                                        text: 'Gestione calendario',
                                        isModulo: false,
                                        navigateTo: goToGestioneCalendario,
                                        icon: <FontAwesomeIcon icon={faCalendar} style={{ fontSize: '3.5rem', color: '#1976d2' }} />,
                                        permessoNome: 'showGestioneCalendario'
                                    },
                                    {
                                        text: 'Elenco colloqui',
                                        isModulo: false,
                                        navigateTo: goToElencoColloqui,
                                        icon: <FontAwesomeIcon icon={faList} style={{ fontSize: '3.5rem', color: '#1976d2' }} />,
                                        permessoNome: 'showAnnotazioniColloquio'
                                    },
                                ]}
                                dialogSubModulesOpen={dialogSubModulesOpen}
                                closeSubModules={closeSubModules}
                            />
                        </Grid>
                    )}

                    {elencoPermessiUtente.some(permesso => (permesso.nome === "showMonitoriaAppello" && permesso.value === true) || (permesso.nome === "showMonitoriaFirme" && permesso.value === true)) && (
                        <Grid xs={12} sm={6} md={4} lg={3} >
                            <CardHome text={'Monitoraggio'} isModulo={true}
                                permessiModulo={['showMonitoriaAppello', 'showMonitoriaFirme']}
                                icon={<FontAwesomeIcon icon={faListCheck} style={{ fontSize: '2.5rem', color: '#1976d2' }} />} elencoPermessiUtente={elencoPermessiUtente}
                                navigateTo={navigateToSingleModule}
                                subModuli={[
                                    {
                                        text: 'Monitoraggio appello',
                                        isModulo: false,
                                        navigateTo: goToMonitoraggioAppello,
                                        icon: <EditCalendar sx={{ fontSize: '3.5rem' }} color="primary" />,
                                        permessoNome: 'showMonitoriaAppello'
                                    },
                                    {
                                        text: 'Monitoraggio firme',
                                        isModulo: false,
                                        navigateTo: goToMonitoraggioFirme,
                                        icon: <Box sx={{ display: 'flex' }}>
                                            <EditNote sx={{ fontSize: '3.5rem' }} color="primary" />
                                        </Box>,
                                        permessoNome: 'showMonitoriaFirme'
                                    },
                                ]}
                                dialogSubModulesOpen={dialogSubModulesOpen}
                                closeSubModules={closeSubModules}
                            />
                        </Grid>
                    )}
                    {elencoPermessiUtente.some(permesso => permesso.nome === "showRiepilogoArgomentiSvolti" && permesso.value === true) && (
                        <Grid xs={12} sm={6} md={4} lg={3} >
                            <CardHome text={'Riepilogo argomenti svolti'} isModulo={false} navigateTo={goToRiepilogoArgomentiSvolti}
                                icon={<FontAwesomeIcon icon={faPaperclip} style={{ fontSize: '2.5rem', color: '#1976d2' }} />}
                            />
                        </Grid>
                    )}

                    {elencoPermessiUtente.some(permesso => permesso.nome === "showCreditiFormativi" && permesso.value === true) && (
                        <Grid xs={12} sm={6} md={4} lg={3} >
                            <CardHome text={'Gestione crediti formativi'} isModulo={false} navigateTo={goToGestioneCreditiFormativi}
                                icon={<FontAwesomeIcon icon={faCoins} style={{ fontSize: '2.5rem', color: '#1976d2' }} />}
                            />
                        </Grid>
                    )}

                    {elencoPermessiUtente.some(permesso => permesso.nome === "showElencoAccertamentoPrerequisiti" && permesso.value === true) && (
                        <Grid xs={12} sm={6} md={4} lg={3} >
                            <CardHome text={'Accertamento prerequisiti'} isModulo={false} navigateTo={goToAccertamentoPrerequisiti}
                                icon={<FontAwesomeIcon icon={faListOl} style={{ fontSize: '2.5rem', color: '#1976d2' }} />}
                            />
                        </Grid>
                    )}

                    {elencoPermessiUtente.some(permesso => permesso.nome === "canAccessGtm" && permesso.value === true) && (
                        <Grid xs={12} sm={6} md={4} lg={3} >
                            <CardHome text={'Vai a GTM'} isModulo={false} backgroundColorCard={'#9c27b0'} colorTextCard={'white'} navigateTo={goToLoginGtm}
                                icon={<FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '2.5rem' }} />}

                            />
                        </Grid>
                    )}
                </Grid>
                {process.env.REACT_APP_IS_DEBUG == "true" ? (
                    <Typography sx={{ color: 'grey' }}> Buildato in data {dataBuildVersion} </Typography>
                ) : null}
            </Box>
            <Dialog fullScreen open={dialogLoginGtmOpen} sx={{ zIndex: 3000 }}>
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                    <Typography sx={{ mt: 3, width: 'fit-content' }}>Caricamento in corso...</Typography>
                </DialogContent>
            </Dialog>

            <Helmet>
                <title>{t("name")} - {t("pages.home.title")}</title>
            </Helmet>
        </Fragment >
    );
}
