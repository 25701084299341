import { Fragment, useEffect, useState } from "react"
import CustomTimePicker from "../../shared/customTimePicker"
import { Box, Divider, IconButton, Skeleton, TableCell } from "@mui/material"
import { Close } from "@mui/icons-material";


export default function GiornataOrarioFormativoCell(props) {

    const [objPeriodo, setObjPeriodo] = useState(undefined);

    const [isPrimaFascia, setIsPrimaFascia] = useState(true);

    const [orarioInizio, setOrarioInizio] = useState(null)
    const [orarioFine, setOrarioFine] = useState(null)
    const [isCanEdit, setIsCanEdit] = useState(true);
    const [isVariazione, setIsVariazione] = useState(true);
    const [hasLoaded, setHasLoaded] = useState(false);

    const [minTime, setMinTime] = useState(null)
    const [maxTime, setMaxTime] = useState(null)

    const [indexRender, setIndexRender] = useState(0);

    useEffect(() => {
        if (props.isVariazione !== undefined) {
            setIsVariazione(props.isVariazione)
        }
    }, [props.isVariazione])

    useEffect(() => {
        if (props.isCanEdit !== undefined) {
            setIsCanEdit(props.isCanEdit)
        }
    }, [props.isCanEdit])

    useEffect(() => {
        setIndexRender(prev => prev + 1);
        //Qui arrivano i dati quando modifico altri time picker, non devo togliere 1 ora
        if (props.isPrimaFascia === true) {
            if (props.objPeriodo !== undefined) {
                if (props.objPeriodo.secondaFascia.oraInizio !== null) {
                    let dateFormatted = `2024-01-01T${props.objPeriodo.secondaFascia.oraInizio}.000Z`;
                    const localTime = new Date(dateFormatted);
                    localTime.setHours(localTime.getHours() - 1); // Rimuovo un'ora 
                    const utcTimeString = localTime.toISOString();
                    setMaxTime(utcTimeString)
                } else setMaxTime(null)
            }
        } else {
            if (props.objPeriodo !== undefined) {
                if (props.objPeriodo.primaFascia.oraFine !== null) {
                    let dateFormatted = `2024-01-01T${props.objPeriodo.primaFascia.oraFine}.000Z`;
                    const localTime = new Date(dateFormatted);
                    localTime.setHours(localTime.getHours() - 1); // Rimuovo un'ora 
                    const utcTimeString = localTime.toISOString();
                    setMinTime(utcTimeString)
                } else setMinTime(null)
            }
        }
    }, [props.indexRender])

    useEffect(() => {
        if (props.objPeriodo !== undefined && props.isPrimaFascia !== undefined) {
            // if (props.objPeriodo !== objPeriodo) {
            setObjPeriodo(props.objPeriodo);
            setIsPrimaFascia(props.isPrimaFascia);
            if (props.isPrimaFascia === false) {
                if (props.objPeriodo !== undefined) {
                    if (props.objPeriodo.primaFascia.oraFine !== null) {
                        let dateFormatted = `2024-01-01T${props.objPeriodo.primaFascia.oraFine}.000Z`;
                        const localTime = new Date(dateFormatted);
                        localTime.setHours(localTime.getHours() - 1); // Rimuovo un'ora 
                        const utcTimeString = localTime.toISOString();
                        setMinTime(utcTimeString)

                    } else setMinTime(null)
                }
            } else {
                if (props.objPeriodo !== undefined) {
                    if (props.objPeriodo.secondaFascia.oraInizio !== null) {
                        let dateFormatted = `2024-01-01T${props.objPeriodo.secondaFascia.oraInizio}.000Z`;
                        const localTime = new Date(dateFormatted);
                        localTime.setHours(localTime.getHours() - 1); // Rimuovo un'ora 
                        const utcTimeString = localTime.toISOString();
                        setMaxTime(utcTimeString)
                    } else setMaxTime(null)
                }
            }


            formatDay(props.objPeriodo, props.isPrimaFascia);
            setHasLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.objPeriodo, props.isPrimaFascia])

    function formatDay(day, isPrimaFascia) {
        let orarioInizioObj = null;
        let orarioFineObj = null;
        if (isPrimaFascia) { //Arriva in utc iso
            orarioInizioObj = formatOraFascia(day.primaFascia?.oraInizio ?? null);
            orarioFineObj = formatOraFascia(day.primaFascia?.oraFine ?? null);
        } else {
            orarioInizioObj = formatOraFascia(day.secondaFascia?.oraInizio ?? null);
            orarioFineObj = formatOraFascia(day.secondaFascia?.oraFine ?? null);
        }
        setOrarioInizio(orarioInizioObj);
        setOrarioFine(orarioFineObj);
    }

    function formatOraFascia(ora) {
        if (ora !== null) {
            let dateFormatted = `2024-01-01T${ora}.000Z`;
            const localTime = new Date(dateFormatted);
            localTime.setHours(localTime.getHours() - 1); // Rimuovo un'ora 
            const utcTimeString = localTime.toISOString();
            return utcTimeString
        } else {
            return null
        }
    }

    function timeChanged(objPeriodo) {
        if (props.timeChanged) props.timeChanged(objPeriodo);
    }

    function changeOrarioInizioPrimaFascia(e) {
        setOrarioInizio(e);
        let utcDateTimeTmp = e !== null ? e.toISO() : null; // utc globale

        // let utcDateTimeTmp = e !== null ? e.toUTC().toISO() : null;
        let oraFormatted1 = utcDateTimeTmp !== null ? utcDateTimeTmp.split('T') : null;
        let oraFormatted2 = oraFormatted1 !== null ? oraFormatted1[1].split('.') : null;
        let oraFormatted = oraFormatted2 !== null ? oraFormatted2[0] : null;
        if (objPeriodo !== undefined) {
            if (isPrimaFascia) objPeriodo.primaFascia.oraInizio = oraFormatted;
            else objPeriodo.secondaFascia.oraInizio = oraFormatted;
        timeChanged(objPeriodo)
        }
    }

    function changeOrarioFinePrimaFascia(e) {

        setOrarioFine(e);
        let utcDateTimeTmp = e !== null ? e.toISO() : null; // utc globale

        let oraFormatted1 = utcDateTimeTmp !== null ? utcDateTimeTmp.split('T') : null;
        let oraFormatted2 = oraFormatted1 !== null ? oraFormatted1[1].split('.') : null;
        let oraFormatted = oraFormatted2 !== null ? oraFormatted2[0] : null;

        if (objPeriodo !== undefined) {
            if (isPrimaFascia)
                objPeriodo.primaFascia.oraFine = oraFormatted;
            else objPeriodo.secondaFascia.oraFine = oraFormatted;
           timeChanged(objPeriodo)
        }

    }
    function resetOrarioInizioPrimaFascia() {
        setOrarioInizio(null);
        if (objPeriodo !== undefined) {
            if (isPrimaFascia)
                objPeriodo.primaFascia.oraInizio = null;
            else objPeriodo.secondaFascia.oraInizio = null;
            timeChanged(objPeriodo)
        }

    }
    function resetOrarioFinePrimaFascia() {
        setOrarioFine(null);
        if (objPeriodo !== undefined) {
            if (isPrimaFascia)
                objPeriodo.primaFascia.oraFine = null;
            else objPeriodo.secondaFascia.oraFine = null;
            timeChanged(objPeriodo)
        }

    }
    return (
        <TableCell sx={{ border: '1px solid rgb(220,220,220)', px: 0, py: 0 }}>
            {hasLoaded ? (
                <Fragment>
                    <Box sx={{ px: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CustomTimePicker
                            value={orarioInizio}
                            isStyleMod={true}
                            minTimeMinusFive={true}
                            maxTimeMinusFive={true}
                            minTime={!isPrimaFascia ? minTime : null}
                            maxTime={orarioFine}
                            isDisabled={!isCanEdit && !isVariazione}
                            timeChanged={changeOrarioInizioPrimaFascia}
                            showResetButton={isCanEdit || isVariazione}
                            resetValue={resetOrarioInizioPrimaFascia}
                        />

                    </Box>
                    <Divider sx={{ my: 1 }} />
                    <Box sx={{ px: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CustomTimePicker
                            value={orarioFine}
                            isStyleMod={true}
                            minTimeMinusFive={true}
                            maxTimeMinusFive={true}
                            minTime={orarioInizio}
                            maxTime={isPrimaFascia ? maxTime : null}
                            isDisabled={!isCanEdit && !isVariazione}
                            timeChanged={changeOrarioFinePrimaFascia}
                            showResetButton={isCanEdit || isVariazione}
                            resetValue={resetOrarioFinePrimaFascia}
                        />
                    </Box>
                </Fragment>
            ) : (
                <Fragment>
                    <Box sx={{ px: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Skeleton sx={{ width: "100%" }} />
                    </Box>
                    <Box sx={{ px: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Skeleton sx={{ width: "100%" }} />
                    </Box>
                </Fragment>
            )}
        </TableCell>
    )
}