import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import { Paper, Button, TextField, Collapse, Container, Typography, Dialog, FormControl, InputLabel, Select, MenuItem, Box, Snackbar, Alert, Divider, Chip, CircularProgress, DialogTitle, IconButton, DialogContent, DialogActions } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import { AccessTime, Add, Archive, ArrowBack, Check, Clear, Close, Delete, Download, Factory, Home, KeyboardArrowDown, KeyboardArrowUp, LockClock, SupervisorAccount } from '@mui/icons-material';

import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';

import 'react-virtualized/styles.css';

import { RicercaAPI } from '../../../api/ricercaApi';
import Breadcrumb from '../../registri/shared/breadcrumb';

import { getAnnoFormativo } from '../../shared/utilityMethods';
import { TirociniAPI } from '../../../api/tirociniApi';
// import DettaglioAzienda from './dettaglioAzienda';
// import TableConvenzioni from './tableConvenzioni';
import DialogConferma from '../../shared/dialogConferma';
// import DettaglioConvenzione from './dettaglioConvenzione';

import { useLocation, useNavigate } from "react-router-dom";
import CustomDatePicker from '../../shared/customDatePicker';
import { convertDataGetProssimeLezione } from '../../shared/metodiDateUtility';
// import TableProgettiFormativi from './tableProgettiFormativi';
// import DettaglioProgettoFormativo from './dettaglioProgettoFormativo';
// import LegendaTirocini from '../legendaTirocini';
import axios from 'axios';
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { TimeClock } from '@mui/x-date-pickers';
import TableAziendaSimulata from './tableAziendaSimulata';
import LegendaAziendaSimulata from './legendaAziendaSimulata';
import DettaglioAziendaSimulata from './dettaglioAziendaSimulata';
// import VariazioneTutor from './dettaglio-progetto-formativo/variazioneTutor';
// import DettaglioVariazione from './dettaglioVariazione';
// import TableElencoVariazioni from './tableElencoVariazioni';

// import ElencoDocumentiScaricabili from '../elencoDocumentiScaricabili';
export default function GestioneAziendaSimulata(props) {

    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [sediList, setSediList] = useState([]);

    const [hasLoaded, setHasLoaded] = useState(false);
    const [searchStarted, setSearchStarted] = useState(false);
    // const [searchLoaded, setSearchLoaded] = useState(false);


    //Selezione
    const [isInsideOtherComponent, setIsInsideOtherComponent] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [annoFormativo, setAnnoFormativo] = useState("");

    const [dialogAggiungiProgettoOpen, setDialogAggiungiProgettoOpen] = useState(false);
    const [dialogAssegnaAllievoOpen, setDialogAssegnaAllievoOpen] = useState(false);
    const [dialogVariazioneSedeOrarioOpen, setDialogVariazioneSedeOrarioOpen] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [idConvenzione, setIdConvenzione] = useState(-1);
    const [idProgettoFormativo, setIdProgettoFormativo] = useState(-1);
    const [idStatoModifica, setIdStatoModifica] = useState(-1);

    const [ragioneSociale, setRagioneSociale] = useState('');
    const [ragioneSocialeValid, setRagioneSocialeValid] = useState(true);
    const [ragioneSocialeMessageError, setRagioneSocialeMessageError] = useState('');


    const [pIva, setPIva] = useState('');
    const [pIvaValid, setPIvaValid] = useState(true);
    const [pIvaMessageError, setPIvaMessageError] = useState('');

    const [codProgettoFormativo, setCodProgettoFormativo] = useState('');
    const [codProgettoFormativoValid, setCodProgettoFormativoValid] = useState(true);
    const [codProgettoFormativoMessageError, setCodProgettoFormativoMessageError] = useState('');

    const [protocolloConvenzione, setProtocolloConvenzione] = useState('');
    const [protocolloConvenzioneValid, setProtocolloConvenzioneValid] = useState(true);
    const [protocolloConvenzioneMessageError, setProtocolloConvenzioneMessageError] = useState('');

    const [sedeSelected, setSedeSelected] = useState(-1);

    const [statoProgetto, setStatoProgetto] = useState(-1);

    const [confirmAlertDeleteIsOpen, setConfirmAlertDeleteIsOpen] = useState(false);
    const [buttonConfermaDisabled, setButtonConfermaDisabled] = useState(false);
    const [filtriRicercaNotCollapsed, setFiltriRicercaNotCollapsed] = useState(true);

    const [pivaAziendaLocationState, setPivaAziendaLocationState] = useState(-1);

    const [dataDaInserimento, setDataDaInserimento] = useState(null);
    const [dataAInserimento, setDataAInserimento] = useState(null);
    const [listProgetti, setListProgetti] = useState([]);
    const [progettoStatiList, setProgettoStatiList] = useState([]);

    const [corsiList, setCorsiList] = useState([]);
    const [corsoSelected, setCorsoSelected] = useState(-1);

    const [nomeAlunno, setNomeAlunno] = useState('');
    const [nomeAlunnoValid, setNomeAlunnoValid] = useState(true);
    const [nomeAlunnoMessageError, setNomeAlunnoMessageError] = useState('');

    const [cognomeAlunno, setCognomeAlunno] = useState('');
    const [cognomeAlunnoValid, setCognomeAlunnoValid] = useState(true);
    const [cognomeAlunnoMessageError, setCognomeAlunnoMessageError] = useState('');

    const [CFAlunno, setCFAlunno] = useState('');
    const [CFAlunnoValid, setCFAlunnoValid] = useState(true);
    const [CFAlunnoMessageError, setCFAlunnoMessageError] = useState('');

    // const [canEdit, setCanEdit] = useState(true);

    const [protocolloConvenzioneLocation, setProtocolloConvenzioneLocation] = useState(undefined);

    const [dialogElencoVariazioniOpen, setDialogElencoVariazioniOpen] = useState(false);
    const [dialogLegendaOpen, setDialogLegendaOpen] = useState(false);
    const [dialogVariazioneOpen, setDialogVariazioneOpen] = useState(false);
    const [dialogVariazioneTutorOpen, setDialogVariazioneTutorOpen] = useState(false);

    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const [isVariazioneSede, setIsVariazioneSede] = useState(false);
    const [documentiDialogOpen, setDocumentiDialogOpen] = useState(false);
    const [listDocumenti, setListDocumenti] = useState([]);

    const [codiceProgetto, setCodiceProgetto] = useState('');

    const [debugMode, setDebugMode] = useState(false);

    const [isModifica, setIsModifica] = useState(true);
    const [aziendaSimulata,setAziendaSimulata] = useState(null);

    useEffect(() => {
        if (process.env.REACT_APP_IS_DEBUG == "true") setDebugMode(true);
        else setDebugMode(false);
        getProgettoFormativoStatiList();
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
        getSedi(annoFormativoTmp);
        // if (location.state !== null && location.state.pIva !== undefined) {
        //     setPIva(location.state.pIva);
        //     setPivaAziendaLocationState(location.state.pIva); //Se diverso da -1 abilito il pulsante torna indietro
        //     // ricercaConvenzione(annoFormativoTmp, location.state.pIva)

        // }
        // if (location.state !== null && location.state.protocolloConvenzione !== undefined) {
        //     setProtocolloConvenzione(location.state.protocolloConvenzione);
        //     if (location.state.aggiungiProgettoFormativo !== undefined && location.state.aggiungiProgettoFormativo === true) {
        //         setDialogAggiungiProgettoOpen(true);
        //         setProtocolloConvenzioneLocation(location.state.protocolloConvenzione);
        //     }
        //     else ricercaProgetto(annoFormativoTmp, location.state.protocolloConvenzione);

        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.isInsideOtherComponent !== undefined)
            setIsInsideOtherComponent(props.isInsideOtherComponent);
    }, [props.isInsideOtherComponent])



    function goBackToGestioneAziende() {
        navigate("/tirocini/gestione-aziende", { state: { pIva: pivaAziendaLocationState } })
    }

    function validate(e) {
        e.preventDefault()
        startSearch()
    }

    function changeCorso(e) {
        if (e.target.value !== '') {
            setCorsoSelected(e.target.value);
        }
        else {
            setCorsoSelected(-1);
        }
    };


    function startSearch() {
        setSearchStarted(true)
    }

    function removeFilters() {

        setFiltriRicercaNotCollapsed(true); //Se rimuovo i filtri apro il collapse

        setRagioneSociale('');
        setRagioneSocialeValid(true);
        setRagioneSocialeMessageError('');

        setCorsoSelected(-1);

        setNomeAlunno('');
        setNomeAlunnoValid(true);
        setNomeAlunnoMessageError('');

        setCognomeAlunno('');
        setCognomeAlunnoValid(true);
        setCognomeAlunnoMessageError('');

        setPIva('');
        setPIvaValid(true);
        setPIvaMessageError('');

        setCFAlunno('');
        setCFAlunnoValid(true);
        setCFAlunnoMessageError('')

        setCodProgettoFormativo('');
        setCodProgettoFormativoValid(true);
        setCodProgettoFormativoMessageError('');

        setProtocolloConvenzione('');
        setProtocolloConvenzioneValid(true);
        setProtocolloConvenzioneMessageError('');

        setDataAInserimento(null);
        setDataDaInserimento(null);
        setStatoProgetto(-1);

        if (sediList.length > 1) setSedeSelected(-1);
        setCorsoSelected(-1);

        setSearchStarted(false);
        // setSearchLoaded(false);

        setTimeout(() => {
            setListProgetti([]);
            setHasLoaded(false)
        }, 200)
    }

    function openAggiungiProgettoFormativo() {
        setDialogAggiungiProgettoOpen(prev => !prev);

        // setListProgetti([]);
        // removeFilters();
    }

    function openDialogAssegnaAllievo() {
        setDialogAssegnaAllievoOpen(prev => !prev);
    }

    function closeDialogAssegnaAllievo() {
        setIsModifica(true);
        ricercaProgetto(annoFormativo);
        openDialogAssegnaAllievo();
        setAziendaSimulata(null);
    }

    function proceduraConclusa(idProgettoFormativo) {
        openAggiungiProgettoFormativo()
        ricercaProgetto(annoFormativo);
        openAlertMsg('Salvataggio avvenuto con successo', 'success')
        // downloadConvenzione(idProgettoFormativo)
    }

    function changeSede(e) {
        if (e.target.value !== '') {
            setSedeSelected(e.target.value);
            getClassiBySede(e.target.value, annoFormativo);
        }
        else {
            setSedeSelected(-1);
        }
    };

    async function getSedi(annoFormativo) {
        RicercaAPI.getSedi({ annoFormativo: annoFormativo })
            .then((result) => {
                let sediTmp = JSON.parse(JSON.stringify(result.sedi)); // This creates a deep copy of the array                
                let indexSedeLegale = sediTmp.findIndex(sede => sede?.id === 1);
                if (indexSedeLegale !== -1) {
                    sediTmp[indexSedeLegale].nomeSede = "Tutte le sedi"
                }
                setSediList([...result.sedi])

                if (result.sedi.length === 1) {
                    let idSedeTmp = result?.sedi[0]?.id
                    setSedeSelected(idSedeTmp ?? -1)
                    getClassiBySede(idSedeTmp, annoFormativo)
                }
            })
            .catch(function (error) {
            })
    }
    async function getClassiBySede(idSede, annoFormativo) {
        let dataObj = {
            idSede: idSede,
            annoFormativo: annoFormativo
        }

        RicercaAPI.getClassiBySede(dataObj)
            .then((result) => {
                setCorsiList(result.sedi ?? []);
            })
            .catch(function (error) {
            })
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg)
        setAlertSeverity(severity)
        setAlertOpen(true)
    }

    function openVisualizzaDettaglioProgetto(aziendaSimulata, isModifica) {
        setIsModifica(isModifica);
        // setIdProgettoFormativo(idProgettoFormativo);
        setAziendaSimulata(aziendaSimulata);
        setTimeout(openDialogAssegnaAllievo(), 300);
        //removeFilters();
    }


    function deleteConvenzione(idConvenzione) {
        setConfirmAlertDeleteIsOpen(true);
        setIdConvenzione(idConvenzione);
    }

    function openLegendaDialog() {
        setDialogLegendaOpen(prev => !prev);
    }

    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
        setAziendaSimulata(null);
    }

    function deleteItem(idProgetto) {
        setIdProgettoFormativo(idProgetto);
        setConfirmAlertIsOpen(true);
    }

    // function changeIdProgettoFormativo(idProgettoFormativo) {
    //     setIdProgettoFormativo(idProgettoFormativo)
    // }

    function gestioneFiltroRicerca() {
        if (process.env.REACT_APP_IS_DEBUG == "true") {
            if (ragioneSocialeValid &&
                nomeAlunnoValid &&
                cognomeAlunnoValid &&
                pIvaValid &&
                CFAlunnoValid &&
                codProgettoFormativoValid
            ) return false
            else return true
        }
        else {
            if (ragioneSocialeValid &&
                nomeAlunnoValid &&
                cognomeAlunnoValid &&
                pIvaValid &&
                CFAlunnoValid &&
                codProgettoFormativoValid &&
                (ragioneSociale !== '' ||
                    nomeAlunno !== '' ||
                    cognomeAlunno !== '' ||
                    pIva !== '' ||
                    CFAlunno !== '' ||
                    codProgettoFormativo !== '' ||
                    (dataAInserimento !== null && dataDaInserimento !== null) ||
                    protocolloConvenzione !== '' ||
                    corsoSelected !== -1
                )
            ) return false
            else return true
        }
    }

    async function ricercaProgetto(annoFormativoTmp) {
        setFiltriRicercaNotCollapsed(false); //Se avvio la ricerca  chiudo il collapse dei filtri
        setButtonConfermaDisabled(true);
        let obj = {
            annoFormativo: annoFormativoTmp === undefined ? annoFormativo : annoFormativoTmp,
            idCorso: corsoSelected !== -1 ? corsoSelected : null,
            idSede: sedeSelected
        }

        if (dataDaInserimento !== null && dataAInserimento === null)
            setDataDaInserimento(null);

        TirociniAPI.getElencoAlunniAziendaSimulata(obj)
            .then((result) => {
                setListProgetti([...result.allievi]);
                setSearchStarted(true);

                setTimeout(() => {
                    setHasLoaded(true);
                }, 200)
                setButtonConfermaDisabled(false);
            })
            .catch(function (error) {
                setTimeout(() => {
                    setHasLoaded(true);
                }, 200)
                setListProgetti([]);
                setButtonConfermaDisabled(false);
            })

    }

    async function getProgettoFormativoStatiList() {
        TirociniAPI.getProgettoFormativoStatiList()
            .then((result) => {
                setProgettoStatiList(result);
            })
            .catch(function (error) {
            })
    }


   

    async function deleteAlunnoAziendaSimulata() {
        setButtonConfermaDisabled(true);
        let obj = {
            idProgetto: idProgettoFormativo
        }
        TirociniAPI.deleteAlunnoAziendaSimulata(obj)
            .then((result) => {
                setIdProgettoFormativo(-1);
                let msgTmp = result.msg ?? "Eliminazione avvenuta con successo.";
                openAlertMsg(msgTmp, 'success');
                closeAlertConfirmInserimento();
                ricercaProgetto(annoFormativo);
                setButtonConfermaDisabled(false);
            })
            .catch(function (error) {
                if (error?.response !== undefined) {
                    let errorMsg = "Errore, riprovare tra qualche istante.";
                    if (error.response.data && Array.isArray(error.response.data.data) && error.response.data.data.length > 0) {
                        errorMsg = error.response.data.data[0]?.msg ?? errorMsg;
                    }
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore, riprovare tra qualche istante.", "error");
                }
                setButtonConfermaDisabled(false);
            })
    }

    const exportFile = (data, fileName, type) => {

        const blob = new Blob([data], { type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        setIsDownloading(false);
    };
    return (
        <Fragment>

            {!isInsideOtherComponent ? (
                <Breadcrumb sections={["Gestione azienda simulata"]} isTirocinio={true} />
            ) : null}


            <Container disableGutters={isInsideOtherComponent} maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>

                <Paper sx={{ width: '100%', mt: isInsideOtherComponent ? 0 : 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", p: 2 }}>
                        <Typography variant="h6">Ricerca alunni assegnati ad azienda simulata</Typography>
                        <Chip icon={filtriRicercaNotCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />} label={filtriRicercaNotCollapsed ? "Riduci filtri" : "Espandi filtri"} variant="outlined" onClick={() => setFiltriRicercaNotCollapsed(prev => !prev)} />
                    </Box>
                    <Divider />
                    <Collapse in={filtriRicercaNotCollapsed} sx={{ p: filtriRicercaNotCollapsed ? 2 : 0 }}>
                        <form onSubmit={validate} style={{ width: '100%' }}>
                            <Grid container spacing={2} sx={{ width: '100%', pt: 2 }} alignItems={"start"}>
                                <Grid xs={12} md={4}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="sede-label">
                                            Seleziona sede Ciofs
                                        </InputLabel>
                                        <Select
                                            data-testid='sedeRicercaSelect'
                                            label="Seleziona sede Ciofs"
                                            labelId="sede-label"
                                            value={sedeSelected}
                                            onChange={changeSede}
                                            disabled={sediList.length <= 1}
                                        >
                                            <MenuItem
                                                key={-1}
                                                value={-1}
                                            >
                                                ...
                                            </MenuItem>
                                            {sediList.map((sede) => (
                                                <MenuItem
                                                    key={sede.id}
                                                    value={sede.id}
                                                >
                                                    {sede.nomeSede}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={8}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="corso-label">
                                            Seleziona corso
                                        </InputLabel>
                                        <Select
                                            label="Seleziona corso"
                                            labelId="corso-label"
                                            value={corsoSelected}
                                            onChange={changeCorso}
                                            disabled={!corsiList.length > 0 || sedeSelected === -1}
                                        >
                                            <MenuItem
                                                key={-1}
                                                value={-1}
                                            >
                                                ...
                                            </MenuItem>
                                            {corsiList.map((corso) => (
                                                <MenuItem
                                                    key={corso.id}
                                                    value={corso.id}
                                                >
                                                    {corso?.codiceCorso ?? ''} - {corso.nomeCorso}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </form>
                    </Collapse>
                    {(!debugMode) && (<Box p={2}>
                        <Alert severity="info">Per avviare la ricerca almeno uno dei campi del form deve essere valorizzato (escluso il campo "Stato progetto").</Alert>
                    </Box>)}
                    {filtriRicercaNotCollapsed && (<Divider />)}
                    <Box sx={{ p: 2, display: "flex", alignItems: "start", flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'end', lg: "space-between" } }}>
                        <Box sx={{ width: { xs: '100%', lg: 'fit-content' } }}>
                            {/* {pivaAziendaLocationState !== -1 && (
                                <Button startIcon={<ArrowBack />} sx={{ height: 'fit-content', mr: { xs: 0, lg: 1 }, mt: { xs: 1, lg: 0 }, px: { xs: 0, lg: 5 }, width: { xs: '100%', lg: 'fit-content' } }} variant='outlined' onClick={goBackToGestioneAziende}>Torna alla gestione aziende</Button>
                            )} */}
                            {searchStarted ? (
                                <Button sx={{ height: 'fit-content', mt: { xs: 1, lg: 0 }, px: { xs: 0, lg: 5 }, width: { xs: '100%', lg: 'fit-content' } }} onClick={removeFilters} variant="outlined" color="error" endIcon={<Clear />}>
                                    Rimuovi filtri
                                </Button>
                            ) : (
                                <span></span>
                            )}
                        </Box>
                        <Box sx={{ width: { xs: '0px', md: '15px' } }}></Box>
                        <Box sx={{ width: { xs: '100%', lg: 'fit-content' } }}>
                            {/* {(corsoSelected !== -1 && sedeSelected !== -1 && searchStarted && listProgetti.length > 0) && (
                                <Button disabled={isDownloading} sx={{ height: 'fit-content', ml: { xs: 0, lg: 1 }, px: { xs: 0, lg: 5 }, mt: { xs: 1, lg: 0 }, width: { xs: '100%', lg: 'fit-content' } }} onClick={() => downloadDocumento()} endIcon={<Download />} color='secondary' variant="contained">
                                    Download elenco aziende tirocinio formativo
                                </Button>
                            )} */}
                            <Button sx={{ height: 'fit-content', ml: { xs: 0, lg: 1 }, px: { xs: 0, lg: 5 }, mt: { xs: 1, lg: 0 }, width: { xs: '100%', lg: 'fit-content' } }} onClick={() => ricercaProgetto(annoFormativo)} endIcon={<SearchIcon />} disabled={gestioneFiltroRicerca() || buttonConfermaDisabled} variant="contained">
                                Avvia ricerca
                            </Button>
                            <Button
                                sx={{ height: 'fit-content', ml: { xs: 0, lg: 1 }, px: { xs: 0, lg: 5 }, mt: { xs: 1, lg: 0 }, width: { xs: '100%', lg: 'fit-content' } }}
                                // onClick={() => setDialogAggiungiProgettoOpen(true)}
                                onClick={() => setDialogAssegnaAllievoOpen(true)}
                                endIcon={<Add />}
                                color="success"
                                variant="contained">
                                Assegna allievo ad azienda simulata
                            </Button>
                        </Box>
                    </Box>
                </Paper>
                <Collapse in={searchStarted} sx={{ my: 2, width: '100%' }}>
                    <Paper sx={{ overflowY: 'auto', maxHeight: '60vh' }}>
                        {hasLoaded ? (
                            listProgetti.length > 0 ?
                                (
                                    <Fragment>
                                        <TableAziendaSimulata
                                            list={listProgetti}
                                            visualizzaItem={openVisualizzaDettaglioProgetto}
                                            openLegendaDialog={openLegendaDialog}
                                            deleteItem={deleteItem}
                                        />
                                    </Fragment>
                                )
                                :
                                (
                                    <Box sx={{ height: '60vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Alert sx={{ mb: 2 }} severity="info">La ricerca non ha prodotto alcun risultato con i filtri selezionati.</Alert>
                                    </Box>
                                )

                        ) : (
                            <Box sx={{ height: '65vh' }} flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                <CircularProgress sx={{ mb: 4 }} />
                                <Typography >Caricamento in corso...</Typography>
                            </Box>
                        )}
                    </Paper>
                </Collapse>
            </Container>

            <Dialog fullWidth fullScreen maxWidth={'xs'} open={dialogAssegnaAllievoOpen} sx={{ mt: '8vh' }}>
                <DettaglioAziendaSimulata
                    closeDialog={closeDialogAssegnaAllievo}
                    idConvenzione={idConvenzione}
                    aziendaSimulata={aziendaSimulata}
                    protocolloConvenzione={protocolloConvenzioneLocation}
                    // changeIdProgettoFormativo={changeIdProgettoFormativo}
                    proceduraConclusa={proceduraConclusa}
                    isModifica={isModifica}
                />
            </Dialog>
            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={'Conferma eliminazione'}
                icon={<Delete sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                text={`Confermi di voler eliminare l'assegnazione dell'alunno ad azienda simulata? `}
                showAlunni={false}
                confermaButton={'Elimina'}
                severity={'error'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={deleteAlunnoAziendaSimulata}//{modificaStatoProgetto}
                disabledButton={buttonConfermaDisabled}
            />
            <Dialog fullWidth maxWidth={'sm'} open={dialogLegendaOpen} sx={{ mt: '8vh' }} onClose={openLegendaDialog}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Legenda</Typography>
                    <IconButton onClick={openLegendaDialog}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <LegendaAziendaSimulata />
                </DialogContent>
            </Dialog>
            {
                !isInsideOtherComponent ? (
                    <Helmet>
                        <title>{t("name")} - Gestione progetti formativi</title>
                    </Helmet>
                ) : null
            }

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>

        </Fragment >
    );
}
