import React from 'react';
import { useState, useEffect, Fragment, useRef } from "react"
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, useTheme, useMediaQuery, IconButton,
    Dialog, DialogContent, DialogTitle, Button, Collapse, Alert, CircularProgress, Divider,
    DialogActions,
    ToggleButtonGroup,
    ToggleButton,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Close, ArrowBack, Edit, Help, WarningAmber } from '@mui/icons-material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ScrutiniAPI } from '../../../api/scrutiniApi';
import EventoChip from '../../registri/shared/eventoChip';
import { DateTime } from 'luxon';
import { changeColorVoto, copyArrayWithoutReference } from '../../shared/utilityMethods';
import SchedaAlunnoNoteAnnotazioni from './schedaAlunnoNoteAnnotazioni';
import DialogConferma from '../../shared/dialogConferma';
import { convertDataDiNascita } from '../../shared/metodiDateUtility';

export default function SchedaAlunno(props) {
    const [showCondotta, setShowCondotta] = useState(false)
    const [legendaDialogOpen, setLegendaDialogOpen] = useState(false);

    const [showCrediti, setShowCrediti] = useState(false);

    const [listMaterie, setListMaterie] = useState([]);
    const [nomeSede, setNomeSede] = useState("Ginori");
    const [idCorso, setIdCorso] = useState(828);
    const [nomeCorso, setNomeCorso] = useState("");
    const [idScrutinio, setIdScrutinio] = useState(null);

    const [alunnoSelezionato, setAlunnoSelezionato] = useState(null);
    const [periodoSelezionato, setPeriodoSelezionato] = useState(null);
    const [nomeStato, setNomeStato] = useState("-")
    const [showIniziaScrutinio, setShowIniziaScrutinio] = useState(true);

    const [indexAlunnoSelezionato, setIndexAlunnoSelezionato] = useState(0);
    const [listAlunni, setListAlunni] = useState([]);
    const [listNote, setListNote] = useState([]);
    const [listAnnotazioni, setListAnnotazioni] = useState([]);

    const [hasLoaded, setHasLoaded] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [condottaObj, setCondottaObj] = useState(null);

    const [inserimentoVotiOpen, setInserimentoVotiOpen] = useState(false);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

    const [loadingSchedaScrutinio, setLoadingSchedaScrutinio] = useState(false);

    const [showAlertNextStudente, setShowAlertNextStudente] = useState(false);

    const [timestampInizioScrutinioAlunno, setTimestampInizioScrutinioAlunno] = useState("");
    const [votiScrutinio, setVotiScrutinio] = useState([]);
    const [condottaScrutinioMotivazione, setCondottaScrutinioMotivazione] = useState(null);
    const [condottaScrutinioInterazione, setCondottaScrutinioInterazione] = useState(null);
    const [condottaScrutinioRelazione, setCondottaScrutinioRelazione] = useState(null);
    const [isPrescrutinio, setIsPrescrutinio] = useState(false);
    const [isScrutinioConcluso, setIsScrutinioConcluso] = useState(false);

    const [votiProposti, setVotiProposti] = useState([]);
    const votiScrutinioRef = useRef([])
    const votoCondottaMotRef = useRef()
    const votoCondottaIntRef = useRef()
    const votoCondottaRelRef = useRef()
    const noteRef = useRef()

    const nomeRef = useRef()

    const [isButtonConfermaDisabled, setIsButtonConfermaDisabled] = useState(true);
    const [showConcludi, setShowConcludi] = useState(false);
    const [isConfermaLoading, setIsConfermaLoading] = useState(false);
    const [frequenzaObj, setFrequenzaObj] = useState(null);

    const [esitoAlunnoVisualizzazione, setEsitoAlunnoVisualizzazione] = useState(undefined)
    const [esitoAlunnoIdoneo, setEsitoAlunnoIdoneo] = useState(undefined)
    const [showEsito, setShowEsito] = useState(true);
    const [isUltimoPeriodo, setIsUltimoPeriodo] = useState(false);
    const [provaDiPassaggio, setProvaDiPassaggio] = useState(-1);
    const [showErrorEsito, setShowErrorEsito] = useState(false);
    const [provaDiPassaggioInput, setProvaDiPassaggioInput] = useState(-1);

    const [firstColLimit, setFirstColLimit] = useState(0);
    const [secondColLimit, setSecondColLimit] = useState(0);
    const [esitoHasProblems, setEsitoHasProblems] = useState(false);
    const dialogContentRef = useRef(null);
    const provaDiPassaggioRef = useRef(null);

    const [numericoCorso, setNumericoCorso] = useState(1);
    const [creditiFormativi, setCreditiFormativi] = useState(0)
    const [canCalculateCrediti, setCanCalculateCrediti] = useState(true);
    const [showProvaPassaggio, setShowProvaPassaggio] = useState(false);

    useEffect(() => {
        if (props.idCorso !== undefined && props.periodoSelezionato !== undefined && props.alunno !== undefined && props.listAlunni !== undefined && props.indexAlunno !== undefined) {
            setIsUltimoPeriodo(props?.isUltimoPeriodo ?? false);
            setIdCorso(props.idCorso);
            setPeriodoSelezionato(props.periodoSelezionato);
            setIndexAlunnoSelezionato(props.indexAlunno);
            setListAlunni([...props.listAlunni])
            let alunnoSelezionato = props.listAlunni[props.indexAlunno]
            setAlunnoSelezionato(alunnoSelezionato)
            getSchedaAlunno(props.idCorso, props.periodoSelezionato.id, props.alunno.id);
            if (props.idScrutinio !== undefined && props.idScrutinio !== null) {
                //recuperoVotiScrutinioAlunno(props.idCorso, props.periodoSelezionato.id, props.alunno.id, props.idScrutinio);
                setIdScrutinio(props.idScrutinio);
            }
        }
    }, [props.idCorso, props.periodoSelezionato, props.alunno, props.idScrutinio, props.indexAlunno, props.listAlunni, props.isUltimoPeriodo])

    useEffect(() => {
        if (props.numericoCorso !== undefined)
            setNumericoCorso(props.numericoCorso);
    }, [props.numericoCorso])

    useEffect(() => {
        if (props.isScrutinioConcluso !== undefined)
            setIsScrutinioConcluso(props.isScrutinioConcluso);
    }, [props.isScrutinioConcluso])

    useEffect(() => {
        if (props.showConcludi !== undefined)
            setShowConcludi(props.showConcludi);
    }, [props.showConcludi])

    useEffect(() => {
        if (props.nomeSede !== undefined)
            setNomeSede(props.nomeSede)
    }, [props.nomeSede])

    useEffect(() => {
        if (props.nomeStato !== undefined)
            setNomeStato(props.nomeStato)
    }, [props.nomeStato])

    useEffect(() => {
        if (props.nomeCorso !== undefined) {
            setNomeCorso(props.nomeCorso)
        }
    }, [props.nomeCorso])

    useEffect(() => {
        if (props.showIniziaScrutinio !== undefined) {
            setShowIniziaScrutinio(props.showIniziaScrutinio)
        }
    }, [props.showIniziaScrutinio])

    function changeOpenCondotta() {
        setShowCondotta(prev => !prev)
    }

    function openInserimentoVoti() {
        setLoadingSchedaScrutinio(true)
        let isOpening = !inserimentoVotiOpen;
        if (isOpening) recuperoVotiScrutinioAlunno(idCorso, periodoSelezionato?.id, alunnoSelezionato?.id, idScrutinio, false);
        setInserimentoVotiOpen(isOpening);
        let nowInRome = DateTime.now().setZone('Europe/Rome');

        // Converte l'ora di Roma in formato UTC
        let romeTimeInUTC = DateTime.utc(nowInRome.year, nowInRome.month, nowInRome.day, nowInRome.hour, nowInRome.minute, nowInRome.second);

        // Formatta la data e l'ora
        let formattedDate = romeTimeInUTC.toISO();
        setTimestampInizioScrutinioAlunno(formattedDate);
    }

    function openModificaVoti() {
        setLoadingSchedaScrutinio(true)
        let isOpening = !inserimentoVotiOpen;
        setInserimentoVotiOpen(isOpening);
        if (isOpening) recuperoVotiScrutinioAlunno(idCorso, periodoSelezionato?.id, alunnoSelezionato?.id, idScrutinio, true);
        //setTimestampInizioScrutinioAlunno(timestamp());

    }

    function closeInserimento() {
        setShowAlertNextStudente(false);
        setInserimentoVotiOpen(false);
        setVotiScrutinio([])
        setEsitoAlunnoIdoneo(undefined);
        setProvaDiPassaggioInput(-1);
        //getSchedaAlunno(idCorso,periodoSelezionato?.id, alunnoSelezionato?.id)
    }

    function saveCloseInserimentiVoti() {

        saveVotiScrutinio(true);
        // props.closeDettaglio();
    }

    function saveVotiAndGoNext() {

        setCanCalculateCrediti(true);
        setIsConfermaLoading(true);
        setShowIniziaScrutinio(false); //Quando faccio salva e prosegui imposto questo a false, in modo che al posto di "Inizia scrutinio" appaia solo il pulsante "Scrutinio"
        setLoadingSchedaScrutinio(true);
        setShowAlertNextStudente(true);
        saveVotiScrutinio(false);
        // let newIndexAlunnoSelezionato = indexAlunnoSelezionato +1;
        // setIndexAlunnoSelezionato(newIndexAlunnoSelezionato);
        // setAlunnoSelezionato(listAlunni[newIndexAlunnoSelezionato])
        // // setInserimentoVotiOpen(false);

        // setHasLoaded(false);
        // getSchedaAlunno(idCorso, periodoSelezionato.id, listAlunni[newIndexAlunnoSelezionato].id)
        // nomeRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    function goBackToVistaClasse() {
        setListAnnotazioni([]);
        setListNote([]);
        props.closeDettaglio();
    }

    function changeEsitoAlunnoIdoneo(e) {
        if (esitoHasProblems) {
            if (e.target.value == "false") {
                setShowErrorEsito(false);
                setEsitoHasProblems(false);
            }
        }
        setEsitoAlunnoIdoneo(e.target.value);
    }

    function calcolaColorePercentuale(percentualeString) {
        if (percentualeString !== undefined) {
            let nuovaString = percentualeString.split("%", 2);
            let percentualeIntera = parseInt(nuovaString);
            if (percentualeIntera < 25) return "green"
            else return "red";
        } else return "black"
    }
    function calcolaFontWeightPercentuale(percentualeString) {
        if (percentualeString !== undefined && percentualeString !== -1) {
            let nuovaString = percentualeString.split("%", 2);
            let percentualeIntera = parseInt(nuovaString);
            if (percentualeIntera < 25) return "normal"
            else return "bold";
        } else return "normal"
    }

    function calcolaColoreMedia(mediaString) {
        if (mediaString !== undefined && mediaString !== -1) {
            let percentualeIntera = parseInt(mediaString);
            if (percentualeIntera < 18) return "red"
            else return "green";
        } else return "black"
    }

    async function getSchedaAlunno(idCorso, idPeriodo, idAlunno) {

        const obj = {
            idAlunno: idAlunno,
            idCorso: idCorso,
            idPeriodo: idPeriodo
        }
        if (idCorso !== undefined && idPeriodo !== undefined && idAlunno !== undefined) {
            ScrutiniAPI.getSchedaAlunno(obj)
                .then((result) => {
                    setCreditiFormativi(0);
                    setCondottaObj(result?.periodo); //Contiene le 3 medie
                    setIsPrescrutinio(result?.periodo?.condottaScrutinio?.is_prescrutinio)
                    let materieTmp = [];
                    for (let materia of result.materie) {
                        let materiaTmp = materia;
                        if (!materieTmp.some(materiaTmp => materiaTmp.id === materia.id)) {
                            materieTmp.push(materiaTmp);
                        }
                        else {
                            let indexMateria = materieTmp.findIndex(m => m.id === materia.id)
                            if (indexMateria !== -1) {
                                let nomeDocente = materieTmp[indexMateria].docente + ", " + materia.docente
                                materieTmp[indexMateria].docente = nomeDocente;
                            }
                        }
                    }

                    //materieTmp.sort((a, b) => a.nome.localeCompare(b.nome));

                    setFirstColLimit(Math.ceil(materieTmp.length / 3))
                    setSecondColLimit(Math.ceil(2 * materieTmp.length / 3))

                    setListMaterie([...materieTmp])
                    let votiPropostiTmp = []
                    for (let materia of result?.materie) {
                        let votoProposto = {
                            idMateria: materia.id,
                            voto: materia.votoProposto
                        }
                        if (!votiPropostiTmp.some(voto => voto.idMateria === materia.id))
                            votiPropostiTmp.push(votoProposto);
                    }
                    setVotiProposti([...votiPropostiTmp])
                    //setVotiScrutinio([...votiScrutinioTmp])
                    votiScrutinioRef.current = votiScrutinioRef.current.slice(0, materieTmp.length);

                    setProvaDiPassaggio(result?.provaDiPassaggio ?? -1);
                    setProvaDiPassaggioInput(result?.provaDiPassaggio ?? -1);

                    if (provaDiPassaggioRef?.current?.value !== undefined) {
                        if (result?.provaDiPassaggio == -1)
                            provaDiPassaggioRef.current.value = "/"
                        if (result?.provaDiPassaggio == 0)
                            provaDiPassaggioRef.current.value = "N.V."
                        if (result?.provaDiPassaggio != -1 && result?.provaDiPassaggio != 0)
                            provaDiPassaggioRef.current.value = result?.provaDiPassaggio ?? "/"
                    }

                    if (result?.esitoScrutinio !== undefined && result?.esitoScrutinio != null) {
                        if (result?.esitoScrutinio !== undefined && result?.esitoScrutinio !== null) {
                            let esitoTmp = result?.esitoScrutinio === false ? "false" : "true"
                            setEsitoAlunnoIdoneo(esitoTmp)
                            setEsitoAlunnoVisualizzazione(esitoTmp)
                        }
                    }

                    setIsConfermaLoading(false);
                    setListAnnotazioni([...result?.comunicazioni?.annotazioni])
                    setIsConfermaLoading(false);
                    setListNote([...result?.comunicazioni?.note])
                    setHasErrors(false);
                    setHasLoaded(true);

                    setTimeout(() => {
                        if (dialogContentRef.current) {
                            dialogContentRef.current.scrollTop = 0;
                        }
                    }, 500)
                })
                .catch((error) => {
                    setHasLoaded(true);
                    setHasErrors(true);
                    setErrorMsg("Al momento non è possibile consultare la scheda alunno. Riprovare tra qualche minuto.")
                })
        }
    }

    async function recuperoVotiScrutinioAlunno(idCorso, idPeriodo, idAlunno, idScrutinio, getDataInizio) {
        const obj = {
            idAlunno: idAlunno,
            idCorso: idCorso,
            idScrutinio: idScrutinio,
            idPeriodo: idPeriodo
        }

        if (idCorso !== undefined && idPeriodo !== undefined && idAlunno !== undefined && idScrutinio !== undefined) {
            ScrutiniAPI.recuperoVotiScrutinioAlunno(obj)
                .then((result) => {
                    setShowEsito(result?.showEsito ?? undefined);
                    if (result?.showEsito !== undefined && result?.showEsito === true) {
                        if (result?.votiScrutinioDaDB[0]?.esito !== undefined && result?.votiScrutinioDaDB[0]?.esito !== null) {
                            let esitoTmp = result?.votiScrutinioDaDB[0]?.esito === false ? "false" : "true"
                            setEsitoAlunnoIdoneo(esitoTmp)

                        }
                    }

                    setShowCrediti(result?.showCrediti ?? false)
                    setShowProvaPassaggio(result?.showProvaPassaggio ?? false);

                    if (result?.datiFrequenza !== undefined)
                        setFrequenzaObj(result?.datiFrequenza ?? null)

                    if (result?.votiScrutinioDaDB[0]?.voti !== undefined) {
                        setCondottaScrutinioInterazione(result?.votiScrutinioDaDB[0]?.condotta?.interazione ?? null)
                        setCondottaScrutinioMotivazione(result?.votiScrutinioDaDB[0]?.condotta?.motivazione ?? null)
                        setCondottaScrutinioRelazione(result?.votiScrutinioDaDB[0]?.condotta?.relazione ?? null)


                        if (votoCondottaMotRef.current !== undefined) {
                            votoCondottaMotRef.current.value = returnVotoConverted(result?.votiScrutinioDaDB[0]?.condotta?.motivazione)
                        }

                        if (votoCondottaIntRef.current !== undefined) {
                            votoCondottaIntRef.current.value = returnVotoConverted(result?.votiScrutinioDaDB[0]?.condotta?.interazione)
                        }

                        if (votoCondottaRelRef.current !== undefined) {
                            votoCondottaRelRef.current.value = returnVotoConverted(result?.votiScrutinioDaDB[0]?.condotta?.relazione)
                        }

                        if (getDataInizio) {
                            if (result?.votiScrutinioDaDB[0]?.data_inizio_scrutinio != null && result?.votiScrutinioDaDB[0]?.data_inizio_scrutinio != undefined)
                                setTimestampInizioScrutinioAlunno(result?.votiScrutinioDaDB[0]?.data_inizio_scrutinio ?? null);
                            else {
                                let nowInRome = DateTime.now().setZone('Europe/Rome');
                                let romeTimeInUTC = DateTime.utc(nowInRome.year, nowInRome.month, nowInRome.day, nowInRome.hour, nowInRome.minute, nowInRome.second);
                                let formattedDate = romeTimeInUTC.toISO();
                                setTimestampInizioScrutinioAlunno(formattedDate);
                            }
                        }
                        let votiScrutinioTmp = copyArrayWithoutReference(votiProposti);
                        for (let voto of result?.votiScrutinioDaDB[0]?.voti) {
                            if (listMaterie.some(m => m.id === voto.idMateria)) { //Arrivano alcune materie che non mi restituisce il metodo scheda alunno
                                let indexVotoScrutinio = votiScrutinioTmp.findIndex(v => v.idMateria === voto.idMateria);
                                if (indexVotoScrutinio !== -1) {
                                    votiScrutinioTmp[indexVotoScrutinio].idMateria = voto.idMateria;
                                    let votoTmp = voto?.voto ?? null;
                                    //if (votoTmp === 0) votoTmp = "N.V."
                                    votiScrutinioTmp[indexVotoScrutinio].voto = votoTmp;
                                    if (votiScrutinioRef.current[indexVotoScrutinio] !== undefined)
                                        votiScrutinioRef.current[indexVotoScrutinio].value = returnVotoConverted(votoTmp, false);
                                } else {
                                    let votoTmp = voto?.voto ?? null;
                                    //  if (votoTmp === 0) votoTmp = "N.V."
                                    let votoScrutinio = {
                                        idMateria: voto.idMateria,
                                        voto: votoTmp
                                    }
                                    votiScrutinioTmp.push(votoScrutinio);

                                }
                            }
                        }
                        setVotiScrutinio([...votiScrutinioTmp])


                        if (votiScrutinioTmp.some(voto => voto?.voto == null) || result?.votiScrutinioDaDB[0]?.condotta?.interazione == null || result?.votiScrutinioDaDB[0]?.condotta?.motivazione == null || result?.votiScrutinioDaDB[0]?.condotta?.relazione == null)
                            setIsButtonConfermaDisabled(true);
                        else setIsButtonConfermaDisabled(false);


                    } else {

                        let votiScrutinioTmp = [];
                        let index = 0;
                        for (let materia of listMaterie) {
                            let votoScrutinio = {
                                idMateria: materia.id,
                                voto: materia?.votoProposto != undefined ? materia?.votoProposto : null
                            }
                            votiScrutinioTmp.push(votoScrutinio);
                            if (votiScrutinioRef.current[index] !== undefined)
                                votiScrutinioRef.current[index].value = returnVotoConverted(votoScrutinio.voto, false);
                            index++;
                        }
                        setVotiScrutinio([...votiScrutinioTmp]);
                        setIsButtonConfermaDisabled(true); //Se i voti sono undefined, quindi non ci sono voti, non posso premere SALVA (lo disabilito)
                        setCondottaScrutinioInterazione(null)
                        setCondottaScrutinioMotivazione(null)
                        setCondottaScrutinioRelazione(null)

                        if (votoCondottaMotRef.current !== undefined) {
                            votoCondottaMotRef.current.value = returnVotoConverted(null, false)
                        }

                        if (votoCondottaIntRef.current !== undefined) {
                            votoCondottaIntRef.current.value = returnVotoConverted(null, false)
                        }

                        if (votoCondottaRelRef.current !== undefined) {
                            votoCondottaRelRef.current.value = returnVotoConverted(null, false)
                        }

                        let nowInRome = DateTime.now().setZone('Europe/Rome');

                        // Converte l'ora di Roma in formato UTC
                        let romeTimeInUTC = DateTime.utc(nowInRome.year, nowInRome.month, nowInRome.day, nowInRome.hour, nowInRome.minute, nowInRome.second);

                        // Formatta la data e l'ora
                        let formattedDate = romeTimeInUTC.toISO();
                        setTimestampInizioScrutinioAlunno(formattedDate);
                    }
                    setHasErrors(false);
                    setHasLoaded(true);
                    setTimeout(() => {
                        setLoadingSchedaScrutinio(false);
                        setTimeout(() => {
                            if (nomeRef?.current !== undefined && nomeRef?.current !== null)
                                nomeRef.current.scrollIntoView({ behavior: 'instant', block: 'start' });
                        }, 100)
                    }, 1000)
                })
                .catch((error) => {
                    setHasLoaded(true);
                    setHasErrors(true);
                    setErrorMsg("Al momento non è possibile consultare la scheda alunno. Riprovare tra qualche minuto.")
                    setLoadingSchedaScrutinio(false);

                })
        }
    }

    async function saveVotiScrutinio(parametro) {

        let votiScrutinioTmp = copyArrayWithoutReference(votiScrutinio);
        for (let i = 0; i < votiScrutinioTmp.length; i++) {
            if (votiScrutinioTmp[i].voto === "N.V.")
                votiScrutinioTmp[i].voto = 0;
        }

        let nowInRome = DateTime.now().setZone('Europe/Rome');

        // Converte l'ora di Roma in formato UTC
        let romeTimeInUTC = DateTime.utc(nowInRome.year, nowInRome.month, nowInRome.day, nowInRome.hour, nowInRome.minute, nowInRome.second);

        // Formatta la data e l'ora
        let formattedDate = romeTimeInUTC.toISO();
        const obj = {
            fineScrutinioAlunno: formattedDate,//convertDataScrutinio(DateTime.now(), false),
            inizioScrutinioAlunno: timestampInizioScrutinioAlunno,
            idScrutinio: idScrutinio,
            idCorso: idCorso,
            idAlunno: alunnoSelezionato.id,
            idPeriodo: periodoSelezionato.id,
            votiScrutinio: votiScrutinioTmp,
            condottaScrutinio:
            {
                motivazione: condottaScrutinioMotivazione,
                interazione: condottaScrutinioInterazione,
                relazione: condottaScrutinioRelazione,
                // mediaCondotta: null
            }
        }
        if (showEsito && (alunnoSelezionato?.isRitirato === undefined || alunnoSelezionato?.isRitirato !== 1))
            obj.esitoScrutinio = esitoAlunnoIdoneo == "true" ? true : false;//esitoAlunnoIdoneo è una stringa quindi la devo convertire
        else if (showEsito && (alunnoSelezionato?.isRitirato !== undefined && alunnoSelezionato?.isRitirato === 1))
            obj.esitoScrutinio = null; //Se un alunno è ritirato l'esito deve essere null

        if (isUltimoPeriodo) obj.provaDiPassaggio = provaDiPassaggioInput ?? null;


        ScrutiniAPI.saveVotiScrutinio(obj)
            .then((result) => {
                setShowErrorEsito(false);
                setHasErrors(false);
                //setInserimentoVotiOpen(false);
                setListAnnotazioni([]);
                setListNote([]);
                if (!parametro) {
                    let newIndexAlunnoSelezionato = indexAlunnoSelezionato + 1;
                    setIndexAlunnoSelezionato(newIndexAlunnoSelezionato);
                    setAlunnoSelezionato(listAlunni[newIndexAlunnoSelezionato])
                    // setInserimentoVotiOpen(false);
                    setEsitoAlunnoIdoneo(undefined);
                    setProvaDiPassaggioInput(-1);
                    setHasLoaded(false);
                    //TODO: controllare quale funzione utilizzare
                    setVotiScrutinio([])
                    setTimeout(() => {
                        getSchedaAlunno(idCorso, periodoSelezionato.id, listAlunni[newIndexAlunnoSelezionato].id)
                        recuperoVotiScrutinioAlunno(idCorso, periodoSelezionato.id, listAlunni[newIndexAlunnoSelezionato].id, idScrutinio, true)

                    }, 500)

                } else {
                    setInserimentoVotiOpen(false);
                    props.closeDettaglioSavedScrutinio(alunnoSelezionato.nome, alunnoSelezionato.cognome);
                    setHasErrors(false);
                    setHasLoaded(true);
                }
            })
            .catch((error) => {
                setLoadingSchedaScrutinio(false);
                setHasLoaded(true);
                // setHasErrors(true);
                setIsConfermaLoading(false);
                let errorMsg = error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile salvare i voti dello scrutinio. Riprovare tra qualche minuto."
                setErrorMsg(errorMsg)
                setShowErrorEsito(true);

                if (error.response.status === 409) {
                    setEsitoHasProblems(true);
                } else setEsitoHasProblems(false);


            })

    }

    async function getCreditiFormativi() {
        const obj = {
            idCorso: idCorso,
            votiScrutinio: votiScrutinio
        }

        ScrutiniAPI.calcolaCrediti(obj)
            .then((result) => {
                setCreditiFormativi(result?.crediti ?? 0)
                setCanCalculateCrediti(false);
            })
            .catch((err) => { })
    }

    function changeVotoScrutinio(e, index) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        } else if (newValue === "/") {
            newStr = '/';
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            newStr = 'N.V.'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr == 0) newStr = 'N.V.'

        } else {
            newStr = ""; //resetta a ''
        }
        votiScrutinioRef.current[index].value = newStr;
        for (let i = 0; i < votiScrutinioRef.current.length; i++)

            setIsButtonConfermaDisabled(checkIfButtonIsDisabled());
        if (canCalculateCrediti === false) setCanCalculateCrediti(true)

    }
    function onBlurVotoScrutinio(e, index) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = '0';
        } else if (newValue === "/") {
            newStr = '-1';
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = '0'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
        } else {
            newStr = ""; //resetta a ''
        }

        let votiTmp = [];
        if (votiScrutinio.length > 0)
            votiTmp = copyArrayWithoutReference(votiScrutinio);
        else { //Se voti scrutinio arriva vuoto devo comunque inizializzare l'array
            for (let i = 0; i < votiScrutinioRef.current.length; i++) {
                votiTmp.push({
                    idMateria: listMaterie[i].id,
                    voto: null
                });
            }
        }

        let parsed = parseInt(newStr);
        if (votiTmp[index] !== undefined) {
            if (isNaN(parsed))
                votiTmp[index].voto = null;
            else votiTmp[index].voto = parseInt(newStr)
        }
        setVotiScrutinio([...votiTmp])

    }


    function changeScrutinioMotivazione(e) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        } else if (newValue === "/") {
            newStr = '/';
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            newStr = 'N.V.'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr == 0) newStr = 'N.V.'

        } else {
            newStr = ""; //resetta a ''
        }

        votoCondottaMotRef.current.value = newStr; //setCondottaScrutinioMotivazione(null);
        setIsButtonConfermaDisabled(checkIfButtonIsDisabled());
        if (canCalculateCrediti === false) setCanCalculateCrediti(true)

    }
    function onBlurScrutinioMotivazione(e) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = '0';
        } else if (newValue === "/") {
            newStr = '-1';
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = '0'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
        } else {
            newStr = ""; //resetta a ''
        }

        let parsed = parseInt(newStr);
        if (isNaN(parsed)) setCondottaScrutinioMotivazione(null);
        else setCondottaScrutinioMotivazione(parseInt(newStr));

    }

    function changeScrutinioInterazione(e) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        } else if (newValue === "/") {
            newStr = '/';
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            newStr = 'N.V.'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr == 0) newStr = 'N.V.'

        } else {
            newStr = ""; //resetta a ''
        }

        votoCondottaIntRef.current.value = newStr;//setCondottaScrutinioInterazione(parseInt(newStr));
        setIsButtonConfermaDisabled(checkIfButtonIsDisabled());
        if (canCalculateCrediti === false) setCanCalculateCrediti(true)

    }
    function onBlurScrutinioInterazione(e) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = '0';
        } else if (newValue === "/") {
            newStr = '-1';
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = '0'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30

        } else {
            newStr = ""; //resetta a ''
        }

        let parsed = parseInt(newStr);
        if (isNaN(parsed)) setCondottaScrutinioInterazione(null);
        else setCondottaScrutinioInterazione(parseInt(newStr));

    }

    function changeScrutinioRelazione(e) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        } else if (newValue === "/") {
            newStr = '/';
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            newStr = 'N.V.'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr == 0) newStr = 'N.V.'
        } else {
            newStr = ""; //resetta a ''
        }

        votoCondottaRelRef.current.value = newStr;//setCondottaScrutinioRelazione(parseInt(newStr));
        setIsButtonConfermaDisabled(checkIfButtonIsDisabled());
        if (canCalculateCrediti === false) setCanCalculateCrediti(true)

    }
    function onBlurScrutinioRelazione(e) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = '0';
        } else if (newValue === "/") {
            newStr = '-1';
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = '0'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
        } else {
            newStr = ""; //resetta a ''
        }

        let parsed = parseInt(newStr);
        if (isNaN(parsed)) setCondottaScrutinioRelazione(null);
        else setCondottaScrutinioRelazione(parseInt(newStr));
    }

    function changeProvaDiPassaggio(e) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        } else if (newValue === "/") {
            newStr = '/';
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            newStr = 'N.V.'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr == 0) newStr = 'N.V.'
        } else {
            newStr = ""; //resetta a ''
        }

        provaDiPassaggioRef.current.value = newStr;//setCondottaScrutinioRelazione(parseInt(newStr));

    }
    function onBlurProvaDiPassaggio(e) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = '0';
        } else if (newValue === "/") {
            newStr = '-1';
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = '0'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30

        } else {
            newStr = ""; //resetta a ''
        }

        let parsed = parseInt(newStr);
        if (isNaN(parsed)) setProvaDiPassaggioInput(-1);
        else setProvaDiPassaggioInput(parseInt(newStr));
    }

    function checkIfButtonIsDisabled() {
        if (votiScrutinioRef.current.some(r => r.value == "") || votiScrutinioRef.current.some(r => r.value == "-") ||
            (votoCondottaMotRef?.current?.value == "" || votoCondottaMotRef?.current?.value == "-") ||
            (votoCondottaIntRef?.current?.value == "" || votoCondottaIntRef?.current?.value == "-") ||
            (votoCondottaRelRef?.current?.value == "" || votoCondottaRelRef?.current?.value == "-")) return true
        else return false
    }

    function noteScroll() {
        noteRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    function returnVotoConverted(voto, returnDash) {
        if (voto != null && voto !== undefined && !isNaN(voto)) {
            if (voto > 0) {
                return voto;
            } else if (voto === 0) {
                return "N.V."
            } else return "/"
        } else {
            if (returnDash !== undefined) {
                if (returnDash === true) return "-"
                else return ""
            } else return "-"
        }
    }

    function openDialogLegenda() {
        setLegendaDialogOpen(prev => !prev);
    }

    return (
        <Fragment>
            <Box sx={{ px: 0 }}>
                {hasLoaded ? (
                    !hasErrors ? (
                        <Fragment>
                            <Box sx={{ display: "flex", flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                                <Box>
                                    <Button variant="contained" color="primary" sx={{ ml: 0, mt: 1, width: { xs: '100%', md: 'unset' } }} startIcon={<ArrowBack />} onClick={goBackToVistaClasse}>Torna alla vista di classe</Button>
                                    {(showIniziaScrutinio && idScrutinio > 0) && (
                                        <Button variant="contained" color="success" sx={{ ml: { xs: 0, md: 2 }, mt: 1, width: { xs: '100%', md: 'unset' } }} onClick={openInserimentoVoti}>Inizia scrutinio (inserisci voti)</Button>
                                    )}
                                    {(!showIniziaScrutinio && idScrutinio > 0 && !isScrutinioConcluso && showConcludi) && (
                                        <Button variant="contained" startIcon={<Edit />} color="secondary" sx={{ ml: { xs: 0, md: 2 }, mt: 1, width: { xs: '100%', md: 'unset' } }} onClick={openModificaVoti}>
                                            Scrutinio
                                        </Button>
                                    )}

                                    <Button variant="outlined" color="primary" sx={{ ml: { xs: 0, md: 2 }, mt: 1, width: { xs: '100%', md: 'unset' } }} onClick={changeOpenCondotta}>{showCondotta ? "Nascondi " : "Mostra "} condotta</Button>
                                    <Button variant="outlined" color="warning" sx={{ ml: { xs: 0, md: 2 }, mt: 1, width: { xs: '100%', md: 'unset' } }} onClick={noteScroll}>Note e annotazioni</Button>
                                    <Button startIcon={<Help />} variant="outlined" color="secondary" sx={{ ml: { xs: 0, md: 2 }, mt: 1, width: { xs: '100%', md: 'unset' } }} onClick={openDialogLegenda}>Mostra legenda</Button>
                                </Box>

                            </Box>
                            <Box sx={{ display: "flex", alignItems: 'center' }}>
                                <Box sx={{ mr: 3 }}>
                                    <Typography variant='body2'>Sede:</Typography>
                                    <Typography variant='body1'><b>{nomeSede}</b></Typography>
                                </Box>
                                <Box sx={{ mr: 3 }}>
                                    <Typography variant='body2'>Corso:</Typography>
                                    <Typography color="primary" variant='body1'><b>{nomeCorso}</b></Typography>
                                </Box>
                                <Box sx={{ mr: 3 }}>
                                    <Typography variant='body2'>Periodo:</Typography>
                                    <Typography variant='body1'><b>{periodoSelezionato?.nome} ({periodoSelezionato?.formattedInizio + " - " + periodoSelezionato?.formattedFine})</b></Typography>
                                </Box>
                                <Box>
                                    <Typography variant='body2'>Stato:</Typography>
                                    <Typography color="secondary" variant='body1'><b>{nomeStato ?? "-"}</b></Typography>
                                </Box>
                            </Box>

                            <Collapse in={showCondotta}>
                                <Grid container columnSpacing={2} sx={{ display: "flex", mt: 2, flexDirection: 'row', alignItems: "stretch" }}>
                                    <Grid xs={12} md={4}>
                                        <Box sx={{ border: '1px solid #1976d2', p: 2, mb: { xs: 2, md: 0 }, borderRadius: '5px' }}>
                                            <Typography sx={{ mb: 1 }} variant="h6">Condotta (media voti proposti docenti)</Typography>
                                            <Box sx={{ display: "flex", alignItems: 'start', flexWrap: 'wrap' }}>
                                                <Box sx={{ mr: 1, mt: 1, display: 'flex', alignItems: 'center', background: '#EFEFEF', p: 1, border: '1px solid #707070', borderRadius: '10px' }}>
                                                    <Typography><b>Motivazione e interesse</b>: {condottaObj?.condottaDocenti?.motivazione ?? "-"}</Typography>
                                                </Box>
                                                <Box sx={{ mr: 1, mt: 1, display: 'flex', alignItems: 'center', background: '#EFEFEF', p: 1, border: '1px solid #707070', borderRadius: '10px' }}>
                                                    <Typography><b>Interazione con il contesto</b>: {condottaObj?.condottaDocenti?.interazione ?? "-"}</Typography>
                                                </Box>
                                                <Box sx={{ mr: 1, mt: 1, display: 'flex', alignItems: 'center', background: '#EFEFEF', p: 1, border: '1px solid #707070', borderRadius: '10px' }}>
                                                    <Typography><b>Relazione con gli altri</b>: {condottaObj?.condottaDocenti?.relazione ?? "-"}</Typography>
                                                </Box>
                                                <Box sx={{ mr: 1, mt: 1, display: 'flex', alignItems: 'center', background: '#EFEFEF', p: 1, border: '1px solid #707070', borderRadius: '10px' }}>
                                                    <Typography><b>Media</b>: {condottaObj?.condottaDocenti?.mediaCondotta ?? "-"}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} md={4} >
                                        <Box sx={{ border: '1px solid #1976d2', p: 2, mb: { xs: 2, md: 0 }, borderRadius: '5px' }}>
                                            <Typography sx={{ mb: 1 }} variant="h6">Condotta (voti proposti collegio formatori)</Typography>
                                            <Box sx={{ display: "flex", alignItems: 'start', flexWrap: 'wrap' }}>
                                                <Box sx={{ mr: 1, mt: 1, display: 'flex', alignItems: 'center', background: '#EFEFEF', p: 1, border: '1px solid #707070', borderRadius: '10px' }}>
                                                    <Typography><b>Motivazione e interesse</b>: {condottaObj?.condottaTutor?.motivazione ?? "-"}</Typography>
                                                </Box>
                                                <Box sx={{ mr: 1, mt: 1, display: 'flex', alignItems: 'center', background: '#EFEFEF', p: 1, border: '1px solid #707070', borderRadius: '10px' }}>
                                                    <Typography><b>Interazione con il contesto</b>: {condottaObj?.condottaTutor?.interazione ?? "-"}</Typography>
                                                </Box>
                                                <Box sx={{ mr: 1, mt: 1, display: 'flex', alignItems: 'center', background: '#EFEFEF', p: 1, border: '1px solid #707070', borderRadius: '10px' }}>
                                                    <Typography><b>Relazione con gli altri</b>: {condottaObj?.condottaTutor?.relazione ?? "-"}</Typography>
                                                </Box>
                                                <Box sx={{ mr: 1, mt: 1, display: 'flex', alignItems: 'center', background: '#EFEFEF', p: 1, border: '1px solid #707070', borderRadius: '10px' }}>
                                                    <Typography><b>Media</b>: {condottaObj?.condottaTutor?.mediaCondotta ?? "-"}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <Box sx={{ border: '1px solid #1976d2', p: 2, borderRadius: '5px' }}>
                                            <Typography sx={{ mb: 1 }} variant="h6">{!isPrescrutinio ? "Condotta (voto scrutinio)" : "Condotta (voto prescrutinio)"}</Typography>
                                            <Box sx={{ display: "flex", alignItems: 'start', flexWrap: 'wrap' }}>
                                                <Box sx={{ mr: 1, mt: 1, display: 'flex', alignItems: 'center', background: '#EFEFEF', p: 1, border: '1px solid #707070', borderRadius: '10px' }}>
                                                    <Typography><b>Motivazione e interesse</b>: {condottaObj?.condottaScrutinio?.motivazione ?? "-"}</Typography>
                                                </Box>
                                                <Box sx={{ mr: 1, mt: 1, display: 'flex', alignItems: 'center', background: '#EFEFEF', p: 1, border: '1px solid #707070', borderRadius: '10px' }}>
                                                    <Typography><b>Interazione con il contesto</b>: {condottaObj?.condottaScrutinio?.interazione ?? "-"}</Typography>
                                                </Box>
                                                <Box sx={{ mr: 1, mt: 1, display: 'flex', alignItems: 'center', background: '#EFEFEF', p: 1, border: '1px solid #707070', borderRadius: '10px' }}>
                                                    <Typography><b>Relazione con gli altri</b>: {condottaObj?.condottaScrutinio?.relazione ?? "-"}</Typography>
                                                </Box>
                                                <Box sx={{ mr: 1, mt: 1, display: 'flex', alignItems: 'center', background: '#EFEFEF', p: 1, border: '1px solid #707070', borderRadius: '10px' }}>
                                                    <Typography><b>Media</b>: {condottaObj?.condottaScrutinio?.mediaCondotta ?? "-"}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Collapse>

                            <Box sx={{ mb: 3, mt: 2, width: '100%', maxHeight: { xs: 'unset', md: '70vh' }, display: "flex", justifyContent: 'center' }}>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: '#1976d2' }}>
                                                <TableCell colSpan={1} sx={{ p: 0, borderRight: '3px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 6 }}>
                                                    <Typography textAlign={"center"} sx={{ color: 'white' }}>Materie</Typography>
                                                </TableCell>
                                                <TableCell colSpan={2} sx={{ p: 0, borderRight: '3px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 6 }}>
                                                    <Typography textAlign={"center"} sx={{ color: 'white' }}>Ore</Typography>
                                                </TableCell>
                                                <TableCell colSpan={5} sx={{ p: 0, borderRight: '2px solid black', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 6 }}>
                                                    <Typography textAlign={"center"} sx={{ color: 'white' }}>Voti</Typography>
                                                </TableCell>
                                                <TableCell colSpan={3} sx={{ p: 0, borderRight: '2px solid black', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 6 }}>
                                                    <Typography textAlign={"center"} sx={{ color: 'white' }}>Media</Typography>
                                                </TableCell>
                                                <TableCell colSpan={2} sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 6 }}>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="center" rowSpan={2}
                                                    className="colonna-nomi row-head" sx={{ p: 0, borderRight: '3px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 6 }}
                                                >
                                                    <Box sx={{
                                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                        backgroundColor: 'white',
                                                        height: '100px',
                                                        px: 2,
                                                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "start"
                                                    }}>
                                                        {/* <Typography variant="caption" >Alunno:</Typography> */}
                                                        <Typography color="secondary" variant='body1' sx={{ fontWeight: 'bold', textAlign: 'start' }}>{(indexAlunnoSelezionato + 1) + ") " + alunnoSelezionato?.cognome + " " + alunnoSelezionato?.nome}</Typography>
                                                        {(isUltimoPeriodo && numericoCorso < 3 ) && (
                                                            <Fragment>
                                                                <Typography variant="body2"><b>Prova di passaggio</b>: {returnVotoConverted(provaDiPassaggio, true)}</Typography>
                                                                {showEsito && (
                                                                    alunnoSelezionato?.isRitirato === undefined || alunnoSelezionato?.isRitirato === 0 ? (
                                                                        <Typography variant="body2"><b>Esito scrutinio</b>:
                                                                            {esitoAlunnoVisualizzazione !== undefined ? (
                                                                                <span style={{ color: esitoAlunnoVisualizzazione == "true" ? 'green' : 'red' }}>{esitoAlunnoVisualizzazione == "true" ? ' Idoneo' : ' Non idoneo'}</span>
                                                                            ) : (
                                                                                <span> -</span>
                                                                            )}
                                                                        </Typography>
                                                                    ) : (
                                                                        <Typography color="error"><b>Ritirato il {convertDataDiNascita(alunnoSelezionato?.iscrittoAl)}</b></Typography>
                                                                    )
                                                                )}
                                                            </Fragment>
                                                        )}
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="center"
                                                    className="row-head" sx={{ minWidth: '80px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                >
                                                    <Box sx={{
                                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                        backgroundColor: 'white',
                                                        height: '100px',
                                                        px: 2,
                                                        display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                    }}>
                                                        <Typography variant='body2'>{periodoSelezionato?.nome}</Typography>
                                                        <Typography variant='caption' >*<b >A</b>/<b >S</b></Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="center"
                                                    className="row-head" sx={{ minWidth: '80px', p: 0, borderRight: '3px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                >
                                                    <Box sx={{
                                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                        backgroundColor: 'white',
                                                        height: '100px',
                                                        px: 2,
                                                        display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                    }}>
                                                        <Typography variant='body2'>Complessive</Typography>
                                                        <Typography variant='caption' >*<b >A</b>/
                                                            <b>S</b>/
                                                            <b >T</b></Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="center" rowSpan={2}
                                                    className="row-head" sx={{ minWidth: '70px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                >
                                                    <Box sx={{
                                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                        backgroundColor: 'white',
                                                        height: '100px',
                                                        px: 2,
                                                        minWidth: '50px',
                                                        display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                    }}>
                                                        <Typography variant='body2'>Ex ante</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="center" rowSpan={2}
                                                    className="row-head" sx={{ minWidth: '70px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                >
                                                    <Box sx={{
                                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                        backgroundColor: 'white',
                                                        height: '100px',
                                                        px: 2,
                                                        minWidth: '50px',
                                                        display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                    }}>
                                                        <Typography variant='body2'>Scritti</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="center" rowSpan={2}
                                                    className="row-head" sx={{ minWidth: '70px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                >
                                                    <Box sx={{
                                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                        backgroundColor: 'white',
                                                        height: '100px',
                                                        px: 2,
                                                        minWidth: '50px',
                                                        display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                    }}>
                                                        <Typography variant='body2'>Pratici</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="center" rowSpan={2}
                                                    className="row-head" sx={{ minWidth: '70px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                >
                                                    <Box sx={{
                                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                        backgroundColor: 'white',
                                                        height: '100px',
                                                        px: 2,
                                                        minWidth: '50px',
                                                        display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                    }}>
                                                        <Typography variant='body2'>Orali</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="center" rowSpan={2}
                                                    className="row-head" sx={{ minWidth: '70px', p: 0, borderRight: '2px solid black', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                >
                                                    <Box sx={{
                                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                        backgroundColor: 'white',
                                                        height: '100px',
                                                        px: 2,
                                                        minWidth: '50px',
                                                        display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                    }}>
                                                        <Typography variant='body2'>Valutazioni</Typography>
                                                    </Box>
                                                </TableCell>


                                                <TableCell align="center" rowSpan={2}
                                                    className="row-head" sx={{ minWidth: '70px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                >
                                                    <Box sx={{
                                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                        backgroundColor: 'white',
                                                        height: '100px',
                                                        px: 2,
                                                        minWidth: '50px',
                                                        display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                    }}>
                                                        <Typography variant='body2'>Didattica</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="center" rowSpan={2}
                                                    className="row-head" sx={{ minWidth: '70px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                >
                                                    <Box sx={{
                                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                        backgroundColor: 'white',
                                                        height: '100px',
                                                        px: 2,
                                                        minWidth: '50px',
                                                        display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                    }}>
                                                        <Typography variant='body2'>Delle valutazioni</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="center" rowSpan={2}
                                                    className="row-head" sx={{ minWidth: '70px', p: 0, borderRight: '2px solid black', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                >
                                                    <Box sx={{
                                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                        backgroundColor: 'white',
                                                        height: '100px',
                                                        px: 2,
                                                        minWidth: '50px',
                                                        display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                    }}>
                                                        <Typography variant='body2'>Suggerita</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="center" rowSpan={2}
                                                    className="row-head" sx={{ width: '80px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                >
                                                    <Box sx={{
                                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                        backgroundColor: '#cecece',
                                                        height: '100px',
                                                        px: 2,
                                                        minWidth: '50px',
                                                        display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                    }}>
                                                        <Typography variant='body2'>Voto proposto docente</Typography>
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="center" rowSpan={2}
                                                    className="row-head" sx={{ width: '80px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                                                >
                                                    <Box sx={{
                                                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                        backgroundColor: 'white',
                                                        height: '100px',
                                                        px: 2,
                                                        minWidth: '50px',
                                                        display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                                    }}>
                                                        <Typography variant='body2'>Voto scrutinio</Typography>
                                                    </Box>
                                                </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {listMaterie.map((materia, index) => (
                                                <TableRow key={materia?.id + materia?.docente + materia?.nome}>
                                                    <TableCell className="colonna-nomi" sx={{ minWidth: isMobile ? '300px' : '400px', p: 0, borderRight: '3px solid rgba(224, 224, 224, 1)' }} >
                                                        <Box sx={{
                                                            backgroundColor: 'white',
                                                            height: materia?.nome.length > 40 ? '100px' : '80px',
                                                            px: 2,
                                                            display: 'flex',
                                                            alignItems: 'center'
                                                        }}>
                                                            <Box display={"flex"} width={'100%'} alignItems={"center"} justifyContent={'space-between'}>
                                                                <Box >
                                                                    <Typography variant={isMobile ? 'caption' : 'body1'} sx={{ mr: 2 }}>
                                                                        <b>{materia?.nome}</b>
                                                                    </Typography>
                                                                    <Typography variant="body2">
                                                                        {materia.docente}
                                                                    </Typography>
                                                                </Box>


                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', }}>
                                                        <Typography> {materia?.orePeriodo?.oreAssenza ?? "-"}/{materia?.orePeriodo?.oreSvolte ?? "-"}</Typography>
                                                        <Typography sx={{ fontWeight: calcolaFontWeightPercentuale(materia?.orePeriodo?.percentuale), color: calcolaColorePercentuale(materia?.orePeriodo?.percentuale) }} variant='subtitle1'>
                                                            {materia?.orePeriodo?.percentuale ?? "-"}</Typography>
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ p: 0, borderRight: '3px solid rgba(224, 224, 224, 1)', }}>
                                                        <Typography> {materia?.oreComplessive?.oreAssenza ?? "-"}/{materia?.oreComplessive?.oreSvolte ?? "-"}/{materia?.oreComplessive?.oreTotali ?? "-"}</Typography>
                                                        <Typography sx={{ fontWeight: calcolaFontWeightPercentuale(materia?.oreComplessive?.percentuale), color: calcolaColorePercentuale(materia?.oreComplessive?.percentuale) }} variant='subtitle1'>{materia?.oreComplessive?.percentuale ?? "-"}</Typography>
                                                    </TableCell>

                                                    <TableCell align="center" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', }} >
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {materia?.votiExAnte !== undefined && (
                                                                materia?.votiExAnte.map((voto, indexVoto) => (
                                                                    <Fragment key={materia?.id + indexVoto + "exAnte"}>
                                                                        <EventoChip canChangeColorVoto={true} alunno={alunnoSelezionato} cantModify={true} objComunicazione={voto} />
                                                                    </Fragment>
                                                                ))
                                                            )}
                                                        </Box>
                                                    </TableCell>

                                                    <TableCell align="center" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', }} >
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {materia.votiScritti.map((voto, indexVoto) => (
                                                                <Fragment key={materia?.id + indexVoto + "scritto"}>
                                                                    <EventoChip canChangeColorVoto={true} alunno={alunnoSelezionato} cantModify={true} objComunicazione={voto} />
                                                                </Fragment>
                                                            ))}
                                                        </Box>
                                                    </TableCell>

                                                    <TableCell align="center" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', }}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {materia.votiPratici.map((voto, indexVoto) => (
                                                                <Fragment key={materia?.id + indexVoto + "pratico"}>
                                                                    <EventoChip canChangeColorVoto={true} alunno={alunnoSelezionato} cantModify={true} objComunicazione={voto} />
                                                                </Fragment>
                                                            ))}
                                                        </Box>
                                                    </TableCell>

                                                    <TableCell align="center" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', }}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {materia?.votiOrali.map((voto, indexVoto) => (
                                                                <Fragment key={materia?.id + indexVoto + "orale"}>
                                                                    <EventoChip canChangeColorVoto={true} alunno={alunnoSelezionato} cantModify={true} objComunicazione={voto} />
                                                                </Fragment>
                                                            ))}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ p: 0, borderRight: '2px solid black', }}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            {materia?.valutazioni.map((voto, indexVoto) => (
                                                                <Fragment key={materia?.id + indexVoto + "valu"}>
                                                                    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                                                        <Typography color={'secondary'}>{voto?.peso}</Typography>

                                                                        <Box width={"30px"} height={"30px"} minWidth={'30px'}
                                                                            border={"1px solid #ba68c8"}
                                                                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                                                                            sx={{ mx: 1, p: 1, borderRadius: '100%', color: 'white' }}>
                                                                            <Typography sx={{ color: '#ba68c8', fontWeight: 'bold' }}>
                                                                                {voto?.contenuto?.tipologia}
                                                                            </Typography>
                                                                        </Box>
                                                                        {/* <EventoChip alunno={alunnoSelezionato} cantModify={true} objComunicazione={voto} /> */}
                                                                    </Box>
                                                                </Fragment>
                                                            ))}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ width: '80px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', }}>
                                                        <Typography sx={{ color: calcolaColoreMedia(materia?.medie?.mediaDidattica) }}>
                                                            {returnVotoConverted(materia?.medie?.mediaDidattica)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ width: '80px', p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', }}>
                                                        <Typography sx={{ color: calcolaColoreMedia(materia?.medie?.mediaValutazioni) }}>
                                                            {returnVotoConverted(materia?.medie?.mediaValutazioni)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ width: '80px', p: 0, borderRight: '2px solid black', }}>
                                                        <Typography sx={{ color: calcolaColoreMedia(materia?.medie?.mediaSuggerita) }}>
                                                            {returnVotoConverted(materia?.medie?.mediaSuggerita)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ width: '80px', p: 0, background: "#cecece", borderRight: '1px solid rgba(224, 224, 224, 1)', }}>
                                                        <Typography sx={{ color: calcolaColoreMedia(materia?.votoProposto) }}>
                                                            {returnVotoConverted(materia?.votoProposto)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ width: '80px', p: 0, background: materia?.is_prescrutinio ? '#cecece' : 'white' }}>
                                                        <Typography sx={{ color: calcolaColoreMedia(materia?.votoScrutinio) }}>
                                                            {returnVotoConverted(materia?.votoScrutinio)}
                                                        </Typography>

                                                    </TableCell>
                                                </TableRow>
                                            ))}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                            <Divider />
                            <Box ref={noteRef}>
                                <SchedaAlunnoNoteAnnotazioni listAnnotazioni={listAnnotazioni} listNote={listNote} />
                            </Box>
                        </Fragment>
                    ) : (
                        <Box sx={{ mt: 3, mb: 3, width: '100%', height: '70vh', display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography variant="h6"> {errorMsg}</Typography>
                            <Button variant="contained" color="primary" sx={{ mt: 2 }} startIcon={<ArrowBack />} onClick={goBackToVistaClasse}>
                                Torna alla vista di classe
                            </Button>

                        </Box>
                    )
                ) : (
                    <Box sx={{ height: '80vh', display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                        <Typography sx={{ mt: 2 }}>Caricamento in corso...</Typography>
                    </Box>
                )}
            </Box>

            <Dialog sx={{ marginTop: '8vh' }} fullScreen open={inserimentoVotiOpen}>
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} variant="h6">
                    {(indexAlunnoSelezionato + 1) + ") " + alunnoSelezionato?.cognome + " " + alunnoSelezionato?.nome}
                    {/* <IconButton onClick={closeInserimento} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton> */}
                </DialogTitle>
                <Divider />

                <DialogContent ref={dialogContentRef}>
                    <Fragment>

                        <Paper sx={{ mb: 2, p: 2 }}>
                            <Typography variant='h6'>
                                <b>Disciplina</b>
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid xs={12} md={6} lg={4}>
                                    {listMaterie.map((materia, index) => (
                                        ((!isTablet && index <= firstColLimit) || isMobile || (isTablet && index < Math.round(listMaterie.length / 2))) && (
                                            <Fragment>
                                                <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }} key={materia?.id + index + materia?.nome}>
                                                    <Box sx={{ p: 0 }}>
                                                        <Typography variant="subtitle2" sx={{ mr: 2 }}>
                                                            <b>{materia?.nome}</b>
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            {materia.docente}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ display: "flex", alignItems: 'center' }}>
                                                        <Box sx={{ p: 1, display: "flex", justifyContent: 'center' }}>
                                                            <Box className="fakeInputBox"  >
                                                                <Typography sx={{ color: changeColorVoto(votiProposti[index]?.voto) }}>
                                                                    {returnVotoConverted(votiProposti[index]?.voto)}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ p: 1 }}>
                                                            <input
                                                                className="inputEdit"
                                                                ref={el => votiScrutinioRef.current[index] = el}
                                                                defaultValue={returnVotoConverted(votiScrutinio[index]?.voto ?? null)}
                                                                style={{ width: '50px', color: changeColorVoto(votiScrutinioRef?.current[index]?.value ?? null) }}
                                                                onChange={(e) => changeVotoScrutinio(e, index)}
                                                                onBlur={(e) => onBlurVotoScrutinio(e, index)}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Divider />
                                            </Fragment>
                                        )
                                    ))}
                                </Grid>
                                <Grid xs={12} md={6} lg={4} style={{ borderLeft: '1px solid #e0e0e0', borderRight: '1px solid #e0e0e0' }}>
                                    {listMaterie.map((materia, index) => (
                                        ((!isMobile && isTablet && index >= Math.round(listMaterie.length / 2)) || (!isMobile && !isTablet && index > firstColLimit && index <= secondColLimit)) && (
                                            <Fragment>
                                                <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }} key={materia?.id + index + materia?.nome}>
                                                    <Box sx={{ p: 0 }}>
                                                        <Typography variant="subtitle2" sx={{ mr: 2 }}>
                                                            <b>{materia?.nome}</b>
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            {materia.docente}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ display: "flex", alignItems: 'center' }}>
                                                        <Box sx={{ p: 1, display: "flex", justifyContent: 'center' }}>
                                                            <Box className="fakeInputBox"  >
                                                                <Typography sx={{ color: changeColorVoto(votiProposti[index]?.voto) }}>
                                                                    {returnVotoConverted(votiProposti[index]?.voto)}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ p: 1 }}>
                                                            <input
                                                                className="inputEdit"
                                                                ref={el => votiScrutinioRef.current[index] = el}
                                                                defaultValue={returnVotoConverted(votiScrutinio[index]?.voto ?? null)}
                                                                style={{ width: '50px', color: changeColorVoto(votiScrutinioRef?.current[index]?.value ?? null) }}
                                                                onChange={(e) => changeVotoScrutinio(e, index)}
                                                                onBlur={(e) => onBlurVotoScrutinio(e, index)}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Divider />
                                            </Fragment>
                                        )
                                    ))}
                                </Grid>
                                <Grid xs={12} md={6} lg={4}>
                                    {listMaterie.map((materia, index) => (
                                        (!isMobile && !isTablet && index > secondColLimit) && (
                                            <Fragment>
                                                <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }} key={materia?.id + index + materia?.nome}>
                                                    <Box sx={{ p: 0 }}>
                                                        <Typography variant="subtitle2" sx={{ mr: 2 }}>
                                                            <b>{materia?.nome}</b>
                                                        </Typography>
                                                        <Typography variant="body2">
                                                            {materia.docente}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ display: "flex", alignItems: 'center' }}>
                                                        <Box sx={{ p: 1, display: "flex", justifyContent: 'center' }}>
                                                            <Box className="fakeInputBox"  >
                                                                <Typography sx={{ color: changeColorVoto(votiProposti[index]?.voto) }}>
                                                                    {returnVotoConverted(votiProposti[index]?.voto)}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{ p: 1 }}>
                                                            <input
                                                                className="inputEdit"
                                                                ref={el => votiScrutinioRef.current[index] = el}
                                                                defaultValue={returnVotoConverted(votiScrutinio[index]?.voto ?? null)}
                                                                style={{ width: '50px', color: changeColorVoto(votiScrutinioRef?.current[index]?.value ?? null) }}
                                                                onChange={(e) => changeVotoScrutinio(e, index)}
                                                                onBlur={(e) => onBlurVotoScrutinio(e, index)}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Divider />
                                            </Fragment>
                                        )
                                    ))}

                                </Grid>
                            </Grid>
                        </Paper>

                        {/*COMPORTAMENTO */}
                        <Grid container spacing={2}>


                            <Grid xs={12} md={6}>
                                <Paper sx={{ mb: 2, p: 0, height: '100%' }}>
                                    <Typography variant='h6' sx={{ p: 2 }}>
                                        <b>Comportamento</b>
                                    </Typography>
                                    <Box sx={{ px: 2 }}>
                                        <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                                            <Box sx={{ p: 0 }}>
                                                <Typography variant="subtitle2" sx={{ mr: 2 }}>
                                                    Motivazione e interesse
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: "flex", alignItems: 'center' }}>
                                                <Box sx={{ p: 1, display: "flex", justifyContent: 'center' }}>
                                                    <Box className="fakeInputBox"  >
                                                        <Typography sx={{ color: changeColorVoto(condottaObj?.condottaTutor?.motivazione ?? null) }} >
                                                            {returnVotoConverted(condottaObj?.condottaTutor?.motivazione)}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ p: 1 }}>
                                                    <input
                                                        ref={el => votoCondottaMotRef.current = el}
                                                        className="inputEdit"
                                                        defaultValue={returnVotoConverted(condottaScrutinioMotivazione)}
                                                        style={{ width: '50px', color: changeColorVoto(condottaScrutinioMotivazione ?? null) }}
                                                        onChange={changeScrutinioMotivazione}
                                                        onBlur={onBlurScrutinioMotivazione}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Divider />

                                        <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                                            <Box sx={{ p: 0 }}>
                                                <Typography variant="subtitle2" sx={{ mr: 2 }}>
                                                    Interazione con il contesto
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: "flex", alignItems: 'center' }}>
                                                <Box sx={{ p: 1, display: "flex", justifyContent: 'center' }}>
                                                    <Box className="fakeInputBox"  >
                                                        <Typography sx={{ color: changeColorVoto(condottaObj?.condottaTutor?.interazione ?? null) }}>
                                                            {returnVotoConverted(condottaObj?.condottaTutor?.interazione)}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ p: 1 }}>
                                                    <input
                                                        className="inputEdit"
                                                        ref={el => votoCondottaIntRef.current = el}
                                                        defaultValue={returnVotoConverted(condottaScrutinioInterazione)}
                                                        style={{ width: '50px', color: changeColorVoto(condottaScrutinioInterazione ?? null) }}
                                                        onChange={changeScrutinioInterazione}
                                                        onBlur={onBlurScrutinioInterazione}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Divider />

                                        <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                                            <Box sx={{ p: 0 }}>
                                                <Typography variant="subtitle2" sx={{ mr: 2 }}>
                                                    Relazione con gli altri
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: "flex", alignItems: 'center' }}>
                                                <Box sx={{ p: 1, display: "flex", justifyContent: 'center' }}>
                                                    <Box className="fakeInputBox"  >
                                                        <Typography sx={{ color: changeColorVoto(condottaObj?.condottaTutor?.relazione ?? null) }}>
                                                            {returnVotoConverted(condottaObj?.condottaTutor?.relazione)}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ p: 1 }}>
                                                    <input
                                                        className="inputEdit"
                                                        ref={el => votoCondottaRelRef.current = el}
                                                        defaultValue={returnVotoConverted(condottaScrutinioRelazione)}
                                                        style={{ width: '50px', color: changeColorVoto(condottaScrutinioRelazione ?? null) }}
                                                        onChange={changeScrutinioRelazione}
                                                        onBlur={onBlurScrutinioRelazione}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>

                            <Grid xs={12} md={6}>
                                <Paper sx={{ p: 2 }}>
                                    <Typography variant='h6' sx={{ mb: 1 }}>
                                        <b> {isUltimoPeriodo ? "Frequenza complessiva dell'anno" : "Frequenza"} </b>
                                    </Typography>
                                    {alunnoSelezionato?.isRitirato !== undefined && alunnoSelezionato?.isRitirato === 1 && (
                                        <Typography color="error" variant="body1"><b>Alunno ritirato in data {convertDataDiNascita(alunnoSelezionato?.iscrittoAl)}</b></Typography>
                                    )}
                                    <Typography variant="subtitle2">Ore svolte: <b>{frequenzaObj?.oreSvolte ?? "-"}</b></Typography>
                                    <Typography variant="subtitle2">Ore di assenza: <b>{frequenzaObj?.oreAssenza ?? "-"} ({frequenzaObj?.percentuale ?? "0"}%) </b></Typography>
                                </Paper>

                                {showCrediti && (
                                    <Paper sx={{ p: 2, mt: 2 }}>
                                        <Typography variant='h6' sx={{ mb: 1 }}>
                                            <b>Totale crediti formativi</b>
                                        </Typography>
                                        <Box >
                                            <Typography>Crediti ottenuti: {creditiFormativi}</Typography>
                                            {canCalculateCrediti && (
                                                <Button color="success" variant="contained" fullWidth onClick={getCreditiFormativi} sx={{ mt: 2 }}>
                                                    Calcola crediti formativi
                                                </Button>
                                            )}
                                        </Box>
                                    </Paper>
                                )}
                                {(isUltimoPeriodo && showEsito && showProvaPassaggio) && (
                                    <Paper sx={{ p: 2, mt: 2, display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                                        <Box sx={{ p: 0 }}>
                                            <Typography variant="h6" sx={{ mr: 2 }}>
                                                Prova di passaggio
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", alignItems: 'center' }}>
                                            <Box sx={{ p: 1, display: "flex", justifyContent: 'center' }}>
                                                <Box className="fakeInputBox"  >
                                                    <Typography sx={{ color: changeColorVoto(provaDiPassaggio ?? null) }}>
                                                        {returnVotoConverted(provaDiPassaggio)}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box sx={{ p: 1 }}>
                                                <input
                                                    className="inputEdit"
                                                    ref={el => provaDiPassaggioRef.current = el}
                                                    defaultValue={returnVotoConverted(provaDiPassaggioInput)}
                                                    style={{ width: '50px', color: changeColorVoto(provaDiPassaggioInput ?? null) }}
                                                    onChange={changeProvaDiPassaggio}
                                                    onBlur={onBlurProvaDiPassaggio}
                                                />
                                            </Box>
                                        </Box>
                                    </Paper>
                                )}

                                {(showEsito && (alunnoSelezionato?.isRitirato === undefined || alunnoSelezionato?.isRitirato !== 1)) && (
                                    <Paper sx={{ p: 2, mt: 2 }}>

                                        <Typography variant='h6' sx={{ mb: 1 }}>
                                            <b>Esito</b>
                                        </Typography>

                                        <Box display={"flex"} alignItems={"center"}>

                                            <Typography sx={{ mr: 2 }}>Esito alunno: </Typography>
                                            <ToggleButtonGroup
                                                color={esitoAlunnoIdoneo !== undefined && esitoAlunnoIdoneo == "true" ? 'success' : 'error'}
                                                value={esitoAlunnoIdoneo}
                                                exclusive
                                                onChange={changeEsitoAlunnoIdoneo}
                                            >
                                                <ToggleButton value="true">Idoneo</ToggleButton>
                                                <ToggleButton value="false">Non idoneo</ToggleButton>
                                            </ToggleButtonGroup>

                                        </Box>

                                    </Paper>
                                )}
                            </Grid>

                        </Grid>
                    </Fragment>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Box sx={{ width: '100%', p: 2 }} display={"flex"} flexDirection={isMobile ? 'column' : 'row'} alignItems={"center"} justifyContent={"space-between"}>
                        <Button sx={{ width: isMobile ? "100%" : "fit-content" }} variant="contained" color="error" onClick={closeInserimento}>
                            Annulla e chiudi
                        </Button>
                        <Box flexDirection={isMobile ? 'column' : 'row'} sx={{ display: "flex", alignItems: 'center', width: isMobile ? "100%" : "fit-content" }}>
                            {(isButtonConfermaDisabled || (showEsito && esitoAlunnoIdoneo === undefined && alunnoSelezionato?.isRitirato != 1) || (isUltimoPeriodo && showEsito && provaDiPassaggioInput == null)) && (
                                <Alert sx={{ ml: 1, mr: isMobile ? 0 : 2, mt: isMobile ? 1 : 0 }} severity='error'>
                                    {/* {showErrorEsito && (
                                        <span>{errorMsg}</span>
                                    )} */}
                                    {isButtonConfermaDisabled && (
                                        <span>  Alcuni voti non sono stati inseriti. Non è possibile salvare le modifiche. <br></br></span>
                                    )}
                                    {((isUltimoPeriodo && showEsito && provaDiPassaggioInput == null)) && (
                                        <span> Per proseguire è necessario inserire la prova di passaggio. <br></br></span>
                                    )}
                                    {(showEsito && esitoAlunnoIdoneo === undefined) && (
                                        <span>Per proseguire è necessario selezionare l'esito di idoneità dell'alunno.</span>
                                    )}
                                </Alert>
                            )}
                            <Button sx={{ width: isMobile ? "100%" : "fit-content", mt: isMobile ? 1 : 0 }} disabled={isButtonConfermaDisabled || isConfermaLoading || (showEsito && esitoAlunnoIdoneo === undefined && alunnoSelezionato?.isRitirato != 1) || (isUltimoPeriodo && showEsito && provaDiPassaggioInput == null)} variant={indexAlunnoSelezionato < listAlunni.length - 1 ? "outlined" : "contained"} color="success" onClick={saveCloseInserimentiVoti}>
                                Conferma e chiudi
                            </Button>
                            {indexAlunnoSelezionato < listAlunni.length - 1 && (
                                <Button sx={{ width: isMobile ? "100%" : "fit-content", ml: isMobile ? 0 : 2, mt: isMobile ? 1 : 0 }} disabled={isButtonConfermaDisabled || isConfermaLoading || (showEsito && esitoAlunnoIdoneo === undefined && alunnoSelezionato?.isRitirato != 1) || (isUltimoPeriodo && showEsito && provaDiPassaggioInput == null)} variant="contained" color="success" onClick={saveVotiAndGoNext}>
                                    Conferma e prosegui all' alunno successivo
                                </Button>
                            )}
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen sx={{ marginTop: '7vh' }} open={loadingSchedaScrutinio}>
                <DialogContent>
                    <Box sx={{ height: '85vh', display: "flex", flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                        {(indexAlunnoSelezionato > 0 && showAlertNextStudente) && (
                            <Alert severity="success" sx={{ mt: 2 }}>Lo scrutinio dell'alunno <b>{listAlunni[indexAlunnoSelezionato - 1].cognome + " " + listAlunni[indexAlunnoSelezionato - 1].nome}</b> è stato caricato correttamente.</Alert>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth="xs" open={legendaDialogOpen} onClose={openDialogLegenda}>
                <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant='h6'>Legenda</Typography>
                    <IconButton onClick={openDialogLegenda}><Close /></IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography sx={{ fontSize: '1.25rem' }}><b style={{ color: '#9c27b0' }}>A</b> = Ore di assenza</Typography>
                    <Typography sx={{ fontSize: '1.25rem' }}><b style={{ color: '#ed6c02' }}>S</b> = Ore svolte</Typography>
                    <Typography sx={{ fontSize: '1.25rem' }}><b style={{ color: '#0288d1' }}>T</b> = Ore totali</Typography>

                    <Button onClick={openDialogLegenda} size='small' sx={{ mt: 4 }} fullWidth variant='contained'>Chiudi</Button>
                </DialogContent>
            </Dialog>


            <DialogConferma
                isDialogOpen={esitoHasProblems}
                title={'Errore durante il salvataggio'}
                icon={<ErrorOutlineIcon sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                text={errorMsg}
                showAlunni={false}
                confermaButton={'Chiudi finestra'}
                severity={'error'}
                closeDialog={() => { setEsitoHasProblems(false) }}
                conferma={() => { setEsitoHasProblems(false) }}
                disabledButton={false}
                showOnlyOneButton={true}
            />
        </Fragment >
    );
}
