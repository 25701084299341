import { Fragment, useEffect, useRef, useState } from "react"

import { Alert, Box, Button, Card, CardActionArea, Checkbox, Chip, Collapse, Divider, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';

import { ComunicazioniAPI } from "../../../api/comunicazioniApi";
import { Close, KeyboardArrowDown, KeyboardArrowUp, Search } from "@mui/icons-material";
import CustomDatePicker from "../../shared/customDatePicker";
import { DateTime } from "luxon";
import { convertDataGetProssimeLezione } from "../../shared/metodiDateUtility";
import DettaglioComunicazione from "../dettaglioComunicazione";

export default function ComunicazioniRicevute(props) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const oggettoComunicazioneRef = useRef(null);
    const mittenteRef = useRef(null);

    const [annoFormativo, setAnnoFormativo] = useState({ id: "20232024" });
    const [oggettoComunicazione, setOggettoComunicazione] = useState(null);
    const [listTipologieComunicazioni, setListTipologieComunicazioni] = useState([]);
    const [tipologiaComunicazione, setTipologiaComunicazione] = useState(-1);
    const [mittente, setMittente] = useState(null);
    const [dataDa, setDataDa] = useState(null);
    const [dataA, setDataA] = useState(null);
    const [daLeggere, setDaLeggere] = useState(false);

    const [listComunicazioni, setListComunicazioni] = useState([]);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [filtriRicercaNotCollapsed, setFiltriRicercaNotCollapsed] = useState(true);
    const [thereIsModificheFiltri, setThereIsModificheFiltri] = useState(false);
    const [numeroPagine, setNumeroPagine] = useState(null);
    const [paginaSelezionata, setPaginaSelezionata] = useState(1);

    const numberOfSkeleton = [0, 1, 2, 3, 4, 5];


    useEffect(() => {
        if (props.annoFormativo !== undefined && props.annoFormativo !== null) {
            setAnnoFormativo(props.annoFormativo);
            const todayDate = DateTime.now();
            const datePrevious30 = DateTime.now().minus({ days: 30 });
            setDataDa(datePrevious30);
            setDataA(todayDate);
            getTipologieComunicazioni(props.annoFormativo, datePrevious30, todayDate);
        }
    }, [props.annoFormativo])



    function changeOggettoComunicazione(e) {
        if (oggettoComunicazioneRef.current)
            oggettoComunicazioneRef.current.value = e.target.value
    }
    function onBlurOggettoComunicazione() {
        setOggettoComunicazione(oggettoComunicazioneRef.current.value);
        if (thereIsModificheFiltri === false) setThereIsModificheFiltri(true);
    }

    function changeMittente(e) {
        if (mittenteRef.current)
            mittenteRef.current.value = e.target.value
    }

    function changeDaLeggere() {
        setDaLeggere(prev => !prev);
        if (thereIsModificheFiltri === false) setThereIsModificheFiltri(true);
    }

    function onBlurMittente() {
        setMittente(mittenteRef.current.value);
        if (thereIsModificheFiltri === false) setThereIsModificheFiltri(true);
    }
    function changeDataDa(date) {
        setDataDa(date);
        if (thereIsModificheFiltri === false) setThereIsModificheFiltri(true);
    }

    function changeDataA(date) {
        setDataA(date);
        if (thereIsModificheFiltri === false) setThereIsModificheFiltri(true);
    }

    function isRicercaValid() {
        if (dataDa !== null || dataA !== null || (oggettoComunicazione !== null && oggettoComunicazione !== ""))
            return true;
        else return false;
    }

    function textfieldsHaveErrors() {
        if ((mittenteRef?.current?.value?.length > 0 && mittenteRef?.current?.value?.length < 3) || (oggettoComunicazioneRef?.current?.value?.length > 0 && oggettoComunicazioneRef?.current?.value?.length < 3))
            return true
        else return false
    }

    function removeFilters() {
        setDaLeggere(false);
        if (mittente !== null) {
            mittenteRef.current.value = null;
            setMittente(null);
        }
        if (dataDa !== null) setDataDa(null);
        if (dataA !== null) setDataA(null);
        if (oggettoComunicazione !== null) {
            oggettoComunicazioneRef.current.value = null;
            setOggettoComunicazione(null);
        }
        setTipologiaComunicazione(99);
        if (thereIsModificheFiltri === false) setThereIsModificheFiltri(true);
    }

    function openAlert(msg, severity) {
        if (props.openAlert) props.openAlert(msg, severity);
    }

    function changeTipologiaComunicazione(e) {
        setTipologiaComunicazione(e.target.value);
        if (thereIsModificheFiltri === false) setThereIsModificheFiltri(true);
    }

    function changePage(nPage) {
        if (nPage != paginaSelezionata) {
            setPaginaSelezionata(nPage);
            elencoComunicazioniRicevute(annoFormativo, dataDa, dataA, undefined, nPage);
        }
    }


    async function elencoComunicazioniRicevute(annoFormativoObj, dataDaTmp, dataATmp, tipologiaComunicazioneTmp, nPaginaTmp) {
        setHasLoaded(false);
        let dataDaUTC = dataDaTmp !== undefined ? convertDataGetProssimeLezione(dataDaTmp, true) : (dataDa !== undefined ? convertDataGetProssimeLezione(dataDa, true) : null);
        let dataAUTC = dataATmp !== undefined ? convertDataGetProssimeLezione(dataATmp, true) : (dataA !== undefined ? convertDataGetProssimeLezione(dataA, true) : null);
        let dataObj = {
            "annoFormativo": annoFormativoObj !== undefined ? annoFormativoObj.id.toString() : annoFormativo.id.toString(),
            "oggetto": oggettoComunicazione,
            "tipologia": tipologiaComunicazioneTmp !== undefined ? (tipologiaComunicazioneTmp !== 99 ? tipologiaComunicazioneTmp : undefined) : (tipologiaComunicazione !== 99 ? tipologiaComunicazione : undefined),
            "dataDa": dataDaUTC ?? null,
            "dataA": dataAUTC ?? null,
            "mittente": mittente ?? null,
            "daLeggere": daLeggere,
            "pagina": nPaginaTmp !== undefined ? nPaginaTmp : paginaSelezionata,
        }
        ComunicazioniAPI.elencoComunicazioniRicevute(dataObj)
            .then((result) => {

                const arrayPagine = Array.from({ length: result.pagine }, (_, index) => index + 1);
                setNumeroPagine([...arrayPagine]);

                let comunicazioniSorted = result.comunicazioni;
                comunicazioniSorted.sort((a, b) => b.id - a.id);
                setThereIsModificheFiltri(false); //Disabilito il pulsante di ricerca fino a che non aggiungo filtri
                setListComunicazioni([...comunicazioniSorted]);
                setTimeout(() => {
                    setHasLoaded(true)
                }, 300)
            })
            .catch((err) => {
                setHasLoaded(true)
                setListComunicazioni([]);
            })
    }

    async function getTipologieComunicazioni(annoFormativoTmp, datePrevious30Tmp, todayDateTmp) {
        ComunicazioniAPI.getTipologieComunicazioni()
            .then((result) => {
                setListTipologieComunicazioni([...result])
                setTipologiaComunicazione(result[0]?.id ?? -1)
                elencoComunicazioniRicevute(annoFormativoTmp, datePrevious30Tmp, todayDateTmp, result[0]?.id);
            })
            .catch((err) => {
                setTipologiaComunicazione([]);
            })
    }

    return (
        <Fragment>
            <Paper>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", p: 2 }}>
                    <Typography variant="h6">Comunicazioni ricevute</Typography>
                    <Chip icon={filtriRicercaNotCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />} label={filtriRicercaNotCollapsed ? "Riduci filtri" : "Espandi filtri"} variant="outlined" onClick={() => setFiltriRicercaNotCollapsed(prev => !prev)} />
                </Box>
                <Divider />
                <Collapse in={filtriRicercaNotCollapsed} sx={{ p: filtriRicercaNotCollapsed ? 2 : 0 }}>
                    <form onSubmit={() => elencoComunicazioniRicevute(undefined)} style={{ width: '100%' }}>
                        <Grid container spacing={2} sx={{ width: '100%' }} alignItems={"start"}>
                            <Grid xs={12}>
                                <TextField
                                    error={oggettoComunicazioneRef?.current?.value?.length > 0 && oggettoComunicazioneRef?.current?.value?.length < 3}
                                    helperText={(oggettoComunicazioneRef?.current?.value?.length > 0 && oggettoComunicazioneRef?.current?.value?.length < 3) ? "Il testo deve essere formato da 3 (o più) caratteri" : ""}
                                    inputRef={oggettoComunicazioneRef}
                                    defaultValue={oggettoComunicazione}
                                    label={'Oggetto comunicazione'}
                                    onChange={changeOggettoComunicazione}
                                    onBlur={onBlurOggettoComunicazione}
                                    sx={{ width: '100%', mb: 1 }}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <CustomDatePicker maxDate={dataA} onChangeValue={changeDataDa}
                                    selectedValue={dataDa} label={"Data da"} />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <CustomDatePicker disabled={dataDa === null} minDate={dataDa !== null ? dataDa : null}
                                    onChangeValue={changeDataA} selectedValue={dataA} label={"Data a"} />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <FormControl sx={{ width: { xs: '100%' } }}>
                                    <InputLabel id="comType-label">Tipologia comunicazione</InputLabel>
                                    <Select
                                        label='Tipologia comunicazione '
                                        labelId="comType-label"
                                        disabled={listTipologieComunicazioni.length === 1}
                                        value={tipologiaComunicazione}
                                        onChange={changeTipologiaComunicazione}
                                    >
                                        <MenuItem value={99} key={-1}>
                                            Tutte
                                        </MenuItem>
                                        {listTipologieComunicazioni.length > 0 && (
                                            listTipologieComunicazioni.map((tipologia, index) => (
                                                <MenuItem key={tipologia?.id ?? index} value={tipologia?.id ?? index}>
                                                    {tipologia?.descrizione ?? ""}
                                                </MenuItem>
                                            ))
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={8}>
                                <TextField
                                    error={mittenteRef?.current?.value?.length > 0 && mittenteRef?.current?.value?.length < 3}
                                    helperText={(mittenteRef?.current?.value?.length > 0 && mittenteRef?.current?.value?.length < 3) ? "Il testo deve essere formato da 3 (o più) caratteri" : ""}
                                    inputRef={mittenteRef}
                                    defaultValue={mittente}
                                    label={'Mittente'}
                                    onChange={changeMittente}
                                    onBlur={onBlurMittente}
                                    sx={{ width: '100%', mb: 1 }}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <FormControlLabel control={<Checkbox checked={daLeggere} onChange={changeDaLeggere} />} label="Mostra solo comunicazioni non lette" />
                            </Grid>
                        </Grid>
                    </form>
                    <Grid container spacing={2} sx={{ width: '100%' }} >
                        <Grid xs={12}>
                            <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row", justifyContent: "end", alignItems: "center", mt: 1 }}>
                                {isRicercaValid() && (
                                    <Button fullWidth={isMobile ? true : false} onClick={removeFilters} disabled={!hasLoaded} startIcon={<Close />} variant="outlined" color="error">Rimuovi filtri</Button>
                                )}
                                <Button fullWidth={isMobile ? true : false} disabled={!hasLoaded || tipologiaComunicazione === -1 || !thereIsModificheFiltri || textfieldsHaveErrors()} startIcon={<Search />} variant="contained" color="success" sx={{ ml: isMobile ? 0 : 2, mt: isMobile ? 1 : 0 }} onClick={() => elencoComunicazioniRicevute(undefined, undefined, undefined)}>
                                    Avvia ricerca
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Collapse>
            </Paper>

            <Paper sx={{ p: 2, my: 2, overflowX: 'auto' }}>
                {listComunicazioni.length > 0 ? (
                    <Fragment>
                        <Table>
                            <TableHead>
                                <TableCell>Data invio</TableCell>
                                <TableCell>Mittente</TableCell>
                                <TableCell>Oggetto</TableCell>
                                <TableCell>Protocollo</TableCell>
                                <TableCell></TableCell>
                            </TableHead>
                            <TableBody>
                                {hasLoaded ? (
                                    listComunicazioni.map((comunicazione, index) => (
                                        <Fragment key={comunicazione?.id ?? index}>
                                            <DettaglioComunicazione showDestinatari={false} openAlert={openAlert} comunicazioneObj={comunicazione} />
                                        </Fragment>
                                    ))
                                ) : (

                                    numberOfSkeleton.map((s, index) => (
                                        <Fragment key={index}>
                                            <TableRow>
                                                <TableCell>
                                                    <Skeleton sx={{ width: '100%' }}></Skeleton>
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton sx={{ width: '100%' }}></Skeleton>
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton sx={{ width: '100%' }}></Skeleton>
                                                </TableCell>
                                                <TableCell>
                                                    <Skeleton sx={{ width: '100%' }}></Skeleton>
                                                </TableCell>
                                            </TableRow>
                                        </Fragment>))
                                )}
                            </TableBody>
                        </Table >
                        {(numeroPagine && numeroPagine.length > 1) && (
                            <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', overflow: 'auto', width: '100%', py: 1 }}>
                                {
                                    numeroPagine.map((pag) => (
                                        <Card sx={{ color: pag == paginaSelezionata ? 'white' : 'black', bgcolor: pag == paginaSelezionata ? '#1976d2' : 'white', mr: pag - 1 < numeroPagine.length ? 1 : 0 }}>
                                            <CardActionArea sx={{ p: 2 }} onClick={() => changePage(pag)}>
                                                <Typography sx={{ fontWeight: pag == paginaSelezionata ? "bold" : "unset" }}>{pag}</Typography>
                                            </CardActionArea>
                                        </Card>
                                    ))
                                }</Box>
                        )}
                    </Fragment>
                ) : (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: '100%' }}>
                        <Alert severity='info'>Non ci sono comunicazioni ricevute che corrispondono ai filtri di ricerca.</Alert>
                    </Box>
                )}
            </Paper>

        </Fragment >
    )
}