import { Fragment, useEffect, useState } from "react";
import { Button, TextField, Container, Snackbar, Alert, Box, Typography, Skeleton, Divider, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { itIT } from "@mui/x-date-pickers/locales";

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from "luxon";
import { RegistroAPI } from "../../../../api/registroApi";
import { EventiAPI } from "../../../../api/eventiApi";
import { convertDataGetProssimeLezione, convertISODate } from "../../../shared/metodiDateUtility";
import { Delete } from "@mui/icons-material";
import DialogConferma from "../../../shared/dialogConferma";

export default function DettaglioExAnte(props) {

    const [comunicazione, setComunicazione] = useState(null);
    const [hasLoaded, setHasLoaded] = useState(false);
    // const [isDocente, setIsDocente] = useState(true);

    const [data, setData] = useState(null);
    const [exAnte, setExAnte] = useState(false);
    const [voto, setVoto] = useState('');
    const [tipologiaVoto, setTipologiaVoto] = useState('');
    const [idMateria, setIdMateria] = useState(-1);
    const [idVoto, setIdVoto] = useState(-1);

    const [nomeMateriaSelected, setNomeMateriaSelected] = useState("");


    const [idAlunno, setIdAlunno] = useState(-1);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("info");
    const [alertMsg, setAlertMsg] = useState("");

    const [modificaMode, setModificaMode] = useState(false);


    const [cantModify, setCantModify] = useState(false);

    const [showPulsanteEdit, setShowPulsanteEdit] = useState(false);
    const [showPulsanteDelete, setShowPulsanteDelete] = useState(false);
    const [confirmDeleteDialogIsOpen, setConfirmDeleteDialogIsOpen] = useState(false);


    const [objPermessiExAnte, setObjPermessiExAnte] = useState({
        insertExAnte: true,
        prerequisitiPassaggio: false,
        prerequisitiRecupero: false
    })

    // useEffect(() => {
    //     if (props.objPermessiExAnte !== undefined) {
    //         setObjPermessiExAnte(props.objPermessiExAnte)
    //     }
    // }, [props.objPermessiExAnte])

    useEffect(() => {
        if (props.cantModify !== undefined)
            setCantModify(props.cantModify)
    }, [props.cantModify])

    useEffect(() => {
        if (props.comunicazione !== undefined) {
            resetModifiche(props.comunicazione, props.objPermessiExAnte)
        }
    }, [props.comunicazione, props.objPermessiExAnte])

    useEffect(() => {
        if (props.idAlunno !== undefined)
            setIdAlunno(props.idAlunno)
    }, [props.idAlunno])



    function changeVoto(e) {
        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            if (/[*0-9]/g.test(newValue) === true) newStr = 'N.V.'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            // newStr = newValue.replace(/(\d)[a-zA-Z]/g, '$1');
            // newStr = newValue.replace(/[-/]/g, "");
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr === 0) newStr = 'N.V.';
        } else {
            newStr = ""; //resetta a ''
        }

        setVoto(newStr);
    }

    function changeData(value) {
        setData(value);
    }

    function annullaModifiche() {
        setModificaMode(false);
        resetModifiche(cantModify, objPermessiExAnte);
    }

    function closeAlert() {
        setAlertOpen(false);
    }
    function changeTipologia(e) {
        setTipologiaVoto(e.target.value)
    }
    function resetModifiche(nonSo, objPermessiExAnte) {
        if (props?.comunicazione !== undefined) {
            setComunicazione(props?.comunicazione)
            //2022-10-02T22:00:00.000Z - FORMATO ISO
            let newDataTmp = DateTime.fromISO(props?.comunicazione?.contenuto?.dataLezione);
            setIdVoto(props?.comunicazione?.id)
            setData(newDataTmp);

            setExAnte(props?.comunicazione?.contenuto?.is_ex_ante)
            setVoto(props?.comunicazione?.contenuto?.voto ?? '');
            if (props?.comunicazione?.contenuto?.tipologia !== undefined) {
                let tipologiaTmp = getTipologiaString(props?.comunicazione?.contenuto?.tipologia);
                setTipologiaVoto(tipologiaTmp)
            }
            // setMaterie([...materie, props.comunicazione.materia])
            setNomeMateriaSelected(props?.comunicazione?.contenuto?.materia.nomeMateria ?? "-")
            // let typeValutazione = props?.comunicazione?.tipologia_comunicazioni === 4 ? 0 : 1;
            setIdMateria(props?.comunicazione?.contenuto?.materia?.idMateria);

            canAccessButtons(props.comunicazione, objPermessiExAnte)
            setHasLoaded(true)
        }
    }

    function valoreTipologia() {
        switch (tipologiaVoto) {
            case -1:
                return '';
            case 0:
                return 'Ex ante';
            case 1: 
                return 'Accertamento prerequisiti per recupero debito';
            case 2:
                return 'Accertamento prerequisiti per passaggio';
        }
    }

    function getTipologiaString(stringTipologia) {
        switch (stringTipologia) {
            case "":
                return -1;
            case 'Ex ante':
                return 0;
            case 'Accertamento prerequisiti per recupero debito':
                return 1;
            case 'Accertamento prerequisiti per passaggio':
                return 2;
        }
    }

  
  



    function closeAlert() {
        setAlertOpen(false)
    }

    function aggiornaRegistro(alertMsg, alertSeverity) {
        if (props.aggiornaRegistro) props.aggiornaRegistro(alertMsg, alertSeverity)
    }

    function closeDialog() {
        if (props.closeDialogDettaglio)
            props.closeDialogDettaglio();
    }

    async function modificaVoto() {

        const obj = {
            voto: voto === 'N.V.' ? '0' : voto,
            idAlunno: [idAlunno],
            idOrario: null,
            tipologia: valoreTipologia(),
            dataRichiesta: convertDataGetProssimeLezione(data, true),
            idMateria: idMateria

        }

        EventiAPI.modificaPrerequisito(obj, idVoto)
            .then((result) => {
                if (props.exAnteModificato)
                    props.exAnteModificato()
            })
            .catch((error) => {
                let errorMsg = "";

                if (error.response.status === 409) {
                    errorMsg = error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione";
                } else {
                    errorMsg = "Al momento non è possibile completare l'operazione";
                }

                setAlertSeverity("error");
                setAlertMsg(errorMsg);
                setAlertOpen(true);
            })
    }


    async function canAccessButtons(objEvento, objPermessiExAnteTmp) {
        let idEventoTmp = objEvento?.id;
        let isEditable = objEvento?.contenuto?.editable;

        let dataObj = {
            idEvento: idEventoTmp
        }
        if (idEventoTmp !== -1 && isEditable) {
            RegistroAPI.showPulsantiEvento(dataObj)
                .then((result) => {
                    if (result?.showPulsanteEdit === false) {
                        setObjPermessiExAnte({ //Se non posso modificare allora imposto tutto a true
                            insertExAnte: true,
                            prerequisitiPassaggio: true,
                            prerequisitiRecupero: true
                        })
                    } else {
                        setObjPermessiExAnte(objPermessiExAnteTmp)
                    }
                    setShowPulsanteEdit(result?.showPulsanteEdit ?? false)
                    setShowPulsanteDelete(result?.showPulsanteDelete ?? false)
                })
                .catch(function (error) { })
        } else {
            setObjPermessiExAnte({ //Se non posso modificare allora imposto tutto a true
                insertExAnte: true,
                prerequisitiPassaggio: true,
                prerequisitiRecupero: true
            })
        }
    }

    async function deleteExante() {
        let dataObj = {
            idEvento: comunicazione?.id,
            tipo: comunicazione?.tipologia_comunicazioni
        }
        RegistroAPI.deleteEvento(dataObj)
            .then((result) => {
                if (props.exAnteEliminato) {
                    setConfirmDeleteDialogIsOpen(false)
                    let msgSuccess = "Ex ante eliminato";
                    props.exAnteEliminato(msgSuccess, "info");
                }

            })
            .catch(function (error) {
                if (props.eventoAggiunto)
                    props.exAnteEliminato(error.response.data.data[0].msg, "error");
            })
    }

    return (

        <Fragment>
            <Container maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Snackbar onClose={closeAlert} autoHideDuration={6000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen}>
                    <Alert sx={{ width: '100%', mb: 2 }} severity={alertSeverity} >
                        {alertMsg}
                    </Alert>
                </Snackbar>
                {hasLoaded ? (
                    <form style={{ width: '100%' }}>
                        <Grid container sx={{ width: '100%', pt: 2 }}>
                            {!modificaMode && (
                                <Grid xs={12} md={12} mb={2}>
                                    <Typography><b>Data di inserimento:</b>  {convertISODate(comunicazione?.created_at)} {'(Inserito da: ' + comunicazione?.created_by_obj?.nome + ')'};</Typography>
                                </Grid>
                            )}

                            {(!modificaMode && comunicazione?.modified_at !== null) && (
                                <Grid xs={12} md={12} mb={2}>
                                    <Typography><b>Data di modifica:</b>  {convertISODate(comunicazione?.modified_at)}  {'(Modificato da: ' + comunicazione?.modified_by_obj?.nome + ')'};</Typography>
                                </Grid>
                            )}
                            <Grid container xs={12} md={12} spacing={2}>
                                <Grid xs={12} md={6} >
                                    <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                        LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                        <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                                            disabled
                                            label="Data"
                                            maxDate={DateTime.now()}
                                            value={data}
                                            format="dd/MM/yyyy"
                                            onChange={(value) => changeData(value)}
                                            dayOfWeekFormatter={(day) => `${day}`}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid xs={12} md={6} >
                                    <TextField sx={{ width: '100%' }} data-testid='votoValutazioneInput'
                                        label="Materia"
                                        disabled
                                        value={nomeMateriaSelected} />
                                </Grid >

                                <Grid xs={12} md={6} >
                                    {/* <TextField sx={{ width: '100%' }} data-testid='votoValutazioneInput'
                                        label="Tipologia voto"
                                        disabled={!modificaMode}
                                        onChange={changeVoto}
                                        value={tipologiaVoto}
                                    /> */}
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="materia-label">
                                            Tipologia 
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            data-testid='selTypeTipologiaSelect'
                                            labelId="TypeTipologia-label"
                                            label='Tipologia'
                                            value={tipologiaVoto}
                                            disabled={!modificaMode}
                                            onChange={changeTipologia}
                                        >
                                            <MenuItem key={-1} value={-1} >
                                            </MenuItem>
                                            {objPermessiExAnte?.insertExAnte !== undefined && objPermessiExAnte?.insertExAnte === true && (
                                                <MenuItem key={0} value={0} >
                                                    Ex ante
                                                </MenuItem>
                                            )}
                                            {objPermessiExAnte?.prerequisitiRecupero !== undefined && objPermessiExAnte?.prerequisitiRecupero === true && (
                                                <MenuItem key={1} value={1} >
                                                    Accertamento prerequisiti per recupero debito
                                                </MenuItem>
                                            )}
                                            {objPermessiExAnte?.prerequisitiPassaggio !== undefined && objPermessiExAnte?.prerequisitiPassaggio === true && (
                                                <MenuItem key={2} value={2} >
                                                    Accertamento prerequisiti per passaggio
                                                </MenuItem>
                                            )}
                                         
                                        </Select>
                                    </FormControl>
                                </Grid>
                                < Grid xs={12} md={6} >
                                    <TextField sx={{ width: '100%' }} data-testid='votoValutazioneInput'
                                        label="Voto"
                                        disabled={!modificaMode}
                                        onChange={changeVoto}
                                        value={voto === '0' ? 'N.V.' : voto}
                                    // helperText={nomeMessage} 
                                    />

                                </Grid >
                            </Grid>

                        </Grid >

                        <Divider sx={{ my: 2 }} />
                        <Box>
                            {showPulsanteEdit ? (
                                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Button endIcon={<Delete />} onClick={() => { setConfirmDeleteDialogIsOpen(true) }} sx={{ mb: { xs: 1, md: 0 }, mr: 1, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="contained">
                                        Elimina ex ante
                                    </Button>
                                    <Box>
                                        {!modificaMode ? (
                                            <Button onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} variant="outlined">
                                                chiudi dettaglio
                                            </Button>
                                        ) : (
                                            <Button onClick={annullaModifiche} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="primary" variant="contained">
                                                Annulla
                                            </Button>
                                        )}
                                        {!modificaMode ? (
                                            <Button onClick={() => setModificaMode(true)} disabled={voto === ''} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                                                variant="contained" color="success">
                                                Modifica
                                            </Button>
                                        ) : (
                                            <Button onClick={modificaVoto} disabled={voto === '' || tipologiaVoto == -1}
                                                sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }} color="success" variant="contained" data-testid='modificaValutazioneBtn' >
                                                Conferma Modifica
                                            </Button>
                                        )}
                                    </Box>
                                </Box>

                            ) : (
                                <Button fullWidth onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 } }} variant="contained">
                                    chiudi dettaglio
                                </Button>
                            )}
                        </Box>
                    </form >
                ) : (
                    <Box sx={{ width: '100%' }}>
                        <Grid container spacing={2} sx={{ width: '100%', pt: 2 }}>
                            <Grid xs={12} md={12} >
                                <Skeleton />
                            </Grid>
                            <Grid xs={12} md={12} >
                                <Skeleton />
                            </Grid>
                            <Grid xs={12} md={6}>
                                <Skeleton height={50} />
                            </Grid>
                            <Grid xs={12} md={6} >
                                <Skeleton height={50} />
                            </Grid>
                            <Grid xs={12} md={6}>
                                <Skeleton height={50} />
                            </Grid>
                            <Grid xs={12} md={6} >
                                <Skeleton height={50} />
                            </Grid>
                        </Grid>
                        <Button fullWidth onClick={closeDialog} sx={{ mt: 2, mb: { xs: 1, md: 0 } }} variant="contained">
                            chiudi dettaglio
                        </Button>
                    </Box>
                )}
            </Container >

            <DialogConferma
                isDialogOpen={confirmDeleteDialogIsOpen}
                title={'Conferma eliminazione ex ante'}
                icon={<Delete sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                text={`Confermi di voler eliminare il voto ex ante?? Una volta confermato, non potrai annullare l'operazione.`}
                showAlunni={false}
                confermaButton={'Conferma eliminazione'}
                severity={'error'}
                closeDialog={() => { setConfirmDeleteDialogIsOpen(false) }}
                conferma={deleteExante}
                disabledButton={false}
            />
        </Fragment >

    )
}