import { Container, Typography, Button, Dialog, DialogContent, DialogTitle, IconButton, Divider, Snackbar, Alert } from "@mui/material";
import { useState, useEffect, Fragment } from "react"

import ElencoComunicazioni from "./elenco-comunicazioni/elencoComunicazioni";
import InserimentoComunicazioni from "./inserimentoComunicazioni";
import Breadcrumb from "../registri/shared/breadcrumb";
import { getAnnoFormativo } from "../shared/utilityMethods";
import { Close, ForwardToInbox, MarkunreadMailboxOutlined, MarkunreadMailboxRounded, Send } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ComunicazioniAPI } from "../../api/comunicazioniApi";

export default function ModuloComunicazioni(props) {

    const [annoFormativoObj, setAnnoFormativoObj] = useState(null);
    const [isNuovaComunicazioneDialog, setIsNuovaComunicazioneDialog] = useState(false);
    const [indexComunicazioneInviata, setIndexComunicazioneInviata] = useState(0); //Ogni volta che invio una comunicazione questo aumenta cosi trigghero il props delle comunicazione inviate
    const [alertMsg, setAlertMsg] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("info");
    const [alertOpen, setAlertOpen] = useState(false);
    const [showComunicazioniRicevute, setShowComunicazioniRicevute] = useState(false);
const [isAnnoFormativoCorrente, setIsAnnoFormativoCorrente] = useState(false);
    const [canShowInserimento, setCanShowInserimento] = useState(false);
    const [canShowComunicazioniInviate, setCanShowComunicazioniInviate] = useState(false);
    const [canShowComunicazioniRicevute, setCanShowComunicazioniRicevute] = useState(false);


    useEffect(() => {
        let annoFormativoTmp = getAnnoFormativo(true);
        let annoFormativoObj = JSON.parse(annoFormativoTmp);
        setIsAnnoFormativoCorrente(annoFormativoObj?.isAnnoFormativoCorrente ?? false);
        setAnnoFormativoObj(annoFormativoObj);
        getPermessiComunicazioni();
    }, [])

    function openNuovaComunicazioneDialog() {
        setIsNuovaComunicazioneDialog(prev => !prev);
    }

    function changeTypeComunicazioneElenco() {
        setShowComunicazioniRicevute(prev => !prev);
    }

    function comunicazioneInviata() {
        setIndexComunicazioneInviata(prev => prev + 1);
        setIsNuovaComunicazioneDialog(false);
        setAlertOpen(true);
        setAlertMsg("Comunicazione inviata correttamente");
        setAlertSeverity("success");
    }

    async function getPermessiComunicazioni() {
        ComunicazioniAPI.getPermessiComunicazioni()
            .then((result) => {
                setCanShowInserimento(result?.canAddComunicazione ?? false);
                setCanShowComunicazioniInviate(result?.canShowComunicazioniInviate ?? false);
                setCanShowComunicazioniRicevute(result?.canShowComunicazioniRicevute ?? false);

                if (result?.canShowComunicazioniInviate === true && result?.canShowComunicazioniRicevute === false){
                    setShowComunicazioniRicevute(false);
                } else setShowComunicazioniRicevute(true);
            })
            .catch((err) => { })
    }


    return (
        <Fragment>
            <Breadcrumb sections={["Modulo comunicazioni", "Comunicazioni"]} />
            <Container maxWidth="xl">
                <Grid container spacing={2} sx={{mt: 2}}>
                    {(canShowComunicazioniInviate && canShowComunicazioniRicevute) && (
                    <Grid xs={12} md={canShowInserimento ? 6 : 12}>
                            <Button onClick={changeTypeComunicazioneElenco} variant='contained' endIcon={showComunicazioniRicevute ? <ForwardToInbox/> : <MarkunreadMailboxOutlined /> } fullWidth sx={{ mb:{xs:0, md:2} }}>
                                {showComunicazioniRicevute ? "Comunicazioni inviate" : "Comunicazioni ricevute"}
                        </Button>
                    </Grid>
                    )}
                    {(canShowInserimento && isAnnoFormativoCorrente) && (
                        <Grid xs={12} md={(canShowComunicazioniInviate && canShowComunicazioniRicevute) ? 6 : 12}>
                        <Button onClick={openNuovaComunicazioneDialog} variant='contained' color="success" endIcon={<Send />} fullWidth sx={{ mb: 2 }}>Invia nuova comunicazione</Button>
                    </Grid>
                    )}
                </Grid>
                <ElencoComunicazioni showComunicazioniRicevute={showComunicazioniRicevute} indexComunicazioneInviata={indexComunicazioneInviata} annoFormativo={annoFormativoObj} />
            </Container>

            <Dialog open={isNuovaComunicazioneDialog} fullScreen sx={{ mt: '8vh' }}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Nuova comunicazione</Typography>
                    <IconButton onClick={openNuovaComunicazioneDialog}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent >
                    <InserimentoComunicazioni comunicazioneInviata={comunicazioneInviata} annoFormativo={annoFormativoObj} />
                </DialogContent>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}