import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import { Paper, Button, TextField, Collapse, Container, Typography, Dialog, FormControl, InputLabel, Select, MenuItem, Box, Snackbar, Alert, Divider, Chip, CircularProgress, Modal, DialogContent } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import { Add, ArrowBack, ArrowForward, Check, Clear, Delete, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';


import 'react-virtualized/styles.css';

import { RicercaAPI } from '../../../../api/ricercaApi';

import { getAnnoFormativo } from '../../../shared/utilityMethods';
import { TirociniAPI } from '../../../../api/tirociniApi';
import TreeViewRicerca from './treeViewRicerca';
import DialogConferma from '../../../shared/dialogConferma';

export default function RicercaAlunno(props) {


    const [hasLoaded, setHasLoaded] = useState(false);
    const [searchStarted, setSearchStarted] = useState(false);

    const [annoFormativo, setAnnoFormativo] = useState('');

    //Selezione
    const [isInsideOtherComponent, setIsInsideOtherComponent] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    const [CF, setCF] = useState('');
    const [CFValid, setCFValid] = useState(true);
    const [CFMessageError, setCFMessageError] = useState('');

    const [filtriRicercaNotCollapsed, setFiltriRicercaNotCollapsed] = useState(true);

    const [nomeAlunno, setNomeAlunno] = useState('');
    const [nomeAlunnoValid, setNomeAlunnoValid] = useState(true);
    const [nomeAlunnoMessageError, setNomeAlunnoMessageError] = useState('');

    const [cognomeAlunno, setCognomeAlunno] = useState('');
    const [cognomeAlunnoValid, setCognomeAlunnoValid] = useState(true);
    const [cognomeAlunnoMessageError, setCognomeAlunnoMessageError] = useState('');

    const [idAlunno, setIdAlunno] = useState(-1);
    const [listAlunni, setListAlunni] = useState([]);

    const [datiRicerca, setDatiRicerca] = useState([]);

    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);
    const [buttonConfermaDisabled, setButtonConfermaDisabled] = useState(false);

    const [debugMode, setDebugMode] = useState(false);

    const [corsoSelected, setCorsoSelected] = useState(-1);
    const [sedeSelected, setSedeSelected] = useState(-1);
    const [sediList, setSediList] = useState([]);
    const [corsiList, setCorsiList] = useState([]);

    const [isDettaglioOpen, setIsDettaglioOpen] = useState(false);

    useEffect(() => {
        if (process.env.REACT_APP_IS_DEBUG == "true") setDebugMode(true);
        else setDebugMode(false);
        let annoFormativoTmp = getAnnoFormativo();
        getSedi(annoFormativoTmp);
        setAnnoFormativo(annoFormativoTmp);
    }, [])

    useEffect(() => {
        if (props.isInsideOtherComponent !== undefined)
            setIsInsideOtherComponent(props.isInsideOtherComponent);
    }, [props.isInsideOtherComponent])

    // useEffect(() => {
    //     if (
    //         // props.alunno && 
    //         props.idCorso !== undefined) {
    //         // setCF(props.alunno?.cf);
    //         setCorsoSelected(props.idCorso);
    //         ricercaAlunni(
    //             // props.alunno?.cf, 
    //             props.idCorso)
    //     }
    // }, [
    //     // props.alunno, 
    //     props.idCorso])

    useEffect(() => {
        if (props.idAlunno !== undefined) {
            setIdAlunno(props.idAlunno);
        }
    }, [props.idAlunno])


    function validate(e) {
        e.preventDefault()
        startSearch()
    }

    function startSearch() {
        setSearchStarted(true)
    }

    function removeFilters() {
        setFiltriRicercaNotCollapsed(true); //Se rimuovo i filtri apro il collapse
        setIdAlunno(-1)
        setNomeAlunno('');
        setNomeAlunnoValid(true);
        setNomeAlunnoMessageError('');

        setCognomeAlunno('');
        setCognomeAlunnoValid(true);
        setCognomeAlunnoMessageError('');

        setCF('');
        setCFValid(true);
        setCFMessageError('');

        setCorsoSelected(-1);
        if (props.changeAlunno) props.changeAlunno(null)
        if (props.changeIdAlunno) props.changeIdAlunno(-1)

        setSearchStarted(false)

        setTimeout(() => {
            setListAlunni([]);
            setHasLoaded(false)
        }, 200)
    }

    function changeCF(e) {
        let valueTmp = e.target.value

        let valueNoSpaceTmp = valueTmp.replace(/\s/g, '');
        let valueNoExtraCharactersTmp;

        valueNoExtraCharactersTmp = valueNoSpaceTmp.replace(/[^a-zA-Z0-9]/g, '');

        setCF(valueNoExtraCharactersTmp);

        if (valueNoExtraCharactersTmp === '') {
            setCFValid(true);
            setCFMessageError('')
        } else {
            if (valueNoExtraCharactersTmp.length === 11 || valueNoExtraCharactersTmp.length === 16) {
                setCFValid(true);
                setCFMessageError('')
            } else {
                // setPIvaValid(false);
                setCFValid(false);
                setCFMessageError('Il C.F si compone di 11 o 16 caratteri')
            }
        }
    }

    function changeNomeAlunno(e) {
        let nomeAlunnoTmp = e.target.value;
        setNomeAlunno(nomeAlunnoTmp)
        if (nomeAlunnoTmp.length > 2 || nomeAlunnoTmp === "") {
            setNomeAlunnoMessageError('');
            setNomeAlunnoValid(true);
        }
        else {
            setNomeAlunnoValid(false);
            setNomeAlunnoMessageError('Min. 3 caratteri.');
        }
    }

    function changeCognomeAlunno(e) {
        let nomeAlunnoTmp = e.target.value;
        setCognomeAlunno(nomeAlunnoTmp)
        if (nomeAlunnoTmp.length > 2 || nomeAlunnoTmp === "") {
            setCognomeAlunnoMessageError('');
            setCognomeAlunnoValid(true);
        }
        else {
            setCognomeAlunnoValid(false);
            setCognomeAlunnoMessageError('Min. 3 caratteri.');
        }
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg)
        setAlertSeverity(severity)
        setAlertOpen(true)
    }


    function validateFormRicerca() {
        if (cognomeAlunnoValid &&
            nomeAlunnoValid &&
            CFValid
        ) return false
        else return true
    }

    function changeIdAlunno(idAlunno) {
        setIdAlunno(idAlunno)
        if (props.changeIdAlunno) props.changeIdAlunno(idAlunno)
    }

    function changeNameAlunno(nomeAlunno) {
        if (props.changeNomeAlunno) props.changeNomeAlunno(nomeAlunno)
    }

    function closeDialog() {
        removeFilters()
        if (props.closeDialog) props.closeDialog()
    }

    function condizioneSede() {
        if (sedeSelected === -1 && nomeAlunno !== '' && nomeAlunnoValid) return true
        else if (sedeSelected === -1 && CF !== '' && CFValid) return true
        else return false
    }

    function gestioneFiltroRicerca() {
        if (process.env.REACT_APP_IS_DEBUG == "true") {
            if (cognomeAlunnoValid &&
                nomeAlunnoValid &&
                CFValid &&
                corsoSelected !== -1
            ) return false
            else return true
        }
        else {
            if (cognomeAlunnoValid &&
                nomeAlunnoValid &&
                CFValid &&
                (cognomeAlunno !== '' ||
                    nomeAlunno !== '' ||
                    CF !== ''
                ) &&
                corsoSelected !== -1
            ) return false
            else return true
        }
    }

    async function ricercaAlunni(idCorsoTmp) {

        setFiltriRicercaNotCollapsed(false); //Se avvio la ricerca  chiudo il collapse dei filtri


        let dataObj = {
            nome: nomeAlunno !== '' ? nomeAlunno : null,
            cognome: cognomeAlunno !== '' ? cognomeAlunno : null,
            codiceFiscale: CF !== '' ? CF : null,
            idSede: sedeSelected !== -1 ? sedeSelected : null,
            idCorso: idCorsoTmp !== -1 ? idCorsoTmp : null,
            annoFormativo: annoFormativo
        }
        setHasLoaded(false);

        RicercaAPI.getAlunni(dataObj)
            .then((result) => {
                let listCorsiTmp = result?.sedi[0]?.corsi ?? [];
                console.log(result.sedi)
                setListAlunni([...listCorsiTmp]);
                let risultati = [];
                let corsiTmp = [];

                for (let corso of listCorsiTmp) {
                    let corsoTmp = {};
                    corsoTmp.id = corso.idCorso;
                    corsoTmp.name = corso.corso;

                    let alunniTmp = [];
                    for (let alunno of corso.alunni) {
                        let alunnoTmp = {};
                        alunnoTmp.id = alunno.idAlunno;
                        alunnoTmp.cf = alunno.codiceFiscale;
                        alunnoTmp.name = alunno.cognomeAlunno + " " + alunno.nomeAlunno;
                        alunnoTmp.type = "studente";
                        alunniTmp.push(alunnoTmp)
                    }
                    corsoTmp.type = "classe";
                    corsoTmp.codiceCorso = corso.codiceCorso;
                    corsoTmp.children = alunniTmp;
                    corsiTmp.push(corsoTmp)
                }

                risultati.push({
                    id: -1,
                    name: '',
                    type: "sede",
                    children: corsiTmp
                })
                setDatiRicerca([...risultati])

                setSearchStarted(true);

                setTimeout(() => {
                    setHasLoaded(true);
                }, 200)
            })
            .catch(function (error) {
                setTimeout(() => {
                    setHasLoaded(true);
                }, 200)
            })
    }

    function changeCorso(e) {
        let idCorsoTmp = e.target.value
        if (idCorsoTmp !== -1) {
            setCorsoSelected(idCorsoTmp);
            let corsoSelectedTmp = corsiList.filter(c => c.id === idCorsoTmp);
            let corsoTmp = {
                idCorso: idCorsoTmp,
                nomeCorso: corsoSelectedTmp[0].codiceCorso + ' - ' + corsoSelectedTmp[0].nomeCorso
            }
            if (props.changeCorso) props.changeCorso(corsoTmp)
        }
        else {
            setCorsoSelected(-1);
            if (props.changeIdCorso) props.changeIdCorso(-1)
        }
    };

    function changeSede(e) {
        if (e.target.value !== '') {

            setSedeSelected(e.target.value);
            getClassiBySede(e.target.value, annoFormativo);
        }
        else {
            setSedeSelected(-1);
            setCorsoSelected(-1);
        }
    };

    async function getClassiBySede(idSede, annoFormativo) {
        let dataObj = {
            idSede: idSede,
            annoFormativo: annoFormativo
        }

        RicercaAPI.getClassiBySede(dataObj)
            .then((result) => {
                setCorsiList(result.sedi)
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403)
                        if (props.logout())
                            props.logout();
                }
            })
    }

    async function getSedi(annoFormativo) {

        RicercaAPI.getSedi({ annoFormativo: annoFormativo })
            .then((result) => {
                setSediList(result.sedi)
                if (result.sedi.length === 1) {
                    setSedeSelected(result.sedi[0].id);
                    getClassiBySede(result.sedi[0].id, annoFormativo);
                }
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403)
                        if (props.logout())
                            props.logout();
                }

            })

    }

    function nextTab() {
        if (props.nextTab) props.nextTab();
    }

    return (
        <Fragment>

            <Container disableGutters={isInsideOtherComponent} maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <Paper sx={{ width: '100%', mt: isInsideOtherComponent ? 0 : 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", p: 2 }}>
                        <Typography variant="h6">Ricerca alunno</Typography>
                        <Chip icon={filtriRicercaNotCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />} label={filtriRicercaNotCollapsed ? "Riduci filtri" : "Espandi filtri"} variant="outlined" onClick={() => setFiltriRicercaNotCollapsed(prev => !prev)} />

                    </Box>
                    <Collapse in={filtriRicercaNotCollapsed}>
                        <Divider />
                        <Box sx={{ p: filtriRicercaNotCollapsed ? 2 : 0 }}>
                            <form onSubmit={validate} style={{ width: '100%' }}>
                                <Grid container spacing={2} sx={{ width: '100%', pt: 2 }} alignItems={"start"}>
                                <Grid xs={12} md={6}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputLabel id="sede-label">
                                                Sede*
                                            </InputLabel>
                                            <Select disabled={sediList.length === 1 && sedeSelected !== -1} label="Sede*" labelId="sede-label" value={sedeSelected} onChange={changeSede}  >
                                                <MenuItem key={-1} value={-1} >
                                                </MenuItem>
                                                {sediList.map((sede) => (
                                                    <MenuItem key={sede.id} value={sede.id}  >
                                                        {sede.nomeSede}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputLabel id="classe-label">
                                                Corso*
                                            </InputLabel>
                                            <Select disabled={sedeSelected !== -1 || corsiList.length !== 0 ? false : true} label="Corso*"
                                                labelId="classe-label" value={corsoSelected} onChange={changeCorso}  >
                                                <MenuItem key={-1} value={-1}  >
                                                </MenuItem>
                                                {corsiList.map((corso, index) => (
                                                    <MenuItem style={{ whiteSpace: 'normal' }} value={corso.id} sx={{ wordBreak: 'break-all' }}>
                                                        {corso?.codiceCorso ?? ""} - {corso?.nomeCorso ?? ""}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <TextField
                                            error={!CFValid ? true : false}
                                            defaultValue={CF ?? ''}
                                            value={CF ?? ''}
                                            label={"C.F. alunno"}
                                            onChange={changeCF}
                                            sx={{ width: '100%' }}
                                            helperText={CFMessageError}
                                            inputProps={{ maxLength: 16 }}
                                        />
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <TextField
                                            defaultValue={nomeAlunno ?? ''}
                                            value={nomeAlunno ?? ''}
                                            label={"Nome alunno"}
                                            onChange={changeNomeAlunno}
                                            sx={{ width: '100%' }}
                                            error={!nomeAlunnoValid}
                                            helperText={nomeAlunnoMessageError}
                                        />
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <TextField
                                            defaultValue={cognomeAlunno ?? ''}
                                            value={cognomeAlunno ?? ''}
                                            label={"Cognome alunno"}
                                            onChange={changeCognomeAlunno}
                                            sx={{ width: '100%' }}
                                            error={!cognomeAlunnoValid}
                                            helperText={cognomeAlunnoMessageError}
                                        />
                                    </Grid>
                                 
                                </Grid>
                                <Grid container spacing={2} sx={{ width: '100%', py: 2, px: 3 }} justifyContent={"end"}>
                                    {condizioneSede() && (<Grid xs={12} md={3}>
                                        <Box textAlign={'center'} color={'red'}>
                                            <Typography variant='caption' sx={{ textAlign: 'center' }}>Per avviare la ricerca è necessario selezionare la sede.</Typography>
                                        </Box>
                                    </Grid>)}
                                 
                                </Grid>
                            </form>
                        </Box>
                    </Collapse>
                    {(!debugMode) && (<Box p={2}>
                        <Alert severity="info">Per avviare la ricerca almeno uno dei campi del form deve essere valorizzato.</Alert>
                    </Box>)}
                    <Divider />
                    <Box sx={{ p: 2, display: "flex", alignItems: "start", flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'end', md: "space-between" } }}>
                        {searchStarted ? (
                            <Button sx={{ height: 'fit-content', px: { xs: 0, md: 5 }, width: { xs: '100%', md: 'fit-content' } }} onClick={removeFilters} variant="outlined" color="error" endIcon={<Clear />}>
                                Rimuovi filtri
                            </Button>
                        ) : (
                            <span></span>
                        )}
                        <Box sx={{ display:"flex", alignItems:'center',justifyContent:'space-between' }}>
                            {corsoSelected === -1 ? (
                                <Alert severity='error'>È necessario <b>selezionare un corso</b> prima di procedere</Alert>
                            ):(<span></span>)}
                            <Button sx={{ height: 'fit-content', ml: { xs: 0, md: 1 }, px: { xs: 0, md: 5 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} onClick={() => ricercaAlunni(corsoSelected)} endIcon={<SearchIcon />} disabled={gestioneFiltroRicerca()} variant="contained">
                                Avvia ricerca
                            </Button>
                        </Box>
                    </Box>
                </Paper>
                <Collapse in={searchStarted} sx={{ my: 2, width: '100%' }}>
                    <Paper sx={{ p: 2, overflowY: 'auto', height: '40vh' }}>
                        {hasLoaded ? (
                            listAlunni.length > 0 ?
                                (<TreeViewRicerca
                                    isMultipleSedi={false}
                                    setCheckedNodes={false}
                                    changeIdAlunno={changeIdAlunno}
                                    changeNomeAlunno={changeNameAlunno}
                                    idAlunnoSelected={idAlunno}
                                    risultatiRicerca={datiRicerca}
                                    showCheckbox={false}
                                    checkedRow={[]}
                                />)
                                : (
                                    <Box sx={{ height: '35vh', display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <Alert severity='info'>La ricerca non ha prodotto nessun risultato con i filtri selezionati.</Alert>
                                    </Box>
                                )
                        ) : (
                            <Box display={"flex"} height={"100%"} width={"100%"} flexDirection={"column"} alignItems="center" justifyContent={"center"}>
                                <CircularProgress />
                                <Typography sx={{ mt: 1 }}>Caricamento in corso...</Typography>
                            </Box>
                        )}
                    </Paper>
                </Collapse>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'end' }, width: { xs: '100%', md: '100%' } }}>
                    <Button sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }}
                        onClick={closeDialog}
                        variant="outlined" fullWidth color={'error'} >Chiudi procedura di inserimento</Button>
                    <Button endIcon={<ArrowForward />}
                        sx={{ width: { xs: 'unset', md: 'fit-content' }, mx: { xs: 4, md: 0 } }}
                        onClick={nextTab}
                        disabled={idAlunno === -1}
                        variant="contained" fullWidth color={'success'} > Conferma e prosegui</Button>
                </Box>
            </Container>
            {/* <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={'Conferma assegnazione'}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={`Confermi di voler assegnare allievo ad azienda simulata? `}
                showAlunni={false}
                confermaButton={'Conferma'}
                severity={'success'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={nextTab}
                disabledButton={buttonConfermaDisabled}
            /> */}
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>

        </Fragment >
    );
}