import { Box, Typography } from "@mui/material";
import { Description, Search, Edit, Person, FormatListBulleted, CopyAll, Download, Add, Delete, Archive, Start, List } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useEffect, useState } from "react";


export default function LegendaAziendaSimulata(props) {

    const [data, setData] = useState('')

    useEffect(() => {
        if (props.data !== undefined) setData(props.data)
    }, [props.data])

    return (
        <Box sx={{ maxHeight: '40vh' }}>
            <Grid container px={1} pb={{ xs: 2, md: 0 }}>
                <Grid xs={12} md={12}
                >
                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"} backgroundColor={'transparent'}
                            border={'1px solid #1976d3'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '6px', color: 'white' }}>
                            <Search color='primary' sx={{ fontSize: '1.8rem' }} />
                        </Box>
                        <Typography>Apri dettaglio</Typography>
                    </Box>
                </Grid>
                <Grid xs={12} md={12}>
                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"} backgroundColor={'transparent'}
                            border={'1px solid #1976d3'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '6px', color: 'white' }}>
                            <Edit color='primary' sx={{ fontSize: '1.8rem' }} />
                        </Box>
                        <Typography>Modifica dati</Typography>
                    </Box>
                </Grid>
                <Grid xs={12} md={12} >
                    <Box display={"flex"} alignItems={"center"} sx={{ mt: 2 }}>
                        <Box width={"30px"} height={"30px"} backgroundColor={'#d32f2f'}
                            display={"flex"} alignItems={"center"} justifyContent={"center"}
                            sx={{ mx: 1, p: 1, mr: 2, borderRadius: '6px', color: 'white' }}>
                            <Delete sx={{ fontSize: '1.8rem', color: 'white' }} />
                        </Box>
                        <Typography>Elimina{data === "aziende" ? ' azienda' : ' convenzione'}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box >
    )
}