import * as React from 'react';
import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import NavSection from './sidebar/NavSection';
import sidebarConfig from './sidebar/SidebarConfig';
import { useLocation, useNavigate } from "react-router-dom";
import { DateTime } from 'luxon';
import Logo from "../img/logo_w.png";
import LogoBlu from "../img/logo.png";
import { useTranslation } from 'react-i18next';
import buildVersion from '../buildVersion.txt';
import { Box, ListItemIcon, ListItemButton, styled, Dialog, Divider, DialogContent, DialogTitle, Snackbar, Alert, ListItemText, FormControl, useTheme, Badge } from '@mui/material';
import CryptoJS from 'crypto-js';
import useMediaQuery from '@mui/material/useMediaQuery';


import { AuthAPI } from '../api/authApi';
import { UtilityAPI } from '../api/utilityApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faFileSignature, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { Close } from '@mui/icons-material';
import DialogOreNonFirmate from './shared/dialogOreNonFirmate';


const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(
    ({ theme }) => ({
        ...theme.typography.body2,
        height: 48,
        position: 'relative',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(2.5),
        color: theme.palette.text.secondary,
        '&:before': {
            top: 0,
            right: 0,
            width: 3,
            bottom: 0,
            content: "''",
            display: 'none',
            position: 'absolute',
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            backgroundColor: theme.palette.primary.main
        }
    })
);

const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

export default function MenuComponent(props) {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("md"));
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(false);
    const id = localStorage.getItem("id") //JSON.parse(localStorage.getItem("id"));
    const [anchorElem, setAnchorElem] = useState(null);
    const [openMenu, setOpenMenu] = useState(false);
    const [buildVersionString, setBuildVersionString] = useState('0.1');
    const [user, setUser] = useState(null);
    const [dataAttuale, setDataAttuale] = useState("");
    const [time, setTime] = useState(new Date());
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const [annoFormativoSelezionato, setAnnoFormativoSelezionato] = useState(20232024);
    const [annoFormativoSelezionatoString, setAnnoFormativoSelezionatoStrng] = useState(20232024);

    const [sendEmailMessage, setSendEmailMessage] = useState("");

    const [permessi, setPermessi] = useState([]);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState('');

    const location = useLocation();

    const [stringColorMenu, setStringColorMenu] = useState("primary");

    const [listAnniFormativi, setListAnniFormativi] = useState([]);

    const [isOreNonFirmateDialogOpen, setIsOreNonFirmateDialogOpen] = useState(false);
    const [oreNonFirmate, setOreNonFirmate] = useState([]);

    useEffect(() => {
        if (refresh) {
            window.location.reload();
        }
    }, [refresh]);

    useEffect(() => {
        //Al cambio pagina cambio colore
        if (location.pathname.includes('/consulta-registro-di-classe') || location.pathname.includes('/registro-di-classe')) setStringColorMenu('success')
        else if (location.pathname.includes('/consulta-registro-docente') || location.pathname.includes('/registro-del-docente')) setStringColorMenu('warning')
        else setStringColorMenu('primary')
        // else setInElencoComunicazioni(false)
    }, [location]);

    useEffect(() => {
        if (props.oreNonFirmate !== undefined)
            setOreNonFirmate([...props.oreNonFirmate]);
    }, [props.oreNonFirmate])

    useEffect(() => {
        if (props.listAnniFormativi !== undefined) {
            let listAnniFormativiTmp = props.listAnniFormativi;
            setListAnniFormativi([...props.listAnniFormativi]);
            // if (process.env.REACT_APP_IS_DEBUG == "true") { //Per ora solo se siamo in DEBUG lasciamo il controllo sugli anni formativi
            if (listAnniFormativiTmp.length > 0) {
                if (!sessionStorage.getItem("annoFormativoObj")) { //Se non è stato selezionato l'anno formativo o se entro nell'applicativo per la prima volta da quando ho chiuso il browser
                    setAnnoFormativoSelezionato(listAnniFormativiTmp[0].id);
                    setAnnoFormativoSelezionatoStrng(listAnniFormativiTmp[0].nomeVisualizzato);
                    sessionStorage.setItem("annoFormativoObj", JSON.stringify(listAnniFormativiTmp[0]));
                    sessionStorage.setItem("annoFormativo", listAnniFormativiTmp[0].id);
                } else {
                    if (sessionStorage.getItem("annoFormativoObj")) {
                        let annoFormativoObjTmp = JSON.parse(sessionStorage.getItem("annoFormativoObj"));
                        setAnnoFormativoSelezionato(annoFormativoObjTmp.id);
                        setAnnoFormativoSelezionatoStrng(annoFormativoObjTmp.nomeVisualizzato);
                    }
                    else { //TODO: Lo posso togliere?
                        sessionStorage.setItem("annoFormativo", 20242025)
                        let annoFormativoTmpObj = {
                            id: 20242025,
                            nomeVisualizzato: "2024/2025",
                            dataDA: "2023-06-30T22:00:00.000Z",
                            dataA: "2024-07-31T22:00:00.000Z",
                            isAnnoFormativoCorrente: true
                        }
                        sessionStorage.setItem("annoFormativoObj", JSON.stringify(annoFormativoTmpObj));
                    }
                }
            }
            //   } 
            // else { //Per ora in modalità NON DEBUG impostiamo l'anno formativo 2023/2024
            //     sessionStorage.setItem("annoFormativo", 20232024)
            //     let annoFormativoTmpObj = {
            //         id: 20232024,
            //         nomeVisualizzato: "2023/2024",
            //         dataDA: "2023-06-30T22:00:00.000Z",
            //         dataA: "2024-07-31T22:00:00.000Z",
            //         isAnnoFormativoCorrente: true
            //     }
            //     sessionStorage.setItem("annoFormativoObj", JSON.stringify(annoFormativoTmpObj));
            // }
        }
    }, [props.listAnniFormativi])

    useEffect(() => {
        fetch(buildVersion)
            .then(r => r.text())
            .then(text => {
                let newVersion = (process.env?.REACT_APP_BUILD_VERSION ?? '0.1'); //+ text;
                setBuildVersionString(newVersion)
            });

        let currentDate = DateTime.now();
        let formattedDate = currentDate.toFormat('dd/MM/yyyy');
        setDataAttuale(formattedDate)

        if (sessionStorage.getItem("annoFormativoObj")) {
            let annoFormativoObjTmp = JSON.parse(sessionStorage.getItem("annoFormativoObj"));
            setAnnoFormativoSelezionato(annoFormativoObjTmp.id);
            setAnnoFormativoSelezionatoStrng(annoFormativoObjTmp.nomeVisualizzato);

        }


        const timer = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (props.user)
            setUser(props.user)
    }, [props.user])

    useEffect(() => {
        if (props.permessi)
            setPermessi([...props.permessi])
    }, [props.permessi])

    function navigateToLogin() {
        localStorage.clear();
        navigate("/login")
    }

    function logout() {
        if (props.logout)
            props.logout()
    }

    const open = Boolean(anchorElem);
    function handleClick(event) {
        setAnchorElem(event.currentTarget);
        setOpenMenu(true)
    };
    function handleClose() {
        setAnchorElem(null);
        setOpenMenu(false)
    };

    function openDialog() {
        setIsDialogOpen(true)
    }

    function closeDialog() {
        setIsDialogOpen(false)
    }

    function openDialogOreNonFirmate() {
        setIsOreNonFirmateDialogOpen(prev => !prev);
    }

    function goToGtm() {
        let idTmp = localStorage.getItem("id");
        let decryptedId = CryptoJS.AES.decrypt(idTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
        let currentUrl = window.location.href;
        let dataObj = {
            idUtente: decryptedId
        }

        UtilityAPI.loginGtm(dataObj)
            .then((result) => {
                let jwt = result;

                const params = new URLSearchParams();
                // params.set('url', currentUrl);
                params.set('registro', jwt);

                let gtmUrl = process.env.REACT_APP_GTM_URL + "?" + params.toString()
                window.location.replace(gtmUrl);
            })
            .catch(function (error) {
            }
            )
    }

    async function richiestaRecuperoPin() {

        let obj = {

        }

        AuthAPI.richiestaRecuperoPin(obj)
            .then((result) => {
                closeDialog();
                openAlertMsg('email inviata', 'success')
                // setSendEmailMessage("Le abbiamo inviato il procedimento per il modicare il suo pin personale alla sua email." )
            })
            .catch((error) => {
                openAlertMsg('Spiacenti, qualcosa è andato storto', 'error')
                // setSendEmailMessage("Siamo spiacenti, qualcosa è andato storto. Riprovare più tardi" )
            })
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertOpen(true);
    }

    function closeAlertMsg() {
        closeDialog();
    }
    function changeAnnoFormativo(e) {
        setAnnoFormativoSelezionato(e.target.value)
        let objAnnoFormativo = listAnniFormativi.find(anno => anno.id == e.target.value);
        setAnnoFormativoSelezionatoStrng(objAnnoFormativo.nomeVisualizzato);
        sessionStorage.setItem("annoFormativo", e.target.value);
        sessionStorage.setItem("annoFormativoObj", JSON.stringify(objAnnoFormativo));

        getPermessiByAnnoFormativo(e.target.value)
    }

    async function getPermessiByAnnoFormativo(annoFormativo) {
        let obj = {
            annoFormativo: annoFormativo
        };
        UtilityAPI.getPermessiByAnnoFormativo(obj)
            .then((result) => {
                if (result.idDocente != null) {
                    let idDocenteString = result.idDocente.toString(); //Crypto non vuole numeri, ma solo stringhe
                    let idDocente = CryptoJS.AES.encrypt(idDocenteString, process.env.REACT_APP_PRIVATE_KEY).toString();
                    localStorage.setItem("doc", idDocente);
                }
                let idString = result.id.toString(); //Crypto non vuole numeri, ma solo stringhe
                let id = CryptoJS.AES.encrypt(idString, process.env.REACT_APP_PRIVATE_KEY).toString();
                let nomeUtente = CryptoJS.AES.encrypt(result.nome, process.env.REACT_APP_PRIVATE_KEY).toString();

                let permessiString = JSON.stringify(result.permessi);
                let permessi = CryptoJS.AES.encrypt(permessiString, process.env.REACT_APP_PRIVATE_KEY).toString();

                let ruolo = CryptoJS.AES.encrypt(result.titoloVisualizzato, process.env.REACT_APP_PRIVATE_KEY).toString();

                let sedeString = JSON.stringify(result.sede);
                let sede = CryptoJS.AES.encrypt(sedeString, process.env.REACT_APP_PRIVATE_KEY).toString();

                let ruoliObjString = JSON.stringify(result.objRuoli);
                let objRuoli = CryptoJS.AES.encrypt(ruoliObjString, process.env.REACT_APP_PRIVATE_KEY).toString();

                localStorage.setItem("id", id);
                localStorage.setItem("usr", nomeUtente);
                localStorage.setItem("permessi", permessi);
                localStorage.setItem("ruolo", ruolo);
                localStorage.setItem("sede", sede);
                localStorage.setItem("ruoliObj", objRuoli)

                localStorage.setItem("annoFormativo", annoFormativo);

                setRefresh(true);
                navigate('/');

            })
            .catch((error) => { })
    }

    function goToAssistenza() {
        navigate('/assistenza', { state: { from: window.location.pathname } });
        handleClose();
    }

    return (
        <Box flexGrow={1} mb={2} >
            <AppBar color={stringColorMenu} position="fixed" sx={{ zIndex: '2000!important' }}>
                <Toolbar sx={{ height: '8vh' }}>
                    <IconButton size="large" edge="start" color="inherit"
                        aria-label="menu" mr={2}
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        {!openMenu ? <MenuIcon /> :
                            <CloseIcon />}
                    </IconButton>


                    <img src={Logo} alt="CIOFS logo" height="50" />
                    <Box sx={{ width: '100%', display: "flex", justifyContent: 'end', alignItems: 'center' }}>
                        {/* <IconButton sx={{ color: 'white' }} onClick={openDialogOreNonFirmate}>
                            <Badge color="error" badgeContent={oreNonFirmate.length}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}>
                                <FontAwesomeIcon icon={faFileSignature} />
                            </Badge>
                        </IconButton> */}
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }} justifyContent={'end'} alignItems={'center'}>

                            <Box sx={{ display: "flex", alignItems: 'center' }}>

                                <Box sx={{ mx: 2 }}>
                                    <Typography variant="body1" >
                                        {user?.nome ?? ''} {user?.ruolo !== "" ? "(" + user?.ruolo + ")" : ''} - {user?.sede?.nome ?? ''}
                                    </Typography>

                                    <Typography variant="subtitle2" sx={{ display: { xs: 'none', md: 'block' }, textAlign: 'center' }}>
                                        <span style={{ color: "#ffd90c" }}>Anno formativo: {annoFormativoSelezionatoString}</span> - {dataAttuale} ({time.toLocaleTimeString()})
                                    </Typography>
                                </Box>
                            </Box>

                            <Button data-testid='logoutBtn' color="inherit" variant="outlined"
                                onClick={logout} endIcon={<ExitToAppIcon />}
                            >{t("menu.logout")}
                            </Button>
                        </Box>

                        <Typography variant='body2' sx={{ color: "#ffd90c", display: { xs: 'flex', md: 'none' } }} >
                            Anno formativo: {annoFormativoSelezionatoString}
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
            {/* <Box sx={{height:'8vh'}}></Box> */}
            <SwipeableDrawer
                allowSwipeInChildren={true}
                anchorel={anchorElem}
                open={open}
                onOpen={() => { return }}
                onClose={handleClose}
                style={{ zIndex: '2100' }}

            >
                {/* <Box p={3}>
                    <img draggable="false" src={LogoBlu} alt="CIOFS logo" style={{ maxWidth: '100%', maxHeight: '100px' }} />
                </Box> */}
                <Box p={3} display="flex" alignItems={"center"} justifyContent={"space-between"}>
                    <img draggable="false" src={LogoBlu} alt="CIOFS logo" style={{ maxWidth: '70%', maxHeight: '60px' }} />
                    <IconButton onClick={handleClose} aria-label="close" color="primary">
                        <CloseIcon />
                    </IconButton>
                    {/* <Button onClick={handleClose} endIcon={<CloseIcon />} fullWidth variant="outlined">{t("menu.close")}</Button> */}

                </Box>
                <Box px={4}>
                    <Box sx={{ background: 'white', mr: 3, mb: 2, width: '100%' }}>
                        <FormControl sx={{ width: '100%', zIndex: '2200' }}>
                            <Typography variant="caption" color={'#637381'}>Anno formativo</Typography>
                            <select className="custom-select" style={{
                                fontSize: '0.9em', fontWeight: '600', fontFamily: [
                                    '-apple-system',
                                    'BlinkMacSystemFont',
                                    '"Segoe UI"',
                                    'Roboto',
                                    '"Helvetica Neue"',
                                    'Arial',
                                    'sans-serif',
                                    '"Apple Color Emoji"',
                                    '"Segoe UI Emoji"',
                                    '"Segoe UI Symbol"',
                                ].join(','),
                                width: '100%',
                                height: '38px',
                                cursor: 'pointer',
                                backgroundImage: "url('data:image/svg+xml;utf8,<svg fill=\"black\" viewBox=\"0 0 24 24\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M7 10l5 5 5-5z\"/></svg>')",
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'right .7em top 50%',
                                backgroundSize: '1em auto',  // Aumenta questo valore per aumentare la dimensione della freccia
                                appearance: 'none'
                            }}
                                value={annoFormativoSelezionato}
                                disabled={listAnniFormativi.length <= 1}
                                onChange={changeAnnoFormativo}
                            >
                                {listAnniFormativi.map((anno) => (
                                    <option key={anno.id} value={anno.id}><Typography sx={{ fontSize: '1.2em', fontWeight: '700' }}> {anno.nomeVisualizzato}</Typography></option>
                                ))}
                            </select>
                            {/* <ExpandLessIcon /> */}
                            {/* <div class="select-icon">&#9660;</div> */}
                        </FormControl>
                    </Box>
                </Box>
                <Divider sx={{ marginY: 1 }} />
                <NavSection permessi={permessi} navConfig={sidebarConfig} closeMenu={handleClose} />

                {/* <Typography onClick={goToGtm} variant='body2' sx={{ px: 5, mt: 1, color: '#637381', cursor: 'pointer' }}>Vai a Gtm</Typography> */}

                {permessi.some(permesso => permesso.nome === "canAccessGtm" && permesso.value === true) && (
                    <React.Fragment>
                        <Divider sx={{ my: 1 }} />
                        <Box>
                            <ListItemStyle onMouseDown={() => goToGtm()}
                                sx={{ textTransform: "none", color: '#637381' }}>
                                <ListItemIconStyle>
                                    <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: '1rem' }} />
                                </ListItemIconStyle>
                                <ListItemText disableTypography primary={'Vai a Gtm'} />
                            </ListItemStyle>
                        </Box>
                    </React.Fragment>
                )}
                <React.Fragment>
                    <Box>
                        <ListItemStyle onMouseDown={goToAssistenza}
                            sx={{ textTransform: "none", color: '#637381' }}>
                            <ListItemIconStyle>
                                <FontAwesomeIcon icon={faQuestion} style={{ fontSize: '1rem' }} />
                            </ListItemIconStyle>
                            <ListItemText disableTypography primary={'Assistenza'} />
                        </ListItemStyle>
                    </Box>
                </React.Fragment>
                <Box px={4} mt={5}>
                    <Button sx={{ mb: 2 }} onClick={logout} endIcon={<ExitToAppIcon />} fullWidth variant="contained">{t("menu.logout")}</Button>
                    <Button onClick={openDialog} fullWidth variant="outlined">Recupero pin</Button>
                </Box>
                <Typography variant="subtitle2" textAlign={"center"} sx={{ mt: 2, opacity: '0.5' }}>Versione {buildVersionString}</Typography>
            </SwipeableDrawer>
            <Dialog fullWidth maxWidth={"xs"} open={isDialogOpen} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <DialogTitle>Recupero pin</DialogTitle>
                <DialogContent>
                    <Box>
                        <Typography textAlign={'center'}>
                            Inviare email per recupero pin?
                        </Typography>
                    </Box>
                    <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} justifyContent={"end"} sx={{ mt: 3 }}>
                        <Button onClick={closeDialog} sx={{ mb: { xs: 1, md: 0 }, width: { xs: '100%', md: 'fit-content' } }} color="error" variant="outlined">annulla</Button>
                        <Button
                            onClick={() => richiestaRecuperoPin()}
                            sx={{ mb: { xs: 1, md: 0 }, ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'fit-content' } }}
                            color="success" variant="contained" >
                            Conferma
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog open={isOreNonFirmateDialogOpen} fullWidth maxWidth="lg" sx={{ mt: '8vh' }}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' >Elenco presenze non firmate</Typography>
                    <IconButton onClick={openDialogOreNonFirmate}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent >
                    <DialogOreNonFirmate oreNonFirmate={oreNonFirmate} />
                </DialogContent>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { closeAlertMsg() }} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Box>
    );
}

