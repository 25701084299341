import { useEffect, useState } from "react"
import { getAnnoFormativo } from "../../shared/utilityMethods";
import CryptoJS from 'crypto-js';
import { TirociniAPI } from "../../../api/tirociniApi";
import { Alert, Box, Button, Container, Divider, Snackbar, TextField, Typography } from "@mui/material";
import { convertDataGetProssimeLezione, convertUtcInDatetime } from "../../shared/metodiDateUtility";
import Grid from "@mui/material/Unstable_Grid2";
import Breadcrumb from "../../registri/shared/breadcrumb";
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import DialogConferma from "../../shared/dialogConferma";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function GestioneDatiRicorrentiSedi() {
    const { t, i18n } = useTranslation();

    const [annoFormativo, setAnnoFormativo] = useState("")
    const [sedeId, setSedeId] = useState(-1)
    const [showError, setShowError] = useState(false);

    const [isAlertSaveConfirmOpen, setIsAlertSaveConfirmOpen] = useState(false)
    const [dataInizioTirocinio, setDataInizioTirocinio] = useState(null);
    const [dataFineTirocinio, setDataFineTirocinio] = useState(null);
    const [objDirettore, setObjDirettore] = useState({
        nome: null,
        cognome: null,
        dataNascita: null,
        luogoNascita: null
    });
    const [inail, setInail] = useState(null);
    const [rcd, setRcd] = useState(null);
    const [polizzaAssicurativa, setPolizzaAssicurativa] = useState(null);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState('');

    useEffect(() => {
        const annoFormativoTmp = getAnnoFormativo('');
        setAnnoFormativo(annoFormativoTmp);
        let sedeTmp = localStorage.getItem("sede");
        let decryptedSede = CryptoJS.AES.decrypt(sedeTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
        let sedeParsed = JSON.parse(decryptedSede).id;
        setSedeId(sedeParsed);

        getPrecompilazioneConvenzioni(sedeParsed, annoFormativoTmp);
    }, [])

    function openIsAlertConfirmDialog() {
        setIsAlertSaveConfirmOpen(prev => !prev)
    }


    async function getPrecompilazioneConvenzioni(sedeIdTmp, annoFormativoTmp) {
        const objData = {
            idSede: sedeIdTmp,
            annoFormativo: annoFormativoTmp
        }

        TirociniAPI.getPrecompilazioneConvenzioni(objData)
            .then((result) => {
                setShowError(false);
                setObjDirettore({
                    nome: result?.anagrafica_direttore?.nome ?? null,
                    cognome: result?.anagrafica_direttore?.cognome ?? null,
                    luogoNascita: result?.anagrafica_direttore?.luogoNascita ?? null,
                    dataNascita: result?.anagrafica_direttore?.dataNascita ? convertUtcInDatetime(result?.anagrafica_direttore?.dataNascita) : null
                });
                setPolizzaAssicurativa(result?.polizza_assicurativa);
                setInail(result?.inail);
                setRcd(result?.rcd);
                // setDataInizioTirocinio(result?.tirocinio_data_inizio ? convertUtcInDatetime(result?.tirocinio_data_inizio) : null);
                // setDataFineTirocinio(result?.tirocinio_data_fine ? convertUtcInDatetime(result?.tirocinio_data_fine) : null);
            })
            .catch(function (error) {
                if (error.response) {
                    setShowError(true);
                }
            })
    }
    async function confirmSalvataggio() {
        const objData = {
            "idSede": sedeId,
            "annoFormativo": parseInt(annoFormativo),
            "anagrafica_direttore": {
                "nome": objDirettore?.nome ?? null,
                "cognome": objDirettore?.cognome ?? null,
                "dataNascita": objDirettore?.dataNascita ? convertDataGetProssimeLezione(objDirettore.dataNascita, true) : null,
                "luogoNascita": objDirettore.luogoNascita ?? null
            },
            "inail": inail ? inail : null,
            "rcd": rcd ? rcd : null,
            "polizza_assicurativa": polizzaAssicurativa ? polizzaAssicurativa : null,
            // "tirocinio_data_inizio": dataInizioTirocinio ? convertDataGetProssimeLezione(dataInizioTirocinio) : null,
            // "tirocinio_data_fine": dataFineTirocinio ? convertDataGetProssimeLezione(dataFineTirocinio) : null,
        }

        TirociniAPI.savePrecompilazioneConvenzioni(objData)
            .then((result) => {
                setIsAlertSaveConfirmOpen(false);
                setAlertMsg("Dati salvati correttamente");
                setAlertSeverity("success");
                setAlertOpen(true);
            })
            .catch(function (error) {
                if (error.response) {
                    const errorMsg = error?.response?.data?.data[0]?.msg ?? "Errore durante il salvataggio dei dati";
                    setAlertMsg(errorMsg);
                    setAlertSeverity("error");
                    setAlertOpen(true);
                }
            })
    }

    return (
        <>
            <Breadcrumb isTirocinio={true} sections={[ "Gestione dati ricorrenti per sedi"]} />
            <Container>
                {showError ? (
                    <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'center', height: '70vh' }}>
                        <Alert severity="error">Errore durante il recupero dei dati. Riprovare tra qualche minuto.</Alert>
                    </Box>
                ) : (
                    <>
                        <Typography sx={{ mb: 2 }} variant="h4">Gestione dati ricorrenti per sedi</Typography>
                        <Grid container spacing={2}>
                            <Grid xs={12} md={4}>
                                <TextField
                                    value={inail ?? ""}
                                    label="INAIL posizione n°"
                                    fullWidth
                                    type="text"
                                    variant='outlined'
                                    onChange={(e) => { setInail(e.target.value); }}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    value={polizzaAssicurativa ?? ""}
                                    label="Polizza Assicurativa n°"
                                    fullWidth
                                    type="text"
                                    variant='outlined'
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    onChange={(e) => { setPolizzaAssicurativa(e.target.value); }}
                                />
                            </Grid>
                            <Grid xs={12} md={4}>
                                <TextField
                                    value={rcd ?? ""}
                                    label="R.C.D. posizione n°"
                                    fullWidth
                                    type="text"
                                    variant='outlined'
                                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    onChange={(e) => { setRcd(e.target.value); }}
                                />
                            </Grid>

                            {/* <Grid xs={12} md={4}>
                                <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                    LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                    <MobileDatePicker views={['year', 'month', 'day']} sx={{ width: '100%' }}
                                        label="Data inizio tirocinio"
                                        value={dataInizioTirocinio ?? null}
                                        format="dd/MM/yyyy"
                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()}
                                        onChange={(newValue) => setDataInizioTirocinio(newValue)} />
                                </LocalizationProvider>
                            </Grid>
                            <Grid xs={12} md={4}>
                                <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                    LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                    <MobileDatePicker views={['year', 'month', 'day']} sx={{ width: '100%' }}
                                        label="Data fine tirocinio"
                                        value={dataFineTirocinio ?? null}
                                        format="dd/MM/yyyy"
                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()}
                                        onChange={(newValue) => setDataFineTirocinio(newValue)} />
                                </LocalizationProvider>
                            </Grid> */}
                        </Grid>

                        <Typography variant="h6" sx={{ mt: 2 }}>Anagrafica direttore</Typography>
                        <Divider sx={{ mb: 2 }}></Divider>

                        <Grid container spacing={2}>
                            <Grid xs={12} md={3}>
                                <TextField value={objDirettore?.nome ?? ""} label="Nome" fullWidth type="text" variant='outlined'
                                    onChange={(e) => setObjDirettore({ ...objDirettore, nome: e.target.value })} />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField value={objDirettore?.cognome ?? ""} label="Cognome" fullWidth type="text" variant='outlined'
                                    onChange={(e) => setObjDirettore({ ...objDirettore, cognome: e.target.value })} />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <TextField value={objDirettore?.luogoNascita ?? ""} label="Luogo di nascita" fullWidth type="text" variant='outlined'
                                    onChange={(e) => setObjDirettore({ ...objDirettore, luogoNascita: e.target.value })} />
                            </Grid>
                            <Grid xs={12} md={3}>
                                <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                    LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                    <MobileDatePicker views={['year', 'month', 'day']} sx={{ width: '100%' }}
                                        label="Data nascita"
                                        value={objDirettore?.dataNascita ?? null}
                                        format="dd/MM/yyyy"
                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()}
                                        onChange={(newValue) => setObjDirettore({ ...objDirettore, dataNascita: newValue })} />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>

                        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
                            <Button color="success" variant="contained" onClick={openIsAlertConfirmDialog}>
                                Conferma e salva
                            </Button>
                        </Box>

                        <DialogConferma
                            isDialogOpen={isAlertSaveConfirmOpen}
                            title={'Conferma modifiche'}
                            // icon={<Delete sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                            text={`Confermi di voler procedere con il salvataggio delle modifiche?`}
                            showAlunni={false}
                            confermaButton={'Conferma salvataggio'}
                            severity={'success'}
                            closeDialog={openIsAlertConfirmDialog}
                            conferma={confirmSalvataggio}
                            disabledButton={false}
                        />

                    </>
                )}
            </Container>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertOpen} autoHideDuration={8000} onClose={() => { setAlertOpen(false) }} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>

            <Helmet>
                <title>{t("name")} - Gestione dati ricorrenti sedi</title>
            </Helmet>

        </>
    )
}
