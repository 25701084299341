import { Box, Button, Chip, Dialog, DialogContent, DialogTitle, Divider, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { convertToItalianDateTime, convertToItalianDateTimeStringaParlante } from "../shared/metodiDateUtility";
import { CheckBox, CheckBoxOutlineBlankOutlined, Close, Download, PriorityHigh, Search, Visibility } from "@mui/icons-material";
import axios from "axios";
import CryptoJS from 'crypto-js';
import { ComunicazioniAPI } from "../../api/comunicazioniApi";


export default function DettaglioComunicazione(props) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [comunicazioneId, setComunicazioneId] = useState(-1)
    const [comunicazioneObj, setComunicazioneObj] = useState(null);
    const [comunicazioneObjDettaglio, setComunicazioneObjDettaglio] = useState(null);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [dialogDettaglioComunicazioneOpen, setDialogDettaglioComunicazioneOpen] = useState(false);
    const [isAllegatoDownloading, setIsAllegatoDownloading] = useState(false);
    const [listDestinatari, setListDestinatari] = useState([]);
    const [showDestinatari, setShowDestinatari] = useState(false);

    useEffect(() => {
        if (props.comunicazioneObj !== undefined) {
            setComunicazioneId(props.comunicazioneObj.id);
            setComunicazioneObj(props.comunicazioneObj);
            setHasLoaded(true);
        }
    }, [props.comunicazioneObj]);

    useEffect(() => {
        if (props.showDestinatari !== undefined) {
            setShowDestinatari(props.showDestinatari);
        }
    }, [props.showDestinatari]);

    function openDettaglioComunicazione() {
        let isDialogOpen = !dialogDettaglioComunicazioneOpen;
        setDialogDettaglioComunicazioneOpen(isDialogOpen);
        if (isDialogOpen) getDettaglioComunicazione(comunicazioneId);
    }

    const exportFile = (data, fileName, type) => {

        const blob = new Blob([data], { type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        setTimeout(() => {
            setIsAllegatoDownloading(false);
        }, 300)
    };

    async function getDettaglioComunicazione(idComunicazione) {
        let dataObj = {
            idComunicazione: idComunicazione
        }

        ComunicazioniAPI.dettaglioComunicazione(dataObj)
            .then((result) => {
                setComunicazioneObjDettaglio(result);
                setListDestinatari([...result.destinatari]);
                setHasLoaded(true);

                let comunicazioneObjTmp = comunicazioneObj;
                if (comunicazioneObj?.isDaLeggere !== undefined && comunicazioneObj?.isDaLeggere === true) {
                    comunicazioneObjTmp.isDaLeggere = false;
                    setComunicazioneObj(comunicazioneObjTmp);
                }
            })
            .catch((err) => {
                //TODO:Aggiungere errore
            })
    }

    async function downloadAllegato(nomeAllegato) {

        let urlDownload = "/comunicazioni/scaricaPDF/" + nomeAllegato;

        let encryptedToken = localStorage.getItem('tkn');
        let decryptedToken = CryptoJS.AES.decrypt(encryptedToken, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
        setIsAllegatoDownloading(true);
        axios.get(process.env.REACT_APP_FETCH_URL + urlDownload, {
            responseType: 'arraybuffer',
            headers: { Authorization: `Bearer ${decryptedToken}` }
        })
            .then((response) => {
                let contentDisposition = response.headers['content-disposition'];
                let filename = "";
                if (contentDisposition) {
                    if (props.openAlert)
                        props.openAlert("Download in corso", "info")
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }
                exportFile(response.data, filename, 'application/pdf');//;charset=utf-8;
            })
            .catch((error) => {
                setIsAllegatoDownloading(false);
                if (props.openAlert)
                    props.openAlert("Errore durante il download del documento", "error");
            });
    }

    return (
        <Fragment>
            {hasLoaded ? (
                <TableRow>
                    <TableCell align="start">
                        <Typography variant="subtitle2">{convertToItalianDateTime(comunicazioneObj?.dataInvio)}</Typography>
                        {comunicazioneObj?.isDaLeggere && (
                            <Chip size="small" sx={{ ml: isMobile ? 0 : 2, mt: 1 }} label="NON LETTA" variant="outlined" color="error" />
                        )}
                    </TableCell>
                    {!showDestinatari && ( //Se c'è questo allora sto su comunicazioni ricevutes
                        <TableCell>
                            <Typography variant="subtitle2">{comunicazioneObj?.mittente?.nome}</Typography>
                        </TableCell>
                    )}
                    <TableCell>
                        <Typography variant="subtitle2">{isMobile ? comunicazioneObj?.oggettoBreve : comunicazioneObj?.oggetto}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="subtitle2">{comunicazioneObj?.protocollo ?? "-"}</Typography>
                    </TableCell>
                    <TableCell align="right">
                        {isMobile ? (
                            <IconButton onClick={openDettaglioComunicazione} variant="contained" color="primary">
                                <Search />
                            </IconButton>
                        ) : (
                            <Button size="small" onClick={openDettaglioComunicazione} variant="contained" color="primary" endIcon={isMobile ? null : <Search />}>
                               Dettaglio
                            </Button>
                        )}
                    </TableCell>
                </TableRow>
            ) : (
                <Box sx={{ width: '100%', display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Skeleton variant="text" width={400} sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="rounded" width={100} height={30} />
                </Box >
            )}
            <Dialog sx={{ mt: '8vh' }} fullScreen={isMobile ? true : false} fullWidth maxWidth="xl" open={dialogDettaglioComunicazioneOpen}>
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} sx={{ m: 0, p: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Comunicazione del {convertToItalianDateTime(comunicazioneObj?.dataInvio)}
                    </Typography>
                    <IconButton onClick={openDettaglioComunicazione} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>

                <Divider />
                <DialogContent>
                    {comunicazioneObjDettaglio?.dataVisualizzazione !== undefined && comunicazioneObjDettaglio?.dataVisualizzazione !== null && (
                        <Typography variant="subitle2" color={"primary"}>
                            {comunicazioneObjDettaglio?.dataVisualizzazione !== null ? "Visualizzata " + convertToItalianDateTimeStringaParlante(comunicazioneObjDettaglio?.dataVisualizzazione) : ""}
                        </Typography>
                    )}
                    <Typography><b>Mittente:</b> {comunicazioneObjDettaglio?.mittente?.nome}</Typography>
                    <Typography><b>Data comunicazione:</b> {convertToItalianDateTimeStringaParlante(comunicazioneObjDettaglio?.dataInvio)}</Typography>
                    <Typography><b>Protocollo:</b> {comunicazioneObjDettaglio?.protocollo ?? "-"}</Typography>
                    <Typography><b>Oggetto comunicazione:</b> {comunicazioneObjDettaglio?.oggetto}</Typography>
                    <Typography><b>Testo comunicazione:</b> {comunicazioneObjDettaglio?.testo}</Typography>
                    {(listDestinatari.length > 0 && showDestinatari) && (
                        <Fragment>
                            <Divider sx={{my:1}}/>
                            <Typography ><b>Destinatari</b>:</Typography>
                            <Paper sx={{ mt: 2, overflowY: 'auto', maxHeight: '50vh' }}>
                                <Table>
                                    <TableHead >
                                        <TableCell className='row-head' style={{ backgroundColor: 'white', p: 2, minWidth: '100px' }}>
                                            Sede
                                        </TableCell>
                                        <TableCell className='row-head' style={{ backgroundColor: 'white', p: 2, minWidth: '100px' }}>
                                            Destinatario
                                        </TableCell>
                                        <TableCell align="center" className='row-head' style={{ backgroundColor: 'white', p: 2, minWidth: '100px' }}>
                                            Visualizzato
                                        </TableCell>
                                        <TableCell className='row-head' style={{ backgroundColor: 'white', p: 2, minWidth: '100px' }}>
                                            Data di presa visione
                                        </TableCell>
                                    </TableHead>
                                    <TableBody>
                                        {listDestinatari.map((destinatario) => (
                                            <Fragment key={destinatario.id}>
                                                <TableRow>
                                                    <TableCell>
                                                        <Typography variant="subtitle2">{destinatario?.sede ?? "-"}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="subtitle2">{destinatario?.nome ?? "-"}</Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {destinatario?.dataVisualizzazione !== null ? <CheckBox color="success" /> : <CheckBoxOutlineBlankOutlined />}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="subitle2" color={"primary"}> {destinatario?.dataVisualizzazione !== null ? "Visualizzata " + convertToItalianDateTimeStringaParlante(destinatario?.dataVisualizzazione) : ""}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </Fragment>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Fragment>
                    )}
                    {(comunicazioneObjDettaglio?.allegato !== undefined && comunicazioneObjDettaglio?.allegato !== null) && (
                        <Button disabled={isAllegatoDownloading} startIcon={<Download />} sx={{ mt: 2 }} onClick={() => downloadAllegato(comunicazioneObj?.allegato)} fullWidth variant="contained">Scarica allegato</Button>
                    )}
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}