

import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const AccertamentoPrerequisitiAPI = {
    elencoAccertamentPrerequisiti: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/elencoAccertamentiPrerequisiti`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getDettaglio: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/dettaglioAccertamentiPrerequisiti`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    eliminaPrerequisito: async function (id, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/eliminaAccertamentoPrerequisiti/${id}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getTipologieAccertamento: async function (cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `test/getTipologieAccertamentoPrerequisiti`,
            method: "POST",
            data: {},
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getListMaterie: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/elencoMaterie`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    saveAssegnazioniAccertamentiPrerequisiti: async function (obj, cancel = false) {
        const response = await api.request({
            sendToken: true,
            url: `/test/saveAssegnazioniAccertamentiPrerequisiti`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },    

}
const cancelApiObject = defineCancelApiObject(AccertamentoPrerequisitiAPI)