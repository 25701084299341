import { Fragment, useState, useEffect } from 'react';
import { Paper, Button, FormControl, Snackbar, Alert, CircularProgress, Box, Select, Typography, FormControlLabel, Checkbox, Dialog, DialogTitle, IconButton, Divider, DialogContent, Collapse } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { RicercaAPI } from '../../api/ricercaApi';
import { UtilityAPI } from '../../api/utilityApi';
import ElencoAlunniSelezionabili from '../eventi/elencoAlunniSelezionabili';
import { CellMeasurer, CellMeasurerCache, List, AutoSizer } from "react-virtualized";
import { AccertamentoPrerequisitiAPI } from '../../api/accertamentoPrerequisiti';
import ElencoMaterieSelezionabili from './elencoMaterieSelezionabili';
import DialogConferma from '../shared/dialogConferma';
import { ArrowBack, ArrowForward, Check } from '@mui/icons-material';

export default function GestioneAccertamento(props) {
    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 75,
    });
    const [corsiList, setCorsiList] = useState([]);
    const [corsoSelected, setCorsoSelected] = useState(-1);
    const [sediList, setSediList] = useState([]);
    const [sedeSelected, setSedeSelected] = useState(-1);
    const [listAlunni, setListAlunni] = useState([]);
    const [listMaterie, setListMaterie] = useState([]);
    const [listMaterieSelezionate, setListMaterieSelezionate] = useState([]);
    const [alunniSearched, setAlunniSearched] = useState(false);
    const [listTipologie, setListTipologie] = useState([]);
    const [alunniSelezionati, setAlunniSelezionati] = useState([]);
    const [getAlunniIsLoading, setGetAlunniIsLoading] = useState(false);
    const [tipologiaSelected, setTipologiaSelected] = useState(-1);
    const [nomeTipologiaSelected, setNomeTipologiaSelected] = useState('');
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState("");
    const [isRiepilogo, setIsRiepilogo] = useState(false);

    const [confermaSalvataggioDialogOpen, setConfermaSalvataggioDialogOpen] = useState(false);

    useEffect(() => {
        getTipologieAccertamento();
    }, [])

    useEffect(() => {
        if (props.annoFormativo)
            getSedi(props.annoFormativo);
    }, [props.annoFormativo])

    function changeTipologia(e) {
        const idTipologiaTmp = e.target.value;
        setTipologiaSelected(idTipologiaTmp);
        const tipologiaTmp = listTipologie.filter(tip => tip.id === idTipologiaTmp);
        if (tipologiaTmp[0] === undefined) {
            setNomeTipologiaSelected('');
        } else {
            setNomeTipologiaSelected(tipologiaTmp[0].nome);
        }
    }

    function changeSede(e) {
        if (e.target.value !== '') {
            setSedeSelected(e.target.value);
            getCorsiBySede(e.target.value);
        }
        else {
            setSedeSelected(-1);
            setCorsoSelected(-1);
        }
        setAlunniSelezionati([]);
        setListMaterieSelezionate([]);
        setTipologiaSelected(-1);
        setAlunniSearched(false);
    };
    function changeCorso(e) {
        if (e.target.value !== '') {
            setCorsoSelected(e.target.value);
        }
        else {
            setCorsoSelected(-1);
        }
        setListAlunni([]);
        setAlunniSelezionati([]);

        setListMaterie([]);
        setListMaterieSelezionate([]);

        setTipologiaSelected(-1);

        setAlunniSearched(false);
    };

    function selectAlunni(listAlunni) {
        setAlunniSelezionati([...listAlunni]);
    }
    function selectMaterie(listMaterie) {
        setListMaterieSelezionate([...listMaterie])
    }
    function closeAlert() {
        setAlertIsOpen(false)
    }
    async function getSedi(annoFormativo) {

        RicercaAPI.getSedi({ annoFormativo: annoFormativo })
            .then((result) => {
                setSediList(result.sedi)
                if (result.sedi.length === 1) {
                    setSedeSelected(result.sedi[0].id);
                    getCorsiBySede(result.sedi[0].id, annoFormativo);
                }
            })
            .catch(function (error) {
                if (error.response) {
                }
            })

    }
    async function getCorsiBySede(idSede) {
        let dataObj = {
            idSede: idSede,
            annoFormativo: props.annoFormativo
        }

        RicercaAPI.getClassiBySede(dataObj)
            .then((result) => {
                setCorsiList([...result.sedi])
            })
            .catch(function (error) {
                if (error.response) {
                }
            })
    }
    async function getElencoAlunni() {
        setGetAlunniIsLoading(true);
        setAlunniSearched(true);
        getListMaterie();
        let dataObj = {
            annoFormativo: props.annoFormativo,
            idCorso: corsoSelected,
            data: null,
        }
        UtilityAPI.getElencoAlunni(dataObj)
            .then((result) => {
                setGetAlunniIsLoading(false);
                setListAlunni([...result])
            })
            .catch(function (error) {
                if (error.response) {
                }
            })
    }
    async function getListMaterie() {
        let dataObj = {
            annoFormativo: props.annoFormativo,
            idCorso: corsoSelected,
        }
        AccertamentoPrerequisitiAPI.getListMaterie(dataObj)
            .then((result) => {
                setListMaterie([...result])
            })
            .catch(function (error) {
                if (error.response) {
                }
            })
    }
    async function getTipologieAccertamento() {
        AccertamentoPrerequisitiAPI.getTipologieAccertamento()
            .then((result) => {
                setListTipologie([...result])
            })
            .catch(function (error) {
                if (error.response) {
                }
            })
    }
    async function saveAssegnazione() {
        const dataObj = {
            idCorso: corsoSelected,
            idSede: sedeSelected,
            annoFormativo: props.annoFormativo,
            tipologia: tipologiaSelected,
            alunni: alunniSelezionati.map(alunno => ({ id: alunno.id })),
            materie: listMaterieSelezionate.map(materia => ({ id: materia.idMateria })),
        }
        AccertamentoPrerequisitiAPI.saveAssegnazioniAccertamentiPrerequisiti(dataObj)
            .then((result) => {
                props.assegnazioneSalvata(corsoSelected, sedeSelected);
                setConfermaSalvataggioDialogOpen(false)
            })
            .catch(function (error) {
                let errorMsgTmp = error?.response?.data?.data[0]?.msg ?? "Errore durante il salvataggio dell'accertamento";
                setAlertMsg(errorMsgTmp);
            })
    }

    return (
        <>
            {isRiepilogo && (<Typography mt={2} fontWeight={'bold'}>Riepilogo richiesta di accertamento prerequisiti</Typography>)}
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid xs={12} md={3}>
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel id="sede-label">
                            Sede
                        </InputLabel>
                        <Select disabled={(sediList.length === 1 && sedeSelected !== -1) || isRiepilogo} label="Sede" labelId="sede-label" value={sedeSelected} onChange={changeSede}  >
                            <MenuItem key={-1} value={-1} >
                            </MenuItem>
                            {sediList.map((sede) => (
                                <MenuItem key={sede.id} value={sede.id}  >
                                    {sede.nomeSede}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12} md={3}>
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel id="corso-label">
                            Corso
                        </InputLabel>
                        <Select disabled={(sedeSelected !== -1 || corsiList.length !== 0 ? false : true) || isRiepilogo} label="Corso"
                            labelId="corso-label" value={corsoSelected} onChange={changeCorso}  >
                            <MenuItem key={-1} value={-1}  >
                            </MenuItem>
                            {corsiList.map((corso) => (
                                <MenuItem style={{ whiteSpace: 'normal' }} value={corso.id} sx={{ wordBreak: 'break-all' }}>
                                    {corso?.codiceCorso ?? ""} - {corso?.nomeCorso ?? ""}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                {isRiepilogo && (<Grid xs={12} md={6}>
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel id="tipologia-label">
                            Tipologia accertamento
                        </InputLabel>
                        <Select label="Tipologia accertamento" disabled
                            labelId="tipologia-label" value={tipologiaSelected}  >
                            <MenuItem key={-1} value={-1}  >
                            </MenuItem>
                            {listTipologie.map((tipologia) => (
                                <MenuItem style={{ whiteSpace: 'normal' }} value={tipologia.id} sx={{ wordBreak: 'break-all' }}>
                                    {tipologia?.nome ?? "-"}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>)}
                {!isRiepilogo && (<Grid xs={12} md={6} sx={{ display: "flex", justifyContent: 'end', alignItems: 'center' }}>
                    <Button sx={{ height: 'fit-content', width: { xs: '100%', md: 'unset' } }} disabled={getAlunniIsLoading || corsoSelected === -1} onClick={getElencoAlunni} color='primary' variant='contained' >Seleziona</Button>
                </Grid>)}
            </Grid>
            <Divider sx={{ mt: 2 }} />
            <Collapse in={alunniSearched}>
                {!isRiepilogo && (<Box sx={{ mt: 2 }}>
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel id="tipologia-label">
                            Tipologia accertamento
                        </InputLabel>
                        <Select label="Tipologia accertamento" disabled={isRiepilogo}
                            labelId="tipologia-label" value={tipologiaSelected} onChange={changeTipologia}  >
                            <MenuItem key={-1} value={-1}  >
                            </MenuItem>
                            {listTipologie.map((tipologia) => (
                                <MenuItem style={{ whiteSpace: 'normal' }} value={tipologia.id} sx={{ wordBreak: 'break-all' }}>
                                    {tipologia?.nome ?? "-"}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>)}
                <Grid container spacing={2} >
                    <Grid xs={12} md={6} >
                        {!isRiepilogo ?
                            (<Fragment>
                                {!getAlunniIsLoading ? (
                                    <ElencoAlunniSelezionabili checkAssenze={false} alunniSelezionabiliNumber={listAlunni.length}
                                        canSelezioneMultipla={true} selectAlunni={selectAlunni} alunniNonSelezionabili={[]} listAlunniSelezionati={alunniSelezionati} listAlunni={listAlunni} />
                                ) : (
                                    <Paper elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid rgba(200, 200, 200, 1)', mt: 2 }}>
                                        <CircularProgress />
                                        <Typography sx={{ mt: 2 }}>Caricamento alunni...</Typography>
                                    </Paper>
                                )}
                            </Fragment>
                            ) : (
                                <Paper elevation={0} sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'start', border: '1px solid rgba(200, 200, 200, 1)', mt: 2 }}>
                                    <Typography mb={1.4} mt={1} ml={2} fontWeight={'bold'}>ALUNNI SELEZIONATI</Typography>
                                    <Divider />
                                    <Box height={'45vh'} overflow={"auto"} width={'100%'}>
                                        {alunniSelezionati.map((alunno) => (
                                            <Box key={alunno?.id} ml={2} my={1}>
                                                <Typography>{alunno?.cognome} {alunno?.nome}</Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </Paper>
                            )}
                    </Grid>

                    <Grid xs={12} md={6} >
                        {!isRiepilogo ? (
                            <ElencoMaterieSelezionabili selectMaterie={selectMaterie} listMaterieSelezionate={listMaterieSelezionate} listMaterie={listMaterie} />
                        ) : (
                            <Paper elevation={0} sx={{  p: 2, display: 'flex', flexDirection: 'column', alignItems: 'start', border: '1px solid rgba(200, 200, 200, 1)', mt: 2 }}>
                                <Typography mb={1.4} mt={1} ml={2} fontWeight={'bold'}>MATERIE SELEZIONATE</Typography>
                                <Divider />
                                <Box height={'45vh'} overflow={"auto"} width={'100%'}>
                                    {listMaterieSelezionate.map((materia) => (
                                        <Box key={materia?.id} ml={2} my={1}>
                                            <Typography>{materia?.nomeMateria}</Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Paper>
                        )}
                    </Grid>
                </Grid>
            </Collapse>
            {alunniSearched && (<Grid container sx={{ mt: 2 }}>
                <Grid xs={12} sx={{ display: "flex", justifyContent: 'end' }}>
                    <Button  sx={{ mr: alunniSearched ? 2 : 0 }} onClick={isRiepilogo ? (() => setIsRiepilogo(false)) : props.closeGestioneDialog} variant='contained' color={isRiepilogo ? 'primary' : 'error'}>
                        {isRiepilogo ? "Indietro" : "Annulla"}
                    </Button>
                    {alunniSearched && (
                        <Button  disabled={listMaterieSelezionate.length === 0 || alunniSelezionati.length === 0 || tipologiaSelected === -1 || corsoSelected === -1} onClick={!isRiepilogo ? (() => setIsRiepilogo(true)) : (() => setConfermaSalvataggioDialogOpen(true))} variant='contained' color='success'>
                            Procedi
                        </Button>
                    )}
                </Grid>
            </Grid>)}

            <DialogConferma
                isDialogOpen={confermaSalvataggioDialogOpen}
                title={'Conferma inserimento'}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={`Confermi di voler inserire la richiesta di accertamento prerequisiti?`}
                showAlunni={false}
                confermaButton={'Conferma'}
                severity={'success'}
                closeDialog={() => { setConfermaSalvataggioDialogOpen(false) }}
                conferma={saveAssegnazione}
                disabledButton={false}
            />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={alertIsOpen} autoHideDuration={3000} onClose={closeAlert}>
                <Alert onClose={closeAlert} severity={"error"} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </>
    )
}


