import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import { Paper, Button, TextField, Collapse, Container, Typography, Dialog, FormControl, InputLabel, Select, MenuItem, Box, Snackbar, Alert, Divider, Chip, CircularProgress, DialogTitle, IconButton, DialogContent, DialogActions } from '@mui/material';

import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import { AccessTime, Add, Archive, ArrowBack, Check, Clear, Close, Delete, Download, Factory, Home, KeyboardArrowDown, KeyboardArrowUp, LockClock, SupervisorAccount } from '@mui/icons-material';

import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';

import 'react-virtualized/styles.css';

import { RicercaAPI } from '../../../api/ricercaApi';
import Breadcrumb from '../../registri/shared/breadcrumb';

import { getAnnoFormativo } from '../../shared/utilityMethods';
import { TirociniAPI } from '../../../api/tirociniApi';
// import DettaglioAzienda from './dettaglioAzienda';
// import TableConvenzioni from './tableConvenzioni';
import DialogConferma from '../../shared/dialogConferma';
// import DettaglioConvenzione from './dettaglioConvenzione';

import { useLocation, useNavigate } from "react-router-dom";
import CustomDatePicker from '../../shared/customDatePicker';
import { convertDataGetProssimeLezione } from '../../shared/metodiDateUtility';
import TableProgettiFormativi from './tableProgettiFormativi';
import DettaglioProgettoFormativo from './dettaglioProgettoFormativo';
import LegendaTirocini from '../legendaTirocini';
import axios from 'axios';
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { TimeClock } from '@mui/x-date-pickers';
import VariazioneTutor from './dettaglio-progetto-formativo/variazioneTutor';
import DettaglioVariazione from './dettaglioVariazione';
import TableElencoVariazioni from './tableElencoVariazioni';

import ElencoDocumentiScaricabili from '../elencoDocumentiScaricabili';
export default function GestioneProgettiFormativi(props) {

    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [sediList, setSediList] = useState([]);

    const [hasLoaded, setHasLoaded] = useState(false);
    const [searchStarted, setSearchStarted] = useState(false);
    // const [searchLoaded, setSearchLoaded] = useState(false);


    //Selezione
    const [isInsideOtherComponent, setIsInsideOtherComponent] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [annoFormativo, setAnnoFormativo] = useState("");

    const [dialogAggiungiProgettoOpen, setDialogAggiungiProgettoOpen] = useState(false);
    const [dialogVariazioneSedeOrarioOpen, setDialogVariazioneSedeOrarioOpen] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [idConvenzione, setIdConvenzione] = useState(-1);
    const [idProgettoFormativo, setIdProgettoFormativo] = useState(-1);
    const [idStatoModifica, setIdStatoModifica] = useState(-1);

    const [ragioneSociale, setRagioneSociale] = useState('');
    const [ragioneSocialeValid, setRagioneSocialeValid] = useState(true);
    const [ragioneSocialeMessageError, setRagioneSocialeMessageError] = useState('');


    const [pIva, setPIva] = useState('');
    const [pIvaValid, setPIvaValid] = useState(true);
    const [pIvaMessageError, setPIvaMessageError] = useState('');

    const [codProgettoFormativo, setCodProgettoFormativo] = useState('');
    const [codProgettoFormativoValid, setCodProgettoFormativoValid] = useState(true);
    const [codProgettoFormativoMessageError, setCodProgettoFormativoMessageError] = useState('');

    const [protocolloConvenzione, setProtocolloConvenzione] = useState('');
    const [protocolloConvenzioneValid, setProtocolloConvenzioneValid] = useState(true);
    const [protocolloConvenzioneMessageError, setProtocolloConvenzioneMessageError] = useState('');

    const [sedeSelected, setSedeSelected] = useState(-1);

    const [statoProgetto, setStatoProgetto] = useState(-1);

    const [confirmAlertDeleteIsOpen, setConfirmAlertDeleteIsOpen] = useState(false);
    const [buttonConfermaDisabled, setButtonConfermaDisabled] = useState(false);
    const [filtriRicercaNotCollapsed, setFiltriRicercaNotCollapsed] = useState(true);

    const [pivaAziendaLocationState, setPivaAziendaLocationState] = useState(-1);

    const [dataDaInserimento, setDataDaInserimento] = useState(null);
    const [dataAInserimento, setDataAInserimento] = useState(null);
    const [listProgetti, setListProgetti] = useState([]);
    const [progettoStatiList, setProgettoStatiList] = useState([]);

    const [corsiList, setCorsiList] = useState([]);
    const [corsoSelected, setCorsoSelected] = useState(-1);

    const [nomeAlunno, setNomeAlunno] = useState('');
    const [nomeAlunnoValid, setNomeAlunnoValid] = useState(true);
    const [nomeAlunnoMessageError, setNomeAlunnoMessageError] = useState('');

    const [cognomeAlunno, setCognomeAlunno] = useState('');
    const [cognomeAlunnoValid, setCognomeAlunnoValid] = useState(true);
    const [cognomeAlunnoMessageError, setCognomeAlunnoMessageError] = useState('');

    const [CFAlunno, setCFAlunno] = useState('');
    const [CFAlunnoValid, setCFAlunnoValid] = useState(true);
    const [CFAlunnoMessageError, setCFAlunnoMessageError] = useState('');

    // const [canEdit, setCanEdit] = useState(true);

    const [protocolloConvenzioneLocation, setProtocolloConvenzioneLocation] = useState(undefined);

    const [dialogElencoVariazioniOpen, setDialogElencoVariazioniOpen] = useState(false);
    const [dialogLegendaOpen, setDialogLegendaOpen] = useState(false);
    const [dialogVariazioneOpen, setDialogVariazioneOpen] = useState(false);
    const [dialogVariazioneTutorOpen, setDialogVariazioneTutorOpen] = useState(false);

    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const [isVariazioneSede, setIsVariazioneSede] = useState(false);
    const [documentiDialogOpen, setDocumentiDialogOpen] = useState(false);
    const [listDocumenti, setListDocumenti] = useState([]);

    const [codiceProgetto, setCodiceProgetto] = useState('');

    const [debugMode, setDebugMode] = useState(false);

    useEffect(() => {
        if (process.env.REACT_APP_IS_DEBUG == "true") setDebugMode(true);
        else setDebugMode(false);
        getProgettoFormativoStatiList();
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
        getSedi(annoFormativoTmp);
        if (location.state !== null && location.state.pIva !== undefined) {
            setPIva(location.state.pIva);
            setPivaAziendaLocationState(location.state.pIva); //Se diverso da -1 abilito il pulsante torna indietro
            // ricercaConvenzione(annoFormativoTmp, location.state.pIva)

        }
        if (location.state !== null && location.state.protocolloConvenzione !== undefined) {
            setProtocolloConvenzione(location.state.protocolloConvenzione);
            if (location.state.aggiungiProgettoFormativo !== undefined && location.state.aggiungiProgettoFormativo === true) {
                setDialogAggiungiProgettoOpen(true);
                setProtocolloConvenzioneLocation(location.state.protocolloConvenzione);
            }
            else ricercaProgetto(annoFormativoTmp, location.state.protocolloConvenzione);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (props.isInsideOtherComponent !== undefined)
            setIsInsideOtherComponent(props.isInsideOtherComponent);
    }, [props.isInsideOtherComponent])



    function goBackToGestioneAziende() {
        navigate("/tirocini/gestione-aziende", { state: { pIva: pivaAziendaLocationState } })
    }

    function validate(e) {
        e.preventDefault()
        startSearch()
    }

    function changeCorso(e) {
        if (e.target.value !== '') {
            setCorsoSelected(e.target.value);
        }
        else {
            setCorsoSelected(-1);
        }
    };

    function changeStatoProgetto(e) {
        setStatoProgetto(e.target.value)
    }


    function startSearch() {
        setSearchStarted(true)
    }

    function removeFilters() {

        setFiltriRicercaNotCollapsed(true); //Se rimuovo i filtri apro il collapse

        setRagioneSociale('');
        setRagioneSocialeValid(true);
        setRagioneSocialeMessageError('');

        setCorsoSelected(-1);

        setNomeAlunno('');
        setNomeAlunnoValid(true);
        setNomeAlunnoMessageError('');

        setCognomeAlunno('');
        setCognomeAlunnoValid(true);
        setCognomeAlunnoMessageError('');

        setPIva('');
        setPIvaValid(true);
        setPIvaMessageError('');

        setCFAlunno('');
        setCFAlunnoValid(true);
        setCFAlunnoMessageError('')

        setCodProgettoFormativo('');
        setCodProgettoFormativoValid(true);
        setCodProgettoFormativoMessageError('');

        setProtocolloConvenzione('');
        setProtocolloConvenzioneValid(true);
        setProtocolloConvenzioneMessageError('');

        setDataAInserimento(null);
        setDataDaInserimento(null);
        setStatoProgetto(-1);

        if (sediList.length > 1) setSedeSelected(-1);
        setCorsoSelected(-1);

        setSearchStarted(false);
        // setSearchLoaded(false);

        setTimeout(() => {
            setListProgetti([]);
            setHasLoaded(false)
        }, 200)
    }

    function openAggiungiProgettoFormativo() {
        setDialogAggiungiProgettoOpen(prev => !prev);
        // setListProgetti([]);
        // removeFilters();
    }

    function openDialogVariazioneSedeOrarioOpen(isVariazioneSede) {
        setDialogVariazioneSedeOrarioOpen(true);
        setIsVariazioneSede(isVariazioneSede);
    }

    function closeAggiungiProgettoFormativo() {
        openAggiungiProgettoFormativo()
        closeVariazioneDialog()
        if (location.state != null)
            navigate('/tirocini/gestione-progetti-formativi', { replace: true, state: { protocolloConvenzione: undefined, aggiungiProgettoFormativo: undefined } });
        setProtocolloConvenzioneLocation(undefined);
    }

    function proceduraConclusa(idProgettoFormativo) {
        openAggiungiProgettoFormativo()
        ricercaProgetto(annoFormativo);
        openAlertMsg('Salvataggio avvenuto con successo', 'success')
        // downloadConvenzione(idProgettoFormativo)
    }

    function changeSede(e) {
        if (e.target.value !== '') {
            setSedeSelected(e.target.value);
            getClassiBySede(e.target.value, annoFormativo);
        }
        else {
            setSedeSelected(-1);
        }
    };

    async function getSedi(annoFormativo) {
        RicercaAPI.getSedi({ annoFormativo: annoFormativo })
            .then((result) => {
                let sediTmp = JSON.parse(JSON.stringify(result.sedi)); // This creates a deep copy of the array                
                let indexSedeLegale = sediTmp.findIndex(sede => sede?.id === 1);
                if (indexSedeLegale !== -1) {
                    sediTmp[indexSedeLegale].nomeSede = "Tutte le sedi"
                }
                setSediList([...result.sedi])

                if (result.sedi.length === 1) {
                    let idSedeTmp = result?.sedi[0]?.id
                    setSedeSelected(idSedeTmp ?? -1)
                    getClassiBySede(idSedeTmp, annoFormativo)
                }
            })
            .catch(function (error) {
            })
    }
    async function getClassiBySede(idSede, annoFormativo) {
        let dataObj = {
            idSede: idSede,
            annoFormativo: annoFormativo
        }

        RicercaAPI.getClassiBySede(dataObj)
            .then((result) => {
                setCorsiList(result.sedi ?? []);
            })
            .catch(function (error) {
            })
    }

    function changeRagioneSociale(e) {
        let ragioneSocialeTmp = e.target.value;
        setRagioneSociale(ragioneSocialeTmp);
        if (ragioneSocialeTmp.length > 2 || ragioneSocialeTmp === "") {
            setRagioneSocialeMessageError('');
            setRagioneSocialeValid(true);
        }
        else {
            setRagioneSocialeValid(false);
            setRagioneSocialeMessageError('Min. 3 caratteri.');
        }
    }

    function changeNomeAlunno(e) {
        let nomeAlunnoTmp = e.target.value;
        setNomeAlunno(nomeAlunnoTmp)
        if (nomeAlunnoTmp.length > 2 || nomeAlunnoTmp === "") {
            setNomeAlunnoMessageError('');
            setNomeAlunnoValid(true);
        }
        else {
            setNomeAlunnoValid(false);
            setNomeAlunnoMessageError('Min. 3 caratteri.');
        }
    }
    function changeCognomeAlunno(e) {
        let cognomeAlunnoTmp = e.target.value;
        setCognomeAlunno(cognomeAlunnoTmp)
        if (cognomeAlunnoTmp.length > 2 || cognomeAlunnoTmp === "") {
            setCognomeAlunnoMessageError('');
            setCognomeAlunnoValid(true);
        }
        else {
            setCognomeAlunnoValid(false);
            setCognomeAlunnoMessageError('Min. 3 caratteri.');
        }
    }

    function changecodProgettoFormativo(e) {
        let codProgettoFormativoTmp = e.target.value;
        setCodProgettoFormativo(codProgettoFormativoTmp)
        if (codProgettoFormativoTmp.length > 2 || codProgettoFormativoTmp === "") {
            setCodProgettoFormativoMessageError('');
            setCodProgettoFormativoValid(true);
        }
        else {
            setCodProgettoFormativoValid(false);
            setCodProgettoFormativoMessageError('Min. 3 caratteri.');
        }
    }

    function changeProtocolloConvenzione(e) {
        let protocolloConvenzioneTmp = e.target.value;
        setProtocolloConvenzione(protocolloConvenzioneTmp)
        if (protocolloConvenzioneTmp.length === 20 || protocolloConvenzioneTmp === "") {
            setProtocolloConvenzioneMessageError('');
            setProtocolloConvenzioneValid(true);
        }
        else {
            setProtocolloConvenzioneValid(false);
            setProtocolloConvenzioneMessageError('Il numero protocollo si compone di 20 caratteri.');
        }
    }

    function changeCFAlunno(e) {
        let valueTmp = e.target.value

        let valueNoSpaceTmp = valueTmp.replace(/\s/g, '');
        let valueNoExtraCharactersTmp;

        valueNoExtraCharactersTmp = valueNoSpaceTmp.replace(/[^a-zA-Z0-9]/g, '');

        setCFAlunno(valueNoExtraCharactersTmp);

        if (valueNoExtraCharactersTmp === '') {
            setCFAlunnoValid(true);
            setCFAlunnoMessageError('')
        } else {
            if (valueNoExtraCharactersTmp.length === 11 || valueNoExtraCharactersTmp.length === 16) {
                setCFAlunnoValid(true);
                setCFAlunnoMessageError('')
            } else {
                // setPIvaValid(false);
                setCFAlunnoValid(false);
                setCFAlunnoMessageError('Il C.F si compone di 11 o 16 caratteri.')
            }
        }
    }

    function changePIva(e) {
        let valueTmp = e.target.value

        let valueNoSpaceTmp = valueTmp.replace(/\s/g, '');
        let valueNoExtraCharactersTmp;

        valueNoExtraCharactersTmp = valueNoSpaceTmp.replace(/[^0-9]/g, '');

        setPIva(valueNoExtraCharactersTmp);

        if (valueNoExtraCharactersTmp === '') {
            setPIvaValid(true);
            setPIvaMessageError('')
        } else {
            if (valueNoExtraCharactersTmp.length === 11) {
                setPIvaValid(true);
                setPIvaMessageError('')
            } else {
                // setPIvaValid(false);
                setPIvaValid(false);
                setPIvaMessageError('La P.Iva si compone di 11 caratteri.')
            }
        }

    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg)
        setAlertSeverity(severity)
        setAlertOpen(true)
    }

    function openVisualizzaDettaglioProgetto(idProgettoFormativo) {
        setIdProgettoFormativo(idProgettoFormativo);
        setTimeout(openAggiungiProgettoFormativo(), 300);
        removeFilters();
    }

    function validateFormRicerca() {
        if (ragioneSocialeValid &&
            nomeAlunnoValid &&
            cognomeAlunnoValid &&
            pIvaValid &&
            CFAlunnoValid &&
            codProgettoFormativoValid
        ) return false
        else return true
    }

    function changeIdConvenzione(idConvenzione) {
        setIdConvenzione(idConvenzione)
    }
    function changeIdProgettoFormativo(idProgettoFormativo) {
        setIdProgettoFormativo(idProgettoFormativo)
    }

    function closeAlertConfirmDelete() {
        setConfirmAlertDeleteIsOpen(false);
        setIdConvenzione(-1);
    }

    function deleteConvenzione(idConvenzione) {
        setConfirmAlertDeleteIsOpen(true);
        setIdConvenzione(idConvenzione);
    }


    function downloadProgetto(idProgetto) {

    }

    function onChangeValueDataDaInserimento(e) {
        let formattedDataTmp = convertDataGetProssimeLezione(e, true);
        setDataDaInserimento(formattedDataTmp)
    }

    function resetValueDataDaInserimento() {
        setDataDaInserimento(null)
    }

    function onChangeValueDataAInserimento(e) {
        let formattedDataTmp = convertDataGetProssimeLezione(e, true);
        setDataAInserimento(formattedDataTmp)
    }

    function resetValueDataAInserimento() {
        setDataAInserimento(null)
    }

    function openLegendaDialog() {
        setDialogLegendaOpen(prev => !prev);
    }

    function openElencoVariazioniDialog(idProgetto, codiceProgetto) {
        setIdProgettoFormativo(idProgetto);
        setCodiceProgetto(codiceProgetto);
        setTimeout(() => {
            setDialogElencoVariazioniOpen(true);
        }, 200)
    }

    function closeElencoVariazioniDialog() {
        setDialogElencoVariazioniOpen(false);
        setIdProgettoFormativo(-1);
        setCodiceProgetto('')
        ricercaProgetto(annoFormativo);
    }

    function openVariazioneDialog(idProgettoFormativo) {
        setIdProgettoFormativo(idProgettoFormativo ?? -1);
        setDialogVariazioneOpen(true);
    }

    function openVariazioneTutorDialog() {
        setDialogVariazioneTutorOpen(true);
    }

    function closeVariazioneDialog() {
        setDialogVariazioneOpen(false);
        setIdProgettoFormativo(-1);
    }

    function closeVariazioneTutorDialog() {
        setDialogVariazioneTutorOpen(false);
        closeVariazioneDialog()
        setIdProgettoFormativo(-1);
        ricercaProgetto(annoFormativo);
    }


    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
        setIdProgettoFormativo(-1);
        setIdStatoModifica(-1);
    }

    function openDialogCambioStato(idProgetto, idStato) {
        setIdProgettoFormativo(idProgetto);
        setIdStatoModifica(idStato);
        setConfirmAlertIsOpen(true);
    }

    function openVariazioneOrario() {
        openVisualizzaDettaglioProgetto(idProgettoFormativo,);
    }

    function closeVariazione() {
        setDialogVariazioneSedeOrarioOpen(false);
        closeVariazioneDialog();
        setIsVariazioneSede(false);
        ricercaProgetto(annoFormativo);
    }
    function openDialogDownloadDocumenti(idProgetto) {
        setDocumentiDialogOpen(true);
        downloadConvenzione(idProgetto);
    }

    function gestioneFiltroRicerca() {
        if (process.env.REACT_APP_IS_DEBUG == "true") {
            if (ragioneSocialeValid &&
                nomeAlunnoValid &&
                cognomeAlunnoValid &&
                pIvaValid &&
                CFAlunnoValid &&
                codProgettoFormativoValid
            ) return false
            else return true
        }
        else {
            if (ragioneSocialeValid &&
                nomeAlunnoValid &&
                cognomeAlunnoValid &&
                pIvaValid &&
                CFAlunnoValid &&
                codProgettoFormativoValid &&
                (ragioneSociale !== '' ||
                    nomeAlunno !== '' ||
                    cognomeAlunno !== '' ||
                    pIva !== '' ||
                    CFAlunno !== '' ||
                    codProgettoFormativo !== '' ||
                    (dataAInserimento !== null && dataDaInserimento !== null) ||
                    protocolloConvenzione !== '' ||
                    corsoSelected !== -1
                )
            ) return false
            else return true
        }
    }

    function returnMsgProssimoStato(idStato) {
        if (idStato === 6) {
            return "DOCUMENTAZIONE GENERATA";
        }
        else if (idStato === 3) {
            return "ATTUAZIONE";
        } else return "ARCHIVIATO"
    }

    async function ricercaProgetto(annoFormativoTmp, locationProtocolloConvenzione) {
        setFiltriRicercaNotCollapsed(false); //Se avvio la ricerca  chiudo il collapse dei filtri
        setButtonConfermaDisabled(true);
        let obj = {
            annoFormativo: annoFormativoTmp === undefined ? annoFormativo : annoFormativoTmp,
            idCorso: corsoSelected !== -1 ? corsoSelected : null,
            ragioneSocialeAzienda: ragioneSociale !== '' ? ragioneSociale : null,
            pIvaAzienda: pIva !== '' ? pIva : null,
            CFAlunno: CFAlunno !== '' ? CFAlunno : null,
            nomeAlunno: nomeAlunno !== '' ? nomeAlunno : null,
            cognomeAlunno: cognomeAlunno !== '' ? cognomeAlunno : null,
            codiceProgetto: codProgettoFormativo !== '' ? codProgettoFormativo : null,
            protocolloConvenzione: locationProtocolloConvenzione === undefined ? (protocolloConvenzione !== '' ? protocolloConvenzione : null) : locationProtocolloConvenzione,
            // protocolloConvenzione: protocolloConvenzione !== '' ? protocolloConvenzione : null,
            dataA: dataAInserimento ?? null,
            dataDa: dataDaInserimento ?? null,
            stato: statoProgetto !== -1 ? statoProgetto : null,
        }

        if (dataDaInserimento !== null && dataAInserimento === null)
            setDataDaInserimento(null);

        TirociniAPI.ricercaProgetto(obj)
            .then((result) => {
                setListProgetti([...result]);
                setSearchStarted(true);

                setTimeout(() => {
                    setHasLoaded(true);
                }, 200)
                setButtonConfermaDisabled(false);
            })
            .catch(function (error) {
                setTimeout(() => {
                    setHasLoaded(true);
                }, 200)
                setListProgetti([]);
                setButtonConfermaDisabled(false);
            })

    }

    async function getProgettoFormativoStatiList() {
        TirociniAPI.getProgettoFormativoStatiList()
            .then((result) => {
                setProgettoStatiList(result);
            })
            .catch(function (error) {
            })
    }

    async function modificaStatoProgetto() {
        setButtonConfermaDisabled(true);
        let obj = {
            idStato: idStatoModifica,
            idProgetto: idProgettoFormativo
        }
        TirociniAPI.changeStatoProgetto(obj)
            .then((result) => {
                setIdProgettoFormativo(-1);
                let msgTmp = result.msg ?? "Modifica dello stato del progetto avvenuta con successo..";
                openAlertMsg(msgTmp, 'success');
                closeAlertConfirmInserimento();
                ricercaProgetto(annoFormativo);
                setButtonConfermaDisabled(false);
            })
            .catch(function (error) {
                if (error?.response !== undefined) {
                    let errorMsg = "Errore durante la modifica di stato. Riprovare tra qualche istante.";
                    if (error.response.data && Array.isArray(error.response.data.data) && error.response.data.data.length > 0) {
                        errorMsg = error.response.data.data[0]?.msg ?? errorMsg;
                    }
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante la modifica di stato. Riprovare tra qualche istante.", "error");
                }
                setButtonConfermaDisabled(false);
            })
    }

    async function downloadDocumento() {
        setIsDownloading(true);
        let encryptedToken = localStorage.getItem('tkn');
        let decryptedToken = CryptoJS.AES.decrypt(encryptedToken, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
        axios.get(process.env.REACT_APP_FETCH_URL + "/gestioneProgettiFormativi/downloadElencoCorso/" + corsoSelected, {
            responseType: 'arraybuffer',
            headers: { Authorization: `Bearer ${decryptedToken}` }
        })
            .then((response) => {
                let contentDisposition = response.headers['content-disposition'];
                let filename = "";
                if (contentDisposition) {
                    setAlertMsg("Download in corso");
                    setAlertSeverity("info");
                    setAlertOpen(true);
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }
                setIsDownloading(false)
                exportFile(response.data, filename, 'application/pdf');//;charset=utf-8;
            })
            .catch((error) => {
                setAlertMsg("Errore durante il download del documento");
                setAlertSeverity("error");
                setAlertOpen(true);
                setIsDownloading(false);


            });
    }

    function downloadConvenzione(idConvenzione) {
        setDocumentiDialogOpen(true);
        TirociniAPI.getElencoDocumentiProgetto(idConvenzione)
            .then((result) => {
                setListDocumenti([...result]);
            })
            .catch(function (error) {
                setListDocumenti([])
            })
    }


    const exportFile = (data, fileName, type) => {

        const blob = new Blob([data], { type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        setIsDownloading(false);
    };
    return (
        <Fragment>

            {!isInsideOtherComponent ? (
                <Breadcrumb sections={["Gestione progetti formativi"]} isTirocinio={true} />
            ) : null}


            <Container disableGutters={isInsideOtherComponent} maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>

                <Paper sx={{ width: '100%', mt: isInsideOtherComponent ? 0 : 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", p: 2 }}>
                        <Typography variant="h6">Ricerca progetti formativi</Typography>
                        <Chip icon={filtriRicercaNotCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />} label={filtriRicercaNotCollapsed ? "Riduci filtri" : "Espandi filtri"} variant="outlined" onClick={() => setFiltriRicercaNotCollapsed(prev => !prev)} />
                    </Box>
                    <Divider />
                    <Collapse in={filtriRicercaNotCollapsed} sx={{ p: filtriRicercaNotCollapsed ? 2 : 0 }}>
                        <form onSubmit={validate} style={{ width: '100%' }}>
                            <Grid container spacing={2} sx={{ width: '100%', pt: 2 }} alignItems={"start"}>
                                <Grid xs={12} md={4}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="sede-label">
                                            Seleziona sede Ciofs
                                        </InputLabel>
                                        <Select
                                            data-testid='sedeRicercaSelect'
                                            label="Seleziona sede Ciofs"
                                            labelId="sede-label"
                                            value={sedeSelected}
                                            onChange={changeSede}
                                            disabled={sediList.length <= 1}                                        >
                                            <MenuItem
                                                key={-1}
                                                value={-1}
                                            >
                                                ...
                                            </MenuItem>
                                            {sediList.map((sede) => (
                                                <MenuItem
                                                    key={sede.id}
                                                    value={sede.id}
                                                >
                                                    {sede.nomeSede}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={8}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="corso-label">
                                            Seleziona corso
                                        </InputLabel>
                                        <Select
                                            label="Seleziona corso"
                                            labelId="corso-label"
                                            value={corsoSelected}
                                            onChange={changeCorso}
                                            disabled={!corsiList.length > 0 || sedeSelected === -1}
                                        >
                                            <MenuItem
                                                key={-1}
                                                value={-1}
                                            >
                                                ...
                                            </MenuItem>
                                            {corsiList.map((corso) => (
                                                <MenuItem
                                                    key={corso.id}
                                                    value={corso.id}
                                                >
                                                    {corso.nomeCorso}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={4}>
                                    <TextField
                                        defaultValue={nomeAlunno}
                                        value={nomeAlunno}
                                        label={'Nome alunno'}
                                        onChange={changeNomeAlunno}
                                        sx={{ width: '100%' }}
                                        error={!nomeAlunnoValid}
                                        helperText={nomeAlunnoMessageError}
                                    />
                                </Grid>
                                <Grid xs={12} md={4}>
                                    <TextField
                                        defaultValue={cognomeAlunno}
                                        value={cognomeAlunno}
                                        label={'Cognome alunno'}
                                        onChange={changeCognomeAlunno}
                                        sx={{ width: '100%' }}
                                        error={!cognomeAlunnoValid}
                                        helperText={cognomeAlunnoMessageError}
                                    />
                                </Grid>
                                <Grid xs={12} md={4}>
                                    <TextField
                                        error={!CFAlunnoValid ? true : false}
                                        defaultValue={CFAlunno ?? ''}
                                        value={CFAlunno ?? ''}
                                        label={"CF Alunno"}
                                        onChange={changeCFAlunno}
                                        sx={{ width: '100%' }}
                                        helperText={CFAlunnoMessageError}
                                        inputProps={{ maxLength: 16 }}
                                    />
                                </Grid>
                                <Grid xs={12} md={4}>
                                    <TextField
                                        defaultValue={ragioneSociale}
                                        value={ragioneSociale}
                                        label={'Ragione sociale azienda'}
                                        onChange={changeRagioneSociale}
                                        sx={{ width: '100%' }}
                                        error={!ragioneSocialeValid}
                                        helperText={ragioneSocialeMessageError}
                                    />
                                </Grid>
                                <Grid xs={12} md={4}>
                                    <TextField
                                        error={!pIvaValid ? true : false}
                                        defaultValue={pIva ?? ''}
                                        value={pIva ?? ''}
                                        label={"P.Iva azienda"}
                                        onChange={changePIva}
                                        sx={{ width: '100%' }}
                                        helperText={pIvaMessageError}
                                        inputProps={{ maxLength: 11 }}
                                    />
                                </Grid>
                                <Grid xs={12} md={4}>
                                    <TextField
                                        defaultValue={protocolloConvenzione ?? ''}
                                        value={protocolloConvenzione ?? ''}
                                        label={"Protocollo convenzione"}
                                        onChange={changeProtocolloConvenzione}
                                        sx={{ width: '100%' }}
                                        error={!protocolloConvenzioneValid}
                                        helperText={protocolloConvenzioneMessageError}
                                        inputProps={{ maxLength: 20 }}
                                    />
                                </Grid>
                                <Grid xs={12} md={3}>
                                    <TextField
                                        defaultValue={codProgettoFormativo ?? ''}
                                        value={codProgettoFormativo ?? ''}
                                        label={"Codice progetto formativo"}
                                        onChange={changecodProgettoFormativo}
                                        sx={{ width: '100%' }}
                                        error={!codProgettoFormativoValid}
                                        helperText={codProgettoFormativoMessageError}
                                    />
                                </Grid>
                                <Grid xs={12} md={3}>
                                    <CustomDatePicker
                                        showResetButton={true}
                                        resetValue={resetValueDataDaInserimento}
                                        // maxDate={dataAInserimento}
                                        label={"Data inserimento progetto da ..."}
                                        selectedValue={dataDaInserimento ?? null}
                                        onChangeValue={onChangeValueDataDaInserimento}
                                        year={true}
                                    />
                                </Grid>
                                <Grid xs={12} md={3}>
                                    {dataDaInserimento === null ? (
                                        <LocalizationProvider
                                            localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                            LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'
                                        >
                                            <MobileDatePicker sx={{ width: '100%' }}
                                                disabled={true}
                                                format='dd/MM/yyyy'
                                            />
                                        </LocalizationProvider>
                                    ) : (
                                        <CustomDatePicker
                                            showResetButton={true}
                                            minDate={dataDaInserimento}
                                            resetValue={resetValueDataAInserimento}
                                            label={"Data inserimento progetto a ..."}
                                            selectedValue={dataAInserimento ?? null}
                                            onChangeValue={onChangeValueDataAInserimento}
                                            year={true}
                                        />)}
                                </Grid>
                                <Grid xs={12} md={3}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel>
                                            Stato progetto  </InputLabel>
                                        <Select
                                            label='Stato progetto'
                                            value={statoProgetto}
                                            onChange={changeStatoProgetto}
                                        // disabled={!modifica}
                                        >
                                            <MenuItem
                                                key={-1}
                                                value={-1}
                                            >
                                                ...
                                            </MenuItem>
                                            {progettoStatiList.map((stato) => (
                                                <MenuItem
                                                    key={stato.id}
                                                    value={stato.id}
                                                >
                                                    {stato.descrizione}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </form>
                    </Collapse>
                    {(!debugMode) && (<Box p={2}>
                        <Alert severity="info">Per avviare la ricerca almeno uno dei campi del form deve essere valorizzato (escluso il campo "Stato progetto").</Alert>
                    </Box>)}
                    {filtriRicercaNotCollapsed && (<Divider />)}
                    <Box sx={{ p: 2, display: "flex", alignItems: "start", flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'end', lg: "space-between" } }}>
                        <Box sx={{ width: { xs: '100%', lg: 'fit-content' } }}>
                            {pivaAziendaLocationState !== -1 && (
                                <Button startIcon={<ArrowBack />} sx={{ height: 'fit-content', mr: { xs: 0, lg: 1 }, mt: { xs: 1, lg: 0 }, px: { xs: 0, lg: 5 }, width: { xs: '100%', lg: 'fit-content' } }} variant='outlined' onClick={goBackToGestioneAziende}>Torna alla gestione aziende</Button>
                            )}
                            {searchStarted ? (
                                <Button sx={{ height: 'fit-content', mt: { xs: 1, lg: 0 }, px: { xs: 0, lg: 5 }, width: { xs: '100%', lg: 'fit-content' } }} onClick={removeFilters} variant="outlined" color="error" endIcon={<Clear />}>
                                    Rimuovi filtri
                                </Button>
                            ) : (
                                <span></span>
                            )}
                        </Box>
                        <Box sx={{ width: { xs: '0px', md: '15px' } }}></Box>
                        <Box sx={{ width: { xs: '100%', lg: 'fit-content' } }}>
                            {(corsoSelected !== -1 && sedeSelected !== -1 && searchStarted && listProgetti.length > 0) && (
                                <Button disabled={isDownloading} sx={{ height: 'fit-content', ml: { xs: 0, lg: 1 }, px: { xs: 0, lg: 5 }, mt: { xs: 1, lg: 0 }, width: { xs: '100%', lg: 'fit-content' } }} onClick={() => downloadDocumento()} endIcon={<Download />} color='secondary' variant="contained">
                                    Download elenco aziende tirocinio formativo
                                </Button>
                            )}
                            <Button sx={{ height: 'fit-content', ml: { xs: 0, lg: 1 }, px: { xs: 0, lg: 5 }, mt: { xs: 1, lg: 0 }, width: { xs: '100%', lg: 'fit-content' } }} onClick={() => ricercaProgetto(annoFormativo)} endIcon={<SearchIcon />} disabled={gestioneFiltroRicerca() || buttonConfermaDisabled} variant="contained">
                                Avvia ricerca
                            </Button>
                            <Button
                                sx={{ height: 'fit-content', ml: { xs: 0, lg: 1 }, px: { xs: 0, lg: 5 }, mt: { xs: 1, lg: 0 }, width: { xs: '100%', lg: 'fit-content' } }}
                                onClick={() => setDialogAggiungiProgettoOpen(true)}
                                endIcon={<Add />}
                                color="success"
                                variant="contained">
                                Aggiungi progetto formativo
                            </Button>
                        </Box>

                    </Box>

                    {dataDaInserimento != null && dataAInserimento === null && (
                        <Alert sx={{ m: 2 }} severity='error'>Se "Data inserimento DA" è popolata e la <b>"Data inserimento A" non è popolata</b> i campi verranno ignorati.</Alert>
                    )}
                </Paper>
                <Collapse in={searchStarted} sx={{ my: 2, width: '100%' }}>
                    <Paper sx={{ overflowY: 'auto', maxHeight: '60vh' }}>
                        {hasLoaded ? (
                            listProgetti.length > 0 ?
                                (
                                    <Fragment>
                                        <TableProgettiFormativi
                                            list={listProgetti}
                                            deleteItem={deleteConvenzione}
                                            visualizzaItem={openVisualizzaDettaglioProgetto}
                                            openLegendaDialog={openLegendaDialog}
                                            openDialogCambioStato={openDialogCambioStato}
                                            openVariazioneDialog={openVariazioneDialog}
                                            openElencoVariazioniDialog={openElencoVariazioniDialog}
                                            openDialogDownloadDocumenti={openDialogDownloadDocumenti}
                                        // addProgettoItem={addProgettoItem}
                                        />
                                    </Fragment>
                                )
                                :
                                (
                                    <Box sx={{ height: '60vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Alert sx={{ mb: 2 }} severity="info">La ricerca non ha prodotto alcun risultato con i filtri selezionati.</Alert>
                                    </Box>
                                )

                        ) : (
                            <Box sx={{ height: '65vh' }} flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                <CircularProgress sx={{ mb: 4 }} />
                                <Typography >Caricamento in corso...</Typography>
                            </Box>
                        )}
                        {/* {risultatiRicerca.length > 0 ?
                            (<TreeViewRicerca openDettaglioAlunno={openDettaglioAlunno} risultatiRicerca={risultatiRicerca} showCheckbox={showCheckbox} checkedRow={checkedRow} />)
                            : (<Typography align='center'> La ricerca non ha prodotto alcun risultato con i filtri selezionati.</Typography>)} */}
                    </Paper>
                </Collapse>
            </Container>

            <Dialog fullWidth fullScreen maxWidth={'xs'} open={dialogAggiungiProgettoOpen} sx={{ mt: '8vh' }}>
                <DettaglioProgettoFormativo
                    closeDialog={closeAggiungiProgettoFormativo}
                    idConvenzione={idConvenzione}
                    idProgettoFormativo={idProgettoFormativo}
                    protocolloConvenzione={protocolloConvenzioneLocation}
                    changeIdConvenzione={changeIdConvenzione}
                    changeIdProgettoFormativo={changeIdProgettoFormativo}
                    proceduraConclusa={proceduraConclusa}
                // isVisualizza={isVisualizza}
                />
            </Dialog>

            <Dialog fullWidth fullScreen maxWidth={'xs'} open={dialogVariazioneSedeOrarioOpen} sx={{ mt: '8vh' }}>
                <DettaglioVariazione
                    closeDialog={closeAggiungiProgettoFormativo}
                    idConvenzione={idConvenzione}
                    idProgettoFormativo={idProgettoFormativo}
                    protocolloConvenzione={protocolloConvenzioneLocation}
                    changeIdConvenzione={changeIdConvenzione}
                    changeIdProgettoFormativo={changeIdProgettoFormativo}
                    proceduraConclusa={proceduraConclusa}
                    isVariazioneSede={isVariazioneSede}
                    closeVariazione={closeVariazione}
                // isVisualizza={isVisualizza}
                />
            </Dialog>
            <Dialog open={documentiDialogOpen} fullWidth maxWidth="md">
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Documenti convenzione</Typography>
                    <IconButton onClick={() => { setDocumentiDialogOpen(false) }}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <ElencoDocumentiScaricabili closeDialog={() => { setDocumentiDialogOpen(false) }} isProgettiFormativi={true} listDocumenti={listDocumenti} />
                </DialogContent>
            </Dialog>

            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={'Conferma cambio di stato'}
                icon={(idStatoModifica === 3 || idStatoModifica === 6) ? <Check sx={{ fontSize: '5rem', my: 2 }} color="success" /> : <Archive sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                text={`Confermi di voler modificare lo stato del progetto in : ${returnMsgProssimoStato(idStatoModifica)}  ? `}
                showAlunni={false}
                confermaButton={(idStatoModifica === 3 || idStatoModifica === 6) ? 'Conferma' : 'Archivia'}
                severity={(idStatoModifica === 3 || idStatoModifica === 6) ? 'success' : 'error'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={modificaStatoProgetto}
                disabledButton={buttonConfermaDisabled}
            />
            <Dialog fullWidth maxWidth={'lg'} open={dialogElencoVariazioniOpen} sx={{ mt: '8vh' }} onClose={closeElencoVariazioniDialog}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Lista Variazioni</Typography>
                    <IconButton onClick={closeElencoVariazioniDialog}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <TableElencoVariazioni codiceProgetto={codiceProgetto} idProgetto={idProgettoFormativo} closeDialog={closeElencoVariazioniDialog} openAlertMsg={openAlertMsg} />
                </DialogContent>
            </Dialog>
            <Dialog fullWidth maxWidth={'lg'} open={dialogLegendaOpen} sx={{ mt: '8vh' }} onClose={openLegendaDialog}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Legenda</Typography>
                    <IconButton onClick={openLegendaDialog}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <LegendaTirocini data={'progetti'} />
                </DialogContent>
            </Dialog>
            <Dialog fullWidth maxWidth={'md'} open={dialogVariazioneOpen} sx={{ mt: '8vh' }} onClose={closeVariazioneDialog}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Seleziona tipo di variazione</Typography>
                    <IconButton onClick={closeVariazioneDialog}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Typography textAlign={'center'}>Selezionare il tipo di variazione che si intende eseguire:</Typography>
                    <Box sx={{ px: { xs: 0, md: 2 }, py: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'center' }}>
                        <Button endIcon={<Factory />}
                            sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mr: { xs: 0, md: 1 } }}
                            onClick={() => openDialogVariazioneSedeOrarioOpen(true)}
                            variant="contained" fullWidth color={'secondary'} >Variazione sede operativa</Button>
                        <Button endIcon={<AccessTime />}
                            sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mr: { xs: 0, md: 1 } }}
                            onClick={() => openDialogVariazioneSedeOrarioOpen(false)}
                            variant="contained" fullWidth color={'success'} >Variazione Orario</Button>
                        <Button endIcon={<SupervisorAccount />}
                            sx={{ width: { xs: 'unset', md: 'fit-content' } }}
                            onClick={openVariazioneTutorDialog}
                            variant="contained" fullWidth color={'primary'} >Variazione tutor</Button>
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog fullScreen maxWidth={'md'} open={dialogVariazioneTutorOpen} sx={{ mt: '8vh' }} onClose={closeVariazioneTutorDialog}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Progetto formativo: variazione Tutor - Variazione</Typography>
                    <IconButton onClick={closeVariazioneTutorDialog}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ p: 0 }}>
                    <VariazioneTutor
                        idProgetto={idProgettoFormativo}
                        closeVariazioneTutorDialog={closeVariazioneTutorDialog}
                        openAlertMsg={openAlertMsg}
                    />
                </DialogContent>
            </Dialog>
            {
                !isInsideOtherComponent ? (
                    <Helmet>
                        <title>{t("name")} - Gestione progetti formativi</title>
                    </Helmet>
                ) : null
            }

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>

        </Fragment >
    );
}
