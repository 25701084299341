import { useState, useEffect, Fragment } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { MenuItem, Box, Button, CircularProgress, FormControl, InputLabel, Select, Typography, Divider, Alert } from '@mui/material';
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import { RicercaAPI } from "../../api/ricercaApi";
import { getAnnoFormativo, getDataInizioFinePeriodo } from "../shared/utilityMethods";
import CustomDatePicker from "../shared/customDatePicker";

export default function SelezioneClasseOperatore(props) {

    const navigate = useNavigate();

    const [idClasseSelezionata, setIdClasseSelezionata] = useState(-1);
    const [sediList, setSediList] = useState([]);
    const [sedeSelezionata, setSedeSelezionata] = useState(-1);
    const [sedeSelezionataNome, setSedeSelezionataNome] = useState('');
    const [dontShowDate, setDontShowDate] = useState(false);

    const [tipoRegistro, setTipoRegistro] = useState("classe")
    const [tabIndex, setTabIndex] = useState(0) //0 Seleziona classe,  1 Selezione materia

    const [dataDiInteresse, setDataDiInteresse] = useState(null);
    const [classiInGiornata, setClassiInGiornata] = useState([])
    const [materieClasseSelezionata, setMaterieClasseSelezionata] = useState([])
    const [idMateriaSelezionata, setIdMateriaSelezionata] = useState(-1);
    const [nomeMateriaSelezionata, setNomeMateriaSelezionata] = useState('');

    const [nomeClasseSelezionata, setNomeClasseSelezionata] = useState("");
    const [hasLoaded, setHasLoaded] = useState(false)

    const [annoFormativo, setAnnoFormativo] = useState('');

    const [codiceCorso, setCodiceCorso] = useState('');
    const [codiceSimon, setCodiceSimon] = useState('');

    useEffect(() => {
        if (props.tipoRegistro !== undefined)
            setTipoRegistro(props.tipoRegistro)
    }, [props.tipoRegistro])
    useEffect(() => {
        if (props.dontShowDate !== undefined)
            setDontShowDate(props.dontShowDate);
    }, [props.dontShowDate])


    useEffect(() => {
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
        if (localStorage.getItem("sede")) {
            let objDateInteresse = getDataInizioFinePeriodo();
            setDataDiInteresse(objDateInteresse?.dataDiInteresse ?? null)
            getSedi(annoFormativoTmp);
        }
        if (props.changeTipo)
            props.changeTipo()
    }, [])

    function changeIdClasseSelezionata(idClasse, nomeClasse, codiceCorso, codiceSimon) {
        setIdClasseSelezionata(idClasse);
        setNomeClasseSelezionata(nomeClasse);
        setCodiceCorso(codiceCorso)
        setCodiceSimon(codiceSimon)

    }

    function changeIdMateriaSelezionata(idMateria, nomeMateria) {
        setIdMateriaSelezionata(idMateria)
        setNomeMateriaSelezionata(nomeMateria)
    }

    function changeDataDiInteresseSelezioneClasse(value) {
        setDataDiInteresse(value);
    }

    function confirmClasse() {
        if (tipoRegistro === "classe" || (tipoRegistro === "docente" && idMateriaSelezionata !== -1)) {

            let dataInizio = dataDiInteresse.startOf('week') //dataDiInteresse.toISO();
            let dataFine = dataDiInteresse.endOf('week') //dataDiInteresse.plus({ days: 7 });

            // let dataInizio = dataDiInteresse.toISO();
            //   let dataFine = dataDiInteresse.plus({ days: 7 });
            let idMateria = idMateriaSelezionata !== -1 ? idMateriaSelezionata : null
            dataFine = dataFine.toISO();
            dataInizio = dataInizio.toISO();

            let oreClasseSelezionata = generateArrayMaterieInClasseSelezionata();

            props.changeIdClasseSelezionata(idClasseSelezionata, dataInizio, dataFine, idMateria, nomeClasseSelezionata, oreClasseSelezionata, null, false, nomeMateriaSelezionata, [], codiceCorso, codiceSimon);
        } else {
            //Schermata selezione materia
            setHasLoaded(false);
            getMaterieByClasse(idClasseSelezionata);
            setTabIndex(1);
        }
    }

    function generateArrayMaterieInClasseSelezionata() {
        let arrayOreTmp = [];
        for (let lezione of classiInGiornata) {
            if (lezione != null) {
                if (lezione?.idCorso === idClasseSelezionata) {
                    arrayOreTmp.push(lezione)
                }
            }
        }
        return arrayOreTmp;
    }

    function changeSede(e) {
        let index = sediList.findIndex(s => s.id === e.target.value);
        setSedeSelezionata(e.target.value);
        setSedeSelezionataNome(sediList[index]?.nomeSede)
        props.changeSede(sediList[index]?.nomeSede, e.target.value)
        getClassiBySede(e.target.value, annoFormativo)
    }

    function getSedi(annoFormativo) {
        if (localStorage.getItem("sede")) {
            let idSede = localStorage.getItem("sede");
            let decryptedIdSede = CryptoJS.AES.decrypt(idSede, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
            let parsedSede = JSON.parse(decryptedIdSede);
            decryptedIdSede = parseInt(parsedSede.id);
            setSedeSelezionata(decryptedIdSede);
            if (decryptedIdSede === 1) { //Sede legale
                RicercaAPI.getSedi({ annoFormativo: annoFormativo })
                    .then((result) => {
                        if (result.sedi.length === 1) {
                            setSedeSelezionata(result?.sedi[0]?.id);
                            setSedeSelezionataNome(result?.sedi[0]?.nomeSede)
                            props.changeSede(result?.sedi[0]?.nomeSede)
                            getClassiBySede(result?.sedi[0]?.id, annoFormativo)
                            // getClassiBySede(result?.sedi[0]?.id);
                        } else {
                            getClassiBySede(decryptedIdSede, annoFormativo);
                        }
                        setSediList([...result.sedi])
                    })
            } else {
                let sediListTmp = [{
                    id: parsedSede?.id,
                    nomeSede: parsedSede.nome
                }]
                setSedeSelezionata(parsedSede?.id);
                setSedeSelezionataNome(parsedSede?.nome)
                props.changeSede(parsedSede?.nome)
                setSediList([...sediListTmp])
                getClassiBySede(parsedSede?.id, annoFormativo);
            }

        }
    }
    async function getClassiBySede(idSedeTmp, annoFormativo) {
        let dataObj = {
            idSede: idSedeTmp,
            annoFormativo: annoFormativo
        }

        RicercaAPI.getClassiBySede(dataObj)
            .then((result) => {
                setHasLoaded(true);
                setClassiInGiornata([...result.sedi])
            })
            .catch(function (error) {
                if (error.response) {
                    setHasLoaded(true);
                    setClassiInGiornata([])
                    if (error.response.status === 403)
                        if (props.logout())
                            props.logout();
                }
            })
    }

    async function getMaterieByClasse(idClasse) {
        let dataObj = {
            idCorso: idClasse
        }

        RicercaAPI.getMaterieByClasse(dataObj)
            .then((result) => {
                setHasLoaded(true);
                setMaterieClasseSelezionata([...result?.materie])
            })
            .catch(function (error) {
                if (error.response) {
                    if (error.response.status === 403)
                        if (props.logout())
                            props.logout();
                }
            })
    }

    function goToHome() {
        navigate("/")
    }

    function goBackToSelezioneClasse() {
        setTabIndex(0)
    }

    return (
        <Box sx={{ maxHeight: "70vh" }}>
            {tabIndex === 0 ? (
                <Fragment>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{ mt: 2, mb: 3 }}>
                        {dontShowDate === false && (
                            <CustomDatePicker
                                getDatePeriodo={true}
                                selectedValue={dataDiInteresse}
                                onChangeValue={changeDataDiInteresseSelezioneClasse}
                                label="Data"
                                isFullWidth={false}
                            />
                        )}
                        {sediList.length > 0 ? (
                            <FormControl sx={{ width: '100%', ml: dontShowDate === false ? 3 : 0 }}>
                                <InputLabel id="sede-label">
                                    Sede
                                </InputLabel>
                                <Select
                                    fullWidth
                                    disabled={sediList.length <= 1}
                                    data-testid='sedeRicercaSelect'
                                    label="Sede"
                                    labelId="sede-label"
                                    value={sedeSelezionata}
                                    onChange={changeSede}
                                >
                                    {sediList.map((sede) => (
                                        <MenuItem
                                            key={sede.id}
                                            value={sede.id}
                                        >
                                            {sede.nomeSede}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        ) : (null)}
                    </Box>
                    {hasLoaded ? (
                        classiInGiornata.length > 0 ? (
                            <Fragment>
                                <Box maxHeight={"35vh"} style={{ overflow: "scroll" }}>
                                    <Table >
                                        <TableBody>
                                            {classiInGiornata.map((classe, index) => (
                                                <TableRow key={"classe" + classe.codiceCorso} sx={{ cursor: 'pointer' }} onClick={() => changeIdClasseSelezionata(classe?.id, classe?.nomeCorso, classe?.codiceCorso, classe?.codiceSimon)}>
                                                    <TableCell sx={{ minWidth: '200px', background: (classe.id === idClasseSelezionata) ? '#1976D2' : 'white', color: (classe.id === idClasseSelezionata) ? 'white' : 'black' }}>
                                                        {classe?.codiceCorso + " - " + classe?.nomeCorso + " (" + classe?.codiceSimon + ")"}  {(classe.id === idClasseSelezionata) ? "- SELEZIONATA" : ""}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                            )}
                                        </TableBody>
                                    </Table>
                                </Box>
                                <Button sx={{ mt: 2 }} onClick={goToHome} variant="outlined" fullWidth color="info" >Torna alla home</Button>
                                <Button sx={{ my: 2 }} onClick={confirmClasse} variant="contained" fullWidth color="success" disabled={idClasseSelezionata === -1}>Conferma e prosegui</Button>
                            </Fragment>
                        ) : (
                            (sedeSelezionata !== -1 && sedeSelezionata !== 1) ? (
                                <Alert severity="error">Nessuna classe associata alla sede selezionata.</Alert>
                            ) : (
                                <Alert severity="info">Prima di procedere è necessario selezionare una sede.</Alert>
                            )
                        )
                    ) : (
                        <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"100%"}>
                            <CircularProgress />
                        </Box>
                    )}
                </Fragment>
            ) : (
                <Fragment>
                    <Typography><b>Classe selezionata:</b> {nomeClasseSelezionata} ({sedeSelezionataNome})</Typography>
                    <Divider sx={{ mt: 2 }} />
                    {hasLoaded ? (
                        materieClasseSelezionata.length > 0 ? (
                            <Box maxHeight={"50vh"} style={{ overflow: "scroll" }}>
                                <Table>
                                    <TableBody>
                                        {materieClasseSelezionata.map((materia, index) => (
                                            <TableRow key={materia.idMateria} sx={{ cursor: 'pointer' }} onClick={() => changeIdMateriaSelezionata(materia?.idMateria, materia?.nomeMateria)}>
                                                <TableCell sx={{ minWidth: '200px', background: (materia.idMateria === idMateriaSelezionata) ? '#1976D2' : 'white', color: (materia.idMateria === idMateriaSelezionata) ? 'white' : 'black' }}>
                                                    {materia?.nomeMateria}  {(materia.idMateria === idMateriaSelezionata) ? "- SELEZIONATA" : ""}
                                                </TableCell>
                                            </TableRow>
                                        )
                                        )}
                                    </TableBody>
                                </Table>
                            </Box>
                        ) : (
                            <Fragment>
                                <Typography textAlign={"center"} color="red">Nessuna materia associata alla classe selezionata</Typography>
                                <Button sx={{ mt: 2 }} onClick={goToHome} variant="outlined" fullWidth color="info" >Torna alla home</Button>
                            </Fragment>
                        )
                    ) : (
                        <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"100%"}>
                            <CircularProgress />
                        </Box>
                    )}
                    <Box>
                        <Button sx={{ mt: 2 }} onClick={goBackToSelezioneClasse} variant="outlined" fullWidth color="info" >Torna alla selezione della classe</Button>
                        <Button sx={{ my: 2 }} onClick={confirmClasse} variant="contained" fullWidth color="success" disabled={idClasseSelezionata === -1}>Conferma e prosegui</Button>
                    </Box>
                </Fragment>
            )}
        </Box>
    )
}