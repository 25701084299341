import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCalendar, faCalendarDays, faCheckDouble, faComments, faDownload, faEnvelope, faListUl, faMagnifyingGlass, faPaperclip, faPenRuler, faPencil, faPerson, faUsers, faListCheck, faCoins, faBriefcase, faQuestion, faWrench, faListOl } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';

const sidebarConfig = [
  // {
  //   title: 'Assistenza',
  //   path: '/assistenza',
  //   idPermesso: null,
  //   icon: <FontAwesomeIcon icon={faQuestion} style={{ fontSize: '1rem' }} />
  // },
  {
    title: 'Home',
    path: '/home',
    idPermesso: null,
    icon: <FontAwesomeIcon icon={faHome} style={{ fontSize: '1rem' }} />
  },
  { /* REGISTRI */
    idPermesso: [0, 7, 8],
    title: 'Registro',
    path: '/registro',
    icon: <FontAwesomeIcon icon={faCalendar} style={{ fontSize: '1rem' }} />,
    children: [
      {
        idPermesso: [0],
        title: 'Registro di classe',
        path: '/registro/registro-di-classe',
      },
      {
        idPermesso: [0],
        title: 'Registro del docente',
        path: '/registro/registro-del-docente',
      },
      {
        idPermesso: [7],
        title: 'Consulta registro di classe',
        path: '/registro/consulta-registro-di-classe',
      },
      {
        idPermesso: [8],
        title: 'Consulta registro del docente',
        path: '/registro/consulta-registro-docente',
      }

    ]
  },

  { /* RICERCA ALUNNI */
    idPermesso: null,
    title: 'Ricerca alunni',
    path: '/ricerca-alunni',
    icon: <FontAwesomeIcon icon={faMagnifyingGlass} style={{ fontSize: '1rem' }} />
  },
  { /* COMUNICAZIONI */
    idPermesso: null,
    title: 'Comunicazioni',
    path: '/comunicazioni/elenco',
    icon: <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: '1rem' }} />,
  },
  { /* Tirocini */
    idPermesso: [29, 30, 31, 2, 32],
    title: 'Tirocini formativo',
    path: '/tirocini',
    icon: <FontAwesomeIcon icon={faBriefcase} style={{ fontSize: '1rem' }} />,
    children: [
      {
        idPermesso: [29],
        title: 'Valutazione tirocinio formativo',
        path: '/tirocini/gestione-tirocini',
      },
      {
        idPermesso: [30],
        title: 'Registro sintesi del tirocinio',
        path: '/tirocini/registro-sintesi-tirocini',
      },
      {
        idPermesso: [31],
        title: 'Presenze tutor di tirocinio',
        path: '/tirocini/presenze-tutor-tirocinio',
      },
      {
        idPermesso: [2],
        title: 'Validazione presenze tirocinio',
        path: '/tirocini/validazione-presenze-tirocini',
      },
      {
        idPermesso: [2],
        title: 'Validazione presenze tutor di tirocinio',
        path: '/tirocini/validazione-presenze-tutor-tirocinio',
      },
      {
        idPermesso: [32],
        title: 'Gestione aziende',
        path: '/tirocini/gestione-aziende',
      },
      {
        idPermesso: [33],
        title: 'Gestione convenzioni',
        path: '/tirocini/gestione-convenzioni',
      },
      {
        idPermesso: [34],
        title: 'Gestione progetti formativi',
        path: '/tirocini/gestione-progetti-formativi',
      },
      {
        idPermesso: [34],
        title: 'Gestione azienda simulata',
        path: '/tirocini/gestione-azienda-simulata',
      },
      {
        idPermesso: [37],
        title: 'Gestione dati ricorrenti per sedi',
        path: '/tirocini/gestione-dati-ricorrenti-sedi',
      },
      {
        idPermesso: [37],
        title: 'Gestione dati ricorrenti per corsi',
        path: '/tirocini/gestione-dati-ricorrenti-corsi',
      },
      {
        idPermesso: [37],
        title: 'Giorni di sospensione',
        path: '/tirocini/giorni-sospensione',
      }

    ]
  },

  { /* */
    idPermesso: [2],
    title: 'Validazione presenze',
    path: '/validazione-presenze',
    icon: <FontAwesomeIcon icon={faCheckDouble} style={{ fontSize: '1rem' }} />
  },
  // { /* TIROCINI */
  //   idPermesso: null,
  //   title: 'Gestione tirocinio',
  //   path: '/gestione-tirocini'
  // },
  {
    idPermesso: [28],
    title: 'Gestione crediti formativi',
    path: '/gestione-crediti-formativi',
    icon: <FontAwesomeIcon icon={faCoins} style={{ fontSize: '1rem' }} />
  },

  { /* COLLOQUI */
    idPermesso: [21, 22],
    title: 'Colloqui',
    path: '/colloqui',
    icon: <FontAwesomeIcon icon={faComments} style={{ fontSize: '1rem' }} />,
    children: [
      {
        idPermesso: [21],
        title: 'Gestione calendario colloqui',
        path: 'colloqui/gestione-calendario',
      },
      {
        idPermesso: [22],
        title: 'Elenco colloqui',
        path: '/colloqui/elenco',
      }

    ]
  },

  { /* GESTIONE TRIMESTRI */
    idPermesso: [13],
    title: 'Gestione periodi',
    path: '/gestione-periodi',
    icon: <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <FontAwesomeIcon icon={faPencil} style={{ fontSize: '1rem', marginRight: '3px' }} />
      <FontAwesomeIcon icon={faCalendarDays} style={{ fontSize: '1rem' }} />
    </Box>
  },
  { /* BES */
    idPermesso: [3],
    title: 'Tracciati BES',
    path: '/tracciati-bes',
    icon: <FontAwesomeIcon icon={faPerson} style={{ fontSize: '1rem' }} />
  },
  { /* exante */
    idPermesso: [15],
    title: 'Export ex ante',
    path: '/export-ex-ante',
    icon: <FontAwesomeIcon icon={faDownload} style={{ fontSize: '1rem' }} />
  },
  { /* Registro didattico sintesi della materia */
    idPermesso: [18],
    title: 'Registro didattico sintesi della materia',
    path: '/registro-didattico-sintesi-materia',
    icon: <FontAwesomeIcon icon={faPenRuler} style={{ fontSize: '1rem' }} />
  },
  { /* ELENCO ALUNNI RIEPILOGO DIDATTICO */
    idPermesso: [19],
    title: 'Elenco alunni riepilogo didattico',
    path: '/elenco-alunni-riepilogo-didattico',
    icon: <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <FontAwesomeIcon icon={faListUl} style={{ fontSize: '1rem', marginRight: '3px' }} />
      <FontAwesomeIcon icon={faUsers} style={{ fontSize: '1rem' }} />
    </Box>
  },
  {
    idPermesso: [27],
    title: 'Riepilogo argomenti svolti',
    path: '/riepilogo-argomenti-svolti',
    icon: <FontAwesomeIcon icon={faPaperclip} style={{ fontSize: '1rem' }} />
  },

  { /* MONITORAGGIO */
    idPermesso: [23, 26],
    title: 'Monitoraggio',
    path: '/monitoraggio',
    icon: <FontAwesomeIcon icon={faListCheck} style={{ fontSize: '1rem' }} />,
    children: [
      {
        idPermesso: [23],
        title: 'Monitoraggio appello',
        path: '/monitoraggio/monitoraggio-appello',
      },
      {
        idPermesso: [26],
        title: 'Monitoraggio firme',
        path: '/monitoraggio/monitoraggio-firme',
      }

    ]
  },


  {
    idPermesso: [36],
    title: 'Accertamento prerequisiti',
    path: '/accertamento-prerequisiti',
    icon: <FontAwesomeIcon icon={faListOl} style={{ fontSize: '1rem' }} />
  },
];

export default sidebarConfig;
