import { Alert, Box, Button, Card, Collapse, Divider, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Snackbar, SnackbarContent, Switch, Table, TableBody, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { TirociniAPI } from "../../../../api/tirociniApi";
import DialogConferma from "../../../shared/dialogConferma";
import { ArrowBack, ArrowForward, Check, Close, ExpandLess, ExpandMore } from "@mui/icons-material";
import CustomDatePicker from "../../../shared/customDatePicker";
import OrarioFormativo from "../orarioFormativo";
import CustomTimePicker from "../../../shared/customTimePicker";
import { convertDataDiNascita } from "../../../shared/metodiDateUtility";

export default function GestioneOrario(props) {

    const [idProgetto, setIdProgetto] = useState(-1)

    const [oreSettimanali, setOreSettimanali] = useState('')
    const [oreTotali, setOreTotali] = useState('')
    const [oreSettimanaliValid, setOreSettimanaliValid] = useState(true)
    const [oreTotaliValid, setOreTotaliValid] = useState(true)

    const [inizioPausa, setInizioPausa] = useState(null)
    const [inizioPausaFormatted, setInizioPausaFormatted] = useState('')
    const [finePausa, setFinePausa] = useState(null)
    const [finePausaFormatted, setFinePausaFormatted] = useState('')
    const [dataInizioTirocinio, setDataInizioTirocinio] = useState(null)
    const [dataFineTirocinio, setDataFineTirocinio] = useState(null)

    const [dataInizioVariazione, setDataInizioVariazione] = useState(null)
    const [dataFineVariazione, setDataFineVariazione] = useState(null)

    const [isLocaleAziendale, setIsLocaleAziendale] = useState(false)

    const [isCanEdit, setIsCanEdit] = useState(true);
    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);
    const [buttonConfermaDisabled, setButtonConfermaDisabled] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState('');

    const [oreTotaliPreviste, setOreTotaliPreviste] = useState(0);

    const [orarioAttuale, setOrarioAttuale] = useState([]);
    const [isVariazione, setIsVariazione] = useState(false);
    const [isVariazioneSede, setIsVariazioneSede] = useState(false);
    const [dataSede, setDataSede] = useState(null);

    const [orariVariati, setOrariVariati] = useState([]);
    const [dateOrariVariati, setDateOrariVariati] = useState([]);

    const [collapseOrariOpen, setCollapseOrariOpen] = useState([]);

    const [idCorso, setIdCorso] = useState(-1);

    useEffect(() => {
        if (props.idProgettoFormativo !== undefined) {
            let isVariazioneTmp = props?.isVariazione ?? false
            setIsVariazione(isVariazioneTmp);
            setIdProgetto(props.idProgettoFormativo);
            if (props.idProgettoFormativo !== -1) getOrarioByIdProgetto(props.idProgettoFormativo, isVariazioneTmp);
        }
    }, [props.idProgettoFormativo, props.isVariazione])

    useEffect(() => {
        if (props.isVariazioneSede !== undefined) {
            setIsVariazioneSede(props.isVariazioneSede);
        }
    }, [props.isVariazioneSede])

    useEffect(() => {
        if (props.idCorso !== undefined) {
            setIdCorso(props.idCorso);
        }
    }, [props.idCorso])

    useEffect(() => {
        if (props.dataSede) {
            setDataSede(props.dataSede);
            setDataFineVariazione(props.dataSede?.dataFineVariazione);
            setDataInizioVariazione(props.dataSede?.dataInizioVariazione);
        }
    }, [props.dataSede])

    function previousTab() {
        if (props.previousTab) {
            props.previousTab();
        }
    }

    function confermaProsegui() {
        if (props.nextTab) props.nextTab()
    }

    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog()
    }

    function changeIsLocaleAziendale() {
        setIsLocaleAziendale(prev => !prev)
    }

    function changeOreSettimanali(e) {
        let valueTmp = e.target.value
        let valueNoExtraCharactersTmp = valueTmp.replace(/[^0-9]/g, '');
        valueNoExtraCharactersTmp = valueNoExtraCharactersTmp.replace(/^0+(?=\d)/, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
        setOreSettimanali(valueNoExtraCharactersTmp)
        if (valueNoExtraCharactersTmp !== '') {
            setOreSettimanaliValid(true)
        } else {
            setOreSettimanaliValid(false)
        }
    }

    function changeOreTotali(e) {

        let valueTmp = e.target.value
        let valueNoExtraCharactersTmp = valueTmp.replace(/[^0-9]/g, '');
        valueNoExtraCharactersTmp = valueNoExtraCharactersTmp.replace(/^0+(?=\d)/, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
        setOreTotali(valueNoExtraCharactersTmp)
        if (valueNoExtraCharactersTmp !== '') {
            setOreTotaliValid(true)
        } else {
            setOreTotaliValid(false)
        }
    }

    function changeOrarioFinePausa(e) {
        setFinePausa(e);
        // let utcDateTimeTmp = e !== null ? e.toUTC().toISO() : null;
        let utcDateTimeTmp = e !== null ? e.toISO() : null; // utc globale
        let oraFormatted = utcDateTimeTmp !== null ? utcDateTimeTmp.split('T')[1].split('.')[0] : null;
        setFinePausaFormatted(oraFormatted);

    }

    function changeOrarioInizioPausa(e) {
        setInizioPausa(e);
        // let utcDateTimeTmp = e !== null ? e.toUTC().toISO() : null;
        let utcDateTimeTmp = e !== null ? e.toISO() : null; // utc globale
        let oraFormatted = utcDateTimeTmp !== null ? utcDateTimeTmp.split('T')[1].split('.')[0] : null;
        setInizioPausaFormatted(oraFormatted);
    }

    function changeDataInizioTirocinio(e) {
        let utcDateTimeTmp = e !== null ? e.toUTC().toISO() : null;
        setDataInizioTirocinio(utcDateTimeTmp);

        calcoloOreTotali(undefined, utcDateTimeTmp, undefined);
    }

    function changeDataFineTirocinio(e) {
        let utcDateTimeTmp = e !== null ? e.toUTC().toISO() : null;
        setDataFineTirocinio(utcDateTimeTmp);

        calcoloOreTotali(undefined, undefined, utcDateTimeTmp);
    }


    function changeDataInizioVariazione(e) {
        let utcDateTimeTmp = e !== null ? e.toUTC().toISO() : null;
        setDataInizioVariazione(utcDateTimeTmp);

        calcoloOreTotali(undefined, utcDateTimeTmp, undefined);
    }

    function changeDataFineVariazione(e) {
        let utcDateTimeTmp = e !== null ? e.toUTC().toISO() : null;
        setDataFineVariazione(utcDateTimeTmp);

        calcoloOreTotali(undefined, undefined, utcDateTimeTmp);

    }

    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }

    function changeOrario(arrayWeek) {
        setOrarioAttuale([...arrayWeek]);
        calcoloOreTotali(arrayWeek);
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg)
        setAlertSeverity(severity)
        setAlertOpen(true)
    }

    function formatOra(ora) {
        if (ora !== null) {
            let dateFormatted = `2024-01-01T${ora}.000Z`;
            return dateFormatted
        } else {
            return null
        }
    }

    function validateFormData() {
        if (dataInizioVariazione !== null
            &&
            dataFineVariazione !== null)
            return false
        else return true
    }

    function validateOrario() {
        if (oreSettimanali !== ''
            &&
            oreTotali !== ''
            &&
            dataInizioTirocinio !== null
            &&
            dataFineTirocinio !== null
        )
            return false
        else return true
    }

    function closeVariazione() {
        if (props.closeVariazione) props.closeVariazione();
    }

    function openCollapseOrario(index) {
        let openCollapseTmp = collapseOrariOpen;
        for (let i = 0; i < openCollapseTmp.length; i++) {
            if (i === index && openCollapseTmp[i] === false) openCollapseTmp[i] = true;
            else openCollapseTmp[i] = false
        }
        setCollapseOrariOpen([...openCollapseTmp]);
    }

    async function getOrarioByIdProgetto(idProgetto, isVariazioneTmp) {
        let obj = {
            idProgetto: idProgetto
        }
        TirociniAPI.getOrarioByIdProgetto(obj)
            .then((result) => {
                let objProgetto = result.orari[0];
                setIsCanEdit(result?.isEditable ?? true);
                setOreSettimanali(result?.oreSettimanali ?? '');
                setOreTotali(result?.oreTotali ?? '');
                setInizioPausa(formatOra(result?.inizioPausa ?? null));
                setFinePausa(formatOra(result?.finePausa ?? null));
                setDataInizioTirocinio(result?.dataInizio ?? null);
                setDataFineTirocinio(result?.dataFine ?? null);
                setIsLocaleAziendale(result?.isLocaleAziendale ?? false);
                let weekTmp = [
                    objProgetto?.lunedi,
                    objProgetto?.martedi,
                    objProgetto?.mercoledi,
                    objProgetto?.giovedi,
                    objProgetto?.venerdi,
                    objProgetto?.sabato,
                    objProgetto?.domenica
                ]
                const checkFascia = (fascia) => {
                    return (fascia?.oraInizio === null && fascia?.oraFine === null);
                };
            
                let allNull = weekTmp.every(day => 
                    checkFascia(day?.primaFascia) && checkFascia(day?.secondaFascia)
                );   
                setOreTotaliPreviste(allNull ? 0 : result?.oreTotali  );

                setOrarioAttuale(weekTmp);
                if (result.orari.length > 0 && isVariazioneTmp) {
                    let orarioVariatiTmp = [];
                    let dateOrariVariatiTmp = [];
                    let collapseOrariOpenTmp = [];
                    for (let i = 0; i < result.orari.length; i++) {
                        let weekTmp = [
                            result.orari[i]?.lunedi,
                            result.orari[i]?.martedi,
                            result.orari[i]?.mercoledi,
                            result.orari[i]?.giovedi,
                            result.orari[i]?.venerdi,
                            result.orari[i]?.sabato,
                            result.orari[i]?.domenica
                        ]
                        orarioVariatiTmp.push(weekTmp);
                        collapseOrariOpenTmp.push(false);
                        dateOrariVariatiTmp.push(result.orari[i].date)
                    }
                    setOrariVariati([...orarioVariatiTmp]);
                    setDateOrariVariati([...dateOrariVariatiTmp])
                    setCollapseOrariOpen([...collapseOrariOpenTmp]);
                }
            })
            .catch(function (error) {
            })

    }

    async function saveOrarioProgetto() {
        setButtonConfermaDisabled(true);
        let obj = {
            idProgetto: idProgetto,
            dataInizio: dataInizioTirocinio,
            dataFine: dataFineTirocinio,
            inizioPausa: inizioPausaFormatted !== '' ? inizioPausaFormatted : null,
            finePausa: finePausaFormatted !== '' ? finePausaFormatted : null,
            oreSettimanali: oreSettimanali !== '' ? parseInt(oreSettimanali) : null,
            oreTotali: oreTotali !== '' ? parseInt(oreTotali) : null,
            isLocaleAziendale: isLocaleAziendale,
            lunedi: orarioAttuale[0],
            martedi: orarioAttuale[1],
            mercoledi: orarioAttuale[2],
            giovedi: orarioAttuale[3],
            venerdi: orarioAttuale[4],
            sabato: orarioAttuale[5],
            domenica: orarioAttuale[6],
        }
        TirociniAPI.saveOrarioProgetto(obj)
            .then((result) => {
                setConfirmAlertIsOpen(false);
                let msgTmp = result.msg ?? 'Salvataggio effettuato con successo.';
                openAlertMsg(msgTmp, 'success');
                setButtonConfermaDisabled(false);
                confermaProsegui();
            })
            .catch(function (error) {
                setConfirmAlertIsOpen(false);

                if (error?.response !== undefined) {
                    let errorMsg = "Errore durante il salvataggio. Riprovare tra qualche istante.";
                    if (error.response.data && Array.isArray(error.response.data.data) && error.response.data.data.length > 0) {
                        errorMsg = error.response.data.data[0]?.msg ?? errorMsg;
                    }
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante il salvataggio. Riprovare tra qualche istante.", "error");
                }
                setButtonConfermaDisabled(false);
            })

    }

    async function variazioneProgetto() {
        setButtonConfermaDisabled(true);

        let orarioTmp = {
            dataInizio: dataInizioTirocinio,
            dataFine: dataFineTirocinio,
            inizioPausa: inizioPausaFormatted !== '' ? inizioPausaFormatted : null,
            finePausa: finePausaFormatted !== '' ? finePausaFormatted : null,
            oreSettimanali: oreSettimanali !== '' ? parseInt(oreSettimanali) : null,
            oreTotali: oreTotali !== '' ? parseInt(oreTotali) : null,
            isLocaleAziendale: isLocaleAziendale,
            lunedi: orarioAttuale[0],
            martedi: orarioAttuale[1],
            mercoledi: orarioAttuale[2],
            giovedi: orarioAttuale[3],
            venerdi: orarioAttuale[4],
            sabato: orarioAttuale[5],
            domenica: orarioAttuale[6],
        }

        let variazioneTmp = isVariazioneSede ? { sedeOperativa: dataSede?.sedeOperativa, orario: orarioTmp } : orarioTmp

        let obj = {
            idProgetto: idProgetto,
            tipologia: isVariazioneSede ? 2 : 1,
            dataInizioVariazione: dataInizioVariazione,
            dataFineVariazione: dataFineVariazione,
            variazione: { ...variazioneTmp }
        }
        TirociniAPI.variazioneProgetto(obj)
            .then((result) => {
                setConfirmAlertIsOpen(false);

                let msgTmp = result.msg ?? 'Salvataggio variazione effettuato con successo.';
                openAlertMsg(msgTmp, 'success');
                setButtonConfermaDisabled(false);
                closeVariazione();
            })
            .catch(function (error) {
                setConfirmAlertIsOpen(false);

                if (error?.response !== undefined) {
                    let errorMsg = "Errore durante il salvataggio variazione. Riprovare tra qualche istante.";
                    if (error.response.data && Array.isArray(error.response.data.data) && error.response.data.data.length > 0) {
                        errorMsg = error.response.data.data[0]?.msg ?? errorMsg;
                    }
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante il salvataggio variazione. Riprovare tra qualche istante.", "error");
                }
                setButtonConfermaDisabled(false);
            })

    }

    async function calcoloOreTotali(orarioAttualeTmp, dataInizioValue, dataFineValue) {

        let dataInizioTmp = dataInizioValue ?? (isVariazione ? dataInizioVariazione : dataInizioTirocinio);
        let dataFineTmp = dataFineValue ?? (isVariazione ? dataFineVariazione : dataFineTirocinio);

        setTimeout(() => {
            let obj = {
                idCorso: idCorso !== -1 ? idCorso : null,
                dataInizio: dataInizioTmp,
                dataFine: dataFineTmp,
                settimana: {
                    lunedi: orarioAttualeTmp !== undefined ? orarioAttualeTmp[0] : orarioAttuale[0],
                    martedi: orarioAttualeTmp !== undefined ? orarioAttualeTmp[1] : orarioAttuale[1],
                    mercoledi: orarioAttualeTmp !== undefined ? orarioAttualeTmp[2] : orarioAttuale[2],
                    giovedi: orarioAttualeTmp !== undefined ? orarioAttualeTmp[3] : orarioAttuale[3],
                    venerdi: orarioAttualeTmp !== undefined ? orarioAttualeTmp[4] : orarioAttuale[4],
                    sabato: orarioAttualeTmp !== undefined ? orarioAttualeTmp[5] : orarioAttuale[5],
                    domenica: orarioAttualeTmp !== undefined ? orarioAttualeTmp[6] : orarioAttuale[6]
                }
            }
            TirociniAPI.calcoloOreTotali(obj)
                .then((result) => {
                    setOreTotaliPreviste(result?.oreTotaliPreviste ?? 0)
                })
                .catch(function (error) { })
        }, 300)
    }
    return (
        <Fragment>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', maxHeight: { xs: '50vh', md: '60vh' }, overflowY: 'scroll', overflowX: 'hidden' }}>
                <Paper elevation={1} sx={{ p: 2, mt: !isVariazioneSede && isVariazione ? 2 : 0 }}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography textAlign={'start'} sx={{ fontWeight: 'bold' }}>Orario</Typography>
                        </Box>
                    </Box>
                    <Grid container spacing={2} mt={1}>
                        {(isVariazione && !isVariazioneSede) ? (
                            <Grid xs={12} md={4}>
                                <Card>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell sx={{ border: '1px solid rgb(220,220,220)' }}>
                                                    <Typography textAlign={"center"} >Durata della variazione</Typography>
                                                </TableCell>
                                                <TableCell sx={{ border: '1px solid rgb(220,220,220)' }}>
                                                    <CustomDatePicker selectedValue={dataInizioVariazione}
                                                        isDisabled={!isCanEdit && !isVariazione}
                                                        // maxDate={dataFineTirocinio}
                                                        minDate={dataInizioTirocinio}
                                                        // maxDate={dataFineTirocinio !== null ? dataFineTirocinio.minus({ days: 1 }) : null} 
                                                        label={"Giorno di inizio *"} isFullWidth={false} onChangeValue={changeDataInizioVariazione} />
                                                    <Divider sx={{ my: 1 }} />
                                                    <CustomDatePicker selectedValue={dataFineVariazione}
                                                        minDate={dataInizioVariazione}
                                                        isDisabled={dataInizioVariazione === null}
                                                        // minDate={dataInizioTirocinio !== null ? dataInizioTirocinio.plus({ days: 1 }) : null} 
                                                        label={"Giorno di fine *"} isFullWidth={false} onChangeValue={changeDataFineVariazione} />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Card>
                            </Grid>
                        ) : (
                            <Fragment>

                                {!isVariazioneSede && (<Grid xs={12} md={4}>
                                    <Card>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ border: '1px solid rgb(220,220,220)' }}>
                                                        <Typography textAlign={"center"} >Periodo di tirocinio</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ border: '1px solid rgb(220,220,220)' }}>
                                                        <CustomDatePicker selectedValue={dataInizioTirocinio}
                                                            isDisabled={!isCanEdit && !isVariazione}
                                                            maxDate={dataFineTirocinio}
                                                            // maxDate={dataFineTirocinio !== null ? dataFineTirocinio.minus({ days: 1 }) : null} 
                                                            label={"Giorno di inizio *"} isFullWidth={false}
                                                            onChangeValue={changeDataInizioTirocinio} />
                                                        <Divider sx={{ my: 1 }} />
                                                        <CustomDatePicker selectedValue={dataFineTirocinio}
                                                            isDisabled={!isCanEdit && !isVariazione}
                                                            minDate={dataInizioTirocinio}
                                                            // minDate={dataInizioTirocinio !== null ? dataInizioTirocinio.plus({ days: 1 }) : null} 
                                                            label={"Giorno di fine *"} isFullWidth={false}
                                                            onChangeValue={changeDataFineTirocinio} />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Card>
                                </Grid>)}
                            </Fragment>
                        )}

                        <Grid xs={12} md={2}>
                            <Card>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ border: '1px solid rgb(220,220,220)' }}>
                                                <TextField
                                                    required
                                                    disabled={!isCanEdit && !isVariazione}
                                                    defaultValue={oreSettimanali}
                                                    value={oreSettimanali}
                                                    error={oreSettimanali === "" && !oreSettimanaliValid}
                                                    onChange={changeOreSettimanali}
                                                    label={'Ore settimanali'}
                                                    sx={{ width: '100%' }}
                                                />
                                                <Divider sx={{ my: 1 }} />
                                                <TextField
                                                    required
                                                    error={oreTotali === "" && !oreTotaliValid}
                                                    disabled={!isCanEdit && !isVariazione}
                                                    defaultValue={oreTotali}
                                                    value={oreTotali}
                                                    onChange={changeOreTotali}
                                                    label={'Ore totali'}
                                                    sx={{ width: '100%' }}
                                                />
                                                {(isCanEdit || isVariazione) && (
                                                    <Fragment>
                                                        <Divider sx={{ my: 1 }} />
                                                        <TextField
                                                            disabled
                                                            value={oreTotaliPreviste}
                                                            label={'Ore totali selezionate'}
                                                            sx={{ width: '100%' }}
                                                        />
                                                    </Fragment>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Card>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Card sx={{ height: '100%' }}>
                                <Table sx={{ height: '100%' }}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ border: '1px solid rgb(220,220,220)' }}>
                                                <Typography textAlign={"center"} >Pausa pranzo</Typography>
                                            </TableCell>
                                            <TableCell sx={{ border: '1px solid rgb(220,220,220)' }}>
                                                <Typography textAlign={"center"}>dalle</Typography>
                                                <Divider sx={{ my: 1 }} />
                                                <Typography textAlign={"center"}>alle</Typography>
                                            </TableCell>
                                            <TableCell sx={{ border: '1px solid rgb(220,220,220)', px: 0, py: 0 }}>
                                                <Box sx={{ px: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <CustomTimePicker
                                                        value={inizioPausa}
                                                        isStyleMod={true}
                                                        maxTimeMinusFive={true}
                                                        maxTime={finePausa}
                                                        timeChanged={changeOrarioInizioPausa}
                                                        isDisabled={!isCanEdit && !isVariazione}
                                                        showResetButton={isCanEdit || isVariazione}
                                                        resetValue={() => {
                                                            setInizioPausa(null);
                                                            setInizioPausaFormatted('');
                                                        }}
                                                    />

                                                </Box>
                                                <Divider sx={{ my: 1 }} />
                                                <Box sx={{ px: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <CustomTimePicker
                                                        value={finePausa}
                                                        isStyleMod={true}
                                                        minTimeMinusFive={true}
                                                        minTime={inizioPausa}
                                                        timeChanged={changeOrarioFinePausa}
                                                        isDisabled={!isCanEdit && !isVariazione}
                                                        showResetButton={isCanEdit || isVariazione}
                                                        resetValue={() => {
                                                            setFinePausa(null);
                                                            setFinePausaFormatted('');
                                                        }}
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell sx={{ border: '1px solid rgb(220,220,220)' }}>
                                                <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }} >
                                                    <Switch
                                                        disabled={!isCanEdit && !isVariazione}
                                                        checked={isLocaleAziendale} onChange={changeIsLocaleAziendale} label="" />
                                                    <Typography>Nei locali aziendali.</Typography>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Card>
                        </Grid>

                        <Grid xs={12} md={12}>
                            <OrarioFormativo
                                week={orarioAttuale}
                                isCanEdit={isCanEdit}
                                isVariazione={isVariazione}
                                changeOrario={changeOrario}
                            />
                        </Grid>

                    </Grid>
                </Paper>

                {orariVariati.length > 0 && (
                    orariVariati.map((orario, index) => (
                        <Paper sx={{ p: 2, mt: 1 }}>
                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} >
                                <Typography textAlign={'start'} sx={{ fontWeight: 'bold' }}>Variazione dal {convertDataDiNascita(dateOrariVariati[index].inizio)} al {convertDataDiNascita(dateOrariVariati[index].fine)}</Typography>
                                <IconButton onClick={() => openCollapseOrario(index)}>
                                    {collapseOrariOpen[index] ? <ExpandMore /> : <ExpandLess />}
                                </IconButton>
                            </Box>
                            <Collapse in={collapseOrariOpen[index]}>
                                <OrarioFormativo
                                    week={orario}
                                    isCanEdit={false}
                                    isVariazione={false}
                                />
                            </Collapse>
                        </Paper>
                    ))
                )}
            </Box>
            <Divider />
            {
                isVariazione ?
                    (
                        <Fragment>
                            {((isVariazione && !isVariazioneSede) && (dataFineVariazione === null || dataInizioVariazione === null)) && (
                                <Box sx={{ mx: 2, mt: 2 }}>
                                    <Alert severity="error">Per proseguire è necessario indicare inizio e fine della variazione.</Alert>
                                </Box>
                            )}
                            <Box sx={{ px: { xs: 0, md: 2 }, py: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'end' } }}>
                                <Button sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }}
                                    onClick={closeVariazione}
                                    variant="outlined" fullWidth color={'error'} >Annulla e chiudi variazione</Button>
                                {isVariazioneSede && (<Button startIcon={<ArrowBack />}
                                    sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }}
                                    onClick={previousTab}
                                    variant="contained" fullWidth color={'secondary'} > torna indietro</Button>)}
                                <Button endIcon={<ArrowForward />}
                                    sx={{ width: { xs: 'unset', md: 'fit-content' }, mx: { xs: 4, md: 0 } }}
                                    onClick={() => setConfirmAlertIsOpen(true)}
                                    disabled={validateFormData()}
                                    variant="contained" fullWidth color={'success'} >Conferma Variazione</Button>
                            </Box>
                        </Fragment>
                    )
                    :
                    (
                        <Box sx={{ px: { xs: 0, md: 2 }, py: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'end' } }}>
                            <Button sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }}
                                onClick={closeDialog}
                                variant="outlined" fullWidth color={'error'} >{isCanEdit ? 'Chiudi procedura di inserimento' : 'Torna a gestione progetto formativo'}</Button>
                            <Button startIcon={<ArrowBack />}
                                sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }}
                                onClick={previousTab}
                                variant="contained" fullWidth color={'secondary'} > torna indietro</Button>
                            <Button endIcon={<ArrowForward />}
                                sx={{ width: { xs: 'unset', md: 'fit-content' }, mx: { xs: 4, md: 0 } }}
                                onClick={() => isCanEdit ? setConfirmAlertIsOpen(true) : confermaProsegui()}
                                disabled={validateOrario()}
                                variant="contained" fullWidth color={'success'} >{isCanEdit ? 'Conferma e prosegui' : 'Prosegui'}</Button>
                        </Box>
                    )
            }
            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={'Conferma salvataggio'}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={isVariazione ? `Confermi di voler salvare le variazioni apportate? ` : `Confermi di voler salvare l'orario dell'attività formativa? `}
                showAlunni={false}
                confermaButton={'Conferma'}
                severity={'success'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={() => isVariazione ? variazioneProgetto() : saveOrarioProgetto()}
                disabledButton={buttonConfermaDisabled}
            />
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={10000} onClose={() => { setAlertOpen(false) }} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%', zIndex: '3000!important' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment >
    )
}