import { Alert, Button, TextField, Typography } from "@mui/material";
import { itIT } from "@mui/x-date-pickers/locales";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime, Interval } from "luxon";
import { Fragment, useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { convertDataGetProssimeLezione, convertToItalianDateTimeStringaParlante, convertToItalianTime, convertUtcInLuxon } from "../../../shared/metodiDateUtility";
import { EventiAPI } from "../../../../api/eventiApi";
import { RegistroAPI } from "../../../../api/registroApi";
import { getDataInizioFinePeriodo } from "../../../shared/utilityMethods";

export default function NewAggiuntaExAnte(props) {

    const [data, setData] = useState(null);
    const [voto, setVoto] = useState("");
    const [isInserimentoLoading, setIsInserimentoLoading] = useState(false);
    const [isModifica, setIsModifica] = useState(false);
    const [idVoto, setIdVoto] = useState(-1);

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [dataInizioPeriodo, setDataInizioPeriodo] = useState("")
    const [dataFinePeriodo, setDataFinePeriodo] = useState("")
   
useEffect(()=>{
    let objDateInteresse = getDataInizioFinePeriodo();
    let inizioPeriodoTmp = objDateInteresse?.inizioPeriodo ?? null
    let finePeriodoTmp = objDateInteresse?.finePeriodo ?? null

    setDataInizioPeriodo(inizioPeriodoTmp ?? null);
    setDataFinePeriodo(finePeriodoTmp ?? null);
},[])

    useEffect(() => {
        if (props.dataSelezionata && props.votoSelezionato) {
            const voto =  props.votoSelezionato ? (props?.votoSelezionato > 0 ? props?.votoSelezionato : 'N.V.') : "";
            setVoto(voto);
            const dataConverted = convertUtcInLuxon(props.dataSelezionata);
            setData(dataConverted);
            setIdVoto(props.idVoto);
            setIsModifica(true);
        }
    }, [props.dataSelezionata, props.votoSelezionato, props.idVoto])

    function changeData(value) {
        setData(value);
    }

    function changeVoto(e) {

        let newValue = e.target.value;

        let newStr = "";

        if (newValue === "N.V") { //Se la stringa è cosi allora ho fatto il delete
            newStr = "";
        }
        else if (/[nN]/g.test(newValue) === true) {
            newStr = newValue.replace(/[nN]{1,10}$/g, 'N.V.');//Sostituisce nN con N.V
            if (/[*0-9]/g.test(newValue) === true) newStr = 'N.V.'
        }
        else if (/[0-9]/g.test(newValue) === true) {
            let strWithoutLetters = newValue.replace(/[^0-9]/g, '')//Rimuove i caratteri speciali e le lettere
            newStr = strWithoutLetters.replace(/0+(?=\d)/g, '');//Rimuove lo 0 se è seguito da un numero 01 => 1
            if (newStr > 30) newStr = '30'; //non permette di superare voto 30
            if (newStr == 0) newStr = 'N.V.';
        } else {
            newStr = '' //resetta a ''
        }
        setVoto(newStr);
    }

    async function inserisciExAnte() {
        setIsInserimentoLoading(true);

        const obj = {
            idAccertamento: props.idAccertamento,
            voto: voto === 'N.V.' ? "0" : voto,
            note: '',
            idAlunno: [props.idAlunno],
            idOrario: null,
            annoFormativo: props.annoFormativo,
            dataRichiesta: convertDataGetProssimeLezione(data, true),
            idMateria: props?.idMateria ?? -1,
            nomeMateria: props.materiaSeleziona,
            nomeBreveMateria: props.materiaSeleziona ?? "",
            tipologia: props.tipologiaSelezionata
        }

        try {
            await EventiAPI.insertExante(obj);
            // setConfirmAlertIsOpen(false);
            setTimeout(() => {
                setIsInserimentoLoading(false);
            }, 500)
            props.votoAggiunto();
        } catch (error) {
            let errorMsg = "";

            if (error.response.status === 409) {
                errorMsg = error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione";
            } else {
                errorMsg = error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione";
            }
            setAlertMsg(errorMsg);
            setIsAlertOpen(true);
            setTimeout(() => {
                setIsInserimentoLoading(false);
            }, 500)
        }
    }

    async function modificaExAnte() {

        const obj = {
            idAccertamento: props.idAccertamento,
            voto: voto === 'N.V.' ? "0" : voto,
            idVoto: idVoto,
            idAlunno: [props.idAlunno],
            idOrario: null,
            tipologia: props.tipologiaSelezionata,
            dataRichiesta: convertDataGetProssimeLezione(data, true),
            idMateria: props?.idMateria ?? -1,

        }

        EventiAPI.modificaPrerequisito(obj, idVoto)
            .then((result) => {
                if (props.exAnteModificato)
                    props.exAnteModificato()
            })
            .catch((error) => {
                let errorMsg = "";

                if (error.response.status === 409) {
                    errorMsg = error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione";
                } else {
                    errorMsg = error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione";
                }

                setAlertMsg(errorMsg);
                setIsAlertOpen(true);
            })
    }

    async function eliminaAccertamento() {
        let dataObj = {
            idEvento: idVoto,
            tipo: 8 //Tipologia fissa: exante
        }
        RegistroAPI.deleteEvento(dataObj)
            .then((result) => {
                if (props.exAnteEliminato)
                    props.exAnteEliminato("Voto eliminato", "info")
            })
            .catch(function (error) {
                let errorMsg = "";

                if (error.response.status === 409) {
                    errorMsg = error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione";
                } else {
                    errorMsg = error?.response?.data?.data[0]?.msg ?? "Al momento non è possibile completare l'operazione";
                }

                setAlertMsg(errorMsg);
                setIsAlertOpen(true);
            })
    }

    return (
        <Fragment>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    {props.objVotoSelezionato?.createdBy && (
                        <Typography>Assegnato da: <b>{props.objVotoSelezionato?.createdBy?.nome}</b> il {convertToItalianTime(props?.objVotoSelezionato?.createdAt)}</Typography>
                    )}
                    {props.objVotoSelezionato?.modifiedAt && (
                        <Typography>Modificato da: <b>{props.objVotoSelezionato?.modifiedBy?.nome}</b> il {convertToItalianTime(props?.objVotoSelezionato?.modifiedAt)}</Typography>
                    )}
                </Grid>
                <Grid xs={12} md={6}>
                    <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                        LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                        <MobileDatePicker views={['month', 'day']} sx={{ width: '100%' }}
                          minDate={dataInizioPeriodo}
                          maxDate={DateTime.now() || dataFinePeriodo}
                            label="Data"
                            value={data}
                            format="dd/MM/yyyy"
                            onChange={(value) => changeData(value)}
                            dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                    </LocalizationProvider>
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField fullWidth data-testid='votoInput'
                        label="Voto"
                        onChange={changeVoto}
                        value={voto}
                        inputProps={{ maxLength: 4 }}
                    />
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField fullWidth disabled value={props?.materiaSeleziona ?? ""} label="Materia" variant="outlined" />
                </Grid>
                <Grid xs={12} md={6}>
                    <TextField fullWidth disabled value={props?.tipologiaSelezionata ?? ""} label="Tipologia" variant="outlined" />
                </Grid>
                {isAlertOpen && (
                    <Grid xs={12}>
                        <Alert severity="error">{alertMsg}</Alert>
                    </Grid>
                )}
                <Grid xs={12} sx={{ display: "flex", justifyContent: 'end' }}>
                    {isModifica && (
                        <Button sx={{ mr: 1 }} onClick={eliminaAccertamento} color="error" variant="outlined">
                            Elimina accertamento prerequisito
                        </Button>
                    )}
                    <Button onClick={isModifica ? modificaExAnte : inserisciExAnte} disabled={voto === "" || data === null || isInserimentoLoading} color="success" variant="contained">
                        {isModifica ? "Conferma modifica" : "Aggiungi accertamento prerequisiti"}
                    </Button>
                </Grid>
            </Grid>
        </Fragment>
    )
}