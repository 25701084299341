import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import { Divider, Paper, Collapse, Container, Typography, Dialog, DialogContent, DialogTitle, IconButton, Snackbar, Alert, Box } from '@mui/material';
import { Close, Delete, Search } from '@mui/icons-material';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import 'react-virtualized/styles.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CryptoJS from 'crypto-js';
import Breadcrumb from '../../registri/shared/breadcrumb';
import { getAnnoFormativo } from '../../shared/utilityMethods';
import FiltriElencoAccertamentoPrerequisiti from './filtriElencoAccertamentoPrerequisiti';
import { styled } from '@mui/material/styles';
import DettaglioAccertamentoPrerequisiti from '../dettaglioAccertamentoPrerequisiti';
import GestioneAccertamento from '../gestioneAccertamento';



const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function ElencoAccertamentoPrerequisiti(props) {

    const { t, i18n } = useTranslation();
    const [risultatiRicerca, setRisultatiRicerca] = useState([]);
    const [searchStarted, setSearchStarted] = useState(false);
    const [idAlunnoSelezionato, setIdAlunnoSelezionato] = useState(-1);
    const [nomeAlunnoSelezionato, setNomeAlunnoSelezionato] = useState("");
    const [idCorsoSelezionato, setIdCorsoSelezionato] = useState(-1);
    const [idSedeSelezionata, setIdSedeSelezionata] = useState(-1);
    const [permessiUtente, setPermessiUtente] = useState([]);
    const [annoFormativo, setAnnoFormativo] = useState("");
    const [isDettaglioAccertamentoDialogOpen, setIsDettaglioAccertamentoDialogOpen] = useState(false);
    const [tipologiaSelezionata, setTipologiaSelezionata] = useState("");
    const [nomeCorsoSelezionato, setNomeCorsoSelezionato] = useState("");
    const [indexDialogDettaglioChiusa, setIndexDialogDettaglioChiusa] = useState(0);
    const [corsoSedeFiltro, setCorsoSedeFiltro] = useState(null);
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("info");
    const [alertMsg, setAlertMsg] = useState("");
    const [isGestioneModalOpen, setIsGestioneModalOpen] = useState(false);

    useEffect(() => {
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
        if (localStorage.getItem("permessi")) {
            let permessiTmp = localStorage.getItem("permessi");
            let decryptedPermessi = CryptoJS.AES.decrypt(permessiTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
            let permessiParsed = JSON.parse(decryptedPermessi);
            setPermessiUtente(permessiParsed);
        }

    }, [])



    function getElencoAccertamentoPrerequisiti(elencoRisultati, idSede) {
        setRisultatiRicerca([...elencoRisultati]);
        setSearchStarted(true);
        setIdSedeSelezionata(idSede)
    }

    function removeFilters() {
        setSearchStarted(false);
    }

    function openDettaglioAccertamento(idAlunno, nomeAlunno, idCorso, nomeCorso, tipologia) {
        setIdAlunnoSelezionato(idAlunno)
        setNomeAlunnoSelezionato(nomeAlunno)
        setIdCorsoSelezionato(idCorso);
        setNomeCorsoSelezionato(nomeCorso);
        setTipologiaSelezionata(tipologia);
        setIsDettaglioAccertamentoDialogOpen(prev => !prev);
    }

    function closeDettaglioAccertamento() {
        setIsDettaglioAccertamentoDialogOpen(false);
        setIndexDialogDettaglioChiusa(prev => prev + 1);
    }

    function openGestioneAccertamento() {
        setIsGestioneModalOpen(prev => !prev);
    }

    function closeGestioneAccertamento(){
        setIndexDialogDettaglioChiusa(prev => prev+1)
        setIsGestioneModalOpen(prev => !prev);
    }

    function assegnazioneSalvata(corsoSelected, sedeSelected){
        setIsGestioneModalOpen(false);
        setCorsoSedeFiltro({corso:corsoSelected, sede: sedeSelected})
        //setIndexDialogDettaglioChiusa(prev => prev + 1);
        setAlertSeverity("success")
        setAlertMsg("Assegnazione salvata correttamente");
        setAlertIsOpen(true);
    }
    function closeAlert() {
        setAlertIsOpen(false)
    }
    return (
        <Fragment>

            <Breadcrumb sections={["Accertamento prerequisiti"]} />
            <Container maxWidth={"xl"} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <FiltriElencoAccertamentoPrerequisiti corsoSedeFiltro={corsoSedeFiltro} indexDialogDettaglioChiusa={indexDialogDettaglioChiusa} permessiUtente={permessiUtente} annoFormativo={annoFormativo}
                    getElencoAccertamentoPrerequisiti={getElencoAccertamentoPrerequisiti} removeFilters={removeFilters} openGestioneAccertamentoDialog={openGestioneAccertamento}/>
                <Collapse in={searchStarted} sx={{ my: 2, width: '100%' }}>
                    <Paper sx={{ p: 0, overflowY: 'auto', height: { xs: '40vh', md: '50vh' } }}>
                        {risultatiRicerca.length > 0 ?
                            (
                                <TableContainer>
                                    <Table >
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: '#1976d2' }}>
                                                <TableCell sx={{ color: 'white' }} >Codice Fiscale</TableCell>
                                                <TableCell sx={{ color: 'white' }} >Nome </TableCell>
                                                <TableCell sx={{ color: 'white' }} >Corso</TableCell>
                                                <TableCell sx={{ color: 'white' }}>Tipologia (assegnati/da assegnare)</TableCell>
                                                <TableCell sx={{ color: 'white' }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {risultatiRicerca.map((alunno, index) => (
                                                <StyledTableRow
                                                    key={alunno?.idAlunno ?? index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {alunno.codiceFiscale}
                                                    </TableCell>
                                                    <TableCell>
                                                        {alunno.cognome} {alunno.nome}
                                                    </TableCell>
                                                    <TableCell>
                                                        {alunno.nomeCorso}
                                                    </TableCell>
                                                    <TableCell >
                                                        {alunno.tipologia} ({alunno.contatore})
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton onClick={() => openDettaglioAccertamento(alunno.idAlunno, alunno.nome + " " + alunno.cognome, alunno.idCorso, alunno.nomeCorso, alunno.tipologia)}>
                                                            <Search />
                                                        </IconButton>
                                                        {/* <IconButton >
                                                            <Delete />
                                                        </IconButton> */}
                                                    </TableCell>
                                                </StyledTableRow >
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )
                            : (
                                <Box sx={{height:'50vh', display:'flex',alignItems:'center', justifyContent:'center'}}>
                                <Alert severity="info">
                                    Nessun dato disponibile
                                </Alert>
                            </Box>
                            )
                        }
                    </Paper>

                </Collapse>
            </Container>


            <Dialog fullScreen open={isDettaglioAccertamentoDialogOpen} onClose={closeDettaglioAccertamento} sx={{ mt: '8vh' }} >
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Dettaglio accertamento ({nomeAlunnoSelezionato}) </Typography>
                    <IconButton onClick={closeDettaglioAccertamento} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />

                <DialogContent sx={{ pt: 0 }}>
                    <DettaglioAccertamentoPrerequisiti  nomeAlunno={nomeAlunnoSelezionato} tipologiaAP={tipologiaSelezionata} corso={nomeCorsoSelezionato}
                        idCorso={idCorsoSelezionato} idSede={idSedeSelezionata} idAlunno={idAlunnoSelezionato} annoFormativo={annoFormativo} />
                </DialogContent>
            </Dialog>


            <Dialog fullScreen open={isGestioneModalOpen} onClose={closeGestioneAccertamento} sx={{ mt: '8vh' }} >
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={"center"}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Inserisci richiesta di accertamento prerequisiti</Typography>
                    <IconButton onClick={closeGestioneAccertamento} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />

                <DialogContent sx={{ pt: 0 }}>
                    <GestioneAccertamento assegnazioneSalvata={assegnazioneSalvata} annoFormativo={annoFormativo} closeGestioneDialog={closeGestioneAccertamento}/>
                </DialogContent>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertIsOpen} autoHideDuration={10000} onClose={closeAlert}>
                <Alert onClose={closeAlert} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>

            <Helmet>
                <title>{t("name")} - Accertamento prerequisiti</title>
            </Helmet>

        </Fragment >
    );
}