import { Box, useTheme, Step, StepLabel, Stepper, useMediaQuery, CircularProgress, Typography, DialogTitle, Divider } from "@mui/material";
import { Fragment, useEffect, useState } from "react";

import RicercaAlunno from "./dettaglio-azienda-simulata/ricercaAlunno";
import GestioneOrario from "./dettaglio-azienda-simulata/gestioneOrario";

export default function DettaglioAziendaSimulata(props) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [idAlunno, setIdAlunno] = useState(-1);
    const [nomeAlunno, setNomeAlunno] = useState(null);
    const [corso, setCorso] = useState(null);
    const [idProgettoFormativo, setIdProgettoFormativo] = useState(-1);
    const [tabIndex, setTabIndex] = useState(0);
    const [hasLoaded, setHasLoaded] = useState(true);
    const [idCorso, setIdCorso] = useState(-1);

    const [isVisualizza, setIsVisualizza] = useState(false);
    const [isModifica, setIsModifica] = useState(true);

    const [aziendaSimulata, setAziendaSimulata] = useState(null);

    useEffect(() => {
        if (props.aziendaSimulata !== undefined) {
            setAziendaSimulata(props.aziendaSimulata);
            if (props.aziendaSimulata === null) {
                setTabIndex(0);
            }
            else {
                setTabIndex(1);
            }
            setTimeout(() => { setHasLoaded(true) }, 100)
        }
    }, [props.aziendaSimulata])

    // useEffect(() => {
    //     if (props.nomeAlunno !== undefined)
    //         setNomeAlunno(props.nomeAlunno);
    // }, [props.nomeAlunno])

    useEffect(() => {
        if (props.isModifica !== undefined)
            setIsModifica(props.isModifica);
    }, [props.isModifica])


    function changeIdAlunno(idAlunno) {
        setIdAlunno(idAlunno)
    }

    function changeNomeAlunno(nomeAlunno) {
        setNomeAlunno(nomeAlunno)
    }
    function changeCorso(corso) {
        setCorso(corso)
        setIdCorso(corso.idCorso);
    }

    // function changeTabIndex(event, newValue) {
    //     setTabIndex(newValue);
    // }

    function previousTab() {
        setTabIndex(prev => prev - 1);
        setIdAlunno(-1);
    }

    function nextTab() {
        setTabIndex(prev => prev + 1);
    }

    function closeDialog() {
        if (props.closeDialog) props.closeDialog()
        setTabIndex(0);
        changeIdProgettoFormativo(-1);
        setIdCorso(-1);
        setIdAlunno(-1);
        setNomeAlunno('');
        // setIsVisualizza(false);
    }

    function changeIdProgettoFormativo(idProgettoFormativo) {
        setIdProgettoFormativo(idProgettoFormativo);
        if (props.changeIdProgettoFormativo) props.changeIdProgettoFormativo(idProgettoFormativo);
    }

    function changeIdCorso(idCorso) {
        setIdCorso(idCorso);
    }

    function proceduraConclusa() {
        if (props.proceduraConclusa) props.proceduraConclusa(idProgettoFormativo);
    }

    return (
        <Fragment>
            {!isVisualizza && (<Box sx={{ borderBottom: 1, borderColor: 'divider', p: 2 }}>
                <Stepper activeStep={tabIndex} alternativeLabel={isMobile}>
                    <Step key={0}>
                        <StepLabel>Selezione alunno</StepLabel>
                    </Step>
                    <Step key={1}>
                        <StepLabel>Attività formativa</StepLabel>
                    </Step>
                </Stepper>
            </Box>)}
            <Box sx={{ p: 0 }}>
                {hasLoaded ? (
                    <Fragment>
                        {tabIndex === 0 && (
                            <Fragment>
                                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Azienda simulata: selezione alunno</Typography>
                                </DialogTitle>
                                <Divider />
                                <RicercaAlunno
                                    closeDialog={closeDialog}
                                    changeIdAlunno={changeIdAlunno}
                                    changeNomeAlunno={changeNomeAlunno}
                                    nextTab={nextTab}
                                    changeIdCorso={changeIdCorso}
                                    idCorso={idCorso}
                                    idAlunno={idAlunno}
                                    changeCorso={changeCorso}
                                />
                            </Fragment>
                        )}
                        {tabIndex === 1 && (
                            <Fragment>
                                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Azienda simulata: attività formativa</Typography>
                                </DialogTitle>
                                <Divider />
                                <GestioneOrario
                                    aziendaSimulata={aziendaSimulata}
                                    closeDialog={closeDialog}
                                    proceduraConclusa={proceduraConclusa}
                                    previousTab={previousTab}
                                    idCorso={idCorso}
                                    idAlunno={idAlunno}
                                    nomeAlunno={nomeAlunno}
                                    corso={corso}
                                    isModifica={isModifica}
                                />
                            </Fragment>
                        )}
                    </Fragment>
                ) : (
                    <Box sx={{ height: '65vh' }} flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <CircularProgress sx={{ mb: 4 }} />
                        <Typography >Caricamento in corso...</Typography>
                    </Box>
                )}
            </Box>
        </Fragment>
    )
}