import React from 'react';
import { useState, useEffect, Fragment, useRef } from "react"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Skeleton, Button, Dialog, DialogContent, DialogTitle, Alert, IconButton, Divider, Snackbar, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso'
import { EventiAPI } from '../../../../api/eventiApi';
import { convertDataDiNascita } from '../../../shared/metodiDateUtility';
import DettaglioExAnte from './dettaglioExAnte';
import { Add, Close, Edit, Visibility } from '@mui/icons-material';
import AggiuntaExante from './aggiuntaExAnte';
import { getAnnoFormativo } from '../../../shared/utilityMethods';
import NewAggiuntaExAnte from './newAggiuntaExAnte';

export default function ExAnte(props) {

    const tableVirtuoso = useRef(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [annoFormativo, setAnnoFormativo] = useState("20242025");
    const [idAlunno, setIdAlunno] = useState(-1);
    const [nomeAlunno, setNomeAlunno] = useState("");

    const [idDocente, setIdDocente] = useState(-1)
    const [idCorso, setIdCorso] = useState(-1)
    const [isAggiungiDialogOpen, setIsAggiungiDialogOpen] = useState(false);

    const [isDettaglioDialogOpen, setIsDettaglioDialogOpen] = useState(false)
    const [objVotoSelezionato, setObjVotoSelezionato] = useState(undefined)
    const [indexRowVotoSelezionato, setIndexRowVotoSelezionato] = useState(-1);

    const [hasLoaded, setHasLoaded] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [erroMsg, setErrorMsg] = useState("Errore durante il recupero dei voti.");

    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState("");

    const [listVoti, setListVoti] = useState([]);
    const [objPermessiExAnte, setObjPermessiExAnte] = useState({
        insertExAnte: true,
        prerequisitiPassaggio: false,
        prerequisitiRecupero: false
    })
    const [showInserisciVoto, setShowInserisciVoto] = useState(false);

    const [dataInizioPeriodo, setDataInizioPeriodo] = useState(null)
    const [dataFinePeriodo, setDataFinePeriodo] = useState(null)

    const [isAggiungiVotoDialogOpen, setIsAggiungiVotoDialogOpen] = useState(false);
    const [isModificaVotoDialogOpen, setIsModificaVotoDialogOpen] = useState(false);


    const [dataSelezionata, setDataSelezionata] = useState(null);
    const [votoSelezionato, setVotoSelezionato] = useState("");
    const [materiaSelezionata, setMateriaSelezionata] = useState("");
    const [tipologiaSelezionata, setTipologiaSelezionata] = useState("");
    const [idMateriaSelezionata, setIdMateriaSelezionata] = useState(-1);
    const [idAccertamento, setIdAccertamento] = useState(-1)
    const [idVoto, setIdVoto] = useState(-1);

    const VirtuosoTableComponents = {
        Scroller: React.forwardRef((props, ref) => (
            <TableContainer component={Paper} {...props} ref={ref} />
        )),
        Table: (props) => (
            <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
        ),
        TableHead,
        TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
        TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
    };



    useEffect(() => {
        if (props.nomeAlunno !== undefined)
            setNomeAlunno(props.nomeAlunno);
    }, [props.nomeAlunno])

    useEffect(() => {
        if (props.idDocente !== undefined)
            setIdDocente(props.idDocente)
    }, [props.idDocente])

    useEffect(() => {
        if (props.idAlunno !== undefined) {
            let annoFormativoTmp = getAnnoFormativo();
            setAnnoFormativo(annoFormativoTmp);
            setIdAlunno(props.idAlunno);
            getVotiExAnte(props.idAlunno, annoFormativoTmp)
        }
    }, [props.idAlunno])

    useEffect(() => {
        if (props.idCorso !== undefined) {
            setIdCorso(props.idCorso);
        }
    }, [props.idCorso])

    function closeDialogDettaglio() {
        getVotiExAnte(idAlunno, annoFormativo);
        setIsDettaglioDialogOpen(false);

    }

    function closeDialogAggiunta() {
        setIsAggiungiVotoDialogOpen(false);
    }
    function closeAlert() {
        setAlertIsOpen(false);
    }

    function votoAggiunto() {
        closeDialogAggiunta();
        getVotiExAnte(idAlunno, annoFormativo);

        setAlertIsOpen(true);
        setAlertMsg("Ex ante aggiunto correttamente");
        setAlertSeverity("success")
    }

    function exAnteModificato() {
        closeDialogAggiunta();
        getVotiExAnte(idAlunno, annoFormativo);

        setAlertMsg("Ex ante modificato correttamente");
        setAlertSeverity("success");
        setAlertIsOpen(true);

    }

    function exAnteEliminato(msg, severity) {
        closeDialogAggiunta();
        getVotiExAnte(idAlunno, annoFormativo);

        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertIsOpen(true);
    }

    async function getVotiExAnte(idAlunnoTmp, annoFormativoTmp) {
        let dataObj = {
            annoFormativo: annoFormativoTmp,
            idAlunno: idAlunnoTmp
        }
        setHasLoaded(false)
        EventiAPI.getElencoExAnteAlunno(dataObj)
            .then((result) => {

                setObjPermessiExAnte({
                    insertExAnte: result?.insertExAnte ?? true,
                    prerequisitiPassaggio: result?.prerequisitiPassaggio ?? false,
                    prerequisitiRecupero: result?.prerequisitiRecupero ?? false
                })
                setDataInizioPeriodo(result.dataInizioPeriodo)
                setDataFinePeriodo(result.dataFinePeriodo)
                setShowInserisciVoto(result?.showInserisciVoto);
                setHasError(false);
                let listVotiTmp = result?.accertamentiPrerequisiti;
                setListVoti([...listVotiTmp]);
                setHasLoaded(true)
                if (indexRowVotoSelezionato !== -1) {
                    setTimeout(() => {
                        if (tableVirtuoso.current) {
                            tableVirtuoso.current.scrollToIndex({
                                index: indexRowVotoSelezionato,
                                behavior: "instant"
                            })
                        }
                    }, 200)
                }
            })
            .catch((error) => {
                /*MOSTRARE ERRORE */
                setErrorMsg(error?.response?.data?.data[0]?.msg ?? "Errore durante il recupero dei voti.")
                setHasLoaded(true);
                setHasError(true);
            })

    }

    function openDialogAggiungiVoto(objMateria, tipologia, idAccertamento, dataLezione, voto, idVoto, votoObj) {
        setIsAggiungiVotoDialogOpen(prev => !prev);
        setMateriaSelezionata(objMateria.nomeMateria);
        setIdMateriaSelezionata(objMateria.idMateria);
        setTipologiaSelezionata(tipologia)
        setIdAccertamento(idAccertamento);
        setDataSelezionata(dataLezione);
        setVotoSelezionato(voto);
        setIdVoto(idVoto);

        setObjVotoSelezionato(votoObj)
    }

    function fixedHeaderContent() {
        return (
            <TableRow>
                <TableCell variant="head" sx={{ backgroundColor: 'background.paper', }} >
                    <b>Tipologia</b>
                </TableCell>
                <TableCell variant="head" sx={{ backgroundColor: 'background.paper', }} >
                    <b>Materia</b>
                </TableCell>
                <TableCell align="center" variant="head" sx={{ backgroundColor: 'background.paper', }} >
                    <b>Voto</b>
                </TableCell>
                <TableCell align="center" variant="head" sx={{ backgroundColor: 'background.paper', }} >
                    {/*AZIONI*/}
                </TableCell>
            </TableRow>
        );
    }

    function rowContent(index, voto) {
        return (
            <Fragment>
                <TableCell >
                    {voto?.tipologia ?? "-"}
                </TableCell>
                <TableCell  >
                    {voto?.materia[0]?.nomeMateria}
                </TableCell>
                <TableCell align="center">
                    {voto?.voto ? (voto?.voto > 0 ? voto?.voto : 'N.V.') : "-"}
                </TableCell>
                <TableCell>
                    {voto.insertPossible && (
                        <Button onClick={() => openDialogAggiungiVoto(voto?.materia[0], voto?.tipologia, voto.id, voto.dataLezione, voto.voto, voto.idVoto, voto)} fullWidth variant={voto?.voto === null ? 'contained' : 'outlined'}>
                            {voto?.voto === null ? "Inserisci" : "Modifica"}
                        </Button>
                    )}
                </TableCell>
            </Fragment>
        );
    }

    return (
        <Fragment>
            {hasLoaded ? (
                <Fragment>
                    <Paper sx={{ height: 400, width: '100%' }}>
                        {hasError ? (
                            <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                <Alert severity="error">{erroMsg}</Alert>
                            </Box>
                        ) : (
                            <TableVirtuoso
                                ref={tableVirtuoso}
                                data={listVoti}
                                components={VirtuosoTableComponents}
                                fixedHeaderContent={fixedHeaderContent}
                                itemContent={rowContent}
                            />
                        )}
                    </Paper>

                </Fragment>
            ) : (
                <Box flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <CircularProgress sx={{ mb: 4 }} />
                    <Typography >Caricamento in corso...</Typography>
                </Box>
            )}
            <Dialog open={isDettaglioDialogOpen} fullWidth maxWidth="md">
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Dettaglio Ex ante ({nomeAlunno})
                    </Typography>
                    <IconButton onClick={closeDialogDettaglio} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <DettaglioExAnte annoFormativo={annoFormativo} objPermessiExAnte={objPermessiExAnte} idAlunno={idAlunno} cantModify={objVotoSelezionato?.contenuto?.editable ?? false} exAnteEliminato={exAnteEliminato}
                        comunicazione={undefined} closeDialogDettaglio={closeDialogDettaglio} exAnteModificato={exAnteModificato} />
                </DialogContent>
            </Dialog>

            <Dialog open={isAggiungiVotoDialogOpen} fullWidth maxWidth="md" sx={{ mt: '8vh' }}>
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Accertamento prerequisiti
                    </Typography>
                    <IconButton onClick={closeDialogAggiunta} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <NewAggiuntaExAnte idAccertamento={idAccertamento} idAlunno={idAlunno} annoFormativo={annoFormativo} idMateria={idMateriaSelezionata} idVoto={idVoto}
                        materiaSeleziona={materiaSelezionata} tipologiaSelezionata={tipologiaSelezionata} dataSelezionata={dataSelezionata} votoSelezionato={votoSelezionato}
                        closeDialog={closeDialogAggiunta} votoAggiunto={votoAggiunto} exAnteModificato={exAnteModificato} objVotoSelezionato={objVotoSelezionato} exAnteEliminato={exAnteEliminato} />
                </DialogContent>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertIsOpen} autoHideDuration={3000} onClose={closeAlert}>
                <Alert onClose={closeAlert} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment>
    );
}