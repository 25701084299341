import { ErrorOutline, Warning } from "@mui/icons-material";
import { Box, Checkbox, Divider, Typography } from "@mui/material";
import { useState, useEffect, Fragment } from "react"

export default function ElencoMaterieSelezionabili(props) {

    const [listMaterie, setListMaterie] = useState([]);
    const [listMaterieSelezionate, setListMaterieSelezionate] = useState([])
    const [idMaterieSelezionate, setIdMaterieSelezionate] = useState([]);

    useEffect(() => {
        if (props.listMaterie !== undefined)
            setListMaterie([...props.listMaterie])
    }, [props.listMaterie])


    useEffect(() => {
        if (props.listMaterieSelezionate !== undefined) {
            let listAlunniMaterieTmp = props.listMaterieSelezionate;
            let materieSelezionateID = [];
            for (let materia of listAlunniMaterieTmp) {
                materieSelezionateID.push(materia?.idMateria)
            }
            setListMaterieSelezionate([...listAlunniMaterieTmp])
            setIdMaterieSelezionate([...materieSelezionateID]);
        }
    }, [props.listMaterieSelezionate])



    function selectMateria(e, materia) {
        let materieSelezionateTmp = listMaterieSelezionate;
        let idMaterieSelezionateTmp = idMaterieSelezionate;
        if (materieSelezionateTmp.some(materiaTmp => materiaTmp.idMateria === materia?.idMateria)) {
            let index = materieSelezionateTmp.findIndex((materiaTmp) => materiaTmp.idMateria === materia?.idMateria);
            materieSelezionateTmp.splice(index, 1);
            idMaterieSelezionateTmp.splice(index, 1);
        } else {
            materieSelezionateTmp.push(materia)
            idMaterieSelezionateTmp.push(materia?.idMateria)
        }


        setListMaterieSelezionate([...materieSelezionateTmp])
        setIdMaterieSelezionate([...idMaterieSelezionateTmp])

        if (props.selectMaterie)
            props.selectMaterie(materieSelezionateTmp);
    }



    return (
        <Box sx={{ p: 2, border: '1px solid rgba(200, 200, 200, 1)', mt: 2, height: '50vh' }}>
            {listMaterie.length > 0 ? (
                <Fragment>
                    <Typography mb={1.4} mt={1}>MATERIE</Typography>
                    <Divider/>
                    <Box height={'46vh'} overflow={"scroll"}>
                        {listMaterie.map((materia) => (
                            <Box key={materia?.idMateria} display={"flex"} alignItems={"center"}>
                                <Checkbox checked={idMaterieSelezionate.includes(materia?.idMateria)}
                                    onChange={(e) => { selectMateria(e, materia) }} sx={{ mr: 1 }} />
                                <Typography>{materia.nomeMateria}</Typography>
                            </Box>
                        ))}
                    </Box>
                </Fragment>
            ) : (
                <Box sx={{ height: '20vh', display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <ErrorOutline sx={{ fontSize: '2.5rem' }} color="error" />
                        <Typography color="error" variant="h6" sx={{ mt: 1 }}>Nessuna materia selezionabile</Typography>
                    </Box>
                </Box>
            )}
        </Box>
    )
}
