import { Alert, Box, Button, Card, CircularProgress, Collapse, Divider, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Snackbar, SnackbarContent, Switch, Table, TableBody, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { TirociniAPI } from "../../../../api/tirociniApi";
import DialogConferma from "../../../shared/dialogConferma";
import { ArrowBack, ArrowForward, Check, Close, Edit, ExpandLess, ExpandMore } from "@mui/icons-material";
import CustomDatePicker from "../../../shared/customDatePicker";
import { getAnnoFormativo } from "../../../shared/utilityMethods";
import { DateTime } from "luxon";

export default function GestioneOrario(props) {

    const [idProgetto, setIdProgetto] = useState(-1)
    const [annoFormativo, setAnnoFormativo] = useState("20242025");

    const [dataInizioCorso, setDataInizioCorso] = useState(null)
    const [dataInizio, setDataInizio] = useState(null)
    const [dataFineCorso, setDataFineCorso] = useState(null)
    const [dataFine, setDataFine] = useState(null)



    const [isCanEdit, setIsCanEdit] = useState(true);
    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);
    const [buttonConfermaDisabled, setButtonConfermaDisabled] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState('');

    const [oreTotali, setOreTotali] = useState(0);
    const [corso, setCorso] = useState('');

    const [nomeAlunno, setNomeAlunno] = useState('');
    const [idAlunno, setIdAlunno] = useState(-1);

    const [isModifica, setIsModifica] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (props.isModifica !== undefined)
            setIsModifica(props.isModifica);
    }, [props.isModifica])

    useEffect(() => {
        const annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
    }, [])

    useEffect(() => {
        if (props.corso !== undefined && props.idCorso !== undefined && props.aziendaSimulata !== undefined
            && props.nomeAlunno !== undefined && props.idAlunno !== undefined) {
            if (props.aziendaSimulata !== null) {
                let aziendaSimulataTmp = props.aziendaSimulata;
                setIdProgetto(aziendaSimulataTmp.idProgetto)
                setDataInizio(aziendaSimulataTmp.dataInizio);
                setDataFine(aziendaSimulataTmp.dataFine);
                setOreTotali(aziendaSimulataTmp.oreTotali);
                let corsoTmp = {
                    idCorso: aziendaSimulataTmp.idCorso,
                    nomeCorso: aziendaSimulataTmp.corso
                }
                setCorso(corsoTmp);
                setNomeAlunno(aziendaSimulataTmp.nomeAlunno);
                setIdAlunno(aziendaSimulataTmp.idAlunno)
                setTimeout(() => {
                    setIsLoading(false);
                }, 200)
            }
            else {
                getPrecompilazioneTirocini(props.idCorso);
                setNomeAlunno(props.nomeAlunno);
                setIdAlunno(props.idAlunno);
                if (props.corso !== null) {
                    setCorso(props.corso);
                }
            }
        }
    }, [props.aziendaSimulata, props.corso, props.idCorso, props.nomeAlunno, props.idAlunno])


    function previousTab() {
        if (props.previousTab) {
            props.previousTab();
        }
    }

    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog()
    }

    function changeDataInizio(e) {
        let utcDateTimeTmp = e !== null ? e.toUTC().toISO() : null;
        setDataInizio(utcDateTimeTmp);
    }

    function changeDataFine(e) {
        let utcDateTimeTmp = e !== null ? e.toUTC().toISO() : null;
        setDataFine(utcDateTimeTmp);
    }

    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg)
        setAlertSeverity(severity)
        setAlertOpen(true)
    }

    function validateOrario() {
        if (oreTotali && dataInizio && dataFine)
            return false
        else return true
    }

    async function getPrecompilazioneTirocini(idCorso) {
        let obj = {
            id_corso: idCorso, //il campo del body della richiesta è id_corso
        }
        TirociniAPI.getPrecompilazioneTirocini(obj)
            .then((result) => {
                setDataInizioCorso(result.tirocinio_data_inizio);
                setDataFineCorso(result.tirocinio_data_fine);
                setDataInizio(result.tirocinio_data_inizio);
                setDataFine(result.tirocinio_data_fine);
                setOreTotali(result.oreTirCorso);

                setTimeout(() => {
                    setIsLoading(false);
                }, 200)
            })
            .catch(function (error) {
                //TODO: Gestire
                setTimeout(() => {
                    setIsLoading(false);
                }, 200)
            })

    }

    function changeOreTotali(e) {
        let value = e.target.value.replace(/[^0-9]/g, "");

        if (value.length > 1 && value.startsWith("0")) {
            value = value.slice(1);
        }

        setOreTotali(value);
    }
    async function saveAlunnoAziendaSimulata() {
        let obj = {
            idAlunno: idAlunno,
            annoFormativo: annoFormativo,
            dataInizio: dataInizio,
            dataFine: dataFine,
            oreTotali: oreTotali,
            idProgetto: idProgetto !== -1 ? idProgetto : null
        }
        TirociniAPI.saveAlunnoAziendaSimulata(obj)
            .then((result) => {
                setConfirmAlertIsOpen(false);
                let msgTmp = result.msg ?? 'Salvataggio effettuato con successo.';
                openAlertMsg(msgTmp, 'success');
                setButtonConfermaDisabled(false);
                setConfirmAlertIsOpen(false)
                closeDialog()
            })
            .catch(function (error) {
                setConfirmAlertIsOpen(false);

                if (error?.response !== undefined) {
                    let errorMsg = "Errore durante il salvataggio. Riprovare tra qualche istante.";
                    if (error.response.data && Array.isArray(error.response.data.data) && error.response.data.data.length > 0) {
                        errorMsg = error.response.data.data[0]?.msg ?? errorMsg;
                    }
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante il salvataggio. Riprovare tra qualche istante.", "error");
                }
                setButtonConfermaDisabled(false);
            })
    }

    return (
        <Fragment>
            {!isLoading ? (
                <Box>
                    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', maxHeight: { xs: '50vh', md: '60vh' }, overflowY: 'scroll', overflowX: 'hidden' }}>
                        <Paper elevation={1} sx={{ p: 2, mt: 0 }}>
                            <Typography mb={2}>Corso: <b>{corso?.nomeCorso}</b></Typography>
                            <Typography mb={2}>Studente: <b>{nomeAlunno}</b></Typography>
                            <Grid container spacing={2} alignItems={"center"}>
                                <Grid xs={12} md={4}>
                                    <CustomDatePicker selectedValue={dataInizio}
                                        isDisabled={!isModifica}
                                        minDate={dataInizioCorso}
                                        maxDate={dataFine}
                                        label={"Giorno di inizio *"} isFullWidth={true}
                                        onChangeValue={changeDataInizio} />
                                </Grid>
                                <Grid xs={12} md={4}>
                                    <CustomDatePicker selectedValue={dataFine}
                                        isDisabled={!isModifica}
                                        minDate={dataInizio}
                                        maxDate={dataFineCorso}
                                        label={"Giorno di fine *"} isFullWidth={true}
                                        onChangeValue={changeDataFine} />
                                </Grid>
                                <Divider sx={{ my: 1 }} />
                                <Grid xs={12} md={4}>

                                    <TextField
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        value={oreTotali}
                                        label="Ore totali"
                                        required
                                        disabled={!isModifica}
                                        onChange={changeOreTotali}
                                        inputProps={{ inputMode: "numeric", maxLength: 5 }}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                    <Divider />
                    {isModifica ? (
                        <Box sx={{ px: { xs: 0, md: 2 }, py: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'end' } }}>
                            <Button startIcon={idProgetto === -1 ? null : <ArrowBack />} sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }}
                                onClick={closeDialog}
                                variant="outlined" fullWidth color={'error'} >{idProgetto === -1 ? 'Chiudi procedura di inserimento' : 'Torna a gestione azienda simulata'}</Button>
                            {idProgetto === -1 && (<Button startIcon={<ArrowBack />}
                                sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }}
                                onClick={previousTab}
                                variant="contained" fullWidth color={'secondary'} > torna indietro</Button>)}
                            {isCanEdit && (<Button endIcon={<ArrowForward />}
                                sx={{ width: { xs: 'unset', md: 'fit-content' }, mx: { xs: 4, md: 0 } }}
                                onClick={() => setConfirmAlertIsOpen(true)}
                                disabled={validateOrario()}
                                variant="contained" fullWidth color={idProgetto === -1 ? 'success' : 'warning'} >{idProgetto === -1 ? 'Conferma e chiudi' : 'Conferma e modifica'}</Button>)}
                        </Box>
                    )
                        :
                        (
                            <Box sx={{ px: { xs: 0, md: 2 }, py: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'end' } }}>
                                <Button startIcon={<ArrowBack />} sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }}
                                    onClick={closeDialog}
                                    variant="outlined" fullWidth color={'error'} >Torna a gestione azienda simulata</Button>
                            </Box>
                        )
                    }
                </Box>) : (
                <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"70vh"}>
                    <CircularProgress />
                    <Typography sx={{ mt: 2 }}>Caricamento in corso...</Typography>
                </Box>
            )}
            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={idProgetto === -1 ? 'Conferma assegnazione' : 'Conferma modifica'}
                icon={idProgetto === -1 ? <Check sx={{ fontSize: '5rem', my: 2 }} color="success" /> : <Edit sx={{ fontSize: '5rem', my: 2 }} color="warning" />}
                text={idProgetto === -1 ? `Confermi di voler assegnare lo studente ${nomeAlunno} ad azienda simulata? ` : `Confermi di voler modificare assegnazione dello studente ${nomeAlunno} ad azienda simulata? `}
                showAlunni={false}
                confermaButton={idProgetto === -1 ? 'Conferma' : 'Conferma e modifica'}
                severity={idProgetto === -1 ? 'success' : 'warning'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={saveAlunnoAziendaSimulata}
                disabledButton={buttonConfermaDisabled}
            />
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={10000} onClose={() => { setAlertOpen(false) }} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%', zIndex: '3000!important' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment >
    )
}