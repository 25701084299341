import { RicercaAPI } from "../../../api/ricercaApi";
import Breadcrumb from "../../registri/shared/breadcrumb";
import { getAnnoFormativo } from "../../shared/utilityMethods";
import { Fragment, useState, useEffect } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Paper, Button, TextField, Collapse, Container, Typography, Dialog, FormControl, InputLabel, Select, MenuItem, Box, Snackbar, Alert, Divider, Chip, CircularProgress, DialogTitle, IconButton, DialogContent, DialogActions } from '@mui/material';
import { Add, Clear, Close, Delete, KeyboardArrowDown, KeyboardArrowUp, Search } from "@mui/icons-material";
import { TirociniAPI } from "../../../api/tirociniApi";
import LegendaAziendaSimulata from "../gestione-azienda-simulata/legendaAziendaSimulata";
import TableGiorniSospensione from "./tableGiorniSospensione";
import DettaglioGiornataSospensione from "./dettaglioGiornataSospensione";
import DialogConferma from "../../shared/dialogConferma";




export default function GiorniSospensione() {

    const [annoFormativo, setAnnoFormativo] = useState("");
    const [searchStarted, setSearchStarted] = useState(false);
    const [buttonConfermaDisabled, setButtonConfermaDisabled] = useState(false);

    const [filtriRicercaNotCollapsed, setFiltriRicercaNotCollapsed] = useState(true);
    const [sedeSelected, setSedeSelected] = useState(-1);
    const [corsiList, setCorsiList] = useState([]);
    const [sediList, setSediList] = useState([]);
    const [corsoSelected, setCorsoSelected] = useState(-1);
    const [corsoSelectedName, setCorsoSelectedName] = useState("");
    const [giornataDaModificare, setGiornataDaModificare] = useState(null);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [listGiorni, setListGiorni] = useState([]);
    const [dialogLegendaOpen, setDialogLegendaOpen] = useState(false);
    const [isModifica, setIsModifica] = useState(false);
    const [isDialogDettaglioOpen, setIsDialogDettaglioOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);
    const [isAlertConfermaDeleteOpen, setIsAlertConfermaDeleteOpen] = useState(false);
    useEffect(() => {
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
        getSedi(annoFormativoTmp);
    }, [])

    function changeSede(e) {
        if (e.target.value !== '') {
            setSedeSelected(e.target.value);
            getClassiBySede(e.target.value, annoFormativo);
        }
        else {
            setSedeSelected(-1);
        }
    };
    function changeCorso(e) {
        console.log(e)
        if (e.target.value !== '') {
            const selectedCorso = corsiList.find(corso => corso.id === e.target.value) || { id: -1, nomeCorso: '' };
            setCorsoSelected(selectedCorso.id);
            setCorsoSelectedName(selectedCorso.nomeCorso);
        }
        else {
            setCorsoSelected(-1);
            setCorsoSelectedName("");
        }
    };

    function removeFilters() {
        setFiltriRicercaNotCollapsed(true); //Se rimuovo i filtri apro il collapse
        if (sediList.length > 1) setSedeSelected(-1);
        setCorsoSelected(-1);

        setSearchStarted(false);
        // setSearchLoaded(false);

        setTimeout(() => {
            setListGiorni([]);
            setHasLoaded(false)
        }, 200)
    }

    function validate() {
    }
    function openVisualizzaDettaglioGiornata(giornata, isModifica) {
        setIsModifica(isModifica);
        setGiornataDaModificare(giornata)
        setIsDialogDettaglioOpen(true)

    }
    function deleteItem(giornata) {
        setGiornataDaModificare(giornata);
        setIsAlertConfermaDeleteOpen(true);
    }
    function openLegendaDialog() {
        setDialogLegendaOpen(prev => !prev);
    }

    function closeDialogDettaglio() {
        setIsDialogDettaglioOpen(prev => !prev);
    }

    function giornataAggiunta() {
        avviaRicerca(annoFormativo)
        openAlertMsg('Salvataggio avvenuto con successo', 'success')
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg)
        setAlertSeverity(severity)
        setAlertOpen(true)
    }

    function openDettaglioNuovo() {
        setGiornataDaModificare(null);
        setIsDialogDettaglioOpen(true);
        setIsModifica(true);
    }

    async function getSedi(annoFormativo) {
        RicercaAPI.getSedi({ annoFormativo: annoFormativo })
            .then((result) => {
                let sediTmp = JSON.parse(JSON.stringify(result.sedi)); // This creates a deep copy of the array                
                let indexSedeLegale = sediTmp.findIndex(sede => sede?.id === 1);
                if (indexSedeLegale !== -1) {
                    sediTmp[indexSedeLegale].nomeSede = "Tutte le sedi"
                }
                setSediList([...result.sedi])

                if (result.sedi.length === 1) {
                    let idSedeTmp = result?.sedi[0]?.id
                    setSedeSelected(idSedeTmp ?? -1)
                    getClassiBySede(idSedeTmp, annoFormativo)
                }
            })
            .catch(function (error) {
            })
    }
    async function getClassiBySede(idSede, annoFormativo) {
        let dataObj = {
            idSede: idSede,
            annoFormativo: annoFormativo
        }

        RicercaAPI.getClassiBySede(dataObj)
            .then((result) => {
                setCorsiList(result.sedi ?? []);
            })
            .catch(function (error) {
            })
    }
    async function avviaRicerca(annoFormativoTmp) {
        setFiltriRicercaNotCollapsed(false);
        setButtonConfermaDisabled(true);
        let obj = {
            annoFormativo: annoFormativoTmp === undefined ? annoFormativo : annoFormativoTmp,
            idCorso: corsoSelected !== -1 ? corsoSelected : null,
        }



        TirociniAPI.getGiorniSospensioneTirocinio(obj)
            .then((result) => {
                setListGiorni([...result.giorni])
                setSearchStarted(true);

                setTimeout(() => {
                    setHasLoaded(true);
                }, 200)
                setButtonConfermaDisabled(false);
            })
            .catch(function (error) {
                setTimeout(() => {
                    setHasLoaded(true);
                }, 200)
                setListGiorni([]);
                setButtonConfermaDisabled(false);
            })

    }
    async function deleteGiornata() {
        setButtonConfermaDisabled(true);
        let obj = {
            annoFormativo: annoFormativo,
            idCorso: corsoSelected,
            ordinale: giornataDaModificare.ordinale
        }
        TirociniAPI.deleteGiorniSospensioneTirocinio(obj)
            .then((result) => {

                let msgTmp = result.msg ?? "Eliminazione avvenuta con successo.";
                openAlertMsg(msgTmp, 'success');
                setIsAlertConfermaDeleteOpen(false);
                avviaRicerca(annoFormativo);
                setButtonConfermaDisabled(false);
            })
            .catch(function (error) {
                if (error?.response !== undefined) {
                    let errorMsg = "Errore, riprovare tra qualche istante.";
                    if (error.response.data && Array.isArray(error.response.data.data) && error.response.data.data.length > 0) {
                        errorMsg = error.response.data.data[0]?.msg ?? errorMsg;
                    }
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore, riprovare tra qualche istante.", "error");
                }
                setButtonConfermaDisabled(false);
            })
    }

    return (
        <>
            <Breadcrumb sections={["Giorni di sospensione"]} isTirocinio={true} />

            <Container maxWidth="xl" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <Paper sx={{ width: '100%', mt: 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", p: 2 }}>
                        <Typography variant="h6">Seleziona corso</Typography>
                        <Chip icon={filtriRicercaNotCollapsed ? <KeyboardArrowDown /> : <KeyboardArrowUp />} label={filtriRicercaNotCollapsed ? "Riduci filtri" : "Espandi filtri"} variant="outlined" onClick={() => setFiltriRicercaNotCollapsed(prev => !prev)} />
                    </Box>
                    <Divider />
                    <Collapse in={filtriRicercaNotCollapsed} sx={{ p: filtriRicercaNotCollapsed ? 2 : 0 }}>
                        <form onSubmit={validate} style={{ width: '100%' }}>
                            <Grid container spacing={2} sx={{ width: '100%', pt: 2 }} alignItems={"start"}>
                                <Grid xs={12} md={4}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="sede-label">
                                            Sede
                                        </InputLabel>
                                        <Select
                                            data-testid='sedeRicercaSelect'
                                            label="Sede"
                                            labelId="sede-label"
                                            value={sedeSelected}
                                            onChange={changeSede}
                                            disabled={sediList.length <= 1}
                                        >
                                            <MenuItem
                                                key={-1}
                                                value={-1}
                                            >
                                                ...
                                            </MenuItem>
                                            {sediList.map((sede) => (
                                                <MenuItem
                                                    key={sede.id}
                                                    value={sede.id}
                                                >
                                                    {sede.nomeSede}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={8}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="corso-label">
                                            Corso
                                        </InputLabel>
                                        <Select
                                            label="Corso"
                                            labelId="corso-label"
                                            value={corsoSelected}
                                            onChange={changeCorso}
                                            disabled={!corsiList.length > 0 || sedeSelected === -1}
                                        >
                                            <MenuItem
                                                key={-1}
                                                value={-1}
                                            >
                                                ...
                                            </MenuItem>
                                            {corsiList.map((corso) => (
                                                <MenuItem
                                                    key={corso.id}
                                                    value={corso.id}
                                                >
                                                    {corso?.codiceCorso ?? ''} - {corso.nomeCorso}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </form>
                    </Collapse>
                    {filtriRicercaNotCollapsed && (<Divider />)}
                    <Box sx={{ p: 2, display: "flex", alignItems: "start", flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'end', lg: "space-between" } }}>
                        <Box sx={{ width: { xs: '100%', lg: 'fit-content' } }}>

                            {searchStarted ? (
                                <Button sx={{ height: 'fit-content', mt: { xs: 1, lg: 0 }, px: { xs: 0, lg: 5 }, width: { xs: '100%', lg: 'fit-content' } }} onClick={removeFilters} variant="outlined" color="error" endIcon={<Clear />}>
                                    Rimuovi filtri
                                </Button>
                            ) : (
                                <span></span>
                            )}
                        </Box>
                        <Box sx={{ width: { xs: '0px', md: '15px' } }}></Box>
                        <Box sx={{ width: { xs: '100%', lg: 'fit-content' } }}>
                            <Button sx={{ height: 'fit-content', ml: { xs: 0, lg: 1 }, px: { xs: 0, lg: 5 }, mt: { xs: 1, lg: 0 }, width: { xs: '100%', lg: 'fit-content' } }} onClick={() => avviaRicerca(annoFormativo)} endIcon={<Search />} disabled={corsoSelected === -1} variant="contained">
                                Avvia ricerca
                            </Button>
                            <Button
                                sx={{ height: 'fit-content', ml: { xs: 0, lg: 1 }, px: { xs: 0, lg: 5 }, mt: { xs: 1, lg: 0 }, width: { xs: '100%', lg: 'fit-content' } }}
                                onClick={openDettaglioNuovo}
                                endIcon={<Add />}
                                color="success"
                                variant="contained"
                                disabled={corsoSelected === -1}
                            >
                                Inserisci giornata di sospensione
                            </Button>
                        </Box>
                    </Box>
                </Paper>
                <Collapse in={searchStarted} sx={{ my: 2, width: '100%' }}>
                    <Paper sx={{ overflowY: 'auto', maxHeight: '60vh' }}>
                        {hasLoaded ? (
                            listGiorni.length > 0 ?
                                (
                                    <Fragment>
                                        <TableGiorniSospensione
                                            list={listGiorni}
                                            visualizzaItem={openVisualizzaDettaglioGiornata}
                                            openLegendaDialog={openLegendaDialog}
                                            deleteItem={deleteItem}
                                        />
                                    </Fragment>
                                )
                                :
                                (
                                    <Box sx={{ height: '60vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Alert sx={{ mb: 2 }} severity="info">La ricerca non ha prodotto alcun risultato.</Alert>
                                    </Box>
                                )

                        ) : (
                            <Box sx={{ height: '65vh' }} flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                <CircularProgress sx={{ mb: 4 }} />
                                <Typography >Caricamento in corso...</Typography>
                            </Box>
                        )}
                    </Paper>
                </Collapse>
            </Container>

            <Dialog fullWidth maxWidth={'sm'} open={dialogLegendaOpen} sx={{ mt: '8vh' }} onClose={openLegendaDialog}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>Legenda</Typography>
                    <IconButton onClick={openLegendaDialog}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <LegendaAziendaSimulata />
                </DialogContent>
            </Dialog>

            <Dialog fullWidth maxWidth={'sm'} open={isDialogDettaglioOpen} sx={{ mt: '8vh' }}>
                <DettaglioGiornataSospensione
                    giornataDaModificare={giornataDaModificare}
                    closeDialog={closeDialogDettaglio}
                    nomeCorso={corsoSelectedName}
                    idCorso={corsoSelected}
                    idSede={sedeSelected}
                    annoFormativo={annoFormativo}
                    giornataAggiunta={giornataAggiunta}
                    isModifica={isModifica}
                />
            </Dialog>

            <DialogConferma
                isDialogOpen={isAlertConfermaDeleteOpen}
                title={'Conferma eliminazione'}
                icon={<Delete sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                text={`Confermi di voler la giornata di sospensione? `}
                showAlunni={false}
                confermaButton={'Elimina'}
                severity={'error'}
                closeDialog={() => setIsAlertConfermaDeleteOpen(false)}
                conferma={deleteGiornata}//{modificaStatoProgetto}
                disabledButton={buttonConfermaDisabled}
            />
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={10000} onClose={() => { setAlertOpen(false) }} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%', zIndex: '3000!important' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </>
    )
}