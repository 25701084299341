import { Fragment, useEffect, useState } from "react";
import { AccertamentoPrerequisitiAPI } from "../../api/accertamentoPrerequisiti"
import { styled, Typography, Paper, IconButton, Dialog, DialogTitle, DialogContent, Divider, Snackbar, Alert, Box, } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Add, Close, Delete, Edit, Lens, Search } from "@mui/icons-material";
import NewAggiuntaExAnte from "../dettaglio-utente/sub-compontents/exAnte/newAggiuntaExAnte";
import DialogConferma from "../shared/dialogConferma";
import { getAnnoFormativo } from "../shared/utilityMethods";
import { convertToItalianDateTimeStringaParlante, convertToItalianTime } from "../shared/metodiDateUtility";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function DettaglioAccertamentoPrerequisiti(props) {

    const [listMaterie, setListMaterie] = useState([]);
    const [isAggiuntaVotoDialogOpen, setIsAggiuntaVotoDialogOpen] = useState(false);

    const [dataSelezionata, setDataSelezionata] = useState(null);
    const [votoSelezionato, setVotoSelezionato] = useState("");
    const [materiaSelezionata, setMateriaSelezionata] = useState("");
    const [idMateriaSelezionata, setIdMateriaSelezionata] = useState(-1);
    const [idAccertamento, setIdAccertamento] = useState(-1)
    const [idVoto, setIdVoto] = useState(-1);
    const [alertIsOpen, setAlertIsOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState("");

    const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = useState(false);
    const [idAccertamentoElimina, setIdAccertamentoElimina] = useState(-1)
    const [isAnnoFormativoCorrente, setIsAnnoFormativoCorrente] = useState(false);
const [objVotoSelezionato, setObjVotoSelezionato] = useState(undefined);

    useEffect(() => {
        const annoFormativoTmp = getAnnoFormativo(true);
        const annoFormativoObj = JSON.parse(annoFormativoTmp);
        setIsAnnoFormativoCorrente(annoFormativoObj?.isAnnoFormativoCorrente ?? true)
    }, [])

    useEffect(() => {
        if (props.idAlunno && props.idCorso && props.idSede && props.annoFormativo)
            getDettaglio();
    }, [props.idCorso, props.annoFormativo, props.idAlunno, props.idSede]);

    function votoAggiunto() {
        getDettaglio();
        closeDialogAggiuntaVoto();

        setAlertSeverity("success");
        setAlertMsg("Accertamento prerequisito aggiunto");
        setAlertIsOpen(true);

    }

    function exAnteModificato() {
        getDettaglio();
        closeDialogAggiuntaVoto();

        setAlertSeverity("success");
        setAlertMsg("Accertamento prerequisito modificato");
        setAlertIsOpen(true);
    }


    function exAnteEliminato() {
        getDettaglio();
        closeDialogAggiuntaVoto();

        setAlertSeverity("info");
        setAlertMsg("Accertamento prerequisito eliminato");
        setAlertIsOpen(true);
    }

    function closeDialogAggiuntaVoto() {
        setIsAggiuntaVotoDialogOpen(prev => !prev);
    }

    function openDialogAggiungiVoto(nomeMateria, idMateria, idAccertamento, dataLezione, voto, idVoto,objVoto) {
        setIsAggiuntaVotoDialogOpen(prev => !prev);
        setMateriaSelezionata(nomeMateria);
        setIdMateriaSelezionata(idMateria);
        setIdAccertamento(idAccertamento);
        setDataSelezionata(dataLezione);
        setVotoSelezionato(voto);
        setIdVoto(idVoto);
        setObjVotoSelezionato(objVoto)
    }
    function closeAlert() {
        setAlertIsOpen(false)
    }
    function openDeleteAccertamentoDialog(id) {
        setIdAccertamentoElimina(id);
        setIsDeleteConfirmDialogOpen(true);
    }
    async function deleteAccertamento() {
        AccertamentoPrerequisitiAPI.eliminaPrerequisito(idAccertamentoElimina)
            .then((result) => {
                setIsDeleteConfirmDialogOpen(false);
                setAlertSeverity("success");
                setAlertMsg("Accertamento eliminato");
                setAlertIsOpen(true);
                getDettaglio();
            })
            .catch(function (error) {
                setIsDeleteConfirmDialogOpen(false);
                if (error.response) {
                    let errorMsgTmp = error?.response?.data?.data[0]?.msg ?? "Errore durante l'eliminazione dell'accertamento";
                    setAlertSeverity("error");
                    setAlertMsg(errorMsgTmp);
                    setAlertIsOpen(true);
                }
            })
    }

    async function getDettaglio() {
        const dataObj = {
            idCorso: props.idCorso,
            annoFormativo: props.annoFormativo,
            idAlunno: props.idAlunno,
            idSede: props.idSede
        }

        AccertamentoPrerequisitiAPI.getDettaglio(dataObj)
            .then((result) => {
                setListMaterie([...result.materie]);
            })
            .catch(function (error) {
                if (error.response) {
                }
            })
    }
    return (
        <Fragment>
            <Typography><b>Alunno:</b> {props.nomeAlunno ?? "-"}</Typography>
            <Typography><b>Corso:</b> {props?.corso ?? "-"}</Typography>
            <Typography><b>Tipologia:</b> {props?.tipologiaAP ?? "-"}</Typography>


            <Paper sx={{ p: 0, mt: 2, overflowY: 'auto', height: { xs: '40vh', md: '50vh' } }}>
                {listMaterie.length > 0 ?
                    (
                        <TableContainer>
                            <Table >
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: '#1976d2' }}>
                                        <TableCell sx={{ color: 'white' }} >Materia</TableCell>
                                        <TableCell sx={{ color: 'white' }} >Voto </TableCell>
                                        <TableCell sx={{ color: 'white' }} >Dettagli voto</TableCell>
                                        <TableCell sx={{ color: 'white' }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {listMaterie.map((materia, index) => (
                                        <StyledTableRow
                                            key={materia?.idMateria ?? index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {materia.nomeMateria}
                                            </TableCell>
                                            <TableCell>
                                                {materia?.voto ? (materia?.voto > 0 ? materia?.voto : 'N.V.') : "Non assegnato"}
                                            </TableCell>
                                            <TableCell>
                                                {materia?.contenuto ? (
                                                    <>
                                                        <span>  Voto assegnato da: <b>{materia?.contenuto?.docente?.nomeDocente} {materia?.contenuto?.docente?.cognomeDocente}</b> il {convertToItalianTime(materia?.createdAt)}</span>
                                                        {materia?.modifiedAt && (
                                                            <span><br />  Voto modificato da: <b>{materia?.modifiedBy?.nome}</b> il {convertToItalianTime(materia?.modifiedAt)}</span>
                                                        )}
                                                    </>

                                                ) : (
                                                    <span>-</span>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => openDialogAggiungiVoto(materia?.nomeMateria, materia?.idMateria, materia.idAccertamento, materia.contenuto?.dataLezione, materia.contenuto?.voto, materia.idVoto,materia)} >
                                                    {materia.voto === null ? <Add /> : <Edit />}
                                                </IconButton>
                                                {materia.showDelete && (
                                                    <IconButton onClick={() => openDeleteAccertamentoDialog(materia?.idAccertamento ?? -1)}>
                                                        <Delete />
                                                    </IconButton>
                                                )}

                                            </TableCell>
                                        </StyledTableRow >
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                    : (
                        <Box sx={{ height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Alert severity="info">
                                Nessun dato disponibile
                            </Alert>
                        </Box>
                    )
                }
            </Paper>


            <Dialog open={isAggiuntaVotoDialogOpen} fullWidth maxWidth="md" sx={{ mt: '8vh' }}>
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {idVoto === null ? "Aggiungi accertamento prerequisito" : "Modifica accertamento prerequisito"}
                    </Typography>
                    <IconButton onClick={closeDialogAggiuntaVoto} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <NewAggiuntaExAnte idAccertamento={idAccertamento} idAlunno={props.idAlunno} annoFormativo={props.annoFormativo} idMateria={idMateriaSelezionata} idVoto={idVoto}
                        materiaSeleziona={materiaSelezionata} tipologiaSelezionata={props.tipologiaAP} dataSelezionata={dataSelezionata} votoSelezionato={votoSelezionato}
                        objVotoSelezionato={objVotoSelezionato}
                       closeDialog={closeDialogAggiuntaVoto} votoAggiunto={votoAggiunto} exAnteModificato={exAnteModificato} exAnteEliminato={exAnteEliminato} />
                </DialogContent>
            </Dialog>

            <DialogConferma
                isDialogOpen={isDeleteConfirmDialogOpen}
                title={'Elimina accertamento'}
                icon={<Delete sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                text={`Vuoi davvero eliminare questo accertamento prerequisito?`}
                showAlunni={false}
                confermaButton={'Conferma eliminazione'}
                severity={'error'}
                closeDialog={() => { setIsDeleteConfirmDialogOpen(false) }}
                conferma={deleteAccertamento}
                disabledButton={false}
            />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertIsOpen} autoHideDuration={10000} onClose={closeAlert}>
                <Alert onClose={closeAlert} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}