import { Fragment, useEffect, useState } from "react";
import CustomDatePicker from "../../shared/customDatePicker";
import { Alert, Box, Button, Card, CircularProgress, Collapse, Divider, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Snackbar, SnackbarContent, Switch, Table, TableBody, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { ArrowBack, ArrowForward, Check, Close, Edit, ExpandLess, ExpandMore } from "@mui/icons-material";

import DialogConferma from "../../shared/dialogConferma";
import { TirociniAPI } from "../../../api/tirociniApi";

export default function DettaglioGiornataSospensione(props) {

    const [dataGiornata, setDataGiornata] = useState(null);
    const [buttonConfermaDisabled, setButtonConfermaDisabled] = useState(false);
    const [isModifica, setIsModifica] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState('');

    useEffect(() => {
        if (props.giornataDaModificare) {
            setDataGiornata(props.giornataDaModificare.data)
        }
    }, [props.giornataDaModificare])

    function changeDataGiornata(e) {
        let utcDateTimeTmp = e !== null ? e.toUTC().toISO() : null;
        setDataGiornata(utcDateTimeTmp);
    }

    function closeDialog() {
        if (props.closeDialog) props.closeDialog()
    }
    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }
    function openAlertMsg(msg, severity) {
        setAlertMsg(msg)
        setAlertSeverity(severity)
        setAlertOpen(true)
    }

    async function saveGiornata() {
        let obj = {
            idCorso: props?.idCorso ?? -1,
            annoFormativo: props?.annoFormativo ?? "20242025",
            idSede: props?.idSede ?? -1,
            giorno: dataGiornata,
            ordinale: props?.giornataDaModificare?.ordinale ?? null
        }
        TirociniAPI.saveGiorniSospensioneTirocinio(obj)
            .then((result) => {
                setConfirmAlertIsOpen(false);
                let msgTmp = result.msg ?? 'Salvataggio effettuato con successo.';
                openAlertMsg(msgTmp, 'success');
                setButtonConfermaDisabled(false);
                setConfirmAlertIsOpen(false)
                closeDialog();
                if (props.giornataAggiunta)
                    props.giornataAggiunta();
            })
            .catch(function (error) {
                setConfirmAlertIsOpen(false);

                if (error?.response !== undefined) {
                    let errorMsg = "Errore durante il salvataggio. Riprovare tra qualche istante.";
                    if (error.response.data && Array.isArray(error.response.data.data) && error.response.data.data.length > 0) {
                        errorMsg = error.response.data.data[0]?.msg ?? errorMsg;
                    }
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante il salvataggio. Riprovare tra qualche istante.", "error");
                }
                setButtonConfermaDisabled(false);
            })
    }



    return (
        <Fragment>
            <Typography variant="h6" sx={{p:2}}>
            {!props.giornataDaModificare ? "Aggiungi giornata" : "Modifica giornata"}
            </Typography>
            <Divider/>
            {!isLoading ? (
                <Box>
                    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', maxHeight: { xs: '50vh', md: '60vh' }, overflowY: 'scroll', overflowX: 'hidden' }}>
                        <Typography mb={2}>Corso: <b>{props?.nomeCorso}</b></Typography>
                        <CustomDatePicker selectedValue={dataGiornata}
                            isDisabled={!props.isModifica}
                            label={"Giornata *"} isFullWidth={true}
                            onChangeValue={changeDataGiornata} />
                    </Box>
                    <Divider />
                    {props.isModifica ? (
                        <Box sx={{ px: { xs: 0, md: 2 }, py: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'space-between' } }}>
                            <Button startIcon={<Close />} sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }}
                                onClick={closeDialog}
                                variant="outlined" fullWidth color={'error'} >Chiudi finestra</Button>
                            <Button
                                sx={{ width: { xs: 'unset', md: 'fit-content' }, mx: { xs: 4, md: 0 } }}
                                onClick={() => setConfirmAlertIsOpen(true)}
                                disabled={dataGiornata === null}
                                variant="contained" fullWidth color={!props.giornataDaModificare ? 'success' : 'warning'} >{!props.giornataDaModificare ? 'Conferma e chiudi' : 'Conferma e modifica'}</Button>
                        </Box>
                    )
                        :
                        (
                            <Box sx={{ px: { xs: 0, md: 2 }, py: 2 }}>
                                <Button fullWidth startIcon={<Close />} sx={{  mb: { xs: 1, md: 0 } }}
                                    onClick={closeDialog}
                                    variant="outlined" color={'error'} >Chiudi finestra</Button>
                            </Box>
                        )
                    }
                </Box>) : (
                <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"70vh"}>
                    <CircularProgress />
                    <Typography sx={{ mt: 2 }}>Caricamento in corso...</Typography>
                </Box>
            )}

            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={!props.giornataDaModificare ? 'Conferma aggiunta' : 'Conferma modifica'}
                icon={!props.giornataDaModificare ? <Check sx={{ fontSize: '5rem', my: 2 }} color="success" /> : <Edit sx={{ fontSize: '5rem', my: 2 }} color="warning" />}
                text={!props.giornataDaModificare ? `Confermi di voler aggiungere la giornata di sospensione? ` : `Confermi di voler modificare la giornata di sospensione? `}
                showAlunni={false}
                confermaButton={!props.giornataDaModificare ? 'Conferma' : 'Conferma e modifica'}
                severity={!props.giornataDaModificare ? 'success' : 'warning'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={saveGiornata}
                disabledButton={buttonConfermaDisabled}
            />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={10000} onClose={() => { setAlertOpen(false) }} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%', zIndex: '3000!important' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment >
    )
}