import { ErrorOutline, Warning } from "@mui/icons-material";
import { Box, Checkbox, Divider, Typography } from "@mui/material";
import { useState, useEffect, Fragment } from "react"

export default function ElencoAlunniSelezionabili(props) {

    const [listAlunni, setListAlunni] = useState([]);
    const [listAlunniSelezionati, setListAlunniSelezionati] = useState([]);
    const [idAlunniSelezionati, setIdAlunniSelezionati] = useState([]);
    const [nomeClasse, setNomeClasse] = useState("");
    const [idAlunniNonSelezionabili, setIdAlunniNonSelezionabili] = useState([]);

    const [isTuttaLaClasseChecked, setIsTuttaLaClasseChecked] = useState(false);

    const [alunniSelezionabiliNumber, setAlunniSelezionabiliNumber] = useState(0)
    const [checkAssenze, setCheckAssenze] = useState(false);
    const [canSelezioneMultipla, setSelezionaMultipla] = useState(true);

    useEffect(() => {
        if (props.alunniNonSelezionabili !== undefined && props.alunniSelezionabiliNumber !== undefined) {
            setAlunniSelezionabiliNumber(props.alunniSelezionabiliNumber)

            let listAlunniSelezionatiTmp = props.listAlunniSelezionati;
            let alunniSelezionatiID = [];
            for (let alunno of listAlunniSelezionatiTmp) {
                alunniSelezionatiID.push(alunno?.id)
            }
            setListAlunniSelezionati([...listAlunniSelezionatiTmp])
            setIdAlunniSelezionati([...alunniSelezionatiID]);

            let listAlunniNonSelezionabiliTmp = props.alunniNonSelezionabili;
            let alunniNonSelezionabiliID = [];
            for (let alunno of listAlunniNonSelezionabiliTmp)
                alunniNonSelezionabiliID.push(alunno?.id)
            setIdAlunniNonSelezionabili([...alunniNonSelezionabiliID])


            if (listAlunniSelezionatiTmp.length > 0 && listAlunniSelezionatiTmp.length === props.alunniSelezionabiliNumber) {
                setIsTuttaLaClasseChecked(true);
            }

            if (isTuttaLaClasseChecked) {
                selectAllCorso(alunniNonSelezionabiliID, alunniSelezionatiID, listAlunniSelezionatiTmp, true)
            }

        }
    }, [props.alunniNonSelezionabili, props.alunniSelezionabiliNumber, props.listAlunniSelezionati])

    // useEffect(() => {
    //     if (props.alunniSelezionabiliNumber !== undefined) 
    //         setAlunniSelezionabiliNumber(props.alunniSelezionabiliNumber)
    // }, [props.alunniSelezionabiliNumber])

    useEffect(() => {
        if (props.listAlunni !== undefined)
            setListAlunni([...props.listAlunni])
    }, [props.listAlunni])

    useEffect(() => {
        if (props.listAlunni !== undefined)
            setListAlunni([...props.listAlunni])
    }, [props.listAlunni])

    useEffect(() => {
        if (props.checkAssenze !== undefined)
            setCheckAssenze(props.checkAssenze)
    }, [props.checkAssenze])

    // useEffect(() => {
    //     if (props.listAlunniSelezionati !== undefined) {
    //         let listID = [];
    //         for (let alunno of props.listAlunniSelezionati) {
    //             listID.push(alunno?.id)
    //         }
    //         setListAlunniSelezionati([...props.listAlunniSelezionati])
    //         setIdAlunniSelezionati([...listID]);
    //     }
    // }, [props.listAlunniSelezionati])

    useEffect(() => {
        if (props.canSelezioneMultipla !== undefined)
            setSelezionaMultipla(props.canSelezioneMultipla);
    }, [props.canSelezioneMultipla])

    useEffect(() => {
        if (props.nomeClasse !== undefined)
            setNomeClasse(props.nomeClasse);
    }, [props.nomeClasse])

    function selectAlunno(e, alunno) {
        let alunniSelezionatiTmp = listAlunniSelezionati;
        let idAlunniSelezionatiTmp = idAlunniSelezionati;
        if (canSelezioneMultipla) {
            if (alunniSelezionatiTmp.some(alunnoTmp => alunnoTmp.id === alunno?.id)) {
                let index = alunniSelezionatiTmp.findIndex((alunnoTmp) => alunnoTmp.id === alunno?.id);
                alunniSelezionatiTmp.splice(index, 1);
                idAlunniSelezionatiTmp.splice(index, 1);
            } else {
                alunniSelezionatiTmp.push(alunno)
                idAlunniSelezionatiTmp.push(alunno?.id)
            }

            if (alunniSelezionatiTmp.length === alunniSelezionabiliNumber) {
                setIsTuttaLaClasseChecked(true);
            } else {
                setIsTuttaLaClasseChecked(false);
            }
        } else {

            if (!alunniSelezionatiTmp.some(alunnoTmp => alunnoTmp.id === alunno?.id)) {
                alunniSelezionatiTmp = [];
                idAlunniSelezionatiTmp = [];
                alunniSelezionatiTmp.push(alunno)
                idAlunniSelezionatiTmp.push(alunno?.id)
            } else {
                alunniSelezionatiTmp = [];
                idAlunniSelezionatiTmp = [];
            }
        }
        setListAlunniSelezionati([...alunniSelezionatiTmp])
        setIdAlunniSelezionati([...idAlunniSelezionatiTmp])

        if (props.selectAlunni)
            props.selectAlunni(alunniSelezionatiTmp);
    }

    function selectAllCorso(idAlunniNonSelezionabili, idAlunniSelezionati, listAlunniSelezionati, alwaysAllChecked) {
        let alunniSelezionatiTmp = listAlunniSelezionati;
        let idAlunniSelezionatiTmp = idAlunniSelezionati;
        let listAlunniSelezionabili = [];

        for (let alunno of listAlunni) {
            if (!idAlunniNonSelezionabili.includes(alunno?.id))
                listAlunniSelezionabili.push(alunno)
        }

        if (idAlunniSelezionatiTmp.length === listAlunniSelezionabili.length && !alwaysAllChecked) {
            setIsTuttaLaClasseChecked(false);

            alunniSelezionatiTmp = [];
            idAlunniSelezionatiTmp = [];
        } else {
            setIsTuttaLaClasseChecked(true);

            alunniSelezionatiTmp = [];
            idAlunniSelezionatiTmp = [];
            for (let alunno of listAlunniSelezionabili) {
                alunniSelezionatiTmp.push(alunno)
                idAlunniSelezionatiTmp.push(alunno?.id)
            }
        }

        setListAlunniSelezionati([...alunniSelezionatiTmp]);
        setIdAlunniSelezionati([...idAlunniSelezionatiTmp]);

        if (props.selectAlunni)
            props.selectAlunni(alunniSelezionatiTmp);
    }

    return (
        <Box sx={{ p: 2, border: '1px solid rgba(200, 200, 200, 1)', mt: 2,height:'50vh' }}>
            {listAlunni.length > 0 ? (
                <Fragment>
                    <Box display={"flex"} alignItems={"center"}>
                        {canSelezioneMultipla ? (
                            <Fragment>
                                <Checkbox onChange={() => selectAllCorso(idAlunniNonSelezionabili, idAlunniSelezionati, listAlunniSelezionati, false)} checked={listAlunniSelezionati.length > 0 && listAlunniSelezionati.length === alunniSelezionabiliNumber} indeterminate={listAlunniSelezionati.length > 0 && listAlunniSelezionati.length !== alunniSelezionabiliNumber} sx={{ mr: 1 }} />
                                <Typography color={"primary"}>{nomeClasse !== "" ? nomeClasse.toUpperCase() : "TUTTA LA CLASSE"}  <b>({listAlunniSelezionati.length} su {alunniSelezionabiliNumber})</b></Typography>
                            </Fragment>
                        ) : (
                            <Typography textAlign={"center"} sx={{ width: '100%', mb: 1 }} color={"primary"}>{nomeClasse !== "" ? nomeClasse.toUpperCase() : "TUTTA LA CLASSE"} </Typography>
                        )}
                    </Box>
                    <Divider />

                    <Box height={'45vh'} overflow={"scroll"}>
                        {listAlunni.map((alunno) => (
                            <Box key={alunno?.id} display={"flex"} alignItems={"center"}>
                                <Checkbox checked={(idAlunniSelezionati.includes(alunno?.id) && !idAlunniNonSelezionabili.includes(alunno?.id)) || (checkAssenze === false && idAlunniNonSelezionabili.includes(alunno?.id) && listAlunniSelezionati.length == listAlunni.length)}
                                    disabled={idAlunniNonSelezionabili.includes(alunno?.id)} onChange={(e) => { selectAlunno(e, alunno) }} sx={{ mr: 1 }} />
                                <Typography>{alunno?.cognome} {alunno?.nome}</Typography>
                            </Box>
                        ))}
                    </Box>
                </Fragment>
            ) : (
                <Box sx={{ height: '30vh', display: "flex", alignItems: 'center', justifyContent: 'center' }}>
                        <Box sx={{ display: "flex", flexDirection:'column', alignItems: 'center', justifyContent: 'center' }}>
                        <ErrorOutline sx={{fontSize:'2.5rem'}} color="error" />
                        <Typography color="error" variant="h6" sx={{mt:1}}>Nessun alunno selezionabile</Typography>
                    </Box>
                </Box>
            )}
        </Box>
    )
}
