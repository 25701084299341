import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Snackbar, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { TirociniAPI } from "../../../../api/tirociniApi";
import Grid from '@mui/material/Unstable_Grid2';
import { Add, ArrowBack, Check, Close, Delete, Edit } from "@mui/icons-material";
import DialogConferma from "../../../shared/dialogConferma";
import TableSediPersone from "./tableSediPersone";

export default function SediAzienda(props) {


    const [idAzienda, setIdAzienda] = useState(-1)
    const [listSedi, setListSedi] = useState([])

    const [idSede, setIdSede] = useState(-1)
    const [indirizzoSede, setIndirizzoSede] = useState('');
    const [indirizzoSedeValid, setIndirizzoSedeValid] = useState(true);
    const [capSede, setCapSede] = useState('');
    const [capSedeValid, setCapSedeValid] = useState(true);
    const [capSedeErrorMsg, setCapSedeErrorMsg] = useState('');
    const [cittaSede, setCittaSede] = useState('');
    const [cittaSedeValid, setCittaSedeValid] = useState(true);
    const [mailSede, setMailSede] = useState('');
    const [sitoSede, setSitoSede] = useState('');
    const [telefonoSede, setTelefonoSede] = useState('');
    const [pecSede, setPecSede] = useState('');
    const [faxSede, setFaxSede] = useState('');

    const [dialogAggiungiSedeOpen, setDialogAggiungiSedeOpen] = useState(false);

    const [confirmAlertInsertIsOpen, setConfirmAlertInsertIsOpen] = useState(false);
    const [confirmAlertEditIsOpen, setConfirmAlertEditIsOpen] = useState(false);
    const [confirmAlertDeleteIsOpen, setConfirmAlertDeleteIsOpen] = useState(false);
    const [confirmAlertInsertSedeLegaleIsOpen, setConfirmAlertInsertSedeLegaleIsOpen] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState('');

    const [canBeModified, setCanBeModified] = useState(true);

    const [buttonConfermaDisabled, setButtonConfermaDisabled] = useState(false);
    const [buttonAddSedeLegale, setButtonAddSedeLegale] = useState(false);

    const [hasLoaded, setHasLoaded] = useState(false);

    const [isCanEdit, setIsCanEdit] = useState(true);
    const [dataExist, setDataExist] = useState(false);

    useEffect(() => {
        if (props.dataExist !== undefined)
            setDataExist(props.dataExist);
    }, [props.dataExist])

    useEffect(() => {
        if (props.isCanEdit !== undefined)
            setIsCanEdit(props.isCanEdit)
    }, [props.isCanEdit])

    useEffect(() => {
        if (props.idAzienda !== undefined) {
            setIdAzienda(props.idAzienda);
            ricercaSediAzienda(props.idAzienda)
        }
    }, [props.idAzienda])

    function previousTab() {
        if (props.previousTab) props.previousTab();
    }

    function closeDialogAggiungiAzienda() {
        if (props.closeDialog) props.closeDialog()
    }

    async function ricercaSediAzienda(idAzienda) {
        let obj = {
            "idAzienda": idAzienda,
            "idTipologia": 1 // fisso
        }
        TirociniAPI.ricercaSediAzienda(obj)
            .then((result) => {
                let ListSediTmp = result;
                if (ListSediTmp.length > 0) setButtonAddSedeLegale(false);
                else setButtonAddSedeLegale(true);
                setListSedi([...ListSediTmp])

                setTimeout(() => {
                    setHasLoaded(true);
                }, 200)
            })
            .catch(function (error) {
            })

    }

    async function saveSediAzienda() {
        setButtonConfermaDisabled(true)
        let obj = {
            id: idSede !== -1 ? idSede : null, // per inserimento null , per modifica va inserito l'idSede
            indirizzo: indirizzoSede,
            cap: capSede,
            citta: cittaSede,
            mail: mailSede,
            sito: sitoSede,
            telefono: telefonoSede,
            pec: pecSede,
            fax: faxSede,
            idAzienda: idAzienda,
            idTipologia: 1 // Valore fisso
        }
        TirociniAPI.saveSediAzienda(obj)
            .then((result) => {
                openAlertMsg(result?.msg ?? "Salvataggio effettuato con successo", 'success')
                ricercaSediAzienda(idAzienda);
                resetDataSede();
                closeAlertConfirmInserimento();
                closeAlertConfirmEdit();
                closeDialogAggiungiSede();
                setButtonConfermaDisabled(false)
            })
            .catch(function (error) {
                if (error.response) {
                    let errorMsg = error?.response?.data?.data[0]?.msg ?? "Errore durante il salvataggio. Riprovare tra qualche istante."
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante il salvataggio. Riprovare tra qualche istante.", "error")
                }
                closeAlertConfirmInserimento();
                setButtonConfermaDisabled(false)
            })

    }

    async function deleteSediAzienda() {
        let obj = {
            idSede: idSede
        }
        TirociniAPI.deleteSediAzienda(obj)
            .then((result) => {
                openAlertMsg(result?.msg ?? "Sede eliminata con successo", 'success');
                ricercaSediAzienda(idAzienda);
                closeAlertConfirmDelete();
                setButtonConfermaDisabled(false)
            })
            .catch(function (error) {
                if (error.response) {
                    let errorMsg = error?.response?.data?.data[0]?.msg ?? "Errore durante l'eliminazione della sede. Riprovare tra qualche istante."
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante l'eliminazione della sede. Riprovare tra qualche istante.", "error")
                }
                setButtonConfermaDisabled(false)
            })

    }

    async function legaleAsOperativa() {
        let obj = {
            "idAzienda": idAzienda,
        }
        TirociniAPI.legaleAsOperativa(obj)
            .then((result) => {
                openAlertMsg(result?.msg ?? "Sede operativa impostata con successo.", 'success');
                closeAlertConfirmInserimentoSedeLegale()
                ricercaSediAzienda(idAzienda);
            })
            .catch(function (error) {
                const errorMsg = error?.response?.data?.data[0]?.msg ?? "Errore durante l'impostazione della sede legale.";
                openAlertMsg(errorMsg , 'error');
                closeAlertConfirmInserimentoSedeLegale()
            })
    }

    function openDialogAggiungiSede() {
        setDialogAggiungiSedeOpen(prev => !prev);
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg)
        setAlertSeverity(severity)
        setAlertOpen(true)
    }

    function closeDialogAggiungiSede() {
        setDialogAggiungiSedeOpen(false)
        setTimeout(() => {
            resetDataSede()
        }, 200) //Cosi non abbiamo glitch grafici quando chiudiamo la dialog
    }

    function openDialogConfermaDelete(idSede) {
        setIdSede(idSede);
        setConfirmAlertDeleteIsOpen(true);
    }

    function resetDataSede() {
        setIdSede(-1)
        setIndirizzoSede('');
        setCapSede('');
        setCapSedeValid(true);
        setCapSedeErrorMsg('');
        setCittaSede('');
        setMailSede('');
        setSitoSede('');
        setTelefonoSede('');
        setPecSede('');
        setFaxSede('');
        setCanBeModified(true)
    }

    function visualizzaItem(sede) {
        let sedeTmp = sede;
        setIdSede(sedeTmp?.id)
        setIndirizzoSede(sedeTmp?.indirizzo ?? '');
        setCapSede(sedeTmp?.cap ?? '');
        setCittaSede(sedeTmp?.citta ?? '');
        setMailSede(sedeTmp?.mail ?? '');
        setSitoSede(sedeTmp?.sito ?? '');
        setTelefonoSede(sedeTmp?.telefono ?? '');
        setPecSede(sedeTmp?.pec ?? '');
        setFaxSede(sedeTmp?.fax ?? '');
        setCanBeModified(sedeTmp?.canBeModified ?? true)
        openDialogAggiungiSede()
    }

    function closeAlertConfirmInserimento() {
        setConfirmAlertInsertIsOpen(false)
        resetDataSede()
    }

    function closeAlertConfirmInserimentoSedeLegale() {
        setConfirmAlertInsertSedeLegaleIsOpen(false)
    }

    function closeAlertConfirmDelete() {
        setConfirmAlertDeleteIsOpen(false)
        resetDataSede()
    }

    function closeAlertConfirmEdit() {
        setConfirmAlertEditIsOpen(false)
    }
    function validateFormData() {
        if (
            indirizzoSede !== '' &&
            (capSede !== '' && capSede.length === 5) &&
            cittaSede !== ''
        ) return false
        else return true
    }

    function changeCittaSede(e) {
        let valueTmp = e.target.value;
        setCittaSede(valueTmp)
        if (valueTmp !== '') {
            setCittaSedeValid(true)
        } else {
            setCittaSedeValid(false)
        }
    }

    function changeIndirizzoSede(e) {
        setIndirizzoSede(e.target.value)
        let valueTmp = e.target.value;
        setIndirizzoSede(valueTmp)
        if (valueTmp !== '') {
            setIndirizzoSedeValid(true)
        } else {
            setIndirizzoSedeValid(false)
        }
    }

    function changeCapSede(e) {
        let valueTmp = e.target.value
        let valueNoExtraCharactersTmp = valueTmp.replace(/[^0-9]/g, '');
        setCapSede(valueNoExtraCharactersTmp);
        if (valueNoExtraCharactersTmp.length === 5) {
            setCapSedeValid(true);
            setCapSedeErrorMsg('');
        } else {
            setCapSedeValid(false);
            setCapSedeErrorMsg('Il cap si compone di 5 caratteri.');
        }
    }
    function changeMailSede(e) {
        setMailSede(e.target.value)
    }
    function changePecSede(e) {
        setPecSede(e.target.value)
    }
    function changeSitoSede(e) {
        setSitoSede(e.target.value)
    }

    function changeTelefonoSede(e) {
        setTelefonoSede(e.target.value)
    }
    function changeFaxSede(e) {
        setFaxSede(e.target.value)
    }

    return (
        <Fragment>
            <Box sx={{ px: 3, mt: 2 }}>
                {isCanEdit && (<Grid container spacing={2}>
                    {buttonAddSedeLegale && (<Grid xs={12} md={6}>
                        <Button fullWidth variant="outlined" onClick={() => setConfirmAlertInsertSedeLegaleIsOpen(prev => !prev)} color="success">Aggiungi come sede operativa la sede legale</Button>
                    </Grid>)}
                    <Grid xs={12} md={buttonAddSedeLegale ? 6 : 12}>
                        <Button startIcon={<Add />} fullWidth variant="contained" onClick={() => setDialogAggiungiSedeOpen(prev => !prev)} color="success">
                            Aggiungi nuova sede
                        </Button>
                    </Grid>
                </Grid>)}
                <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', maxHeight: '50vh' }}>
                    {hasLoaded ? (
                        listSedi.length > 0 ?
                            (
                                <TableSediPersone list={listSedi} soggetto={'sedi'} deleteItem={openDialogConfermaDelete} visualizzaItem={visualizzaItem} isCanEdit={isCanEdit} />
                            )
                            :
                            (
                                <Box sx={{ height: '60vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Alert sx={{ mb: 2 }} severity="info">Non risultano sedi registrate per questa azienda.</Alert>
                                </Box>
                            )
                    ) : (
                        <Box sx={{ height: '65vh' }} flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                            <CircularProgress sx={{ mb: 4 }} />
                            <Typography >Caricamento in corso...</Typography>
                        </Box>
                    )}
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'end' }, width: { xs: '100%', md: 'unset' }, mt: 2, mr: { xs: 0, md: 4 } }}>
                <Button startIcon={<ArrowBack />} sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }} variant="contained" fullWidth color={'secondary'} onClick={previousTab} >Torna indietro</Button>
                <Button sx={{ width: { xs: 'unset', md: 'fit-content' }, mx: { xs: 4, md: 0 } }} variant="contained" fullWidth color={'success'} onClick={closeDialogAggiungiAzienda} >{isCanEdit ? (dataExist ? 'Concludi procedura di modifica' : 'Concludi procedura di inserimento') : 'Chiudi'}</Button>
            </Box>
            <Dialog fullWidth maxWidth={'md'} open={dialogAggiungiSedeOpen} sx={{ mt: '8vh' }}>
                <DialogTitle display={"flex"} justifyContent={'space-between'} alignItems={'center'} variant="h6">
                    <Typography variant='h6' sx={{ mt: 1, textAlign: 'start', fontWeight: 'bold' }}>{idSede !== -1 ? (isCanEdit ? 'Modifica sede' : 'Dettaglio sede') : 'Aggiungi sede'}</Typography>
                    <IconButton onClick={closeDialogAggiungiSede}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Grid container spacing={2} mt={2}>
                        <Grid xs={12} md={6}>
                            <TextField
                                disabled={!canBeModified || !isCanEdit}
                                error={indirizzoSede === '' && !indirizzoSedeValid}
                                defaultValue={indirizzoSede ?? ''}
                                value={indirizzoSede ?? ''}
                                required
                                label={"Indirizzo"}
                                onChange={changeIndirizzoSede}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid xs={12} md={2}>
                            <TextField
                                disabled={!canBeModified || !isCanEdit}
                                defaultValue={capSede ?? ''}
                                value={capSede ?? ''}
                                required
                                label={"Cap"}
                                onChange={changeCapSede}
                                sx={{ width: '100%' }}
                                // error={capSede === '' && !capSedeValid}
                                error={(!capSedeValid && capSede === '') || !capSedeValid}
                                helperText={capSedeErrorMsg}
                                inputProps={{ maxLength: 5 }}
                            />
                        </Grid>
                        <Grid xs={12} md={4}>
                            <TextField
                                disabled={!canBeModified || !isCanEdit}
                                error={cittaSede === '' && !cittaSedeValid}
                                defaultValue={cittaSede ?? ''}
                                value={cittaSede ?? ''}
                                required
                                label={"Città"}
                                onChange={changeCittaSede}
                                sx={{ width: '100%' }}
                            />
                        </Grid>


                        <Grid xs={12} md={6}>
                            <TextField
                                disabled={!canBeModified || !isCanEdit}
                                defaultValue={mailSede ?? ''}
                                value={mailSede ?? ''}
                                // required
                                label={"Mail"}
                                onChange={changeMailSede}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid xs={12} md={6}>
                            <TextField
                                disabled={!canBeModified || !isCanEdit}
                                defaultValue={pecSede ?? ''}
                                value={pecSede ?? ''}
                                // required
                                label={"Pec"}
                                onChange={changePecSede}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid xs={12} md={4}>
                            <TextField
                                disabled={!canBeModified || !isCanEdit}
                                defaultValue={sitoSede ?? ''}
                                value={sitoSede ?? ''}
                                // required
                                label={"Sito web"}
                                onChange={changeSitoSede}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid xs={12} md={4}>
                            <TextField
                                disabled={!canBeModified || !isCanEdit}
                                defaultValue={telefonoSede ?? ''}
                                value={telefonoSede ?? ''}
                                // required
                                label={"Telefono"}
                                onChange={changeTelefonoSede}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid xs={12} md={4}>
                            <TextField
                                disabled={!canBeModified || !isCanEdit}
                                defaultValue={faxSede ?? ''}
                                value={faxSede ?? ''}
                                // required
                                label={"Fax"}
                                onChange={changeFaxSede}
                                sx={{ width: '100%' }}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', p: 2 }}>
                        {isCanEdit && (<Button variant="contained" fullWidth color={idSede !== -1 ? 'warning' : 'success'} disabled={validateFormData()} onClick={() => idSede !== -1 ? setConfirmAlertEditIsOpen(true) : setConfirmAlertInsertIsOpen(true)}>
                            {idSede !== -1 ? 'Conferma modifiche sede' : ' Conferma e aggiungi sede'}
                        </Button>)}
                        <Button sx={{ mt: 2 }} variant="outlined" fullWidth color={'error'} onClick={closeDialogAggiungiSede}>Annulla e chiudi</Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <DialogConferma
                isDialogOpen={confirmAlertInsertSedeLegaleIsOpen}
                title={'Conferma aggiunta sede legale come sede operativa'}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={`Confermi di voler aggiungere la sede legale come sede operativa? `}
                showAlunni={false}
                confermaButton={'Conferma'}
                severity={'success'}
                closeDialog={closeAlertConfirmInserimentoSedeLegale}
                conferma={legaleAsOperativa}
                disabledButton={buttonConfermaDisabled}
            />
            <DialogConferma
                isDialogOpen={confirmAlertInsertIsOpen}
                title={'Conferma aggiunta sede'}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={`Confermi di voler aggiungere la sede? `}
                showAlunni={false}
                confermaButton={'Conferma'}
                severity={'success'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={saveSediAzienda}
                disabledButton={buttonConfermaDisabled}
            />
            <DialogConferma
                isDialogOpen={confirmAlertEditIsOpen}
                title={'Conferma modifica sede'}
                icon={<Edit sx={{ fontSize: '5rem', my: 2 }} color="warning" />}
                text={`Confermi di voler modificare i dati della sede?`}
                showAlunni={false}
                confermaButton={'Conferma modifica'}
                severity={'warning'}
                closeDialog={closeAlertConfirmEdit}
                conferma={saveSediAzienda}
                disabledButton={buttonConfermaDisabled}
            />
            <DialogConferma
                isDialogOpen={confirmAlertDeleteIsOpen}
                title={'Conferma eliminazione sede'}
                icon={<Delete sx={{ fontSize: '5rem', my: 2 }} color="error" />}
                text={`Confermi di voler procedere con l'eliminazione dei dati della sede? Una volta confermata, l'operazione non potrà essere annullata.`}
                showAlunni={false}
                confermaButton={'Conferma eliminazione'}
                severity={'error'}
                closeDialog={closeAlertConfirmDelete}
                conferma={deleteSediAzienda}
                disabledButton={buttonConfermaDisabled}
            />
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={10000} onClose={() => { setAlertOpen(false) }} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment>
    )

}