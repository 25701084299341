import React, { Fragment, useEffect, useState } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Alert, Box, Button, CircularProgress, Snackbar, Typography } from '@mui/material';
import { ArrowBack, ArrowForward, Check, Edit, EditCalendar, Help, WarningAmber } from "@mui/icons-material";
import DialogConferma from "../../shared/dialogConferma";
import RowGiornataTirocinio from "./rowGiornataTirocinio";
import { copyArrayWithoutReference } from "../../shared/utilityMethods";
import { TirociniAPI } from "../../../api/tirociniApi";

export default function TableRegistroTirocinio(props) {

    const [canEditPresenze, setCanEditPresenze] = useState(false);
    const [nomeCorso, setNomeCorso] = useState("");
    const [nomeAlunno, setNomeAlunno] = useState("");

    const [idCorso, setIdCorso] = useState(0);
    const [idAlunno, setIdAlunno] = useState(-1);

    const [rowNonCompilate, setRowNonCompilate] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [thereIsModifiche, setThereIsModifiche] = useState(false);

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");

    const [isDialogConfermaOpen, setIsDialogConfermaOpen] = useState(false);
    const [isConfermaSalva, setIsConfermaSalva] = useState(false);

    const [listDays, setListDays] = useState([]);
    const [listDaysAppoggio, setListDaysAppoggio] = useState([]);

    const [formattedDataDiInteresseInizio, setFormattedDataDiInteresseInizio] = useState("");
    const [formattedDataDiInteresseFine, setFormattedDataDiInteresseFine] = useState("");

    const [annoFormativo, setAnnoFormativo] = useState("20242025");

    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if (props.formattedDataDiInteresseInizio !== undefined) setFormattedDataDiInteresseInizio(props.formattedDataDiInteresseInizio);
        if (props.formattedDataDiInteresseFine !== undefined) setFormattedDataDiInteresseFine(props.formattedDataDiInteresseFine);
    }, [props.formattedDataDiInteresseInizio, props.formattedDataDiInteresseFine])

    useEffect(() => {
        if (props.canEditPresenze !== undefined) setCanEditPresenze(props.canEditPresenze);
    }, [props.canEditPresenze])

    useEffect(() => {
        if (props.idCorso) setIdCorso(props.idCorso)
    }, [props.idCorso])

    useEffect(() => {
        if (props.idAlunno) {
            setIdAlunno(props.idAlunno)

        }
    }, [props.idAlunno])

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 300)
    }, [props.idAlunnoSuccessivo])

    useEffect(() => {
        if (props.nomeAlunno !== undefined) setNomeAlunno(props.nomeAlunno);
    }, [props.nomeAlunno])

    useEffect(() => {
        if (props.listDays) {
            const listDaysTmp = copyArrayWithoutReference(props.listDays)
            setListDays([...props.listDays]);
            setListDaysAppoggio([...listDaysTmp]);
        }
    }, [props.listDays])

    useEffect(() => {
        if (props.nomeCorso) setNomeCorso(props.nomeCorso);
    }, [props.nomeCorso])

    useEffect(() => {
        if (props.annoFormativo) setAnnoFormativo(props.annoFormativo);
    }, [props.annoFormativo])

    function openDialogConferma(isConfermaSalva) {
        setIsConfermaSalva(isConfermaSalva)
        setIsDialogConfermaOpen(prev => !prev)
    }


    async function savePresenzeAlunno() {
        setIsSaving(true);
        let dataObj = {
            idAlunno: idAlunno,
            annoFormativo: annoFormativo,
            giorniOrarioTirocinio: listDaysAppoggio
        }
        TirociniAPI.savePresenzeAlunno(dataObj)
            .then((result) => {
                openDialogConferma(false);
                setAlertSeverity("success");
                setAlertMsg("Modifiche salvate correttamente");
                setIsAlertOpen(true)
                setIsSaving(false);
                setThereIsModifiche(false);
            })
            .catch(function (error) {
                if (error.response) {
                    let errorMsg = error?.response?.data?.data[0]?.msg ?? "Errore durante il salvataggio delle modifiche. Riprovare tra qualche istante."
                    setAlertSeverity("error");
                    setAlertMsg(errorMsg);
                    setIsAlertOpen(true)
                    setIsSaving(false);
                }
            })

    }
    function modificaOrari(giornata, indexGiornata) {
        let listDaysTmp = [...listDaysAppoggio];
        listDaysTmp[indexGiornata] = giornata
        if (listDaysTmp.some(a => (a.primaFascia.orarioEffettuato.oraInizio !== null && a.primaFascia.orarioEffettuato.oraFine === null) || (a.primaFascia.orarioEffettuato.oraInizio === null && a.primaFascia.orarioEffettuato.oraFine !== null) ||
            (a.secondaFascia.orarioEffettuato.oraInizio !== null && a.secondaFascia.orarioEffettuato.oraFine === null) || (a.secondaFascia.orarioEffettuato.oraInizio === null && a.secondaFascia.orarioEffettuato.oraFine !== null)))
            setRowNonCompilate(true);
        else setRowNonCompilate(false);
        setThereIsModifiche(true);
        setListDaysAppoggio([...listDaysTmp])

    }


    function resertModifiche() {
        const listDaysResetted = copyArrayWithoutReference(listDays)
        setListDaysAppoggio([...listDaysResetted]);
        setThereIsModifiche(false);
        openDialogConferma()

        setAlertSeverity("success");
        setAlertMsg("Modifiche annullate correttamente");
        setIsAlertOpen(true);

    }

    function openChangeCorso() {
        if (props.openChangeCorso) props.openChangeCorso();
    }

    function openDialogLegenda() {
        if (props.openDialogLegenda) props.openDialogLegenda();
    }

    function openPeriodoDiInteresseDialog() {
        if (props.openPeriodoDiInteresseDialog) props.openPeriodoDiInteresseDialog();
    }

    function goToAlunnoPrecedente() {
        if (props.goToAlunnoPrecedente)
            props.goToAlunnoPrecedente();
    }

    function goToAlunnoSuccessivo() {
        if (props.goToAlunnoSuccessivo)
            props.goToAlunnoSuccessivo();
    }




    return (
        <Fragment>
            {!isLoading ? (
                <Box>
                    <Box display={"flex"} width="100%" flexDirection={"column"} alignItems={"end"} mt={2}>
                        <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} justifyContent={{ xs: 'start', md: 'space-between' }} width={'100%'}>
                            <Box sx={{ width: '100%', mt: { xs: 1, md: 0 }, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'end' }}>
                                <Box display={"flex"} flexDirection={{ xs: 'column', md: 'row' }} alignItems={"center"} width={"100%"} justifyContent={{ xs: 'start', md: (thereIsModifiche && canEditPresenze) ? 'space-between' : "start" }}>
                                    <Box display={"flex"} justifyContent={"space-between"} flexDirection={{ xs: 'column', lg: 'row' }} width={{ xs: '100%', md: (thereIsModifiche && canEditPresenze) ? 'unset' : "100%" }}>
                                        <Box>
                                            <Button onClick={openChangeCorso} sx={{ width: { xs: '100%', lg: 'unset' } }} variant="contained" startIcon={<ArrowBack />}>
                                                Torna indietro
                                            </Button>
                                            <Button sx={{ ml: { xs: 0, lg: 2 }, mt: { xs: 1, lg: 0 }, width: { xs: '100%', lg: 'unset' } }} variant="outlined" startIcon={<EditCalendar />} onClick={openPeriodoDiInteresseDialog}>
                                                Cambia periodo di interesse ({formattedDataDiInteresseInizio} - {formattedDataDiInteresseFine})
                                            </Button>
                                            <Button startIcon={<Help />} sx={{ ml: { xs: 0, lg: 2 }, mt: { xs: 1, lg: 0 }, mb: { xs: 0, md: 1, lg: 0 }, width: { xs: '100%', lg: 'unset' } }} variant="outlined" color="secondary" onClick={openDialogLegenda}>
                                                Legenda
                                            </Button>
                                        </Box>
                                        {(props.idAlunnoPrecedente || props.idAlunnoSuccessivo) && (
                                            <Box flexDirection={{ xs: 'column', lg: 'row' }} sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                                                {props.idAlunnoPrecedente ? (
                                                    <Button sx={{ mr: { xs: 0, lg: 1 }, mb:{xs:1,lg:0}, width: { xs: '100%', lg: 'unset' } }} startIcon={<ArrowBack />} onClick={goToAlunnoPrecedente} variant="outlined" color="success" >Precedente</Button>
                                                ) : (<span></span>)}

                                                {props.idAlunnoSuccessivo ? (
                                                    <Button sx={{ width: { xs: '100%', lg: 'unset' } }} startIcon={<ArrowForward />} onClick={goToAlunnoSuccessivo} variant="outlined" color="success">Successivo</Button>
                                                ) : (<span></span>)}
                                            </Box>
                                        )}
                                    </Box>
                                    {(thereIsModifiche && canEditPresenze) && (
                                        <Box display={"flex"} flexDirection={{ xs: 'column', lg: 'row' }} justifyContent={"end"}>
                                            <Button disabled={isSaving} sx={{ ml: { xs: 0, md: 2 }, width: { xs: '100%', md: 'unset' } }} variant="outlined" color="error" onClick={() => openDialogConferma(false)}>
                                                Annulla modifiche
                                            </Button>
                                            <Button disabled={isSaving || rowNonCompilate} sx={{ ml: { xs: 0, md: 2 }, mt: { xs: 1, md: 0 }, width: { xs: '100%', md: 'unset' } }} variant="contained" color="success" onClick={() => openDialogConferma(true)}>
                                                Conferma e salva
                                            </Button>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        {(thereIsModifiche && rowNonCompilate) && (
                            <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                                <WarningAmber color="error" sx={{ mr: 1 }} />
                                <Typography color="error" variant="caption">Per poter salvare le modifiche è prima necessario compilare tutti gli orari.</Typography>
                            </Box>
                        )}
                    </Box>
                    <Box sx={{ border: '1px solid #eaeaea', borderRadius: '15px', mt: 3, mb: 3, width: '100%', maxHeight: { xs: 'unset', md: '75vh' }, display: "flex", justifyContent: 'center', overflow: 'scroll' }}>
                        <TableContainer sx={{ width: '100%' }} >
                            <Table sx={{ minWidth: 100 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center"
                                            className="colonna-nomi row-head" sx={{ minWidth: '300px', p: 0, zIndex: 6 }}>
                                            <Box sx={{
                                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                borderRight: '1px solid black',
                                                backgroundColor: 'white',
                                                height: '70px',
                                                px: 1,
                                                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start'
                                            }}>
                                                <Typography color={'primary'}
                                                    variant={"caption"}
                                                    sx={{ fontWeight: 'bold', textAlign: 'start' }}>
                                                    {(nomeCorso ?? "").toUpperCase()}
                                                </Typography>
                                                <Typography color={'secondary'}
                                                    variant={"caption2"}
                                                    sx={{ fontWeight: 'bold', textAlign: 'start' }}>
                                                    {(nomeAlunno ?? "").toUpperCase()}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center"
                                            className="colonna-nomi row-head" sx={{ p: 0, zIndex: 6 }}>
                                            <Box sx={{
                                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                borderRight: '1px solid black',
                                                backgroundColor: 'white',
                                                height: '70px',
                                                px: 1,
                                                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                            }}>
                                                <Typography variant="body2">Giornata</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center"
                                            className="colonna-nomi row-head" sx={{ minWidth: '300px', p: 0, zIndex: 6 }}>
                                            <Box sx={{
                                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                borderRight: '1px solid rgba(224, 224, 224, 1)',
                                                backgroundColor: 'white',
                                                height: '70px',
                                                px: 1,
                                                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                            }}>
                                                <Typography variant="body2">1^ Fascia</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center"
                                            className="colonna-nomi row-head" sx={{ minWidth: '300px', p: 0, zIndex: 6 }}>
                                            <Box sx={{
                                                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                                borderRight: '1px solid rgba(224, 224, 224, 1)',
                                                backgroundColor: 'white',
                                                height: '70px',
                                                px: 1,
                                                display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                                            }}>
                                                <Typography variant="body2">2^ Fascia</Typography>
                                            </Box>
                                        </TableCell>

                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {listDaysAppoggio.map((day, index) => (
                                        <RowGiornataTirocinio modificaOrari={modificaOrari} idCorso={idCorso}
                                            canEditPresenze={canEditPresenze} giornata={day} indexGiornata={index} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            ) : (
                <Box flexDirection={"column"} display={"flex"} alignItems={"center"} justifyContent={"center"} height={'70vh'}>
                    <CircularProgress sx={{ mb: 4 }} />
                    <Typography >Caricamento in corso...</Typography>
                </Box>
            )}
            <DialogConferma
                isDialogOpen={isDialogConfermaOpen}
                title={isConfermaSalva ? 'Conferma e salva' : 'Conferma annulla modifiche'}
                icon={isConfermaSalva ? <Check sx={{ fontSize: '5rem', my: 2 }} color="success" /> : <Edit sx={{ fontSize: '5rem', my: 2 }} color="warning" />}
                text={`Confermi di voler ${isConfermaSalva ? 'salvare' : 'annullare'} le modifiche fatte?`}
                showAlunni={false}
                confermaButton={isConfermaSalva ? 'Conferma e salva' : 'Conferma annulla modifica'}
                severity={isConfermaSalva ? 'success' : 'warning'}
                closeDialog={() => openDialogConferma(false)}
                conferma={isConfermaSalva ? savePresenzeAlunno : resertModifiche}
                disabledButton={isSaving}
            />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={isAlertOpen} autoHideDuration={10000} onClose={() => { setIsAlertOpen(false) }}>
                <Alert onClose={() => { setIsAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>


        </Fragment>
    )
}


