import { Box, Dialog, DialogTitle, DialogContent, Typography, Button, Snackbar, Alert, TableCell, Divider, Select, MenuItem, Table, TableRow, FormControlLabel, Checkbox } from "@mui/material";
import { useState, useEffect, Fragment } from "react"
import { useTranslation } from 'react-i18next';
import { itIT } from "@mui/x-date-pickers/locales";
import SelezionaClasse from "./selezionaClasse";
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from "luxon";
import { UtilityAPI } from "../../api/utilityApi";
import { Helmet } from "react-helmet";
import CryptoJS from 'crypto-js';
import { convertDataGetProssimeLezione } from "../shared/metodiDateUtility";
import Breadcrumb from "../registri/shared/breadcrumb";
import { getDataInizioFinePeriodo } from "../shared/utilityMethods";
import { CheckBox } from "@mui/icons-material";


export default function GestionePeriodi() {

    const { t, i18n } = useTranslation();


    const [scegliClasseDialogOpen, setScegliClasseDialogOpen] = useState(true)
    const [idCorsoSelezionato, setIdCorsoSelezionato] = useState(-1);
    const [nomeCorsoSelezionato, setNomeCorsoSelezionato] = useState("");

    const [dataInizioPrimoTrimestre, setDataInizioPrimoTrimestre] = useState(null);
    const [dataFinePrimoTrimestre, setDataFinePrimoTrimestre] = useState(null);
    const [dataFinePropostaVotoPrimoTrimestre, setDataFinePropostaVotoPrimoTrimestre] = useState(null);
    const [dataScrutinioPrimoTrimestre, setDataScrutinioPrimoTrimestre] = useState(null);

    const [dataInizioSecondoTrimestre, setDataInizioSecondoTrimestre] = useState(null);
    const [dataFineSecondoTrimestre, setDataFineSecondoTrimestre] = useState(null);
    const [dataFinePropostaVotoSecondoTrimestre, setDataFinePropostaVotoSecondoTrimestre] = useState(null);
    const [dataScrutinioSecondoTrimestre, setDataScrutinioSecondoTrimestre] = useState(null);

    const [dataInizioTerzoTrimestre, setDataInizioTerzoTrimestre] = useState(null);
    const [dataFineTerzoTrimestre, setDataFineTerzoTrimestre] = useState(null);
    const [dataFinePropostaVotoTerzoTrimestre, setDataFinePropostaVotoTerzoTrimestre] = useState(null);
    const [dataScrutinioTerzoTrimestre, setDataScrutinioTerzoTrimestre] = useState(null);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);

    const [annoRiferimento, setAnnoRiferimento] = useState(-1);

    const [isAnnoFormativoCorrente, setIsAnnoFormativoCorrente] = useState(true);
    const [sedeUtente, setSedeUtente] = useState('');

    const [dataInizioPeriodo, setDataInizioPeriodo] = useState(null)
    const [dataFinePeriodo, setDataFinePeriodo] = useState(null)

    const [isCorsoQuadriennale, setIsCorsoQuadriennale] = useState(false);
    useEffect(() => {
        if (localStorage.getItem("sede")) {
            let sedeTmp = localStorage.getItem("sede");
            let decryptedSede = CryptoJS.AES.decrypt(sedeTmp, process.env.REACT_APP_PRIVATE_KEY).toString(CryptoJS.enc.Utf8);
            let sedeParsed = JSON.parse(decryptedSede);
            setSedeUtente(sedeParsed.id)
        }

        let objDateInteresse = getDataInizioFinePeriodo();
        let inizioPeriodoTmp = objDateInteresse?.inizioPeriodo ?? null
        let finePeriodoTmp = objDateInteresse?.finePeriodo ?? null

        setDataInizioPeriodo(inizioPeriodoTmp);
        setDataFinePeriodo(finePeriodoTmp);
        setIsAnnoFormativoCorrente(objDateInteresse?.isAnnoFormativoCorrente ?? true)
    }, [])

    function changeDataInizioPrimoTrimestre(value) {
        setDataInizioPrimoTrimestre(value);
    }
    function changeDataFinePrimoTrimestre(value) {
        setDataFinePrimoTrimestre(value)
        if (value >= dataFinePropostaVotoPrimoTrimestre) {
            setDataFinePropostaVotoPrimoTrimestre(null)
            setDataScrutinioPrimoTrimestre(null)
        }
        setDataInizioSecondoTrimestre(value.plus({ days: 1 }))
    }

    function changeDataFinePropostaVotoPrimoTrimestre(value) {
        setDataFinePropostaVotoPrimoTrimestre(value);
    }
    function changeDataScrutinioPrimoTrimestre(value) {
        setDataScrutinioPrimoTrimestre(value)
    }

    function changeAnnoRiferimento(e) {
        setAnnoRiferimento(e.target.value);
    }

    function changeDataInizioSecondoTrimestre(value) {
        setDataInizioSecondoTrimestre(value);
        setDataFinePrimoTrimestre(value.minus({ days: 1 }))
    }
    function changeDataFineSecondoTrimestre(value) {
        setDataFineSecondoTrimestre(value)
        if (value >= dataFinePropostaVotoSecondoTrimestre) {
            setDataFinePropostaVotoSecondoTrimestre(null)
            setDataScrutinioSecondoTrimestre(null)
        }
        setDataInizioTerzoTrimestre(value.plus({ days: 1 }))
    }

    function changeDataFinePropostaVotoSecondoTrimestre(value) {
        setDataFinePropostaVotoSecondoTrimestre(value);
    }
    function changeDataScrutinioSecondoTrimestre(value) {
        setDataScrutinioSecondoTrimestre(value)
    }

    function changeDataInizioTerzoTrimestre(value) {
        setDataInizioTerzoTrimestre(value);
        setDataFineSecondoTrimestre(value.minus({ days: 1 }))
    }
    function changeDataFineTerzoTrimestre(value) {
        setDataFineTerzoTrimestre(value)
        if (value >= dataFinePropostaVotoTerzoTrimestre) {
            setDataFinePropostaVotoTerzoTrimestre(null)
            setDataScrutinioTerzoTrimestre(null)
        }
    }

    function changeDataFinePropostaVotoTerzoTrimestre(value) {
        setDataFinePropostaVotoTerzoTrimestre(value);
    }
    function changeDataScrutinioTerzoTrimestre(value) {
        setDataScrutinioTerzoTrimestre(value)
    }

    function confirmClasse(idClasse, nomeClasse) {
        setIdCorsoSelezionato(idClasse);
        setNomeCorsoSelezionato(nomeClasse);
        getTrimestri(idClasse);
        setScegliClasseDialogOpen(false);
    }

    function canSave() {
        if (dataInizioPrimoTrimestre != null
            && dataInizioSecondoTrimestre != null
            && dataInizioTerzoTrimestre != null
            && dataFinePrimoTrimestre != null
            && dataFineSecondoTrimestre != null
            && dataFineTerzoTrimestre != null
            //TODO: 
            // && dataFinePropostaVotoPrimoTrimestre != null
            // && dataFinePropostaVotoSecondoTrimestre != null
            // && dataFinePropostaVotoTerzoTrimestre != null

            // && dataScrutinioPrimoTrimestre != null
            // && dataScrutinioSecondoTrimestre != null
            // && dataScrutinioTerzoTrimestre != null
        )
            return true
        else return false
    }

    function openDialogSelezionaClasse() {

        setDataInizioPrimoTrimestre(null);
        setDataFinePrimoTrimestre(null)
        setDataFinePropostaVotoPrimoTrimestre(null);
        setDataScrutinioPrimoTrimestre(null);

        setDataInizioSecondoTrimestre(null);
        setDataFineSecondoTrimestre(null)
        setDataFinePropostaVotoSecondoTrimestre(null);
        setDataScrutinioSecondoTrimestre(null);

        setDataInizioTerzoTrimestre(null);
        setDataFineTerzoTrimestre(null);
        setDataFinePropostaVotoTerzoTrimestre(null);
        setDataScrutinioTerzoTrimestre(null);

        setScegliClasseDialogOpen(true)
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg);
        setAlertSeverity(severity);
        setAlertOpen(true);
    }

    async function getTrimestri(idCorso) {

        UtilityAPI.getTrimestriCorso(idCorso)
            .then((result) => {
                setIsCorsoQuadriennale(result?.isQuadriennale ?? false);
                if (result.trimestri.length > 0) {
                    let trimestri = result.trimestri;

                    for (var i = 0; i < trimestri.length; i++) {
                        if (trimestri[i].ordinale === 1) {
                            if (trimestri[i].dataInizio != null && trimestri[i].dataFine != null) {
                                let dataInizioConverted = DateTime.fromISO(trimestri[i].dataInizio);
                                let dataFineConverted = DateTime.fromISO(trimestri[i].dataFine);

                                setDataInizioPrimoTrimestre(dataInizioConverted);
                                setDataFinePrimoTrimestre(dataFineConverted);
                            }
                            if (trimestri[i].dataFineProposta != null) {
                                let dataFinePropostaConverted = DateTime.fromISO(trimestri[i].dataFineProposta);
                                setDataFinePropostaVotoPrimoTrimestre(dataFinePropostaConverted);
                            }
                            if (trimestri[i].dataScrutinio != null) {
                                let dataScrutinioConverted = DateTime.fromISO(trimestri[i].dataScrutinio);
                                setDataScrutinioPrimoTrimestre(dataScrutinioConverted);
                            }
                        }
                        else if (trimestri[i].ordinale === 2) {
                            if (trimestri[i].dataInizio != null && trimestri[i].dataFine != null) {
                                let dataInizioConverted = DateTime.fromISO(trimestri[i].dataInizio);
                                let dataFineConverted = DateTime.fromISO(trimestri[i].dataFine);

                                setDataInizioSecondoTrimestre(dataInizioConverted);
                                setDataFineSecondoTrimestre(dataFineConverted);
                            }
                            if (trimestri[i].dataFineProposta != null) {
                                let dataFinePropostaConverted = DateTime.fromISO(trimestri[i].dataFineProposta);
                                setDataFinePropostaVotoSecondoTrimestre(dataFinePropostaConverted);
                            }
                            if (trimestri[i].dataScrutinio != null) {
                                let dataScrutinioConverted = DateTime.fromISO(trimestri[i].dataScrutinio);
                                setDataScrutinioSecondoTrimestre(dataScrutinioConverted);
                            }
                        }
                        else {
                            if (trimestri[i].dataInizio != null && trimestri[i].dataFine != null) {
                                let dataInizioConverted = DateTime.fromISO(trimestri[i].dataInizio);
                                let dataFineConverted = DateTime.fromISO(trimestri[i].dataFine);

                                setDataInizioTerzoTrimestre(dataInizioConverted);
                                setDataFineTerzoTrimestre(dataFineConverted);
                            }
                            if (trimestri[i].dataFineProposta != null) {
                                let dataFinePropostaConverted = DateTime.fromISO(trimestri[i].dataFineProposta);
                                setDataFinePropostaVotoTerzoTrimestre(dataFinePropostaConverted);
                            }
                            if (trimestri[i].dataScrutinio != null) {
                                let dataScrutinioConverted = DateTime.fromISO(trimestri[i].dataScrutinio);
                                setDataScrutinioTerzoTrimestre(dataScrutinioConverted);
                            }
                        }
                    }
                } else {
                    setDataInizioPrimoTrimestre(null);
                    setDataFinePrimoTrimestre(null)
                    setDataFinePropostaVotoPrimoTrimestre(null);
                    setDataScrutinioPrimoTrimestre(null);

                    setDataInizioSecondoTrimestre(null);
                    setDataFineSecondoTrimestre(null)
                    setDataFinePropostaVotoSecondoTrimestre(null);
                    setDataScrutinioSecondoTrimestre(null);

                    setDataInizioTerzoTrimestre(null);
                    setDataFineTerzoTrimestre(null);
                    setDataFinePropostaVotoTerzoTrimestre(null);
                    setDataScrutinioTerzoTrimestre(null);
                }

                if (result.trimestreCorrente !== undefined)
                    setAnnoRiferimento(result?.anno ?? -1)
                else setAnnoRiferimento(-1)

            })
            .catch((error) => {
                if (error.response) {
                }
            })
    }
    async function editTrimestri() {


        let objData = {
            anno: annoRiferimento,
            isQuadriennale: isCorsoQuadriennale ?? false,
            trimestri: [{
                ordinale: 1,
                dataInizio: convertDataGetProssimeLezione(dataInizioPrimoTrimestre),
                dataFine: convertDataGetProssimeLezione(dataFinePrimoTrimestre),
                dataFineProposta: dataFinePropostaVotoPrimoTrimestre === null ? null : convertDataGetProssimeLezione(dataFinePropostaVotoPrimoTrimestre),
                dataScrutinio: dataScrutinioPrimoTrimestre === null ? null : convertDataGetProssimeLezione(dataScrutinioPrimoTrimestre),
                idCorso: idCorsoSelezionato
            }, {
                ordinale: 2,
                dataInizio: convertDataGetProssimeLezione(dataInizioSecondoTrimestre),
                dataFine: convertDataGetProssimeLezione(dataFineSecondoTrimestre),
                dataFineProposta: dataFinePropostaVotoSecondoTrimestre === null ? null : convertDataGetProssimeLezione(dataFinePropostaVotoSecondoTrimestre),
                dataScrutinio: dataScrutinioSecondoTrimestre === null ? null : convertDataGetProssimeLezione(dataScrutinioSecondoTrimestre),
                idCorso: idCorsoSelezionato
            },
            {
                ordinale: 3,
                dataInizio: convertDataGetProssimeLezione(dataInizioTerzoTrimestre),
                dataFine: convertDataGetProssimeLezione(dataFineTerzoTrimestre),
                dataFineProposta: dataFinePropostaVotoTerzoTrimestre === null ? null : convertDataGetProssimeLezione(dataFinePropostaVotoTerzoTrimestre),
                dataScrutinio: dataScrutinioTerzoTrimestre === null ? null : convertDataGetProssimeLezione(dataScrutinioTerzoTrimestre),
                idCorso: idCorsoSelezionato
            }]
        };


        if (annoRiferimento === 4 && !isCorsoQuadriennale) { //Tolto il terzo trimestre se è un quarto anno e non quadriennale
            objData.trimestri = objData.trimestri.filter(trimestre => trimestre.ordinale !== 3);
        }

        UtilityAPI.editTrimestri(idCorsoSelezionato, objData)
            .then((result) => {
                openAlertMsg('Modifiche salvate correttamente', "success")
            })
            .catch((error) => {
                if (error.response) {
                    openAlertMsg('Errore durante il salvataggio delle modifiche', "error")

                }
            })
    }

    function changeIsCorsoQuadriennale() {
        setIsCorsoQuadriennale(prev => !prev);
    }

    return (
        <Fragment>
            <Breadcrumb sections={["Gestione periodo", nomeCorsoSelezionato]} />

            <Box sx={{ p: 5 }}>


                <Table>
                    <TableRow>
                        <TableCell sx={{ width: '100px' }}>
                            <Typography>Primo periodo:</Typography>
                        </TableCell>
                        <TableCell>
                            <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                <Box sx={{ p: 1, display: "flex", justifyContent: "start", alignItems: 'center' }}>
                                    <MobileDatePicker views={['month', 'day']}
                                        disabled={!isAnnoFormativoCorrente}
                                        label="Data inizio periodo (I Periodo)"
                                        value={dataInizioPrimoTrimestre}
                                        format="dd/MM/yyyy"
                                        minDate={dataInizioPeriodo}
                                        maxDate={dataFinePrimoTrimestre != null ? dataFinePrimoTrimestre.minus({ days: 1 }) : null}
                                        onChange={(value) => changeDataInizioPrimoTrimestre(value)}
                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    <Typography sx={{ mx: 2 }}>-</Typography>
                                    <MobileDatePicker
                                        views={['month', 'day']}
                                        label="Data fine periodo (I Periodo)"
                                        disabled={dataInizioPrimoTrimestre == null || !isAnnoFormativoCorrente}
                                        value={dataFinePrimoTrimestre}
                                        format="dd/MM/yyyy"
                                        minDate={dataInizioPrimoTrimestre != null ? dataInizioPrimoTrimestre.plus({ days: 1 }) : null}
                                        maxDate={dataInizioSecondoTrimestre != null ? dataInizioSecondoTrimestre.minus({ days: 1 }) : null}
                                        onChange={(value) => changeDataFinePrimoTrimestre(value)}
                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    <Typography sx={{ mx: 2 }}>-</Typography>
                                    <MobileDatePicker views={['month', 'day']}

                                        label="Fine proposta di voto (I Periodo)"
                                        value={dataFinePropostaVotoPrimoTrimestre}
                                        format="dd/MM/yyyy"
                                        disabled={(dataFinePrimoTrimestre === null && dataInizioPrimoTrimestre === null) || !isAnnoFormativoCorrente}
                                        minDate={dataFinePrimoTrimestre !== null ? dataFinePrimoTrimestre.plus({ days: 1 }) : null}
                                        maxDate={dataScrutinioPrimoTrimestre !== null ? dataScrutinioPrimoTrimestre.minus({ days: 1 }) : null}
                                        onChange={(value) => changeDataFinePropostaVotoPrimoTrimestre(value)}
                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    <Typography sx={{ mx: 2 }}>-</Typography>
                                    <MobileDatePicker
                                        views={['month', 'day']}
                                        label="Data scrutinio (I Periodo)"
                                        disabled={dataFinePropostaVotoPrimoTrimestre == null || !isAnnoFormativoCorrente}
                                        value={dataScrutinioPrimoTrimestre}
                                        format="dd/MM/yyyy"
                                        minDate={dataFinePropostaVotoPrimoTrimestre != null ? dataFinePropostaVotoPrimoTrimestre.plus({ days: 1 }) : null}
                                        maxDate={dataFinePeriodo}
                                        onChange={(value) => changeDataScrutinioPrimoTrimestre(value)}
                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                </Box>
                            </LocalizationProvider>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ width: '100px' }}>
                            <Typography sx={{ mr: 2 }}>Secondo periodo:</Typography>
                        </TableCell>
                        <TableCell>
                            <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                <Box sx={{ p: 1, display: "flex", justifyContent: "start", alignItems: 'center' }}>
                                    <MobileDatePicker views={['month', 'day']}
                                        disabled={!isAnnoFormativoCorrente}
                                        label="Data inizio periodo (II Periodo)"
                                        value={dataInizioSecondoTrimestre}
                                        format="dd/MM/yyyy"
                                        minDate={dataFinePrimoTrimestre != null ? dataFinePrimoTrimestre.plus({ days: 1 }) : null}
                                        maxDate={dataFineSecondoTrimestre != null ? dataFineSecondoTrimestre.minus({ days: 1 }) : null}
                                        onChange={(value) => changeDataInizioSecondoTrimestre(value)}
                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    <Typography sx={{ mx: 2 }}>-</Typography>
                                    <MobileDatePicker
                                        views={['month', 'day']}
                                        label="Data fine periodo (II Periodo)"
                                        disabled={dataInizioSecondoTrimestre == null || !isAnnoFormativoCorrente}
                                        value={dataFineSecondoTrimestre}
                                        format="dd/MM/yyyy"
                                        minDate={dataInizioSecondoTrimestre != null ? dataInizioSecondoTrimestre.plus({ days: 1 }) : null}
                                        maxDate={dataInizioTerzoTrimestre != null ? dataInizioTerzoTrimestre.minus({ days: 1 }) : null}
                                        onChange={(value) => changeDataFineSecondoTrimestre(value)}
                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    <Typography sx={{ mx: 2 }}>-</Typography>
                                    <MobileDatePicker views={['month', 'day']}

                                        label="Fine proposta di voto (II Periodo)"
                                        value={dataFinePropostaVotoSecondoTrimestre}
                                        format="dd/MM/yyyy"
                                        disabled={(dataFineSecondoTrimestre === null && dataInizioSecondoTrimestre === null) || !isAnnoFormativoCorrente}
                                        minDate={dataFineSecondoTrimestre != null ? dataFineSecondoTrimestre.plus({ days: 1 }) : null}
                                        maxDate={dataScrutinioSecondoTrimestre != null ? dataScrutinioSecondoTrimestre.minus({ days: 1 }) : null}
                                        onChange={(value) => changeDataFinePropostaVotoSecondoTrimestre(value)}
                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    <Typography sx={{ mx: 2 }}>-</Typography>
                                    <MobileDatePicker
                                        views={['month', 'day']}
                                        label="Data scrutinio (II Periodo)"
                                        disabled={dataFinePropostaVotoSecondoTrimestre == null || !isAnnoFormativoCorrente}
                                        value={dataScrutinioSecondoTrimestre}
                                        format="dd/MM/yyyy"
                                        minDate={dataFinePropostaVotoSecondoTrimestre != null ? dataFinePropostaVotoSecondoTrimestre.plus({ days: 1 }) : null}
                                        maxDate={dataFinePeriodo}
                                        onChange={(value) => changeDataScrutinioSecondoTrimestre(value)}
                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                </Box>
                            </LocalizationProvider>
                        </TableCell>
                    </TableRow>
                    {((annoRiferimento === 4 && isCorsoQuadriennale) || annoRiferimento < 4) && (
                    <TableRow>
                        <TableCell sx={{ width: '100px' }}>
                            <Typography sx={{ mr: 2 }}>Terzo periodo:</Typography>
                        </TableCell>
                        <TableCell>
                            <LocalizationProvider localeText={itIT.components.MuiLocalizationProvider.defaultProps.localeText}
                                LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale='it'>
                                <Box sx={{ p: 1, display: "flex", justifyContent: "start", alignItems: 'center' }}>
                                    <MobileDatePicker views={['month', 'day']}
                                        disabled={!isAnnoFormativoCorrente}
                                        label="Data inizio periodo (III Periodo)"
                                        value={dataInizioTerzoTrimestre}
                                        format="dd/MM/yyyy"
                                        minDate={dataFineSecondoTrimestre != null ? dataFineSecondoTrimestre.plus({ days: 1 }) : null}
                                        maxDate={dataFineTerzoTrimestre != null ? dataFineTerzoTrimestre.minus({ days: 1 }) : null}
                                        onChange={(value) => changeDataInizioTerzoTrimestre(value)}
                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    <Typography sx={{ mx: 2 }}>-</Typography>
                                    <MobileDatePicker
                                        views={['month', 'day']}
                                        label="Data fine periodo (III Periodo)"
                                        disabled={dataInizioTerzoTrimestre == null || !isAnnoFormativoCorrente}
                                        value={dataFineTerzoTrimestre}
                                        format="dd/MM/yyyy"
                                        minDate={dataInizioTerzoTrimestre != null ? dataInizioTerzoTrimestre.plus({ days: 1 }) : null}
                                        maxDate={dataFinePeriodo}
                                        onChange={(value) => changeDataFineTerzoTrimestre(value)}
                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    <Typography sx={{ mx: 2 }}>-</Typography>
                                    <MobileDatePicker views={['month', 'day']}

                                        label="Fine proposta di voto (III Periodo)"
                                        value={dataFinePropostaVotoTerzoTrimestre}
                                        format="dd/MM/yyyy"
                                        disabled={(dataFineTerzoTrimestre === null && dataInizioSecondoTrimestre === null) || !isAnnoFormativoCorrente}
                                        minDate={dataFineTerzoTrimestre != null ? dataFineTerzoTrimestre.plus({ days: 1 }) : null}
                                        maxDate={dataScrutinioTerzoTrimestre != null ? dataScrutinioTerzoTrimestre.minus({ days: 1 }) : null}
                                        onChange={(value) => changeDataFinePropostaVotoTerzoTrimestre(value)}
                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()} />
                                    <Typography sx={{ mx: 2 }}>-</Typography>
                                    <MobileDatePicker
                                        views={['month', 'day']}
                                        label="Data scrutinio (III Periodo)"
                                        disabled={dataFinePropostaVotoTerzoTrimestre == null || !isAnnoFormativoCorrente}
                                        value={dataScrutinioTerzoTrimestre}
                                        format="dd/MM/yyyy"
                                        minDate={dataFinePropostaVotoTerzoTrimestre != null ? dataFinePropostaVotoTerzoTrimestre.plus({ days: 1 }) : null}
                                        maxDate={dataFinePeriodo}
                                        onChange={(value) => changeDataScrutinioTerzoTrimestre(value)}
                                        dayOfWeekFormatter={(date) => date.toFormat('ccc').charAt(0).toUpperCase()}
                                    />
                                </Box>
                            </LocalizationProvider>
                        </TableCell>
                    </TableRow>
                    )}
                </Table>
                <Box sx={{ mt: 2 }} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                    <Box display={"flex"} alignItems={"center"}>
                        <Box display={"flex"} alignItems={"center"}>
                            <Typography>Anno corso</Typography>
                            <Select sx={{ ml: 2 }}
                                value={annoRiferimento}
                                onChange={changeAnnoRiferimento}
                                disabled={!isAnnoFormativoCorrente}
                            >
                                <MenuItem value={-1}></MenuItem>
                                <MenuItem value={1}>1°</MenuItem>
                                <MenuItem value={2}>2°</MenuItem>
                                <MenuItem value={3}>3°</MenuItem>
                                <MenuItem value={4}>4°</MenuItem>

                            </Select>
                        </Box>
                        <FormControlLabel sx={{ ml: 2 }} control={<Checkbox checked={isCorsoQuadriennale} onChange={changeIsCorsoQuadriennale} />} label="Corso quadriennale" />
                    </Box>

                    <Box display={"flex"} alignItems={"center"} justifyContent={"end"}>
                        {isAnnoFormativoCorrente && (
                            <Box>
                                {canSave() === false && (
                                    <Typography sx={{ mr: 2 }} color="error">
                                        È necessario valorizzare tutte le date prima di poter salvare le modifiche.
                                    </Typography>
                                )}
                                {(canSave() === true && annoRiferimento == -1) && (
                                    <Typography sx={{ mr: 2 }} color="error">
                                        È necessario inserire specificare l'<b>anno del corso</b> per salvare le modifiche.
                                    </Typography>
                                )}
                            </Box>
                        )}
                        <Box>
                            <Button onClick={openDialogSelezionaClasse} sx={{ mr: 2, width: { xs: '100%', md: 'fit-content' }, mb: { xs: 1, md: 0 } }} variant="outlined" >Cambia corso di riferimento</Button>
                            {isAnnoFormativoCorrente && (
                                <Button onClick={editTrimestri} disabled={canSave() === false || annoRiferimento == -1} sx={{ width: { xs: '100%', md: 'fit-content' } }} variant="contained" color="success">Salva modifiche</Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>


            <Dialog fullWidth maxWidth={"md"} open={scegliClasseDialogOpen}>
                <DialogTitle>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Seleziona corso di riferimento</Typography>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <SelezionaClasse sedeUtente={sedeUtente} idClasseSelezionata={idCorsoSelezionato} confirmClasse={confirmClasse} />
                </DialogContent>
            </Dialog>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>

            <Helmet>
                <title>{t("name")} - Gestione periodi</title>
            </Helmet>
        </Fragment >
    )
}