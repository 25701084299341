import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { Snackbar, Alert, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import ComunicazioniInviate from './comunicazioniInviate';
import ComunicazioniRicevute from './comunicazioniRicevute';

export default function ElencoComunicazioni(props) {

  const { t } = useTranslation();

  const [annoFormativoObj, setAnnoFormativoObj] = useState(null);
  const [indexComunicazioneInviata, setIndexComunicazioneInviata] = useState(0);
  const [showComunicazioniRicevute, setShowComunicazioniRicevute] = useState(false);

  const [hasLoaded, setHasLoaded] = useState(false);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("error");
  const [alertMsg, setAlertMsg] = useState("");


  useEffect(() => {
    if (props.annoFormativo !== undefined) {
      setAnnoFormativoObj(props.annoFormativo);
    }
  }, [props.annoFormativo])

  useEffect(() => {
    if (props.indexComunicazioneInviata !== undefined) {
      setIndexComunicazioneInviata(props.indexComunicazioneInviata);
    }
  }, [props.indexComunicazioneInviata])

  useEffect(() => {
    if (props.showComunicazioniRicevute !== undefined) {
      setShowComunicazioniRicevute(props.showComunicazioniRicevute);
      setTimeout(() => {
        setHasLoaded(true);
      }, 200)
    }
  }, [props.showComunicazioniRicevute])

  function openAlert(msg, severity) {
    setAlertMsg(msg);
    setAlertSeverity(severity);
    setAlertOpen(true);
  }

  function closeAlert() {
    setAlertOpen(false);
  }

  return (
    <Fragment>
      {hasLoaded && (
        showComunicazioniRicevute ? (
          <ComunicazioniRicevute indexComunicazioneInviata={indexComunicazioneInviata} openAlert={openAlert} annoFormativo={annoFormativoObj} />
        ) : (
          <ComunicazioniInviate indexComunicazioneInviata={indexComunicazioneInviata} openAlert={openAlert} annoFormativo={annoFormativoObj} />
        )
      )}
      <Snackbar onClose={closeAlert} autoHideDuration={5000} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen}>
        <Alert sx={{ width: '100%', mb: 2 }} severity={alertSeverity} >
          {alertMsg}
        </Alert>
      </Snackbar>

      <Helmet>
        <title>{t("name")} - {t("pages.elencoComunicazioni.title")}</title>
      </Helmet>
    </Fragment >
  );
}