import { Alert, Box, Button, Dialog, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Snackbar, TextField, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { TirociniAPI } from "../../../../api/tirociniApi";
import DialogConferma from "../../../shared/dialogConferma";
import { ArrowForward, Check, Close } from "@mui/icons-material";
import { getAnnoFormativo } from "../../../shared/utilityMethods";
import TableCondizioniTirocinante from "./tableCondizioneTirocinante";

export default function AnagraficaTirocinante(props) {

    const [annoFormativo, setAnnoFormativo] = useState('');
    const [idProgetto, setIdProgetto] = useState(-1);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState('');

    //Fissi
    const [CF, setCF] = useState('');
    const [nomeAlunno, setNomeAlunno] = useState('');
    const [cognomeAlunno, setCognomeAlunno] = useState('');
    const [dataNascitaAlunno, setDataNascitaAlunno] = useState(null);
    //Editabili
    const [nomeCorso, setNomeCorso] = useState('');
    const [comuneResidenza, setComuneResidenza] = useState('');
    const [comuneNascita, setComuneNascita] = useState('');
    const [indirizzoResidenzaVia, setIndirizzoResidenzaVia] = useState('');
    const [indirizzoResidenzaNumero, setIndirizzoResidenzaNumero] = useState('');
    const [capResidenza, setCapResidenza] = useState('');
    const [titoloStudioSelect, setTitoloStudioSelect] = useState(null);
    const [idTitoloStudioSelect, setIdTitoloStudioSelect] = useState(-1);
    const [idTitoloStudioSelectValid, setIdTitoloStudioSelectValid] = useState(-1);

    const [confirmAlertIsOpen, setConfirmAlertIsOpen] = useState(false);
    const [buttonConfermaDisabled, setButtonConfermaDisabled] = useState(false);
    const [isConfermaLoading, setIsConfermaLoading] = useState(false);

    const [isVisualizza, setIsVisualizza] = useState(false);

    const [isCanEdit, setIsCanEdit] = useState(true);
    const [titoliStudioList, setTitoliStudioList] = useState([]);
    const [condizioniLavoro, setCondizioniLavoro] = useState([]);
    const [capSedeValid, setCapSedeValid] = useState(true);
    const [capSedeErrorMsg, setCapSedeErrorMsg] = useState('');

    useEffect(() => {
        if (props.isVisualizza !== undefined) {
            setIsVisualizza(props.isVisualizza)
        }
    }, [props.isVisualizza])


    useEffect(() => {
        if (props.idProgettoFormativo !== undefined) {
            setIdProgetto(props.idProgettoFormativo);
            if (props.idProgettoFormativo !== -1) getTirocinanteByIdProgetto(props.idProgettoFormativo);
        }
    }, [props.idProgettoFormativo])


    useEffect(() => {
        elencoTitoliStudio();
        let annoFormativoTmp = getAnnoFormativo();
        setAnnoFormativo(annoFormativoTmp);
        setIsConfermaLoading(false);
    }, []);


    function closeDialog() {
        if (props.closeDialog)
            props.closeDialog()
    }

    function openAlertMsg(msg, severity) {
        setAlertMsg(msg)
        setAlertSeverity(severity)
        setAlertOpen(true)
    }

    function closeAlertConfirmInserimento() {
        setConfirmAlertIsOpen(false)
    }

    function validateFormData() {
        if (CF !== ''
            &&
            nomeAlunno !== ''
            &&
            cognomeAlunno !== ''
            &&
            dataNascitaAlunno !== ''
            &&
            (capResidenza !== '' && capResidenza.length === 5)
            &&
            indirizzoResidenzaNumero !== ''
            &&
            indirizzoResidenzaVia !== ''
            &&
            comuneResidenza !== ''
            &&
            nomeCorso !== ''
            &&
            (titoloStudioSelect !== null && idTitoloStudioSelect !== -1)
            && condizioniLavoro.some(c => c.stato === true)
        ) return false
        else return true
    }

    function changeCap(e) {
        let valueTmp = e.target.value
        let valueNoExtraCharactersTmp = valueTmp.replace(/[^0-9]/g, '');
        setCapResidenza(valueNoExtraCharactersTmp);
        if (valueNoExtraCharactersTmp.length === 5) {
            setCapSedeValid(true);
            setCapSedeErrorMsg('');
        } else {
            setCapSedeValid(false);
            setCapSedeErrorMsg('Il cap deve essere composto da 5 caratteri.');
        }
    }

    function changeComuneResidenza(e) {
        setComuneResidenza(e.target.value)
    }

    function changeIndirizzoResidenzaVia(e) {
        setIndirizzoResidenzaVia(e.target.value)
    }

    function changeIndirizzoResidenzaNumero(e) {
        setIndirizzoResidenzaNumero(e.target.value)
    }

    function changeTitoloStudio(e) {
        let valueTmp = e.target.value;
        setIdTitoloStudioSelect(valueTmp)
        if (valueTmp !== -1 && valueTmp !== null) {
            setIdTitoloStudioSelectValid(true)
        } else setIdTitoloStudioSelectValid(false)
        let titoloStudioSelectTmp = titoliStudioList.filter(titolo => titolo.id === valueTmp);
        setTitoloStudioSelect(titoloStudioSelectTmp[0]);
    }

    function changeCondizioniLavoro(condizioniLavoro) {
        setCondizioniLavoro(condizioniLavoro)
    }

    function confermaProsegui() {
        if (props.nextTab) props.nextTab()
    }

    function changeIdCorso(idCorsoTmp){
        if(props.changeIdCorso) props.changeIdCorso(idCorsoTmp)
    }

    async function getTirocinanteByIdProgetto(idProgetto) {
        let obj = {
            idProgetto: idProgetto
        }
        TirociniAPI.getTirocinanteByIdProgetto(obj)
            .then((result) => {
                let objProgetto = result;
                setIsCanEdit(objProgetto?.isEditable);
                setCondizioniLavoro([...objProgetto?.condizioniStudioLavoro])
                setNomeAlunno(objProgetto?.tirocinante?.nomeAlunno ?? '');
                setCognomeAlunno(objProgetto?.tirocinante?.cognomeAlunno ?? '');
                setCF(objProgetto?.tirocinante?.CF ?? '');
                setDataNascitaAlunno(objProgetto?.tirocinante?.dataNascita ?? '');
                setNomeCorso(objProgetto?.tirocinante?.nomeCorso ?? '');
                changeIdCorso(objProgetto?.idCorso ?? null);
                setComuneNascita(objProgetto?.tirocinante?.comuneNascita ?? '');
                setComuneResidenza(objProgetto?.tirocinante?.comuneResidenza ?? '');
                setIndirizzoResidenzaVia(objProgetto?.tirocinante?.indirizzoResidenzaVia ?? '');
                setIndirizzoResidenzaNumero(objProgetto?.tirocinante?.indirizzoResidenzaNumero ?? '');
                setCapResidenza(objProgetto?.tirocinante?.capResidenza ?? '');

                let valueNoExtraCharactersTmp = objProgetto?.tirocinante?.capResidenza.replace(/[^0-9]/g, '');
                if (valueNoExtraCharactersTmp !== "" && valueNoExtraCharactersTmp.length === 5) {
                    setCapSedeValid(true);
                    setCapSedeErrorMsg('');
                } else {
                    setCapSedeValid(false);
                    setCapSedeErrorMsg('Il cap deve essere composto da 5 caratteri.');
                }

                setTitoloStudioSelect(objProgetto?.tirocinante?.titoloStudio ?? null);
                setIdTitoloStudioSelect(objProgetto?.tirocinante?.titoloStudio?.id ?? -1)

            })
            .catch(function (error) {
            })

    }

    async function elencoTitoliStudio() {
        TirociniAPI.elencoTitoliStudio()
            .then((result) => {
                setTitoliStudioList([...result]);
            })
            .catch(function (error) {
            })
    }

    async function saveTirocinante() {
        setButtonConfermaDisabled(true);
        let obj = {
            "idProgetto": idProgetto,
            "tirocinante": {
                "nomeAlunno": nomeAlunno,
                "cognomeAlunno": cognomeAlunno,
                "CF": CF,
                "dataNascita": dataNascitaAlunno,
                "nomeCorso": nomeCorso,
                "comuneNascita": comuneNascita,
                "comuneResidenza": comuneResidenza,
                "indirizzoResidenzaVia": indirizzoResidenzaVia,
                "indirizzoResidenzaNumero": indirizzoResidenzaNumero,
                "capResidenza": capResidenza,
                "titoloStudio": titoloStudioSelect ?? null
            },
            "condizioniStudioLavoro": condizioniLavoro ?? null
        }
        TirociniAPI.saveTirocinante(obj)
            .then((result) => {
                let msgTmp = result.msg ?? 'Salvataggio effettuato con successo.';
                openAlertMsg(msgTmp, 'success');
                setButtonConfermaDisabled(false);
                confermaProsegui();
            })
            .catch(function (error) {
                if (error?.response !== undefined) {
                    let errorMsg = "Errore durante il salvataggio. Riprovare tra qualche istante.";
                    if (error.response.data && Array.isArray(error.response.data.data) && error.response.data.data.length > 0) {
                        errorMsg = error.response.data.data[0]?.msg ?? errorMsg;
                    }
                    openAlertMsg(errorMsg, 'error');
                } else {
                    openAlertMsg("Errore durante il salvataggio. Riprovare tra qualche istante.", "error");
                }
                setButtonConfermaDisabled(false);
            })

    }

    return (
        <Fragment>
            <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', maxHeight: { xs: '50vh', md: '60vh' }, overflowY: 'scroll', overflowX: 'hidden' }}>
                <Paper elevation={1} sx={{ p: 2, mt: isVisualizza ? 2 : 0 }}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
                        <Box display={'flex'} alignItems={'center'}>
                            <Typography textAlign={'start'} sx={{ fontWeight: 'bold' }}>Tirocinante</Typography>
                        </Box>
                    </Box>
                    <Grid container spacing={2} mt={2}>
                        <Grid xs={12} md={4}>
                            <TextField
                                defaultValue={nomeAlunno ?? ''}
                                value={nomeAlunno ?? ''}
                                label={"Nome"}
                                sx={{ width: '100%' }}
                                disabled
                            />
                        </Grid>
                        <Grid xs={12} md={4}>
                            <TextField
                                defaultValue={cognomeAlunno ?? ''}
                                value={cognomeAlunno ?? ''}
                                label={"Cognome"}
                                sx={{ width: '100%' }}
                                disabled
                            />
                        </Grid>
                        <Grid xs={12} md={4}>
                            <TextField
                                defaultValue={CF ?? ''}
                                value={CF ?? ''}
                                label={"C.F."}
                                sx={{ width: '100%' }}
                                disabled
                                inputProps={{ maxLength: 16 }}
                            />
                        </Grid>
                        <Grid xs={12} md={4}>
                            <TextField
                                defaultValue={dataNascitaAlunno ?? ''}
                                value={dataNascitaAlunno ?? ''}
                                label={"Data di nascita"}
                                sx={{ width: '100%' }}
                                disabled
                            />
                        </Grid>
                        <Grid xs={12} md={8}>
                            <TextField
                                disabled
                                defaultValue={nomeCorso}
                                value={nomeCorso}
                                label={'Corso'}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid xs={12} md={4}>
                            <TextField
                                error={comuneResidenza === ""}
                                defaultValue={comuneResidenza ?? ''}
                                value={comuneResidenza ?? ''}
                                required
                                onChange={changeComuneResidenza}
                                label={"Comune di residenza"}
                                sx={{ width: '100%' }}
                                disabled={!isCanEdit}
                            />
                        </Grid>
                        <Grid xs={12} md={2}>
                            <TextField
                                error={(!capSedeValid && capResidenza === '') || !capSedeValid}
                                helperText={capSedeErrorMsg}
                                defaultValue={capResidenza ?? ''}
                                value={capResidenza ?? ''}
                                required
                                label={"Cap"}
                                onChange={changeCap}
                                sx={{ width: '100%' }}
                                disabled={!isCanEdit}
                                inputProps={{ maxLength: 5 }}
                            />
                        </Grid>
                        <Grid xs={12} md={4}>
                            <TextField
                                error={indirizzoResidenzaVia === ""}
                                defaultValue={indirizzoResidenzaVia ?? ''}
                                value={indirizzoResidenzaVia ?? ''}
                                required
                                label={"Indirizzo"}
                                onChange={changeIndirizzoResidenzaVia}
                                sx={{ width: '100%' }}
                                disabled={!isCanEdit}
                            />
                        </Grid>
                        <Grid xs={12} md={2}>
                            <TextField
                                error={indirizzoResidenzaNumero === ""}
                                defaultValue={indirizzoResidenzaNumero ?? ''}
                                value={indirizzoResidenzaNumero ?? ''}
                                required
                                label={"n°"}
                                onChange={changeIndirizzoResidenzaNumero}
                                sx={{ width: '100%' }}
                                disabled={!isCanEdit}
                            />
                        </Grid>
                        <Grid xs={12} md={12}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel color={(titoloStudioSelect === null || idTitoloStudioSelect === -1) && !idTitoloStudioSelectValid ? 'error' : "primary"}>
                                    Titolo di studio *</InputLabel>
                                <Select
                                    label='Titolo di studio *'
                                    value={idTitoloStudioSelect ?? -1}
                                    onChange={changeTitoloStudio}
                                    disabled={!isCanEdit}
                                    error={(titoloStudioSelect === null || idTitoloStudioSelect === -1) && !idTitoloStudioSelectValid}
                                >
                                    <MenuItem
                                        key={-1}
                                        value={-1}
                                    >
                                        ...
                                    </MenuItem>
                                    {titoliStudioList.map((titolo, index) => (
                                        <MenuItem
                                            key={titolo?.id}
                                            value={titolo?.id}
                                        >
                                            {titolo?.descrizione}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={12}>
                            <TableCondizioniTirocinante
                                list={condizioniLavoro}
                                changeList={changeCondizioniLavoro}
                                isCanEdit={isCanEdit}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
            <Divider />
            <Box sx={{ px: { xs: 0, md: 2 }, py: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'end' } }}>
                <Button sx={{ width: { xs: 'unset', md: 'fit-content' }, mb: { xs: 1, md: 0 }, mx: { xs: 4, md: 1 } }}
                    onClick={closeDialog}
                    variant="outlined" fullWidth color={'error'} >{isCanEdit ? 'Chiudi procedura di inserimento' : 'Torna a gestione progetto formativo'}</Button>
                <Button endIcon={<ArrowForward />}
                    sx={{ width: { xs: 'unset', md: 'fit-content' }, mx: { xs: 4, md: 0 } }}
                    onClick={() => isCanEdit ? setConfirmAlertIsOpen(true) : confermaProsegui()}
                    disabled={validateFormData()}
                    variant="contained" fullWidth color={'success'} >{isCanEdit ? 'Conferma e prosegui' : 'Prosegui'}</Button>
            </Box>
            <DialogConferma
                isDialogOpen={confirmAlertIsOpen}
                title={'Conferma salvataggio'}
                icon={<Check sx={{ fontSize: '5rem', my: 2 }} color="success" />}
                text={`Confermi di voler salvare i dati indicati? `}
                showAlunni={false}
                confermaButton={'Conferma'}
                severity={'success'}
                closeDialog={closeAlertConfirmInserimento}
                conferma={saveTirocinante}
                disabledButton={buttonConfermaDisabled}
            />

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={10000} onClose={() => { setAlertOpen(false) }} position="fixed" sx={{ mt: '8vh', zIndex: '3000!important' }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>
        </Fragment >
    )
}