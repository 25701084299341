import { useState, useEffect } from "react"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography, useTheme, useMediaQuery, Skeleton, IconButton, Divider, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSearch, faList } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import CustomIconButton from "../../shared/customIconButton";
import { Archive, Delete, Download, Edit, FormatListBulleted, Info, List, Search, Start } from "@mui/icons-material";
import { convertDataDiNascita } from "../../shared/metodiDateUtility";
import { Fragment } from "react";

export default function TableProgettiFormativi(props) {

    const navigate = useNavigate();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [isLoading, setIsLoading] = useState(true);
    const [list, setList] = useState([]);

    const [codiceProgetto, setCodiceProgetto] = useState('');

    useEffect(() => {
        if (props.codiceProgetto) {
            setCodiceProgetto(props.codiceProgetto);
        }
    }, [props.codiceProgetto])

    useEffect(() => {
        if (props.list) {
            setList([...props.list]);
            setTimeout(() => {
                setIsLoading(false)
            }, 100);
        }
    }, [props.list])

    function deleteItem(idItem) {
        if (props.deleteItem) props.deleteItem(idItem)
    }

    function visualizzaItem(idItem, canEdit) {
        if (props.visualizzaItem) props.visualizzaItem(idItem, canEdit)
    }

    function navigateToGestioneConvenzioni(pIva) {
        navigate("/tirocini/gestione-convenzioni", { state: { pIva: pIva } })
    }

    function openLegendaDialog() {
        if (props.openLegendaDialog) props.openLegendaDialog();
    }
    function openDialogCambioStato(idItem, idStato) {
        if (props.openDialogCambioStato) props.openDialogCambioStato(idItem, idStato);
    }
    function openVariazioneDialog(idItem) {
        if (props.openVariazioneDialog) props.openVariazioneDialog(idItem);
    }

    function openElencoVariazioniDialog(idItem, codiceProgetto) {
        if (props.openElencoVariazioniDialog) props.openElencoVariazioniDialog(idItem, codiceProgetto);
    }


    function openDialogDownloadDocumenti(idItem) {
        if (props.openDialogDownloadDocumenti) props.openDialogDownloadDocumenti(idItem);
    }
    return (
        <Paper>
            <TableContainer sx={{ width: '100%' }} >
                <Table sx={{ maxHeight: '40vh' }}>
                    <TableHead>
                        <TableRow >

                            <TableCell align="center"
                                className=" colonna-nomi row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    // px: 1,
                                    minHeight: '40px',
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                }}>
                                    <Typography variant="body2" textAlign={"center"}><b>Cod. Progetto</b></Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className=" colonna-nomi row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    // px: 1,
                                    minHeight: '40px',
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                }}>
                                    <Typography variant="body2" textAlign={"center"}><b>Rag. Sociale Azienda</b></Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className=" row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    // px: 1,
                                    minHeight: '40px',
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                }}>
                                    <Typography variant="body1" textAlign={"center"}><b>Stato</b></Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className="row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    // px: 1,
                                    minHeight: '40px',
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                }}>
                                    <Typography variant="body2" textAlign={"center"}><b>Data inserimento</b></Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className=" row-head" sx={{ p: 0, minWidth: '100px', borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    // borderBottom: '1px solid rgba(224, 224, 224, 1)',
                                    backgroundColor: 'white',
                                    // px: 1,
                                    minHeight: '40px',
                                    display: 'flex', flexDirection: 'column', justifyContent: 'center'
                                }}>
                                    <Typography variant="body2" textAlign={"center"}><b>Alunno</b></Typography>
                                </Box>
                            </TableCell>
                            <TableCell align="center"
                                className=" row-head" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)', borderBottom: '1px solid rgba(224, 224, 224, 1)', zIndex: 5 }}
                            >
                                <Box sx={{
                                    p: 1,
                                    backgroundColor: 'white',
                                    // px: 1,
                                    display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: 'center'
                                }}>
                                    <Typography variant={mobile ? "caption" : "body1"} textAlign={"center"}><b>Azioni</b></Typography>
                                    <Button onClick={openLegendaDialog} variant="outlined" size="small" sx={{ ml: 1 }} startIcon={<Info />}>Legenda</Button>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {list.map((item, index) => (
                            <TableRow style={{ height: '40px' }} key={item?.id}>

                                <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }} >
                                            <Typography variant={mobile ? 'caption' : 'body2'} sx={{ width: '100%', textAlign: "center", fontWeight: 'bold' }}>
                                                {(item?.codiceProgetto ?? '').toUpperCase()}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                                <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }} >
                                            <Typography variant={mobile ? 'caption' : 'body2'} sx={{ width: '100%', textAlign: "center" }}>
                                                {(item?.ragioneSocialeAzienda ?? '')}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                                <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{ height: '100%' }} >
                                            <Typography variant={mobile ? 'caption' : 'body2'} sx={{ textAlign: "center" }}>
                                                {(item?.stato ?? '').toUpperCase()}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>

                                <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }} >
                                            <Typography variant={mobile ? 'caption' : 'body2'} sx={{ width: '100%' }} textAlign={'center'}>
                                                {convertDataDiNascita(item?.dataInserimento ?? '')}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                                <TableCell sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    {!isLoading ? (
                                        <Box display={"flex"} alignItems={"center"} sx={{ height: '100%' }} >
                                            <Typography variant={mobile ? 'caption' : 'body2'} sx={{ ml: 2 }}>
                                                {(item?.cognomeAlunno ?? '').toUpperCase() + " " + (item?.nomeAlunno ?? '').toUpperCase()}
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Skeleton sx={{ mx: 2 }} />
                                    )}
                                </TableCell>
                                <TableCell className="colonna-nomi" sx={{ p: 0, borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                    <Box sx={{ backgroundColor: 'white', px: 1, py: 1, display: 'flex', justifyContent: 'center' }}>

                                        <CustomIconButton variant="outlined" icon={<Search color="primary" />} color="primary" buttonClicked={() => visualizzaItem(item?.id)} />
                                        {(item?.canEditStato || item?.canArchive || item?.showVariazioni || (item.stato === "Completato" || item.stato === "In attuazione" || item.stato === "Concluso" || item.stato === "Archiviato")) && (
                                            <Divider sx={{ mx: 1 }} orientation="vertical" variant="middle" flexItem />
                                        )}
                                        {(item.stato === "Documentazione generata" || item.stato === "In attuazione" || item.stato === "Concluso" || item.stato === "Archiviato") && (
                                            <CustomIconButton buttonClicked={() => openDialogDownloadDocumenti(item?.id)} variant="contained" icon={<Download sx={{ color: 'white' }} />} color="success" mr={1} />
                                        )}
                                        {item?.canEditStato && (
                                            <>
                                                {item.stato === "Completato" && (
                                                    <CustomIconButton variant="outlined" icon={<Start color="success" />} color="success" mr={1} buttonClicked={() => openDialogCambioStato(item?.id, 6)} />
                                                )}
                                                {item.stato === "Documentazione generata" && (
                                                    <CustomIconButton variant="outlined" icon={<Start color="success" />} color="success" mr={1} buttonClicked={() => openDialogCambioStato(item?.id, 3)} />
                                                )
                                                }
                                            </>
                                        )}
                                        {item?.canArchive && (<CustomIconButton variant="outlined" icon={<Archive color="error" />} color="error" mr={1} buttonClicked={() => openDialogCambioStato(item?.id, 5)} />)}
                                        {(item?.showVariazioni && (item?.stato === "In attuazione" || item.stato === "Documentazione generata")) && (<CustomIconButton variant="outlined" icon={<Edit color="secondary" />} color="secondary" mr={1} buttonClicked={() => openVariazioneDialog(item?.id)} />)}
                                        {/* {item?.showVariazioni && (<CustomIconButton variant="outlined" icon={<Edit color="secondary" />} color="secondary" mr={1} buttonClicked={() => openVariazioneDialog(item?.id)} />)} */}
                                        {item?.existVariazioni && (<CustomIconButton variant="outlined" icon={<List color="secondary" />} color="secondary" mr={1} buttonClicked={() => openElencoVariazioniDialog(item?.id, item?.codiceProgetto)} />)}

                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}